import { fontType, toRem } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'
import styled from 'styled-components'

export const GoBackWrapper = styled.nav`
	${fontType(0)}
	a {
		text-decoration: none;
		width: ${toRem(200)};
	}

	.header {
		display: flex;
		align-items: center;
		margin-bottom: 32px;
		cursor: pointer;

		.title {
			margin-left: 6px;
			color: ${colors.secondary};
			font-size: ${toRem(12)};
		}
	}
`
