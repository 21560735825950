import React from 'react'

import MenuHeader from './MenuHeader'
import MenuOption from './MenuOption'
import { IViewProps } from './types'
import { MenuWrapper } from './styles'

export default function MenuPrimaryView(props: IViewProps): JSX.Element {
	const {
		menuData,
		toggleSelected,
		toggleExpanded,
		handleOptionUpdate,
		showChevron,
		styles,
		renderSwitch,
		toggleGeneralPermission
	} = props

	const optionStyles = {
		...styles,
		width: '100%',
		height: styles.optionsHeight
	}

	return (
		<MenuWrapper>
			<MenuHeader
				label={menuData.label}
				selected={menuData.selected}
				expanded={menuData.expanded}
				isGeneralPermission={menuData.generalPermission}
				onSelect={toggleSelected}
				onExpand={toggleExpanded}
				onGeneralPermission={toggleGeneralPermission}
				styles={optionStyles}
				showChevron={menuData.options.length > 0}
				renderSwitch={renderSwitch}
			/>

			{menuData.expanded ? (
				<div className='menu-options-wrapper'>
					{menuData.options.map((option) => {
						return option.visible ? (
							<>
								<MenuOption
									key={option.value}
									id={option.value}
									label={option.label}
									selected={option.selected}
									onSelect={handleOptionUpdate}
									styles={{
										...optionStyles,
										checkboxLevel: optionStyles.checkboxLevel + 1
									}}
								/>
							</>
						) : null
					})}
				</div>
			) : null}
		</MenuWrapper>
	)
}
