import React, { useState, useEffect, ChangeEvent, createElement } from 'react'
import { IViewProps } from './types'
import { ExamDiagnosisEnum, IPhysicianDiagnoses } from 'shared/interfaces/exam'
import CheckBoxDiagnosis from 'shared/components/CheckBoxDiagnosis'
import { screenMessageExam } from '../types'
import { Button } from '@buildbox/components'
import { primaryButtonStyle, secondaryButtonStyle } from 'shared/styles/theme'
import { friendlyDiagnosis } from 'shared/util/friendlyRole'
import { InfoExamWrapper } from './styles'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { fixedPercentage, formatDate } from 'shared/util/format'
import { findUser } from 'shared/services/user.service'
import { IUser } from 'shared/interfaces/user'

function InfoExam(props: IViewProps): JSX.Element {
	const {
		exam,
		imageInfoCircle,
		image,
		btnExportClicked,
		handleSubmit,
		returnReport
	} = props

	const { user } = useTypedSelector(['user'])

	const [checkboxState, setCheckBoxState] = useState({
		name: lastReportUpdated().diagnosis,
		checked: lastReportUpdated().diagnosis ? true : false
	})
	const [diagnosisReport, setDiagnosisReport] = useState<IPhysicianDiagnoses>(
		{} as IPhysicianDiagnoses
	)
	const [enableBtn, setEnableBtn] = useState(false)

	function lastReportUpdated(): IPhysicianDiagnoses {
		if (!exam?.physicianDiagnoses?.length) {
			return {
				date: new Date(),
				observation: '',
				diagnosis: 'NONE',
				physician: ''
			}
		}

		return exam?.physicianDiagnoses?.reduce((a, b) => {
			return new Date(a.date) > new Date(b.date) ? a : b
		})
	}
	function getLastDiagnosis() {
		if (!exam.physicianDiagnoses?.length) return

		const lastReport = lastReportUpdated()
		;(async () => {
			try {
				const physician = await findUser(lastReport?.physician as string)
				setDiagnosisReport({ ...lastReport, physician: physician })
			} catch (err) {
				console.log(err)
			}
		})()
	}

	function diagnosisSelected(diagnosisSelected: ExamDiagnosisEnum) {
		return diagnosisReport.diagnosis === diagnosisSelected
	}

	function noAllowedToEdit() {
		const report = lastReportUpdated()
		if (!exam.physicianDiagnoses?.length) return false
		return report?.physician !== user._id
	}

	function handleCheckbox(selectDiagnosis: ExamDiagnosisEnum) {
		if (noAllowedToEdit()) return
		setCheckBoxState({
			name: selectDiagnosis,
			checked: true
		})
	}

	function handleReturn() {
		if (!btnExportClicked) {
			setEnableBtn(false)
			return
		}
		setCheckBoxState({
			name: diagnosisReport.diagnosis,
			checked: true
		})
	}

	function enableButton(): boolean {
		const report = lastReportUpdated()
		if (
			!exam.physicianDiagnoses?.length &&
			report.diagnosis !== checkboxState.name
		) {
			return true
		}
		if (report?.physician !== user._id) return false
		if (report?.observation !== diagnosisReport.observation) return true
		if (checkboxState.name !== diagnosisReport.diagnosis) return true

		return false
	}

	function handleClick() {
		returnReport({
			diagnosis: checkboxState.name,
			observation: diagnosisReport?.observation || '',
			physician: user._id,
			date: new Date()
		})

		handleSubmit()
	}
	function handleObservation(event: ChangeEvent<HTMLTextAreaElement>) {
		setDiagnosisReport({ ...diagnosisReport, observation: event.target.value })
	}

	useEffect(handleReturn, [btnExportClicked])

	useEffect(() => {
		setCheckBoxState({
			name: lastReportUpdated().diagnosis,
			checked: true
		})
		getLastDiagnosis()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exam.physicianDiagnoses])

	useEffect(() => {
		const enable = enableButton()
		setEnableBtn(enable)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkboxState.name, diagnosisReport])

	function renderPercentage(diagnosisProba: number): any {
		return <strong>{fixedPercentage(diagnosisProba * 100, 2)}%</strong>
	}

	return (
		<InfoExamWrapper id='page-2'>
			<div
				className={`info-exam background-box ${
					btnExportClicked ? 'exported' : 'not-exported'
				}`}
			>
				<h1>{screenMessageExam.titleImageExam}</h1>

				<figure>
					<img
						src={image || exam.image}
						alt='Exame Capturado'
						id='img-report'
					/>
				</figure>
			</div>

			<div
				className={`info-exam background-box ${
					btnExportClicked ? 'exported' : 'not-exported'
				}`}
			>
				<h1>{screenMessageExam.infoMedicalReport}</h1>

				{diagnosisReport?.date && diagnosisReport.physician && (
					<span className='report'>
						<img src={imageInfoCircle} alt='Icone de Informação' />{' '}
						<p>
							Parecer médico feito em{' '}
							{formatDate(new Date(diagnosisReport.date))} pelo profissional{' '}
							{(diagnosisReport.physician as IUser).name}
						</p>
					</span>
				)}

				<div className='box-medical-report'>
					<span>
						<h2>{screenMessageExam.questionMedicalReport}</h2>
						<p className='font-regular'>
							{screenMessageExam.questionResultDiagnosis}
						</p>
					</span>

					<div className='checkbox'>
						<CheckBoxDiagnosis
							className={
								diagnosisSelected('SUSPICIOUS')
									? ''
									: noAllowedToEdit()
									? 'display-none'
									: 'hidden'
							}
							checkboxState={
								btnExportClicked
									? { checked: true, name: 'SUSPICIOUS' }
									: checkboxState
							}
							handleCheckbox={() => handleCheckbox('SUSPICIOUS')}
							type={'SUSPICIOUS'}
							disable={noAllowedToEdit()}
						/>
						<CheckBoxDiagnosis
							className={
								diagnosisSelected('NOT_SUSPICIOUS')
									? ''
									: noAllowedToEdit()
									? 'display-none'
									: 'hidden'
							}
							checkboxState={
								btnExportClicked
									? { checked: true, name: 'NOT_SUSPICIOUS' }
									: checkboxState
							}
							handleCheckbox={() => handleCheckbox('NOT_SUSPICIOUS')}
							type={'NOT_SUSPICIOUS'}
							disable={noAllowedToEdit()}
						/>
					</div>
				</div>

				<div className='textarea'>
					<p
						className={`placeholder ${
							btnExportClicked
								? ''
								: noAllowedToEdit()
								? 'not-allowed'
								: 'allowed'
						}`}
					>
						OBSERVAÇÕES
						<br />
					</p>

					{btnExportClicked ? (
						<div className='box-textarea' style={{ fontFamily: 'sans-serif' }}>
							{diagnosisReport.observation}
						</div>
					) : (
						<>
							<textarea
								name='medical-report'
								id='medical-report'
								className='box-textarea'
								onChange={handleObservation}
								value={diagnosisReport.observation}
								disabled={noAllowedToEdit()}
								maxLength={1200}
								rows={12}
							></textarea>

							<p className='limit-characters'>
								{diagnosisReport.observation?.length || 0}/1200
							</p>
						</>
					)}
				</div>

				<Button
					className={`center hidden ${
						diagnosisReport.diagnosis === 'NONE' ? 'btn-edit-report' : ''
					} `}
					{...(diagnosisReport.diagnosis === 'NONE'
						? { ...secondaryButtonStyle }
						: { ...primaryButtonStyle })}
					onClick={handleClick}
					disabled={!enableBtn}
				>
					{diagnosisReport.diagnosis === 'NONE'
						? 'SALVAR ANÁLISE'
						: 'EDITAR ANÁLISE'}
				</Button>
			</div>

			{exam.diagnosis === 'NONE' ? null : (
				<div className='result-exam'>
					<div className='flex'>
						<h2>{screenMessageExam.titleResultExam}</h2>
						<span
							className={
								exam.diagnosis === 'SUSPICIOUS'
									? 'suspicious'
									: 'not-suspicious'
							}
						>
							{friendlyDiagnosis[exam.diagnosis]}
						</span>
					</div>

					{exam.diagnosis === 'SUSPICIOUS' ? (
						<>
							<p className='result-text'>
								Há {renderPercentage(exam.diagnosisProba)} de indicação para a{' '}
								<strong>
									existência de uma suspeita de lesão na mama da paciente
								</strong>{' '}
								que precisa ser investigada por exames complementares.
							</p>
							<p className='result-text-warning'>
								A análise acima é apenas um indicativo sem valor clínico ou
								médico e não deve ser usada de forma isolada e nem como forma de
								diagnóstico. Esse relatório é para simples referência e não
								possui valor de laudo médico.
							</p>
						</>
					) : (
						<>
							<p className='result-text'>
								Há {renderPercentage(exam.diagnosisProba)} de indicação para a{' '}
								<strong>
									não existência de uma suspeita de lesão na mama da paciente.
								</strong>{' '}
								Isso, não elimina a necessidade da paciente ser submetida ao
								exame anual de rastreamento através da mamografia, conforme
								indicação do Ministério da Saúde.
							</p>
							<p className='result-text-warning'>
								A análise acima é apenas um indicativo sem valor clínico ou
								médico e não deve ser usada de forma isolada e nem como forma de
								diagnóstico. Esse relatório é para simples referência e não
								possui valor de laudo médico.
							</p>
						</>
					)}
				</div>
			)}
		</InfoExamWrapper>
	)
}

export default InfoExam
