import { SelectOption } from '@buildbox/components'
import { ScreenActionsEnum } from 'modules/UsersPage/types'
import { ChangeEvent, FormEvent } from 'react'
import { IAuthenticationModalProps } from 'shared/components/AuthModal/types'
import {
	IModalDeleteTarget,
	TargetTypesEnum
} from 'shared/components/ModalDelete/types'
import { UserProfileContract } from 'shared/interfaces/user'

export interface IViewProps {
	profileOptions: SelectOption[]
	handleChange(event: ChangeEvent<HTMLInputElement>): void
	dataUser: IDataUser
	onChangeProfileAndAccess: (
		index: number,
		updatedProfile: ProfileAndAccess
	) => void
	addProfileAndAccess: () => void
	removeProfile: (index: number, e?: FormEvent<HTMLButtonElement>) => void
	profileAndAccess: ProfileAndAccess[]
	showProfile: boolean
	handleBtnNext: () => void
	enableBtnNext: boolean
	handleSubmit: () => void
	action: ScreenActionsEnum
	renderButtonDelete: () => JSX.Element | null
	targetUser: IModalDeleteTarget | null
	handleDeleteOnClose: () => void
	enableBtnSubmit: boolean
	loading: boolean
	loadingSubmit: boolean
	CRMError: string
	stateError: string
	handleStateChange: (state: SelectOption | null) => void
	hasPhysician: boolean
	authModalConfig: IAuthenticationModalProps
	handleOpenAuthModal(): void
	handleSubmitFunction(): void
	handleSubmitDelete(): void
	isDelete: boolean
	handleAuthSubmitDelete(): void
}
export interface IDataUser {
	name: string
	personalFiscalID: string
	email: string
	crm?: string
	state?: string
}

export interface ProfileAndAccess {
	// id: string
	profile: SelectOption
	contracts: UserProfileContract[] | null
}
export interface IProfilesSelected {
	index: number
	profileId: string
}

export const INITIAL_DATA_USER: IDataUser = {
	name: '',
	email: '',
	personalFiscalID: ''
}

export const INITIAL_DATA_ACCESS: ProfileAndAccess = {
	profile: {
		label: 'Selecione um Perfil',
		value: ''
	},
	contracts: null
}

export const titleScreen = {
	CREATE: 'Cadastrar Novo Usuário',
	EDIT: 'Dados do Usuário '
}

export const textButton = {
	CREATE: 'Cadastrar',
	EDIT: 'Salvar alterações'
}

// export interface IAccess {
// 	profile: ProfileNameEnum
// 	contracts: string[]
// }
// export interface ICreateUser {
// 	_id: string
// 	name: string
// 	email: string
// 	documentNumber?: string
// 	access: Array<IAccess>
// }
