import styled from 'styled-components'

export const MenuWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 4px;

	.menu-options-wrapper {
		//		margin-bottom: 8px;
	}
`
