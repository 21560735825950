import { Button } from '@buildbox/components'
import React from 'react'
import { IKitPaginated, KitStatus } from 'shared/interfaces/kit'
import { primaryButtonStyle } from 'shared/styles/theme'
import { formatDate } from 'shared/util/format'
import { translateStatus } from 'shared/util/translate'
import { ScreenActionsEnum } from './types'

export function renderAssociated(kit: IKitPaginated): JSX.Element {
	const styleStatus =
		kit.status === KitStatus.PENDING
			? 'status statusPending'
			: 'status statusDisassociated'

	if (kit.status !== KitStatus.ACTIVE) {
		return (
			<div className={styleStatus}>
				<p>{translateStatus[kit.status]}</p>
			</div>
		)
	}

	return <p>{kit?.activatedAt && formatDate(new Date(kit?.activatedAt))}</p>
}

export function renderBtnExportExcel(onClick: () => void) {
	return (
		<Button
			className='btn-export'
			onClick={onClick} // handleExportExcel
			{...primaryButtonStyle}
		>
			Exportar excel
		</Button>
	)
}
export function renderEditIcon(
	kitId: string,
	onClick: (action: ScreenActionsEnum, id: string) => void
): JSX.Element {
	// activateScreenUser()
	return (
		<button className='icon' onClick={() => onClick('EDIT', kitId)}>
			<img
				className='img'
				src={require('../../assets/images/dots.svg')}
				alt='Editar'
			/>
		</button>
	)
}
