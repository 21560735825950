import { IViewErrorProps } from './types'
import { ViewErrosWrapper } from './styles'
import React from 'react'

function ViewErrors({ className, errors, color }: IViewErrorProps) {
	return (
		<ViewErrosWrapper className={className}>
			{!!errors.length && (
				<div className='view-errors'>
					{errors.map((error, index) => (
						<p
							key={`error-${index}`}
							style={{ color: color }}
							className='error-text'
						>
							* {error}
						</p>
					))}
				</div>
			)}
		</ViewErrosWrapper>
	)
}

export default ViewErrors
