import { toRem } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'
import styled from 'styled-components'

export const DeleteContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const TextDelete = styled.p`
	font-size: ${toRem(14)};
	color: ${colors.darkGrey};
	text-align: center;
`

export const TargetName = styled.p`
	font-size: ${toRem(14)};
	color: ${colors.darkGrey};
	margin-bottom: ${toRem(40)};
	text-align: center;
`
export const LoadingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
