import React, { createElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import SideNav from 'shared/components/SideNav'
import { Container, FullContainer } from 'shared/styles'
import { routesData } from 'modules/Routes/data'
import ContractFilter from 'modules/ContractFilter'
import Header from '../Header'
import { PageWrapper } from './styles'
import { IProps } from './types'
import SelectProfile from '../SelectProfile'

export default function Page({
	hasFilter,
	hasSelectProfile,
	children,
	fullPage = false,
	hiddenHeader
}: IProps): JSX.Element {
	const history = useHistory()
	const [isOpen, setIsOpen] = useState(false)
	const { user } = useTypedSelector(['user'])

	function handleSetIsOpen() {
		setIsOpen(!isOpen)
	}
	const userType = user.profiles
	if (!userType) history.push('/login')

	const name = user.name

	const headerFilterProps = {
		isOpen,
		handleSetIsOpen,
		filter: hasFilter ? createElement(ContractFilter) : null,
		selectProfile: hasSelectProfile ? createElement(SelectProfile) : null
	}

	const sideNavProps = {
		isOpen,
		handleSetIsOpen,
		routes: routesData,
		name
	}

	return (
		<PageWrapper>
			{!hiddenHeader && <Header {...headerFilterProps} />}
			<SideNav {...sideNavProps} />

			<div className={hiddenHeader ? 'container-page' : 'container-wrapper'}>
				{fullPage ? (
					<FullContainer>{children}</FullContainer>
				) : (
					<Container>{children}</Container>
				)}
			</div>
		</PageWrapper>
	)
}
