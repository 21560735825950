import React from 'react'
import DatePicker from 'shared/components/DatePicker'
import PageTitle from 'shared/components/PageTitle'
import { Column, Row } from 'shared/styles'
import { DashboardWrapper, LoadingContainer } from './styles'
import { IViewProps } from './types'
import arrowDown from '../../assets/images/arrow-down.svg'
import arrowUp from '../../assets/images/arrow-up.svg'
import { PieGraph } from 'shared/components/PieGraph'
import BarGraph from 'shared/components/BarGraph'
import { colors, tableStyle } from 'shared/styles/theme'
import Table from 'shared/components/Table'
import { GoogleMaps } from 'shared/components/GoogleMapsApi'

import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import { ILatLng, IMarkers } from 'shared/components/GoogleMapsApi/types'
import { Loading } from '@buildbox/components'

function DashboardPage(props: IViewProps): JSX.Element {
	const {
		handleSetDates,
		selectedDates,
		tableColumns,
		tableRows,
		centerMap,
		markersMap,
		graphicBarData,
		graphicPieData,
		totalExams,
		neverHadMammogram,
		handleShowDates,
		zoomMap,
		dashboardStatus,
		liveStatus
	} = props

	function renderInfoTotal(title: string, value: string | number) {
		const convertNumber = Number(value)
		return (
			<div className='flex box-info-data background-graph'>
				<h1>{title}</h1>
				<span className='flex'>
					<img src={convertNumber > 0 ? arrowUp : arrowDown} alt='Arrow' />
					<h2 style={{ color: convertNumber > 0 ? '#0B1A9B' : '#B5B3B8' }}>
						{value}
					</h2>
				</span>
			</div>
		)
	}

	function renderGoogleMaps(centerMap: ILatLng, markersMap: IMarkers[]) {
		return (
			<GoogleMaps valueCenter={centerMap} markers={markersMap} zoom={zoomMap} />
		)
	}

	return (
		<DashboardWrapper className='animated-left'>
			<PageTitle>{`Ao Vivo`}</PageTitle>

			{liveStatus === 'loading' && (
				<LoadingContainer>
					<Loading primaryColor={colors.secondary} type='Sentry' />
				</LoadingContainer>
			)}
			<Row>
				<Column sm='12' lg='7'>
					<section className='section-map'>
						{renderGoogleMaps(centerMap, markersMap)}
					</section>
				</Column>

				<Column sm='12' lg='5'>
					{graphicBarData.length ? (
						<section className='background-graph'>
							<p className='title'>Últimas 4h</p>

							<div className='box-graphic-bar'>
								<BarGraph data={graphicBarData} />
							</div>
						</section>
					) : (
						<DefaultPagePlaceholder
							text='No momento, não há Dados para serem exibidos.'
							className='background-graph box-graphic-bar'
							marginTop={0}
						/>
					)}
				</Column>
			</Row>

			<div className='flex period'>
				<PageTitle>Histórico</PageTitle>

				<DatePicker
					dateSetter={handleSetDates}
					value={selectedDates}
					datePickerTitle='Período'
					setShowDates={handleShowDates}
				/>
			</div>

			{dashboardStatus === 'loading' && (
				<LoadingContainer>
					<Loading primaryColor={colors.secondary} type='Sentry' />
				</LoadingContainer>
			)}

			<Row style={{ marginBottom: 56 }}>
				<Column sm='12' lg='6'>
					<div className='box-graphic-pie background-graph'>
						{graphicPieData.length ? (
							<PieGraph data={graphicPieData} />
						) : (
							<DefaultPagePlaceholder
								text='No momento, não há Dados para serem exibidos.'
								marginTop={0}
								className='placeholder'
							/>
						)}
					</div>
				</Column>

				<Column sm='12' lg='6'>
					{renderInfoTotal('Total de Exames', totalExams)}
					{renderInfoTotal(
						'Nunca fizeram Mamografia',
						neverHadMammogram || '0.0 %'
					)}
				</Column>
			</Row>

			<div className='table-dashboard'>
				<Table
					columns={tableColumns as any}
					data={tableRows}
					pageSize={tableRows.length}
					{...tableStyle}
					padding={[26, 16, 26, 16]}
					isLoading={dashboardStatus === 'loading'}
					noItensText='No momento, não há Dados para serem exibidos.'
					total={true}
					showPaginate={false}
					dataSize={tableRows.length - 1}
					totalDocsNumber={tableRows.length - 1}
				/>
			</div>
		</DashboardWrapper>
	)
}
export default DashboardPage
