import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { redirectRoute } from 'shared/util/redirect'
import { routesData } from './data'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import Analytics from 'shared/components/Analytics'
import Page from 'shared/components/Page'
import React, { createElement, useEffect } from 'react'
import { PermissionEnum } from 'shared/interfaces/user'

import useUpdateFilterOptions from 'shared/hooks/useUpdateFilterOptions'
import useUpdateUserProfiles from 'shared/hooks/useUpdateProfile'

function Routes(): JSX.Element {
	const { user, profile, token } = useTypedSelector([
		'user',
		'profile',
		'token'
	])

	const role = user.permissions?.map((item) => item)

	function userHasPermission(accessTypes: PermissionEnum): boolean {
		if (!accessTypes || accessTypes.length === 0) return true
		if (!role?.length) return false

		const hasPermission = role?.includes(accessTypes)

		return !!hasPermission
	}

	useUpdateFilterOptions()
	useUpdateUserProfiles()

	return (
		<BrowserRouter>
			<Analytics />
			<Switch>
				{routesData.map(
					({
						accessTypes,
						component,
						path,
						hasFilter,
						hasSelectProfile,
						noWrapper,
						hiddenHeader,
						fullPage,
						...props
					}) => {
						const isPublic = !accessTypes
						if (accessTypes && userHasPermission(accessTypes)) {
							return (
								<Route key={path} exact path={path}>
									{noWrapper ? (
										<>{createElement(component, props)}</>
									) : (
										<Page
											hasFilter={!!hasFilter}
											hiddenHeader={!!hiddenHeader}
											hasSelectProfile={!!hasSelectProfile}
											fullPage={fullPage}
											accessTypes={accessTypes}
										>
											{createElement(component, props)}
										</Page>
									)}
								</Route>
							)
						} else if (isPublic) {
							return (
								<Route key={path} exact path={path}>
									{createElement(component, props)}
								</Route>
							)
						} else {
							return null
						}
					}
				)}

				<Redirect
					from={'*'}
					to={redirectRoute(role || [], token.accessToken)}
				/>
			</Switch>
		</BrowserRouter>
	)
}

export default React.memo(Routes)
