import React from 'react'
import { FloatLabelInput } from '@buildbox/components'
import { primaryInputStyle } from 'shared/styles/theme'
import { InputCalendarWrapper } from './styles'
import iconCalendar from '../../../assets/images/calendar.svg'
import { IInputCalendarProps } from './types'

export function InputCalendar({
	id,
	label,
	value,
	onFocus,
	...props
}: IInputCalendarProps) {
	return (
		<InputCalendarWrapper>
			<FloatLabelInput
				id={id}
				label={label}
				value={value}
				placeholder={label}
				onFocus={onFocus}
				onChange={() => props.onChange}
				{...primaryInputStyle}
			/>
			<figure className='icon-calendar'>
				<img src={iconCalendar} alt='Icon Calendar' />
			</figure>
		</InputCalendarWrapper>
	)
}
