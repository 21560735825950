import React from 'react'
import { isThisMonth, startOfMonth, endOfMonth } from 'date-fns'
import { Modal } from '@buildbox/components'
import { HeaderContainer, ModalContainer } from './styles'
import { IViewProps } from './types'
import { KitInfo } from './KitInfo'
import { toMoney } from 'shared/util/currency'
import { formatDate } from 'shared/util/format'

function ModalKitsLogs(props: IViewProps): JSX.Element {
	const { isActive, onClose, kit, period } = props
	function returnInfoText(title: string, subtitle: string | number) {
		return (
			<div>
				<strong>{title}</strong>
				<p>{subtitle}</p>
			</div>
		)
	}

	function isMonthSelectedThisMonth() {
		return isThisMonth(returnDate())
	}

	function returnDate(): Date {
		return new Date(period.year, period.month)
	}

	function renderPeriod() {
		const end = formatDate(
			isMonthSelectedThisMonth() ? new Date() : endOfMonth(returnDate())
		)
		return `${formatDate(startOfMonth(returnDate()))} - ${end}`
	}

	return (
		<Modal
			isActive={isActive}
			onClose={onClose}
			title={`${kit.name} - Periodo ${renderPeriod()}`}
			size={740}
			className='modal-primary'
		>
			<ModalContainer>
				{kit?.unit && (
					<HeaderContainer>
						{returnInfoText('UNIDADE', kit.unit.name)}
						{returnInfoText('KITS', kit.unit.kitsActive)}
						{returnInfoText(
							'VALOR TOTAL',
							toMoney(kit.unit.totalSubscriptions)
						)}
					</HeaderContainer>
				)}

				{kit?.unit && <KitInfo logs={kit?.unit.kits} />}
			</ModalContainer>
		</Modal>
	)
}

export default ModalKitsLogs
