import { createElement, useEffect, useState } from 'react'
import { EntityTypeEnum } from 'shared/interfaces/contract'
import { convertNumberToString } from 'shared/util/format'
import { validateDuration } from 'shared/util/validateFields'
import { ICheckBoxState, IProps, IViewProps } from './types'

import FormData from './view'

function FormDataContainer(props: IProps): JSX.Element {
	const {
		handleChange,
		contract,
		handleCompanyType,
		renderUpload,
		action,
		removeUpload,
		handleSetDate,
		checkboxTablet,
		handleCheckboxTablet,
		handleSelectChange
	} = props

	const [checkboxState, setCheckBoxState] = useState<ICheckBoxState>({
		name: contract.entityType,
		checked: contract.entityType ? true : false
	})
	const [duration, setDuration] = useState<string | number>(
		contract.contractMonthLength
	)

	function handleCheckbox(id: EntityTypeEnum) {
		setCheckBoxState({
			name: id,
			checked: true
		})

		handleCompanyType(id)
	}

	function formatedDate(date: Date) {
		if (!date) return ''

		const day = date.getDate().toString().padStart(2, '0'),
			month = (date.getMonth() + 1).toString().padStart(2, '0'),
			year = date.getFullYear()
		return day + '/' + month + '/' + year
	}

	function formatTextDuration() {
		const isValid = validateDuration(contract.contractMonthLength)
		if (!isValid) return

		contract.contractMonthLength === 1
			? setDuration(`${contract.contractMonthLength} mês`)
			: setDuration(`${contract.contractMonthLength} meses`)
	}

	function convertDurationInNumber() {
		if (!duration) return

		if (typeof duration === 'string') {
			const durationNumber = parseInt([duration][0])

			setDuration(convertNumberToString(durationNumber))
		}
	}

	useEffect(() => {
		setCheckBoxState({
			name: contract.entityType,
			checked: contract.entityType ? true : false
		})
	}, [contract.entityType])

	useEffect(() => {
		setDuration(convertNumberToString(contract.contractMonthLength))
	}, [contract.contractMonthLength])

	useEffect(() => {
		if (action === 'EDIT') formatTextDuration()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [action])

	const viewProps: IViewProps = {
		handleChange,
		contract,
		handleCompanyType,
		renderUpload,
		action,
		removeUpload,
		handleSetDate,
		checkboxTablet,
		handleCheckboxTablet,
		checkboxState,
		handleCheckbox,
		formatedDate,
		formatTextDuration,
		convertDurationInNumber,
		duration,
		handleSelectChange
	}

	return createElement(FormData, viewProps)
}

export default FormDataContainer
