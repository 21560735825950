import { ChangeEvent } from 'react'
import { SelectOption } from '@buildbox/components'

import { IUnit, StateFromBrazil } from 'shared/interfaces/unit'
import { ScreenActionsEnum } from 'modules/UnitsPage/types'
import { IModalDeleteTarget } from 'shared/components/ModalDelete/types'
import { EntityTypeEnum } from 'shared/interfaces/contract'
import { IAuthenticationModalProps } from 'shared/components/AuthModal/types'

export interface IViewProps {
	dataUnit: IUnit
	contractsOptions: SelectOption[]
	contractSelected: SelectOption | null
	handleSelectContract: (value: any) => void
	action: ScreenActionsEnum
	renderButtonDelete: () => JSX.Element
	targetUnit: IModalDeleteTarget | null
	enableBtnSubmit: boolean
	viewErrors: string[]
	isLoading: boolean
	handleChange(event: ChangeEvent<HTMLInputElement>): void
	handleCompanyType: (id: EntityTypeEnum) => void
	handleStateChange(state: SelectOption | null): void
	handleCopyFromContract: (check: boolean) => void
	handleDeleteOnClose: () => void
	handleSubmit: () => void
	handleDeleteUnitEnd: () => void
	authModalConfig: IAuthenticationModalProps
	handleSubmitFunction(): void
}

export const INITIAL_DATA_UNIT: IUnit = {
	entityType: 'PUBLIC',
	contract: '',
	name: '',
	CNES: '',
	companyFiscalID: '',
	telephone: '',
	companyName: '',
	observation: '',
	operators: [],
	kits: [],

	postalCode: '',
	streetName: '',
	city: '',
	state: '' as StateFromBrazil,
	addressComplement: '',
	neighborhood: '',
	addressNumber: '',
	location: {
		coordinates: [],
		type: 'Point'
	}
}

export const titleScreen = {
	CREATE: 'Nova Unidade de Atendimento',
	EDIT: 'Editar Unidade de Atendimento'
}

export const textButton = {
	CREATE: 'Cadastrar',
	EDIT: 'Salvar alterações'
}
