import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useFilterOptions } from 'shared/services/filterOptions.service'
import { buildFilterMenu } from 'shared/util/format'
import { friendlyRole } from 'shared/util/friendlyRole'
import { buildingOptionsAccordingToProfile } from 'shared/util/options'
import { useTypedSelector } from './useTypedSelector'
import { Creators as FilterActions } from 'shared/store/ducks/filter'
import { Creators as TokenActions } from 'shared/store/ducks/token'
import { store } from 'shared/store'
import { IMenu } from 'shared/components/MenuPrimary/types'

export default function useUpdateFilterOptions() {
	const dispatch = useDispatch()
	const { user, profile, filter } = useTypedSelector([
		'user',
		'profile',
		'filter'
	])
	const { status, data } = useFilterOptions({ userId: user._id })

	function parseFilterOptions() {
		if (!data || !data.accessToken) return

		// UPDATE ACCESS TOKEN MINUTE BY MINUTE WHEN FILTER IS CALLED
		dispatch(TokenActions.setToken({ accessToken: data.accessToken }))

		if (!data.contractsOptions) return

		const selectedUserProfile = data.contractsOptions.find(
			(option) => option.profileID === profile.profileId
		)

		if (!selectedUserProfile) return

		const profileName = friendlyRole[selectedUserProfile.profileName]

		const updatedfilterMenu = buildFilterMenu(selectedUserProfile.contracts)
		const menuOptions = buildingOptionsAccordingToProfile(
			profileName,
			updatedfilterMenu,
			'HEADER'
		)

		const menuOptionsFilterState: IMenu[] = menuOptions.map((menuOption) => {
			const currentMenuOption = filter.find(
				(filterOption) =>
					String(filterOption.value) === String(menuOption.value)
			)
			return {
				...menuOption,
				selected: currentMenuOption ? !!currentMenuOption.selected : true,
				options: menuOption.options.map((option) => {
					const currentOption = currentMenuOption?.options.find(
						(filterCurrentOption) =>
							String(filterCurrentOption.value) === String(option.value)
					)
					return {
						...option,
						selected: currentOption ? !!currentOption.selected : true
					}
				})
			}
		})

		dispatch(FilterActions.setFilter(menuOptionsFilterState))
	}

	useEffect(parseFilterOptions, [data, profile])
}
