import styled from 'styled-components'
import { fontType, mediaQuery, toRem } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'

export const InfoPatientWrapper = styled.article`
	h1,
	h2 {
		${fontType(1)};
		color: ${colors.darkGrey};
	}

	h1 {
		margin-bottom: ${toRem(20)};
		font-size: ${toRem(24)};
	}

	h2 {
		margin-bottom: ${toRem(18)};
		font-size: ${toRem(16)};
	}
	.box-patient{
		margin-bottom: ${toRem(32)};
	}

	.info-unit {
		margin-bottom: ${toRem(32)};
	}
	.title {
		font-family: sans-serif;
		color: ${colors.darkGrey};
	}
	.font-regular {
		${fontType(0)};
		color: ${colors.darkGrey};
	}

	.info-box {
		font-size: ${toRem(12)};
	}

	.background-box {
		background: ${colors.white};
		padding: ${toRem(58)} ${toRem(48)};
	}

	.flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
	}

	.info-container {
		padding-left: 5px;
	}

	.medical-records {
		+ .medical-records {
			border-top: 1px solid ${colors.backgroundSelect};
		}
		padding: ${toRem(20)} 0;

		h2 {
			margin: 0;
		}
		p {
			font-size: ${toRem(16)};
		}
	}

	.border {
		padding-bottom: ${toRem(12)};
		border-bottom: 1px solid ${colors.backgroundSelect};
		width: 100%;
	}

	${mediaQuery('md')(`
		
		.box-patient .flex {
			display: block;
			div + div {
				margin-top: 8px;
			}
		}
    `)}
`
