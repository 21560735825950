import axios, { AxiosInstance } from 'axios'

import handleError from '../util/handleError'
import { store } from '../store'

export const API_URL = 'https://apilinda.buildbox.com.br'

const axiosInstance: AxiosInstance = axios.create({
	baseURL: `${API_URL}`,
	timeout: 300000
})

axiosInstance.interceptors.request.use(
	(request) => {
		if (process.env.NODE_ENV === 'development') {
			/**
			 * The react-query calling a request does't necessary mean that
			 * will make that number of request or even is going to make a request
			 * look at google chrome network to really see when a request is really made.
			 */
			console.log('REQUEST', { url: request.url, data: request.data })
			// console.log('REQUEST', { request })
		}

		const accessToken = store.getState().token.accessToken
		const secret = store.getState().user._id
		//		const accessToken = 'bearer_access_token'
		if (accessToken) {
			request.headers.Authorization = `Bearer ${accessToken}`
		}
		if (secret) {
			// request.headers.secret = secret
			request.headers.secret = secret
		}

		return request
	},
	(err) => {
		if (process.env.NODE_ENV === 'development') {
			console.log('REQUEST', { err })
		}
		handleError(err?.request?.data)
		throw err
	}
)

axiosInstance.interceptors.response.use(
	(response) => {
		// console.log('RESPONSE', {
		// 	data: response.data,
		// 	status: response.status
		// })

		return response
	},
	(err) => {
		if (process.env.NODE_ENV === 'development') {
			console.log('RESPONSE', { err })
		}
		handleError(err?.response?.data)
		throw err
	}
)

export default axiosInstance
