import styled from 'styled-components'
import { fadeIn } from 'shared/styles/animated'
import { fontType, mediaQuery, toRem } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'

export const LoginWrapper = styled.div`
	animation: ${fadeIn} 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;

	display: flex;
	justify-content: space-between;
	padding: 0 111px;
	width: 100%;
	min-height: 100vh;
	align-items: center;
	background-image: url(${require('assets/images/login-background.png')});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	${mediaQuery('md')(`
        flex-direction: column;
	    justify-content: center;
        padding: 0;
        .logo-wrapper {
            margin-bottom: 50px;
        }
	`)};

	.logo-wrapper {
		max-width: ${toRem(283)};
	}

	.content-wrapper {
		background-color: ${colors.white};
		justify-content: space-between;
		display: flex;
		width: ${toRem(360)};
		height: ${toRem(317)};
		box-shadow: 0px 2px 4px #00000029;
		border-radius: 4px;
		margin-bottom: 10px;

		.formContainer {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 100%;
			padding: 0px 24px;
			text-align: center;

			.input {
				margin-bottom: 12px;
			}

			.button {
				padding: 8px;

				button {
					text-transform: uppercase;
				}
			}
		}
	}
	.forgot-password {
		text-transform: uppercase;
		color: ${colors.white};
		${fontType(2)};
		font-size: 10px;
		cursor: pointer;
		text-align: end;
		text-decoration: underline;
	}

	${mediaQuery('xs')(`
        .forgot-password{
            text-align: center;
        }
        section {
            padding: 0 12px;
            width: 96%;
            .content-wrapper{
                 width: 100%;
            }
        }
	`)};
`
