import React from 'react'
import { IUnitPaginated } from 'shared/interfaces/unit'

export function renderEditIcon(
	unit: IUnitPaginated,
	onClick: (unit: IUnitPaginated) => void
): JSX.Element {
	return (
		<button className='icon' onClick={() => onClick(unit)}>
			<img
				className='img'
				src={require('../../assets/images/dots.svg')}
				alt='Editar'
			/>
		</button>
	)
}
