import styled from 'styled-components'
import { fontType, toRem } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'

export const UnitWrapper = styled.div`
	margin-bottom: ${toRem(200)};

	.row {
		margin-top: ${toRem(40)};
		margin-bottom: ${toRem(34)};
	}
	.btn-next {
		text-align: end;
	}

	.btn button {
		text-transform: uppercase;
	}

	.btn-save {
		margin-top: ${toRem(60)};
		display: flex;
		justify-content: flex-end;
		align-items: center;
		text-transform: uppercase;

		.btn-remove {
			margin-right: ${toRem(16)};
		}
	}

	.btn-clean {
		cursor: pointer;
		background: transparent;
		border: none;
	}

	.btn-remove {
		${fontType(2)};
		color: ${colors.darkGrey};
		text-decoration: underline;
		text-transform: uppercase;
		font-size: ${toRem(10)};
	}

	.subtitle {
		${fontType(0)};
		font-size: ${toRem(16)};
		line-height: 1.312;
		letter-spacing: -0.38px;
		color: #4e4d4f;
		margin-top: ${toRem(48)};
		padding-bottom: ${toRem(12)};
		border-bottom: 1px solid #dfdfd8;
		margin-bottom: ${toRem(37)};
	}

	.input-disabled input {
		background: ${colors.backgroundResultDiagnosis};
		color: ${colors.darkGrey};
		border: 1px solid ${colors.backgroundResultDiagnosis} !important;
	}

	.label-disabled {
		color: #b5b3b8;
	}

	.label-checked {
		color: ${colors.secondary};
	}

	.checkboxes-label,
	.checkbox-copy {
		${fontType(0)};
		font-size: ${toRem(14)};
		line-height: 1.357;
		color: #4e4d4f;
	}

	.checkboxes-label {
		padding-left: 6px;
		padding-bottom: ${toRem(22)};
	}

	.checkboxes {
		${fontType(0)};
		padding-left: 20px;
		display: flex;
		color: #b5b3b8;
		div {
			align-items: center;
			display: flex;
			cursor: pointer;
			p {
				margin: 0 40px 0 8px;
			}
		}
	}

	.checkbox-copy {
		display: flex;
		gap: ${toRem(8)};
		align-items: center;
		height: 100%;
	}

	.comments-div {
		margin-bottom: ${toRem(8)};

		textarea {
			${fontType(0)};
			padding: ${toRem(12)};
			height: ${toRem(127)};
			width: 100%;
			border: 1px solid #dbd8df;
			color: ${colors.darkGrey};
			border-radius: 2px;
			resize: none;
		}
	}
`

export const LoadingContainer = styled.div`
	display: flex;
	justify-content: center;
`
