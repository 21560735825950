import { format } from 'date-fns'
import { clone } from 'ramda'
import { IMenu } from 'shared/components/MenuPrimary/types'
import {
	CustomerType,
	CustomerTypeEnum,
	IFilterOption
} from 'shared/interfaces/contract'
import { IFilter } from 'shared/interfaces/pagination'
import { IProfile } from 'shared/interfaces/profile'
import { UserProfile } from 'shared/interfaces/user'
import { friendlyCustomerType } from './friendlyRole'
import { sortBy } from './onlyUnique'

export function formatDate(date: Date) {
	return format(date, 'dd/MM/yyyy')
}

export function formatMonthAndYear(date: Date) {
	return format(date, 'MM/yyyy')
}

export function formatDateWithHour(date: Date) {
	return format(date, 'dd/MM/yyyy - H:mm')
}

export const DATE_FORMAT = 'dd/MM/yyyy'

export function mappedFilterSelected(filters: IMenu[]) {
	const copied = clone(filters)

	const onlySelected = copied.filter(
		({ selected, options }) =>
			selected || options.some((selected) => selected.selected)
	)

	onlySelected.forEach((item) => {
		item.options = item.options.filter((x) => x.selected)
	})

	return onlySelected
}

export function transformMenu(filters: IMenu[]): IFilter[] {
	const onlySelected = mappedFilterSelected(filters)

	const mapped: IFilter[] = onlySelected.map((option) => {
		return {
			id: option.value,
			units: option.options.map((item) => {
				return { id: item.value }
			})
		}
	})

	return mapped
}

function removeDuplicate(
	value: IFilterOption,
	index: number,
	self: IFilterOption[]
) {
	return self.map((x) => x.id).indexOf(value.id) === index
}

export function buildFilterMenu(filterOptions: IFilterOption[]): IMenu[] {
	const buildFilter: IMenu[] = filterOptions
		.sort((a, b) => sortBy(a.name, b.name))
		.filter(removeDuplicate)
		.map((contract) => {
			return {
				value: contract.id,
				label: contract.name,
				options: contract.units.map(({ id, name }) => {
					return {
						label: name,
						value: id,
						selected: true,
						visible: true
					}
				}),
				expanded: false,
				selected: true,
				visible: true
			}
		})

	return buildFilter
}

export function capitalize(text: string) {
	return text[0].toUpperCase() + text.slice(1)
}

export function convertNumberToString(value: number): string {
	if (isNaN(value) || value === 0 || value === null) return ''
	return value.toString()
}

export function fixedPercentage(percentage: number, fixed: number) {
	return (
		Math.floor(percentage * Math.pow(10, fixed)) / Math.pow(10, fixed)
	).toFixed(fixed)
}

export function formatPermissionsAndProfiles(profiles: UserProfile[]) {
	const permissions = profiles
		.map((userProfile) => (userProfile.profile as IProfile)?.permissions)
		.flat()
	const permissionsSet = [...new Set(permissions)]

	// This is to not break other places.
	const userProfiles = profiles.map((userProfile) => ({
		...userProfile,
		profile: userProfile.profile
	}))

	return {
		permissions: permissionsSet,
		profiles: userProfiles
	}
}

export function formatSelectOption(value: CustomerTypeEnum | null) {
	if (!value) return null

	return {
		label: friendlyCustomerType[value],
		value
	}
}
