import React from 'react'
import { IProps } from './types'
import CheckBox from '../CheckBox'
import { colors } from 'shared/styles/theme'
import { friendlyDiagnosisForward } from 'shared/util/friendlyRole'
import { CheckBoxWrapper } from './styles'

export default function CheckBoxDiagnosis({
	type,
	checkboxState,
	handleCheckbox,
	className,
	disable
}: IProps) {
	const value = checkboxState.name === type && checkboxState.checked

	return (
		<CheckBoxWrapper onClick={() => handleCheckbox(type)} className={className}>
			<div
				className={disable ? 'deselected' : value ? 'selected' : 'deselected'}
			>
				<CheckBox
					id={type}
					name={type}
					checked={value}
					onChange={() => handleCheckbox(type)}
				/>
				<p
					style={{
						color: value ? colors.secondary : ''
					}}
				>
					{friendlyDiagnosisForward[type]}
				</p>
			</div>
		</CheckBoxWrapper>
	)
}
