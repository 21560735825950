import { fontType } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'
import styled from 'styled-components'

export const CheckBoxWrapper = styled.div`
	cursor: pointer;
	p {
		margin-left: 6px;
		${fontType(0)};
		font-size: 12px;
	}
	div {
		padding: 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 4px;
	}

	+ div {
		margin-left: 16px;
	}

	.selected {
		border: 1px solid ${colors.borderDiagnosis};
		background: ${colors.backgroundDiagnosis};
	}

	.deselected {
		background: ${colors.pageBackground};
		border: 1px solid ${colors.pageBackground};
	}
`
