import { colors } from 'shared/styles/theme'
import styled from 'styled-components'
import { fontType, toRem } from '../../shared/styles/functions'

export const KitsListWrapper = styled.div`
	button {
		cursor: pointer;
	}

	.tableContainer {
		margin-top: ${toRem(50)};
		${fontType(0)}

		.icon {
			border: none;
			display: flex;
			margin: auto;
			background: transparent;
			min-width: 16px;
		}

		.status {
			${fontType(2)};
			border-radius: 10px;
			padding: 5px 10px;
			display: flex;
			align-items: center;
			font-size: 10px;
			justify-content: center;
		}

		.statusPending {
			color: #e3b91b;
			background: #fff2c3;
			width: 60px;
		}

		.statusDisassociated {
			color: ${colors.darkGrey};
			background: ${colors.placeholder};
			width: 80px;
		}
	}

	.btn-export {
		margin-top: 40px;
		text-align: end;

		button {
			text-transform: uppercase;
		}
	}
`
