import React from 'react'
import { Button, Loading } from '@buildbox/components'

import { primaryButtonStyle, colors } from 'shared/styles/theme'
import { IViewProps } from './types'
import { ChangePasswordWrapper } from './styles'

function WrapperChangePassword(props: IViewProps): JSX.Element {
	const {
		isFormValid,
		isLoading,
		title,
		subtitle,
		onSubmit,
		titleButton,
		children,
		height
	} = props

	return (
		<ChangePasswordWrapper>
			<div className='container-wrapper'>
				<img
					className='logo-wrapper'
					src={require('assets/images/logo-linda-light.svg')}
					alt='Logo Linda'
				/>

				<div className='change-password-title'>
					{title ? <h1>{title}</h1> : null}
					{subtitle ? <h1>{subtitle}</h1> : null}
				</div>
			</div>

			<section>
				<div
					className='content-wrapper'
					style={{ height: height ? height : 429 }}
				>
					<form
						className='formContainer'
						onSubmit={(e: React.SyntheticEvent) => {
							e.preventDefault()
							onSubmit()
						}}
					>
						{children}

						{isLoading ? (
							<Loading type='Sentry' primaryColor={colors.secondary} />
						) : (
							<Button
								className='button'
								disabled={!isFormValid}
								{...primaryButtonStyle}
							>
								{titleButton}
							</Button>
						)}
					</form>
				</div>
			</section>
		</ChangePasswordWrapper>
	)
}

export default WrapperChangePassword
