import styled from 'styled-components'
import { transition } from '../../styles/functions'
import { colors } from 'shared/styles/theme'

export const CheckBoxPrimaryWrapper = styled.label`
	position: relative;
	width: 16px;
	height: 16px;
	cursor: pointer;
	display: block;

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 16px;
		height: 16px;
		padding: 2px;
		border-radius: 100%;
		background: ${colors.white};
		border: 1px solid #dbd8df;
		color: transparent;

		span {
			width: 6px;
			height: 6px;
			background: ${colors.secondary};
			border-radius: 50%;
		}

		${transition()}
	}

	.checkbox-input {
		position: absolute;
		opacity: 0;
		height: 0;
		width: 0;

		&:checked {
			+ .checkmark {
				border: 1px solid #e61c9529;
				background: ${colors.backgroundCheckBox};
			}
		}

		&[disabled] {
			+ .checkmark {
				border: 1px dashed ${colors.primary};
			}
		}
	}
`
