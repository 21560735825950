import * as AWS from 'aws-sdk'
import { UtilGenerators } from '@buildbox/utils'
import handleError from 'shared/util/handleError'
import { errorMessages } from 'shared/util/Messages'
import { APP_ERRORS } from 'shared/util/appError'

const region = 'us-east-1'
const bucketName = 'storage-linda'
const accessKeyId = 'AKIAIKWWJZO4UODKWBPA'
const secretAccessKey = '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'

function config(): void {
	try {
		AWS.config.update({
			region,
			accessKeyId,
			secretAccessKey
		})
	} catch (err) {
		console.log({ err })
	}
}
export function uploadToS3(file: File) {
	config()
	const S3: AWS.S3 = new AWS.S3({
		params: { Bucket: bucketName }
	})

	const uploads = [
		'xlss',
		'xls',
		'jpg',
		'jpge',
		'png',
		'doc',
		'docx',
		'pdf',
		'wir',
		'zip'
	]

	const extension = file.name.split('.').slice(-1)[0].toLowerCase()
	const name = file.name.replace(/ /, '-').split('.')[0]

	const isValid = uploads.includes(extension)

	if (!isValid) {
		handleError({
			statusCode: 501,
			message: `${errorMessages.extensionFile} ${extension} `
		})
		return Promise.reject(errorMessages.extensionFile)
	}

	const fileKey = `${UtilGenerators.generateRandomSerial(7)}.${extension}`

	const uploadPromise: Promise<AWS.S3.ManagedUpload.SendData> = S3.upload(
		{
			Key: `${name}-${fileKey}`,
			Bucket: bucketName,
			Body: file,
			ContentType: file.type,
			ACL: 'public-read'
		},
		{ partSize: 10_000_000 }
	)
		.promise()
		.catch((e) => {
			if (e.code === 'ETagMissing') {
				handleError(APP_ERRORS.UPLOAD_SIZE_EXCEEDED)
				return Promise.reject(e)
			}

			handleError({ message: e.message, statusCode: e.code })
			return Promise.reject(e)
		})

	return uploadPromise
}

export function removeFileToS3(pdfUrl: string | undefined) {
	config()
	const S3: AWS.S3 = new AWS.S3({
		params: { Bucket: bucketName }
	})

	if (!pdfUrl) return

	// "https://s3-buildbox.s3.amazonaws.com/kVFqM6EIauTtBTX6.pdf"
	// "https://s3-buildbox.s3.amazonaws.com/>>>kVFqM6EIauTtBTX6.pdf<<<"

	// eslint-disable-next-line
	const [_, __, ___, key] = pdfUrl.split('/')

	S3.deleteObject({ Bucket: bucketName, Key: key }, function (err, data) {
		if (err) console.log(err)
		else return data
	})

	// return deletePdfPromise
}
