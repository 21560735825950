import React from 'react'
import { Button, Loading, Modal } from '@buildbox/components'
import {
	colors,
	primaryButtonStyle,
	secondaryButtonStyle
} from '../../../shared/styles/theme'
import { IViewProps } from './types'
import { LoadingContainer } from '../styles'

function ModalConfirm(props: IViewProps) {
	const { isLoading, handleSubmit, onClose, isActive, diagnosis } = props
	return (
		<Modal
			className='modal-primary'
			size={376}
			title='Confirmar análise'
			isActive={isActive}
			onClose={onClose}
		>
			<div className='modal-container'>
				<p>
					Você sinalizou como <strong>{diagnosis}</strong>. Deseja confirmar
					esta análise?
				</p>

				{!isLoading ? (
					<div className='buttons'>
						<Button onClick={onClose} {...secondaryButtonStyle}>
							CORRIGIR
						</Button>
						<Button onClick={handleSubmit} {...primaryButtonStyle}>
							CONFIRMAR
						</Button>
					</div>
				) : (
					<LoadingContainer>
						<Loading primaryColor={colors.secondary} type='Sentry' />
					</LoadingContainer>
				)}
			</div>
		</Modal>
	)
}

export default ModalConfirm
