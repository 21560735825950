import { ChangeEvent, createElement, useEffect, useState } from 'react'
import { INITIAL_STATE } from 'shared/store/ducks/user'
import routesEnum from '../Routes/enum'
import { UtilValidators } from '@buildbox/utils'

import { IViewProps } from './types'
import { useHistory, useParams } from 'react-router-dom'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from 'shared/util/toaster'
import ChangePassword from './view'
import { IUser } from 'shared/interfaces/user'
import { successMessages } from 'shared/util/Messages'
import {
	changePassword,
	validateResetToken
} from 'shared/services/auth.service'
import { TypeWrapper } from 'shared/components/WrapperChangePassword/types'
import { checkPassword } from 'shared/util/validateFields'

function ChangePasswordContainer(): JSX.Element {
	const history = useHistory()
	const { resetToken } = useParams<{ resetToken: string }>()

	const [isFormValid, setIsFormValid] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [user, setUser] = useState<IUser>(INITIAL_STATE)
	const [password, setPassword] = useState('')
	const [passwordCheck, setPasswordCheck] = useState('')
	const [viewErrors, setViewErrors] = useState<string[]>([])
	const [title, setTitle] = useState('')
	const [message, setMessage] = useState('')
	const [type, setType] = useState(TypeWrapper.ChangePassword)

	const [isTermChecked, setIsTermChecked] = useState(false)

	const { checkPasswordMatch } = UtilValidators

	function handleCheckboxStatus(event: ChangeEvent<HTMLInputElement>) {
		setIsTermChecked(event.target.checked)
	}

	function handleInit() {
		;(async () => {
			try {
				setIsLoading(true)
				const response = await validateResetToken(resetToken)

				const { _id, email, name, status } = response

				setUser({ ...user, _id, email, name, status })

				if (status === 'PENDING') {
					setType(TypeWrapper.ChangePassword)
					setTitle(`Olá, ${name}!`)
					setMessage('Para continuar, crie uma senha de acesso ao painel')
				} else {
					setType(TypeWrapper.ForgotPassword)
					setTitle('Redefinir Senha')
					setMessage('Digite agora, sua nova senha de acesso ao painel')
				}
			} catch (error) {
				history.replace(routesEnum.LOGIN)
			} finally {
				setIsLoading(false)
			}
		})()
	}

	async function handleSubmit() {
		try {
			setIsLoading(true)
			const hasError = await handleViewError()
			if (hasError) return

			await changePassword(user._id, user.status, resetToken, password)

			cogoToast.success(successMessages.passwordUpdated, cogoDefaultOptions)
			history.replace(routesEnum.LOGIN)
		} catch (error) {
		} finally {
			setIsLoading(false)
		}
	}

	function handlePassword(event: ChangeEvent<HTMLInputElement>) {
		const { value } = event.target
		setPassword(value)
	}

	function handlePasswordCheck(event: ChangeEvent<HTMLInputElement>) {
		const { value } = event.target
		setPasswordCheck(value)
	}

	async function handleViewError() {
		const errors: string[] = []

		if (!checkPassword(password)) {
			errors.push('Senha não preenchida e/ou inválida')
		}

		if (!checkPasswordMatch(password, passwordCheck)) {
			errors.push('Senhas não são iguais')
		}

		setViewErrors(errors)
		return errors.length
	}

	function handleFormValidation() {
		const isPasswordValid = password.length >= 6
		const isPasswordChecked = passwordCheck.length >= 6

		const result = isPasswordValid && isPasswordChecked && isTermChecked

		setIsFormValid(result)
	}

	useEffect(handleFormValidation, [
		user.email,
		password,
		passwordCheck,
		isTermChecked
	])

	useEffect(handleInit, [])

	const viewProps: IViewProps = {
		handlePassword,
		handlePasswordCheck,
		// email,
		user,
		password,
		passwordCheck,
		handleSubmit,
		isLoading,
		isFormValid,
		viewErrors,
		title,
		message,
		type,
		isTermChecked,
		handleCheckboxStatus
	}

	return createElement(ChangePassword, viewProps)
}

export default ChangePasswordContainer
