import styled from 'styled-components'

export const InputSearchWrapper = styled.div`
	/* display: flex;
	align-items: center; */
	position: relative;

	.search-icon {
		position: absolute;
		right: 20px;
		top: 17px;
	}
`
