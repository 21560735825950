import styled from 'styled-components'
import { fontType, mediaQuery, toRem } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'

export const UserWrapper = styled.div`
	h2 {
		${fontType(0)};
		font-size: ${toRem(16)};
		color: ${colors.darkGrey};
		margin-bottom: ${toRem(10)};
	}
	.btn button {
		text-transform: uppercase;
	}

	.subtitle {
		font-size: ${toRem(14)};
		margin-top: ${toRem(40)};
		margin-bottom: ${toRem(20)};
	}

	.btn-clean {
		cursor: pointer;
		background: transparent;
		border: none;
	}

	.btn-save {
		margin-top: ${toRem(60)};
		display: flex;
		justify-content: flex-end;
		align-items: center;
		text-transform: uppercase;

		.btn-remove {
			margin-right: ${toRem(16)};
		}
	}

	.btn-remove {
		${fontType(2)};
		color: ${colors.darkGrey};
		text-decoration: underline;
		text-transform: uppercase;
		font-size: ${toRem(10)};
	}
`
export const FormContainer = styled.div`
	margin-bottom: 20px;
	.row:first-child {
		margin-top: 48px;
	}
	.checkboxes {
		margin-bottom: ${toRem(48)};
		${fontType(0)};
		padding-left: 20px;
		display: flex;
		color: #b5b3b8;
		div {
			align-items: center;
			display: flex;
			cursor: pointer;
			p {
				margin: 0 40px 0 8px;
			}
		}
		${mediaQuery('xs')(`
			display: block;

			div + div{
				margin-top: 5px;
			}
		`)}
	}

	.input-disabled input {
		background: #efedf0;
		color: ${colors.darkGrey};
		border: 1px solid ${colors.backgroundResultDiagnosis} !important;
	}

	.comments-div {
		margin-bottom: ${toRem(8)};

		h2 {
			padding-bottom: ${toRem(12)};
			border-bottom: 1px solid ${colors.backgroundMenu};
			margin-bottom: ${toRem(30)};
			margin-top: ${toRem(40)};
		}

		textarea {
			${fontType(0)};
			padding: ${toRem(12)};
			height: ${toRem(127)};
			width: 100%;
			border: 1px solid #dbd8df;
			color: ${colors.darkGrey};
			border-radius: 2px;
			resize: none;
		}
	}

	.document {
		& {
			margin-bottom: 2px;
		}
		input {
			display: none;
		}
		padding: ${toRem(12)};
		border-radius: 2px;
		display: flex;
		justify-content: space-between;

		figure {
			display: flex;
			align-items: center;

			legend {
				${fontType(0)};
				font-size: ${toRem(12)};
				margin-left: ${toRem(8)};
			}
			a {
				${fontType(0)};
				font-size: ${toRem(12)};
				margin-left: ${toRem(8)};
				color: ${colors.darkGrey};
				text-decoration: none;
			}
		}
	}
	.edit {
		color: ${colors.darkGrey};
		background-color: ${colors.backgroundResultDiagnosis};
	}
	.create {
		color: #d28ab5;
		cursor: pointer;
		justify-content: center;
		background-color: ${colors.borderDiagnosis};
		margin-bottom: ${toRem(20)};
	}
`

export const LoadingContainer = styled.div`
	display: flex;
	justify-content: center;
`
export const SwitchesWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`
