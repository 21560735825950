import { ChangeEvent, createElement, useEffect, useState } from 'react'
import { UtilValidators } from '@buildbox/utils'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { INITIAL_LOGIN, IUserLogin, IViewProps } from './types'
import { logout } from 'shared/util/session'
import routesEnum from 'modules/Routes/enum'
import { Creators as TokenActions } from 'shared/store/ducks/token'
import { Creators as UserActions } from 'shared/store/ducks/user'

import { login } from 'shared/services/auth.service'
import Login from './view'

import { IProfile } from 'shared/interfaces/profile'
import { formatPermissionsAndProfiles } from 'shared/util/format'

function LoginContainer(): JSX.Element {
	const [userLogin, setUserLogin] = useState<IUserLogin>(INITIAL_LOGIN)
	const [isFormValid, setIsFormValid] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const { checkEmail, checkPassword } = UtilValidators
	const dispatch = useDispatch()
	// const updateContractsFilter = useUpdateContractsFilter()
	const history = useHistory()

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		const { id, value } = event.target

		setUserLogin({
			...userLogin,
			[id]: value.trim()
		})
	}

	async function handleLogin() {
		try {
			setIsLoading(true)
			const response = await login(userLogin.email, userLogin.password)

			const { user, accessToken } = response

			const { permissions, profiles } = formatPermissionsAndProfiles(
				user.profiles
			)

			dispatch(
				UserActions.setUser({
					...user,
					permissions,
					profiles
				})
			)
			dispatch(TokenActions.setToken({ accessToken }))

			if (permissions.length === 1 && permissions.includes('EXAMS')) {
				history.push(routesEnum.EXAMS)
				return
			}

			history.push(routesEnum.DASHBOARD)
		} catch (error) {
		} finally {
			setIsLoading(false)
		}
	}

	function handleRequestChangePassword() {
		history.push(routesEnum.FORGOT_PASSWORD)
	}

	function handleValidate() {
		const { email, password } = userLogin

		const isEmailValid = checkEmail(email)
		const isPasswordValid = checkPassword(password)

		const result = isEmailValid && isPasswordValid

		setIsFormValid(result)
	}

	useEffect(logout, [])
	useEffect(handleValidate, [checkEmail, checkPassword, userLogin])

	const viewProps: IViewProps = {
		handleLogin,
		handleChange,
		handleRequestChangePassword,
		userLogin,
		isFormValid,
		isLoading
	}

	return createElement(Login, viewProps)
}

export default LoginContainer
