import styled from 'styled-components'
import { fontType, mediaQuery, toRem } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'

export const ExamWrapperContainer = styled.section`
	.modal-primary {
		margin-top: 0;
	}
`

export const ExamWrapper = styled.div`
	/* position: relative; */
	margin-bottom: ${toRem(100)};

	.icon {
		border: none;
		display: flex;
		margin: auto;
		background: transparent;
		min-width: 16px;
	}

	button {
		cursor: pointer;
	}
`

export const ExamContent = styled.section`
	position: relative;

	width: ${toRem(890)};
	margin: auto;
	margin-top: ${toRem(50)};
	color: ${colors.darkGrey};

	.box-patient {
		position: relative;
	}

	.btn-export {
		position: absolute;
		top: ${toRem(16)};
		right: ${toRem(16)};
	}

	${mediaQuery('md')(`
		width: 100%; 
    `)}
`

export const LoadingContainer = styled.div`
	display: flex;
	justify-content: center;
`
export const ExamModal = styled.div`
	button {
		cursor: pointer;
	}
	.btn-scroll {
		position: fixed;
		bottom: 98px;
		right: 200px;
	}

	.icon {
		border: none;
		display: flex;
		margin: auto;
		background: transparent;
		min-width: 16px;
	}

	.active {
		z-index: 19;
	}
	.modal-wrapper {
		color: ${colors.darkGrey};
	}

	.modal-container {
		p {
			margin-bottom: ${toRem(40)};
			font-size: ${toRem(14)};
			${fontType(0)};
		}
		.buttons {
			display: flex;
			justify-content: space-between;
			margin-top: 10px;

			div:first-child button {
				border: 2px solid ${colors.darkGrey};
			}
		}
	}
	${mediaQuery('md')(`
		.btn-scroll{
			right: 42px;
		}
    `)}
`
