import React, { useState } from 'react'
import { CheckBox } from '@buildbox/components'
import { IViewProps } from './types'
import { primaryCheckBoxStyle } from 'shared/styles/theme'

export default function CheckboxContract(props: IViewProps) {
	const { value, onChange, isSelected } = props

	const [selected, setSelected] = useState(true)

	function handleSelect() {
        const newValue = !selected
		setSelected(newValue)

        onChange && onChange(newValue, value)
	}

	React.useEffect(() => {
		setSelected(!!isSelected)
	}, [isSelected])

	return (
		<CheckBox
			type='checkbox'
			className='checkbox-files'
			id={`${value}`}
			name={`${value}`}
			onChange={handleSelect}
			checked={selected}
			{...primaryCheckBoxStyle}
		/>
	)
}
