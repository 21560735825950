import axios from 'axios'
import { StateFromBrazil } from 'shared/interfaces/unit'

interface IViaCepReturn {
	cep: string
	state: StateFromBrazil
	city: string
	neighborhood: string
	street: string
	errors?: string
}

async function getAddressFromPostalCode(cep: string) {
	try {
		const response = await axios.get(
			`https://brasilapi.com.br/api/cep/v2/${cep}`
		)
		const address: IViaCepReturn = response.data

		if (address.errors) return

		return {
			streetName: address.street,
			city: address.city,
			state: address.state,
			neighborhood: address.neighborhood
		}
	} catch (error) {
		console.log(error)
	}
}

export default getAddressFromPostalCode
