import { IRouteData } from './types'

import routesEnum from './enum'
import UsersPage from 'modules/UsersPage'
import Login from 'modules/Login'
import CreateUser from 'modules/CreateUser'
import ForgotPassword from 'modules/ForgotPassword'
import ChangePassword from 'modules/ChangePassword'
import UnitsPage from 'modules/UnitsPage'
import UnitCreateAndEdit from 'modules/UnitCreateAndEdit'
import ContractsPage from 'modules/ContractsPage'
import CreateEditContract from 'modules/CreateEditContract'
import FinancePage from 'modules/FinancePage'
import KitsPage from 'modules/KitsPage'
import KitCreateAndEdit from 'modules/KitCreateAndEdit'
import DashboardPage from 'modules/DashboardPage'
import ExamsPage from 'modules/ExamsPage'
import ExamEdit from 'modules/ExamEdit'

import { PermissionEnum } from 'shared/interfaces/user'

export const routesData: IRouteData[] = [
	{
		component: Login,
		key: routesEnum.LOGIN,
		name: 'Login',
		path: routesEnum.LOGIN
	},
	{
		component: ForgotPassword,
		key: routesEnum.FORGOT_PASSWORD,
		name: 'Recuperar Senha',
		path: routesEnum.FORGOT_PASSWORD,
		noWrapper: true
	},
	{
		component: ChangePassword,
		key: routesEnum.SET_PASSWORD,
		name: 'Nova Senha',
		path: routesEnum.SET_PASSWORD,
		noWrapper: true
	},
	{
		component: DashboardPage,
		key: routesEnum.DASHBOARD,
		name: 'Dashboard',
		path: routesEnum.DASHBOARD,
		hasSelectProfile: true,
		hasFilter: true,
		accessTypes: 'DASHBOARD'
	},
	{
		component: UsersPage,
		key: routesEnum.USERS,
		name: 'Usuários',
		path: routesEnum.USERS,
		hasSelectProfile: true,
		hasFilter: true,
		accessTypes: 'USERS'
	},
	{
		component: CreateUser,
		key: routesEnum.USERS_CREATE_AND_UPDATE,
		name: 'Criar/Editar Usuários',
		path: routesEnum.USERS_CREATE_AND_UPDATE,
		accessTypes: 'USERS',
		isHidden: true,
		hasSelectProfile: true
	},

	{
		component: UnitsPage,
		key: routesEnum.UNITS,
		name: 'Unidades de Atendimento',
		path: routesEnum.UNITS,
		accessTypes: 'UNITS',
		hasFilter: true,
		hasSelectProfile: true
	},
	{
		component: UnitCreateAndEdit,
		key: routesEnum.UNITS_PAGE_CREATE,
		name: 'Nova Unidade de Atendimento',
		path: routesEnum.UNITS_PAGE_CREATE,
		accessTypes: 'UNITS',
		isHidden: true,
		hasSelectProfile: true
	},
	{
		component: UnitCreateAndEdit,
		key: routesEnum.UNITS_PAGE_EDIT,
		name: 'Editar Unidade de Atendimento',
		path: routesEnum.UNITS_PAGE_EDIT,
		accessTypes: 'UNITS',
		isHidden: true
	},
	{
		component: ContractsPage,
		key: routesEnum.CONTRACTS,
		path: routesEnum.CONTRACTS,
		name: 'Contratos',
		accessTypes: 'CONTRACTS',
		hasFilter: true,
		hasSelectProfile: true
	},
	{
		component: CreateEditContract,
		key: routesEnum.CONTRACTS_CREATE_AND_UPDATE,
		path: routesEnum.CONTRACTS_CREATE_AND_UPDATE,
		name: 'Criar/Editar Contratos',
		accessTypes: 'CONTRACTS',
		isHidden: true
	},
	{
		component: KitsPage,
		key: routesEnum.KITS,
		path: routesEnum.KITS,
		name: 'Kits',
		accessTypes: 'KITS',
		hasFilter: true,
		hasSelectProfile: true
	},
	{
		component: KitCreateAndEdit,
		key: routesEnum.KITS_CREATE_AND_UPDATE,
		path: routesEnum.KITS_CREATE_AND_UPDATE,
		name: 'Criar/Editar Kit',
		accessTypes: 'KITS',
		isHidden: true,
		hasSelectProfile: true
	},
	{
		component: FinancePage,
		key: routesEnum.FINANCES,
		path: routesEnum.FINANCES,
		name: 'Financeiro',
		accessTypes: 'FINANCES',
		hasFilter: true,
		hasSelectProfile: true,
		fullPage: true
	},
	{
		component: ExamsPage,
		key: routesEnum.EXAMS,
		path: routesEnum.EXAMS,
		name: 'Exames',
		accessTypes: 'EXAMS',
		hasFilter: true,
		hasSelectProfile: true
	},
	{
		component: ExamEdit,
		key: routesEnum.EXAMS_PAGE_EDIT,
		path: routesEnum.EXAMS_PAGE_EDIT,
		name: 'Editar Exame',
		accessTypes: 'EXAMS',
		isHidden: true,
		hiddenHeader: true
	}
]

export function getRouteAccessTypes(
	routePath: string
): PermissionEnum | undefined {
	const route = routesData.find((route) => route.path === routePath)
	return route ? route.accessTypes : undefined
}
