import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import { IViewProps } from './types'
import { fontType } from 'shared/styles/functions'

export function PieGraph({ data }: IViewProps) {
	return (
		<ResponsivePie
			data={data}
			margin={{ top: 45, right: 80, bottom: 45, left: 80 }}
			// startAngle={-120}
			innerRadius={0.5}
			arcLabel={'label'}
			theme={{ fontFamily: fontType(0) }}
			padAngle={2}
			activeOuterRadiusOffset={8}
			colors={[data[0]?.color, data[1]?.color, data[2]?.color]}
			borderWidth={1}
			enableArcLinkLabels={false}
			// arcLinkLabel={(d) => `${d.value} + ${d.id}`}
			// arcLinkLabelsSkipAngle={10}
			// arcLinkLabelsTextColor='#4E4D4F'
			// arcLinkLabelsThickness={2}
			// arcLinkLabelsColor={{ from: 'color' }}
			enableArcLabels={false}
			// arcLabelsSkipAngle={2}
			// arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
			// arcLabelsRadiusOffset={2}
			// arcLinkLabelsDiagonalLength={20}
			// arcLinkLabelsTextOffset={20}
			cornerRadius={1.5}
			borderColor={{ from: 'color', modifiers: [['darker', 0.7]] }}
			legends={[
				{
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: -75,
					translateY: 41,
					itemsSpacing: 0,
					itemWidth: 10,
					itemHeight: 24,
					itemTextColor: '#999',
					itemDirection: 'left-to-right',
					itemOpacity: 1,
					symbolSize: 12,
					symbolShape: 'circle',
					effects: [
						{
							on: 'hover',
							style: {
								itemTextColor: '#000'
							}
						}
					]
				}
			]}
		/>
	)
}
