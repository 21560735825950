import { keyframes } from 'styled-components'

export const fadeIn = keyframes`
	from{
		opacity: 0.7;
	}
	to{
		opacity: 1;
	}
`

export const animate = keyframes`
  to{
    opacity: 1;
    transform: initial;
  }
`
