import React from 'react'
import { IViewProps } from './types'
import { screenMessageExam } from '../types'
import { translateQuestionsDatasheet } from 'shared/util/translate'
import { InfoPatientWrapper } from './styles'
import { formatDateWithHour } from 'shared/util/format'
import { differenceInYears } from 'date-fns'
import { formatDateWithoutHour } from 'shared/util/date'

function InfoPatient(props: IViewProps): JSX.Element {
	const {
		patient,
		questionsDatasheet,
		unit,
		operator,
		contractName,
		accomplishedAt
	} = props

	function renderDataPatient(title: string, subtitle: string | number) {
		return (
			<div>
				<strong className='title'>{title}</strong>
				<p className='font-regular'>{subtitle}</p>
			</div>
		)
	}

	function renderDataMedicalRecords(title: string, subtitle: string) {
		return (
			<div className='flex medical-records'>
				<h2>{title}</h2>
				<p className='font-regular'>{subtitle}</p>
			</div>
		)
	}

	return (
		<InfoPatientWrapper id='page-1'>
			<div className='info-box background-box border'>
				<h1>{screenMessageExam.reportTitle}</h1>

				<div className='info-container box-patient'>
					<h2>{contractName}</h2>
					<div className='info-unit flex'>
						{renderDataPatient('Unidade de Saúde', unit)}
						{renderDataPatient('Operador', operator)}
						{renderDataPatient(
							'Data da captação da imagem',
							accomplishedAt && formatDateWithHour(new Date(accomplishedAt))
						)}
					</div>
					<h2>{screenMessageExam.infoPatient}</h2>

					{patient && (
						<div className='info-patient flex '>
							{renderDataPatient('CPF', patient.personalFiscalID)}
							{renderDataPatient('Nome da Paciente', patient.name)}
							{renderDataPatient(
								'Data de nascimento',
								formatDateWithoutHour(new Date(patient.birthDate))
							)}
							{renderDataPatient(
								'Idade',
								differenceInYears(new Date(), new Date(patient.birthDate))
							)}
							{renderDataPatient('Estado', patient.state)}
							{renderDataPatient('Cidade', patient.city)}
						</div>
					)}
				</div>
			</div>

			<div className='info-box background-box'>
				<h1>Ficha Clínica</h1>
				<div className='info-container'>
					{questionsDatasheet.map((questions) => (
						<React.Fragment key={questions.key}>
							{renderDataMedicalRecords(
								translateQuestionsDatasheet[questions.key],
								questions.value
							)}
						</React.Fragment>
					))}
				</div>
			</div>
		</InfoPatientWrapper>
	)
}

export default InfoPatient
