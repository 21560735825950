import { IContract } from './contract'
import { IDatasheet } from './datasheet'
import { IKit } from './kit'
import { IPatient } from './patient'
import { IUnit } from './unit'
import { IUser } from './user'

export interface IExam {
	_id: string
	contract: IContract | string
	unit: IUnit | string
	kit: IKit | string
	operator: IUser | string
	accomplishedAt: Date
	patient: IPatient | string
	image: string
	diagnosis: ExamDiagnosisEnum
	diagnosisProba: number
	examReport: string
	status: ExamStatusEnum
	createdAt?: Date
	updatedAt?: Date
	datasheet?: IDatasheet | string
	physicianDiagnoses?: IPhysicianDiagnoses[]
}

export interface IPhysicianDiagnoses {
	date: Date
	observation?: string
	diagnosis: ExamDiagnosisEnum
	physician: IUser | string
}

export enum ExamStatus {
	ACTIVE = 'ACTIVE',
	FINALIZED = 'FINALIZED',
	DELETED = 'DELETED'
}
export type ExamStatusEnum = keyof typeof ExamStatus

export enum ExamDiagnosis {
	NONE = 'NONE',
	NOT_SUSPICIOUS = 'NOT_SUSPICIOUS',
	SUSPICIOUS = 'SUSPICIOUS'
}

export interface IExamsPage {
	page: number
	numberOfPages: number
	totalDocs: number
	// pageContent: IExam[]
}

export type ExamDiagnosisEnum = keyof typeof ExamDiagnosis
