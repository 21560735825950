import React from 'react'
import { CheckBox } from '@buildbox/components'
import { primaryCheckBoxStyle } from 'shared/styles/theme'

import { ICheckboxCopyFromContractProps } from './types'

function CheckboxCopyFromContract(props: ICheckboxCopyFromContractProps): JSX.Element {

	const { changeCopyFromContract, checked, disabled } = props

	function getClassForLabel() {
		const classLabel: string[] = []

		if (checked)
			classLabel.push('label-checked')

		if (disabled)
			classLabel.push('label-disabled')

		return classLabel.join(' ')
	}

	return (
		<div className="checkbox-copy">
			<CheckBox
				type="checkbox"
				id='copyFromContract'
				name='copyFromContract'
				onChange={changeCopyFromContract}
				checked={checked}
				disabled={disabled}
				{...primaryCheckBoxStyle}
			/>
			<label htmlFor='copyFromContract' className={getClassForLabel()}>
				Utilizar os dados deste Contrato
			</label>
		</div>
	)
}

export default CheckboxCopyFromContract
