import React from 'react'
import { IViewProps } from './types'
import { ExamsPageWrapper } from './styles'
import { HeaderPage } from 'shared/components/HeaderPage'
import Table from 'shared/components/Table'
import { tableStyle } from 'shared/styles/theme'

function ExamsPage(props: IViewProps): JSX.Element {
	const {
		handleSearch,
		handlerSortColumn,
		itemsPerPage,
		navProps,
		search,
		sortBy,
		tableColumns,
		tableRows,
		onClickRow,
		examStatus
	} = props

	return (
		<ExamsPageWrapper className='animated-left'>
			<HeaderPage
				title={'Exames'}
				labelInput={'Buscar exame'}
				showButton={false}
				handleSearch={handleSearch}
				search={search}
				isSearch={true}
			/>

			<div className='tableContainer'>
				<Table
					// Workaround while we need to fix columns type on lib
					columns={tableColumns as any}
					data={tableRows}
					navProps={navProps}
					pageSize={itemsPerPage}
					{...tableStyle}
					padding={[26, 16, 26, 16]}
					getSelectedColumnAcessorAndOrder={handlerSortColumn}
					noItensText='No momento, não há Exames para serem exibidos.'
					sortBy={sortBy}
					isLoading={examStatus === 'loading'}
					onClickRow={onClickRow}
				/>
			</div>
		</ExamsPageWrapper>
	)
}

export default ExamsPage
