import { ChangeEvent } from 'react'

export interface IViewProps {
	handleLogin: () => void
	handleChange(event: ChangeEvent<HTMLInputElement>): void
	handleRequestChangePassword: () => void
	userLogin: IUserLogin
	isFormValid: boolean
	isLoading: boolean
}

export interface IUserLogin {
	email: string
	password: string
}

export const INITIAL_LOGIN: IUserLogin = {
	email: '',
	password: ''
}
