import { TableNavProps } from '@buildbox/components'
import { QueryStatus } from 'react-query'
import { IAcessorAndOrder } from 'shared/components/Table/types'

export interface IViewProps {
	tableColumns: ITableColumn[]
	tableRows: ITableRow[]
	navProps: TableNavProps
	itemsPerPage: number
	handleSearch(value: string): void
	search: string
	handlerSortColumn(acessor: string, order: number): IAcessorAndOrder
	sortBy: IAcessorAndOrder
	handleCreateKit: () => void
	kitsStatus: QueryStatus
	handleExportExcel(): void
}

export const INITIAL_SORT: IAcessorAndOrder = {
	acessor: 'createdAt',
	order: -1
}

export interface ITableColumn {}

export interface IKitsPage {
	page: number
	numberOfPages: number
	totalDocs: number
}
export interface ITableRow {
	deviceID: string
	deviceModel: string
	simCardID: string
	cameraType: string
	contract: string
	unit: string
	activatedAt: JSX.Element
	editIcon: JSX.Element
}

export enum ScreenActions {
	CREATE,
	EDIT
}

export type ScreenActionsEnum = keyof typeof ScreenActions
