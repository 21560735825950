import React from 'react'

import { HeaderWrapper } from './styles'
import { IProps } from './types'
import { MenuIcon } from '@buildbox/components'

export default function Header({
	isOpen,
	handleSetIsOpen,
	filter,
	selectProfile
}: IProps): JSX.Element {
	return (
		<HeaderWrapper className={isOpen ? 'active' : ''}>
			<div className='menu-container'>
				<MenuIcon onClick={handleSetIsOpen} isActive={isOpen} />
			</div>
			<div className='bar-container'>
				<div className='filter-container'>
					{selectProfile}
					{filter}
				</div>
				<img
					className='logo'
					src={require('assets/images/logo-linda.svg')}
					alt='LINDA Logo'
				/>
			</div>
		</HeaderWrapper>
	)
}
