import { deleteContract } from 'shared/services/contract.service'
import { associateKit, deleteKit, disassociateKit } from 'shared/services/kit.service'
import { deleteUnit } from 'shared/services/unit.service'
import { deleteUser } from 'shared/services/user.service'
import { successMessages } from 'shared/util/Messages'
import { TargetTypesEnum } from './types'

export const TITLE_ENUM: { [key in TargetTypesEnum]: string } = {
	UNIT: 'Excluir unidade',
	CONTRACT: 'Excluir contrato',
	USER: 'Excluir usuario',
	KIT_DISASSOCIATE: 'Desassociar Kit',
	KIT_DELETE: 'Excluir Kit',
	KIT_ASSOCIATE: 'Associar Kit'
}

export const MESSAGE_ENUM: { [key in TargetTypesEnum]: string } = {
	UNIT: 'Você deseja excluir a unidade',
	CONTRACT: 'Você deseja excluir o contrato',
	USER: 'Você deseja excluir o usuário',
	KIT_DISASSOCIATE: 'Você deseja desassociar o Kit',
	KIT_DELETE: 'Você deseja excluir o kit',
	KIT_ASSOCIATE: 'Você deseja associar o kit'

}

export const SUCCESS_MESSAGE: { [key in TargetTypesEnum]: string } = {
	UNIT: successMessages.unitDeleted,
	CONTRACT: successMessages.contractDeleted,
	USER: successMessages.userDeleted,
	KIT_DISASSOCIATE: successMessages.kitDesassociated,
	KIT_DELETE: successMessages.kitDeleted,
	KIT_ASSOCIATE: successMessages.kitAssociated,
}

// TODO: Type properly
export const FUNC_ENUM: {
	[key in TargetTypesEnum]: (
		id: string,
		email: string,
		password: string
	) => Promise<any>
} = {
	UNIT: deleteUnit,
	CONTRACT: deleteContract,
	USER: deleteUser,
	KIT_DISASSOCIATE: disassociateKit,
	KIT_DELETE: deleteKit,
	KIT_ASSOCIATE: associateKit as any
}
