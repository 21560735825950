import React from 'react'

import { Column, Row } from 'shared/styles'
import { FloatLabelInput, Select } from '@buildbox/components'
import { primaryInputStyle, selectStyle } from 'shared/styles/theme'
import CheckBoxLegalNature from 'shared/components/CheckBoxLegalNature'
import { COMPANY_TYPES } from 'shared/interfaces/contract'
import { toMoney } from 'shared/util/currency'
import file from '../../../assets/images/file-text.svg'
import { UtilMask, UtilValidators } from '@buildbox/utils'
import FloatLabelDateInput from 'shared/components/FloatLabelDateInput'
import { FormContainer, SwitchesWrapper } from '../styles'
import { urlAws } from 'shared/util/env'
import { convertNumberToString, formatSelectOption } from 'shared/util/format'
import Switch from 'shared/components/Switch'
import { IViewProps } from './types'
import { CUSTOMER_SELECT_TYPE } from 'shared/util/Consts'

function FormData(props: IViewProps) {
	const {
		handleChange,
		contract,
		renderUpload,
		action,
		removeUpload,
		handleSetDate,
		checkboxTablet,
		handleCheckboxTablet,
		checkboxState,
		handleCheckbox,
		formatedDate,
		formatTextDuration,
		convertDurationInNumber,
		duration,
		handleSelectChange
	} = props

	return (
		<FormContainer>
			<div className='checkboxes'>
				{COMPANY_TYPES.map((type) => (
					<CheckBoxLegalNature
						key={type}
						checkboxState={checkboxState}
						handleCheckbox={handleCheckbox}
						type={type}
					/>
				))}
			</div>

			<Row>
				<Column sm='12' lg='4'>
					<FloatLabelInput
						id='name'
						label='Nome'
						onChange={handleChange}
						value={contract.name}
						{...primaryInputStyle}
					/>
				</Column>

				<Column sm='12' lg='4'>
					<FloatLabelInput
						id='companyFiscalID'
						label='CNPJ'
						onChange={handleChange}
						maxLength={18}
						value={UtilMask.formatCNPJ(contract.companyFiscalID)}
						// disabled={disableInput}
						hasError={!UtilValidators.checkCNPJ(contract.companyFiscalID)}
						errorMessage={'CNPJ inválido'}
						{...primaryInputStyle}
					/>
				</Column>

				<Column sm='12' lg='3.3'>
					<Select
						id='clients'
						className='select-client-type'
						placeholder='Tipo de Cliente'
						options={CUSTOMER_SELECT_TYPE}
						value={formatSelectOption(contract.customerType)}
						onChange={handleSelectChange}
						{...selectStyle}
					/>
				</Column>
			</Row>
			<Row>
				<Column sm='12' lg='2'>
					<FloatLabelInput
						id='contractedKits'
						label='Kits Contratados'
						onChange={handleChange}
						value={convertNumberToString(contract.contractedKits)}
						{...primaryInputStyle}
					/>
				</Column>

				<Column sm='12' lg='2'>
					<FloatLabelInput
						id='subscriptionFee'
						label='Valor Assinatura'
						onChange={handleChange}
						value={toMoney(contract.subscriptionFee)}
						{...primaryInputStyle}
					/>
				</Column>

				<Column sm='12' lg='2'>
					<FloatLabelInput
						id='examFee'
						label='Valor Exame'
						onChange={handleChange}
						value={toMoney(contract.examFee)}
						{...primaryInputStyle}
					/>
				</Column>

				<Column sm='12' lg='2'>
					<FloatLabelDateInput
						id='activatedAt'
						label='Data de Ativação'
						date={formatedDate(new Date(contract.activatedAt))}
						onBlur={handleSetDate}
						className='input-date'
						{...primaryInputStyle}
					/>
				</Column>

				<Column sm='12' lg='3.3'>
					<FloatLabelInput
						id='contractMonthLength'
						label='Tempo de Contrato'
						onBlur={formatTextDuration}
						onFocus={convertDurationInNumber}
						onChange={handleChange}
						value={duration}
						{...primaryInputStyle}
					/>
				</Column>
			</Row>

			<div className='comments-div'>
				<h2>Observações</h2>

				<textarea
					name='observation'
					id='observation'
					placeholder='Campo aberto para escritas sobre contato, informações e outras observações'
					value={contract.observation ? contract.observation : ''}
					onChange={handleChange}
				/>
			</div>

			{renderUpload()}

			{!!contract.files?.length &&
				contract.files.map((upload: string, index: number) => (
					<div className='document edit' key={index + upload}>
						<figure>
							<img src={file} alt='Icon upload' />
							{action === 'CREATE' ? (
								<legend>{upload}</legend>
							) : (
								// eslint-disable-next-line react/jsx-no-target-blank
								<a href={upload} target='_blank'>
									{upload.split(urlAws)}
								</a>
							)}
						</figure>
						<button
							className='btn-clean btn-remove'
							onClick={() => removeUpload(index)}
						>
							Excluir
						</button>
					</div>
				))}

			<SwitchesWrapper>
				<Switch
					label='LINDA First Care'
					isChecked={checkboxTablet}
					onChange={handleCheckboxTablet}
				/>
			</SwitchesWrapper>
		</FormContainer>
	)
}

export default FormData
