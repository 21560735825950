import React, {
	ChangeEvent,
	createElement,
	FocusEvent,
	useCallback,
	useEffect,
	useState
} from 'react'
import { isValid, parse } from 'date-fns'
import pt from 'date-fns/locale/pt-BR'
import { useHistory, useLocation } from 'react-router-dom'

import { IModalDeleteProps, IViewProps } from './types'
import CreateAndEditContracts from './view'
import {
	CustomerTypeEnum,
	EntityTypeEnum,
	IContract,
	INewContrat,
	INITIAL_STATE
} from 'shared/interfaces/contract'
import { ScreenActionsEnum } from 'modules/ContractsPage/types'
import { IModalDeleteTarget } from 'shared/components/ModalDelete/types'
import fileUpload from '../../assets/images/file-upload.svg'
import { IAttachment } from 'shared/interfaces/attachment'
import { toNumber } from 'shared/util/currency'
import { DATE_FORMAT, formatDate } from 'shared/util/format'
import { UtilMask, UtilValidators } from '@buildbox/utils'
import routesEnum from 'modules/Routes/enum'
import {
	createContract,
	deleteContract,
	findContract,
	updateContract
} from 'shared/services/contract.service'
import {
	errorMessages,
	successMessages,
	viewErrorContract
} from 'shared/util/Messages'
import { validateDuration } from 'shared/util/validateFields'
import { cleanDocumentNumber } from 'shared/util/cleanNumberDocument'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from 'shared/util/toaster'
import { removeFileToS3, uploadToS3 } from 'shared/services/awsService'

import { useSelectedProfileIsOneOf } from 'shared/util/profilePermission'
import {
	IAuthenticationModalProps,
	INITIAL_AUTHENTICATION_MODAL_PROPS
} from 'shared/components/AuthModal/types'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'

function CreateAndEditContractsContainer(): JSX.Element {
	const history = useHistory()
	const { state } =
		useLocation<{
			action: ScreenActionsEnum
			contractId: string
		}>()
	const { action, contractId } = state || {
		action: 'CREATE',
		contractId: ''
	}
	const { user } = useTypedSelector(['user'])

	const [targetDeleteContract, setTargetDeleteContract] =
		useState<IModalDeleteTarget | null>(null)
	const [newFormContract, setNewFormContract] =
		useState<INewContrat>(INITIAL_STATE)
	const [storageFiles, setStorageFiles] = useState<string[]>([])

	const [currentContract, setCurrentContract] = useState<IContract>()

	const [enableBtnSubmit, setEnableBtnSubmit] = useState(false)
	const [loading, setLoading] = useState(false)
	const [viewErrors, setViewErrors] = useState<string[]>([])
	const [files, setFiles] = useState<IAttachment[]>([])
	const [checkboxTablet, setCheckboxTablet] = useState(false)
	const [authModal, setAuthModal] = useState(INITIAL_AUTHENTICATION_MODAL_PROPS)
	const [isModalDeleteActive, setIsModalDeleteActive] = useState(false)
	const isAdmin = useSelectedProfileIsOneOf(['ADMIN'])

	function initializeFields() {
		if (action === 'EDIT') {
			getContract()
		} else {
			setNewFormContract({ ...INITIAL_STATE, activatedAt: new Date() })
		}
	}

	function handleSelectChange(selectOption: any) {
		setNewFormContract((props) => ({
			...props,
			customerType: selectOption.value
		}))
	}

	function getContract() {
		;(async () => {
			try {
				const contract = await findContract(contractId)
				const cleanDocument = cleanDocumentNumber(contract.companyFiscalID)

				const newContract = {
					...contract,
					tablet: contract?.tablet ? contract.tablet : false,
					companyFiscalID: cleanDocument
				}

				setCheckboxTablet(newContract.tablet)
				setCurrentContract(newContract)
				setNewFormContract(newContract)
				setStorageFiles(newContract?.files || [])
			} catch (error) {}
		})()
	}

	function handleChange(
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) {
		const { id, value } = event.target

		let formatValue: any = value

		if (id === 'examFee' || id === 'subscriptionFee') {
			formatValue = toNumber(value)
		}

		if (id === 'contractedKits') {
			formatValue = Number.parseInt(value)
		}
		if (id === 'contractMonthLength') {
			formatValue = Number.parseInt(value)
			if (isNaN(formatValue) || !value) formatValue = 0
			if (!validateDuration(formatValue)) {
				addSpecificError(viewErrorContract.sizeMinDuration)
				formatValue = 0
			} else {
				cleanSpecificError(viewErrorContract.sizeMinDuration)
			}
		}

		if (id === 'companyFiscalID') {
			formatValue = cleanDocumentNumber(value)
			if (formatValue.length >= 14 && !validateCNPJ()) {
				addSpecificError(viewErrorContract.CNPJFilledCorrectly)
			}
		}

		setNewFormContract({
			...newFormContract,
			[id]: formatValue
		})
	}

	function handleSetDate(event: FocusEvent<HTMLInputElement>) {
		const { value } = event.target

		const parseDate = parse(value, DATE_FORMAT, new Date(), { locale: pt })
		const isValidDate = isValid(parseDate)

		if (!isValidDate) {
			addSpecificError(viewErrorContract.invalidDate)
			setNewFormContract({
				...newFormContract,
				activatedAt: new Date()
			})

			setTimeout(() => {
				cleanSpecificError(viewErrorContract.invalidDate)
			}, 1000)
			return
		}

		setNewFormContract({
			...newFormContract,
			activatedAt: parseDate
		})
	}

	function addSpecificError(viewError: string) {
		const filterError = viewErrors.filter((error) => error === viewError).length

		if (filterError >= 1) return

		setViewErrors([...viewErrors, viewError])
	}

	function cleanSpecificError(viewError: string) {
		setViewErrors((error) =>
			error.filter((upload) => !upload.includes(viewError))
		)
	}

	function handleCompanyType(type: EntityTypeEnum) {
		setNewFormContract({
			...newFormContract,
			entityType: type
		})
	}

	function renderButtonDelete(): JSX.Element | null {
		if (action === 'CREATE' || !isAdmin) return null

		return (
			<button
				className='btn-clean btn-remove'
				onClick={() => {
					setTargetDeleteContract({
						id: contractId || '',
						name: newFormContract.name
					})
					setIsModalDeleteActive(true)
				}}
			>
				Excluir Contrato
			</button>
		)
	}

	const validateCNPJ = useCallback(() => {
		const { companyFiscalID } = newFormContract

		if (companyFiscalID.length < 14) return false

		return UtilValidators.checkCNPJ(companyFiscalID)
	}, [newFormContract])

	const validateDate = useCallback(() => {
		const { activatedAt } = newFormContract

		const formatD = formatDate(new Date(activatedAt))
		const parseDate = parse(formatD, DATE_FORMAT, new Date(), { locale: pt })

		return isValid(parseDate)
	}, [newFormContract])

	const handleViewError = useCallback((): boolean => {
		const errors: string[] = []
		const { contractMonthLength } = newFormContract

		if (!validateDate()) {
			errors.push(viewErrorContract.invalidDate)
		}

		if (!validateCNPJ()) {
			errors.push(viewErrorContract.CNPJFilledCorrectly)
		}

		if (!validateDuration(contractMonthLength)) {
			errors.push(viewErrorContract.sizeMinDuration)
		}

		if (!newFormContract.customerType) {
			errors.push(viewErrorContract.customerType)
		}

		setViewErrors(errors)

		return !!errors.length
	}, [newFormContract, validateCNPJ, validateDate])

	function cleanErrorUpload() {
		const cleanViewErros =
			newFormContract.files && files.every((upload) => upload.size < 10_000_000)

		if (cleanViewErros) {
			cleanSpecificError(viewErrorContract.uploadSizeExceeded)
		}
	}

	async function handleUploadDocument(event: ChangeEvent<HTMLInputElement>) {
		const selectedDocument = event.target?.files?.item(0)

		if (!selectedDocument) return

		const result: IAttachment = {
			file: selectedDocument,
			name: selectedDocument.name,
			size: selectedDocument.size
		}

		if (result.size >= 10_000_000) {
			addSpecificError(viewErrorContract.uploadSizeExceeded)
			event.target.value = ''

			return
		}

		setFiles([...files, result])
		setNewFormContract({
			...newFormContract,
			files: [
				...(newFormContract.files ? newFormContract.files : []),
				result.name
			]
		})

		event.target.value = ''
	}

	async function handleUploadAttachment(
		document: IAttachment
	): Promise<IAttachment> {
		if (!document.file) return Promise.reject()
		const { Location } = await uploadToS3(document.file)

		if (!Location) return Promise.reject()
		return {
			url: Location,
			name: document.name,
			size: document.size
		}
	}

	function removeUpload(index: number) {
		if (!newFormContract.files) return
		const uploadRemoved = [...newFormContract.files]
		const deleted = uploadRemoved.splice(index, 1)[0]

		setFiles((files) => files.filter((upload) => upload.name !== deleted))
		setNewFormContract({
			...newFormContract,
			files: uploadRemoved
		})
	}

	function renderUpload(): JSX.Element {
		return (
			<label className='document create' htmlFor='input'>
				<figure>
					<img src={fileUpload} alt='Icon upload' />
					<legend>Upload de Contrato</legend>
					<input
						className='input'
						type='file'
						accept='.xlss, .xls, .jpg, .jpge, .png, .doc, .docx, .pdf, .wir, .zip'
						id='input'
						onChange={handleUploadDocument}
					/>
				</figure>
			</label>
		)
	}

	function isAllRequiredFieldsValid() {
		const {
			entityType,
			name,
			companyFiscalID,
			contractedKits,
			activatedAt,
			contractMonthLength
		} = newFormContract

		const array = [
			entityType,
			name,
			companyFiscalID,
			contractedKits,
			activatedAt,
			contractMonthLength
		]

		let isValid = !array.some((item) => !item)

		const companyDocumentIsValid = UtilValidators.checkCNPJ(companyFiscalID)

		if (companyDocumentIsValid) {
			cleanSpecificError(viewErrorContract.CNPJFilledCorrectly)
		}
		return isValid && companyDocumentIsValid
	}

	function isArrayEqual(array: any[], compareArray: any[]) {
		if (!array) return false
		if (array.length !== compareArray.length) return false
		for (var index = 0; index < array.length; ++index) {
			if (JSON.stringify(array[index]) !== JSON.stringify(compareArray[index]))
				return false
		}
		return true
	}

	function wasThereAnUpdateInInputs() {
		if (!currentContract) return false
		const oldValues = [currentContract]
		const newValues = [newFormContract]

		const udpated = !isArrayEqual(oldValues, newValues)
		const allFieldIsFilled = isAllRequiredFieldsValid()
		return udpated && allFieldIsFilled
	}

	const handleDeleteContractEnd = useCallback(() => {
		setIsModalDeleteActive(false)
		handleOpenAuthModal()
	}, [])

	function handleValidateForm() {
		let enableButton: boolean
		if (action === 'CREATE') {
			enableButton = isAllRequiredFieldsValid()
		} else {
			enableButton = wasThereAnUpdateInInputs()
		}

		setEnableBtnSubmit(isAdmin && enableButton)
	}

	const handleDeleteOnClose = useCallback(() => {
		setTargetDeleteContract(null)
		setIsModalDeleteActive(false)
	}, [])

	const formatContract = useCallback(
		(response: IAttachment[]) => {
			return {
				...newFormContract,
				companyFiscalID: UtilMask.formatCNPJ(newFormContract.companyFiscalID),
				files: response.map((item) => item?.url || ''),
				customerType: newFormContract.customerType as CustomerTypeEnum
			}
		},
		[newFormContract]
	)

	const uploadAllFilesToAwsAndCreateContract = useCallback(() => {
		Promise.all(files.map(handleUploadAttachment))
			.then(async (response) => {
				createContract(formatContract(response))
			})
			.then(async () => {
				cogoToast.success(successMessages.contractCreated, cogoDefaultOptions)
				history.replace(routesEnum.CONTRACTS)
			})
			.catch((e) => {
				if (e.code !== 'ETagMissing') {
					cogoToast.error(errorMessages.contractNotCreated, cogoDefaultOptions)
				}
			})
	}, [files, formatContract, history])

	const updateFilesToAwsAndUpdateContract = useCallback(() => {
		const toUpload = files.filter((file) => !file.url && !file.url)
		const toDelete =
			storageFiles.filter(
				(deleted) => !newFormContract?.files?.includes(deleted)
			) || []

		const toKeep = storageFiles
			.filter((file) => !toDelete.includes(file || ''))
			.map((x) => {
				return {
					url: x
				} as IAttachment
			})

		toDelete.map((removed) => removeFileToS3(removed))

		Promise.all(toUpload.map(handleUploadAttachment))
			.then(async (response) => {
				const attachments = [...response, ...toKeep]
				await updateContract(contractId, formatContract(attachments), {
					email: user.email,
					password: authModal.password
				})
			})
			.then(async () => {
				cogoToast.success(successMessages.contractUpdated, cogoDefaultOptions)
				history.replace(routesEnum.CONTRACTS)
			})
			.catch((e) => {
				if (e.code !== 'ETagMissing') {
					cogoToast.error(errorMessages.contractNotUpdated, cogoDefaultOptions)
				}
			})
	}, [
		contractId,
		files,
		formatContract,
		history,
		newFormContract,
		storageFiles,
		user.email,
		authModal.password
	])

	const handleSubmitEdit = useCallback(() => {
		;(async () => {
			if (files.length > 0) {
				updateFilesToAwsAndUpdateContract()
				return
			}

			await updateContract(
				contractId,
				{
					...newFormContract,
					companyFiscalID: UtilMask.formatCNPJ(newFormContract.companyFiscalID),
					customerType: newFormContract.customerType as CustomerTypeEnum
				},
				{
					email: user.email,
					password: authModal.password
				}
			)
			cogoToast.success(successMessages.contractUpdated, cogoDefaultOptions)
			history.replace(routesEnum.CONTRACTS)
		})()
	}, [
		files.length,
		contractId,
		newFormContract,
		user.email,
		authModal.password,
		history,
		updateFilesToAwsAndUpdateContract
	])

	const handleSubmitCreate = useCallback(() => {
		;(async () => {
			if (files.length > 0) {
				uploadAllFilesToAwsAndCreateContract()
				return
			}

			await createContract({
				...newFormContract,
				companyFiscalID: UtilMask.formatCNPJ(newFormContract.companyFiscalID),
				customerType: newFormContract.customerType as CustomerTypeEnum
			})

			cogoToast.success(successMessages.contractCreated, cogoDefaultOptions)
			history.replace(routesEnum.CONTRACTS)
		})()
	}, [
		files.length,
		history,
		newFormContract,
		uploadAllFilesToAwsAndCreateContract
	])

	function handleCheckboxTablet() {
		setCheckboxTablet(!checkboxTablet)
	}

	function handleUpdateCheckbox() {
		setNewFormContract({ ...newFormContract, tablet: checkboxTablet })
	}

	const handleSubmit = useCallback(() => {
		;(async () => {
			const errorsCount = handleViewError()
			if (errorsCount) return

			setLoading(true)
			try {
				if (action === 'EDIT') {
					handleSubmitEdit()
				} else {
					handleSubmitCreate()
				}
			} catch (error) {
			} finally {
				setTimeout(() => {
					setLoading(false)
				}, 1000)
			}
		})()
	}, [action, handleSubmitCreate, handleSubmitEdit, handleViewError])

	const handleDelete = useCallback(() => {
		;(async () => {
			try {
				setLoading(true)

				if (!targetDeleteContract) return null
				await deleteContract(
					targetDeleteContract.id,
					user.email,
					authModal.password
				)

				cogoToast.success(successMessages.unitDeleted, cogoDefaultOptions)
				history.replace(routesEnum.CONTRACTS)
			} finally {
				setAuthModal(INITIAL_AUTHENTICATION_MODAL_PROPS)
				setTargetDeleteContract(null)
				setLoading(false)
			}
		})()
	}, [targetDeleteContract, history, user.email, authModal.password])

	function handleOpenAuthModal() {
		setAuthModal((state) => ({ ...state, value: true }))
	}
	const handleCloseAuthModal = useCallback(() => {
		setAuthModal(INITIAL_AUTHENTICATION_MODAL_PROPS)
		if (!!targetDeleteContract) {
			setTargetDeleteContract(null)
		}
	}, [targetDeleteContract])

	const handlePasswordChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const password = event.target.value || ''
			setAuthModal((state) => ({ ...state, password }))
		},
		[]
	)

	useEffect(initializeFields, [])
	useEffect(handleValidateForm, [newFormContract])
	useEffect(handleUpdateCheckbox, [checkboxTablet])
	useEffect(cleanErrorUpload, [newFormContract.files])

	const onSubmit = action === 'CREATE' ? handleSubmit : handleOpenAuthModal

	const authModalConfig: IAuthenticationModalProps = {
		...authModal,
		handleSubmit: !!targetDeleteContract ? handleDelete : handleSubmit,
		handleClose: handleCloseAuthModal,
		handlePasswordChange,
		loadingSubmit: loading
	}

	const modalDeleteProps: IModalDeleteProps = {
		onClose: handleDeleteOnClose,
		target: targetDeleteContract,
		handleDeleteUserEnd: handleDeleteContractEnd,
		state: isModalDeleteActive
	}

	const viewProps: IViewProps = {
		action,
		renderButtonDelete,
		newFormContract,
		handleChange,
		handleCompanyType,
		renderUpload,
		removeUpload,
		enableBtnSubmit,
		handleSetDate,
		viewErrors,
		loading,
		handleCheckboxTablet,
		checkboxTablet,
		onSubmit,
		authModalConfig,
		modalDeleteProps,
		handleSelectChange
	}

	return createElement(CreateAndEditContracts, viewProps)
}

export default CreateAndEditContractsContainer
