import React, { useState } from 'react'

import { IInputPasswordProps } from './types'
import { Eye, EyeOff } from 'react-feather'
import { InputPasswordWrapper } from './styles'
import { FloatLabelInput } from '@buildbox/components'
import { colors } from 'shared/styles/theme'

function InputPassword({
	id,
	label,
	value,
	onChange,
	...props
}: IInputPasswordProps) {
	const [locked, setEye] = useState(false)

	function handleClickOnEye() {
		setEye(!locked)
	}

	return (
		<InputPasswordWrapper>
			<FloatLabelInput
				id={id}
				label={label}
				value={value}
				onChange={onChange}
				type={locked ? 'text' : 'password'}
				{...props}
			/>
			<>
				{locked ? (
					<span className='at-icon' onClick={handleClickOnEye}>
						<Eye size={20} color={colors.placeholder}/>
					</span>
				) : (
					<span className='lock-icon' onClick={handleClickOnEye}>
						<EyeOff size={20} color={colors.placeholder}/>
					</span>
				)}
			</>
		</InputPasswordWrapper>
	)
}

export default InputPassword
