import React from 'react'
import { IViewProps } from './types'
import { ChangePasswordWrapper, CheckboxForm } from './styles'

import { FloatLabelInput } from '@buildbox/components'
import { primaryInputStyle } from 'shared/styles/theme'

import InputPassword from 'shared/components/InputPassword'
import WrapperChangePassword from 'shared/components/WrapperChangePassword'
import ViewErrors from 'shared/components/ViewErrors'
import { hasNumber, hasLetter } from 'shared/util/validateFields'

function ChangePassword(props: IViewProps): JSX.Element {
	const {
		handlePassword,
		handlePasswordCheck,
		password,
		passwordCheck,
		handleSubmit,
		isLoading,
		isFormValid,
		viewErrors,
		title,
		message,
		user,
		type,
		isTermChecked,
		handleCheckboxStatus
	} = props

	function handleMessageInfoPassword() {
		return password.length >= 6
	}

	function renderMessage(message: string, validation: boolean): JSX.Element {
		return (
			<div className={`message-info ${validation ? 'valid' : 'invalid'}`}>
				<div className='circle'></div>
				<p>{message}</p>
			</div>
		)
	}
	return (
		<ChangePasswordWrapper>
			<WrapperChangePassword
				type={type}
				onSubmit={handleSubmit}
				isFormValid={isFormValid}
				isLoading={isLoading}
				titleButton='Continuar'
				subtitle={message}
				title={title}
				height={!!viewErrors && 450}
			>
				<>
					<FloatLabelInput
						className='input'
						label='E-mail'
						id='email'
						value={user.email}
						disabled={true}
						{...primaryInputStyle}
						style={{ marginBottom: 32 }}
					/>

					<InputPassword
						className='input'
						label='Digite uma senha'
						id='password'
						value={password.trim()}
						onChange={handlePassword}
						onFocus={handleMessageInfoPassword}
						{...primaryInputStyle}
					/>

					<div className='message-container'>
						{renderMessage('6 CARACTERES', password.length >= 6)}
						{renderMessage('NÚMERO', hasNumber(password))}
						{renderMessage('LETRAS', hasLetter(password))}
					</div>

					<InputPassword
						className='input'
						label='Repetir senha'
						id='password'
						value={passwordCheck.trim()}
						onChange={handlePasswordCheck}
						{...primaryInputStyle}
					/>

					<CheckboxForm>
						<input
							checked={isTermChecked}
							onChange={handleCheckboxStatus}
							type='checkbox'
						/>
						<p>
							Tenho ciência que o ID/Senha é pessoal e intransferível e não deve
							ser compartilhado.
						</p>
					</CheckboxForm>

					<ViewErrors errors={viewErrors} color='red' />
				</>
			</WrapperChangePassword>
		</ChangePasswordWrapper>
	)
}

export default ChangePassword
