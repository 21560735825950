import React from 'react'
import { IProps } from './types'
import CheckBox from '../CheckBox'
import { colors } from 'shared/styles/theme'
import { translate } from 'shared/util/translate'

export default function CheckBoxLegalNature({
	type,
	checkboxState,
	handleCheckbox
}: IProps) {
	const value = checkboxState.name === type && checkboxState.checked

	return (
		<div onClick={() => handleCheckbox(type)}>
			<CheckBox
				id={type}
				name={type}
				checked={value}
				onChange={() => handleCheckbox(type)}
			/>
			<p
				style={{
					color: value ? colors.secondary : ''
				}}
			>
				{translate(type)}
			</p>
		</div>
	)
}
