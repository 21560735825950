import { fontType, mediaQuery, toRem } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'
import styled from 'styled-components'

export const DashboardWrapper = styled.div`
	button {
		cursor: pointer;
	}

	.flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.section-map {
		height: 100%;

		.gm-style iframe + div {
			border: none !important;
		}
	}

	.period {
		margin: 40px 0 20px;
	}

	.background-graph {
		background-color: ${colors.white};
	}

	.title {
		padding: 16px;
		${fontType(0)};
		font-size: 14px;
		color: ${colors.darkGrey};
	}

	.box-graphic-bar {
		height: ${toRem(285)};
		${fontType(0)};
		font-size: 12px;
		color: ${colors.darkGrey};
	}

	.box-graphic-pie {
		height: ${toRem(215)};
		${fontType(0)};
		font-size: 12px;
		color: ${colors.darkGrey};
	}

	.placeholder {
		height: 100%;
	}

	.box-info-data {
		padding: 0 ${toRem(24)};
		height: calc(50% - 10px);

		h1 {
			${fontType(-1)};
			font-size: ${toRem(20)};
			color: ${colors.darkGrey};
		}
		span h2 {
			${fontType(-1)};
			font-size: ${toRem(34)};
		}
	}

	.box-info-data + .box-info-data {
		margin-top: ${toRem(20)};
	}

	${mediaQuery('sm')(`
		.box-info-data{
			height: 94px;
		}
		.section-map{
			height: 300px;
		}
		.period{
			display: block;
			h1{
				margin-bottom: 6px;
			}
		}
	`)}
`

export const LoadingContainer = styled.div`
	display: flex;
	justify-content: center;
`
