import { mediaQuery, toRem } from 'shared/styles/functions'
import styled from 'styled-components'

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	${mediaQuery('sm')(`
		& {
			flex-direction: column;

			> h1 {
				align-self: center;
				margin-bottom: ${toRem(20)};
			}
			div {
				flex-direction: column;

				button {
					margin-top: ${toRem(20)};
					margin-left: 0;
				}
			}
		}
	`)};
`

export const HeaderContainer = styled.div`
	display: flex;
	align-items: center;
	#search {
		min-width: ${toRem(250)};
	}
	button {
		margin-left: ${toRem(45)};
		text-transform: uppercase;
	}
`
