import React, { useState } from 'react'
import { Button, Loading, Modal } from '@buildbox/components'
import { colors, primaryButtonStyle } from 'shared/styles/theme'
import { IModalDeleteProps } from './types'
import { MESSAGE_ENUM, TITLE_ENUM } from './data'
import {
	DeleteContainer,
	LoadingWrapper,
	TargetName,
	TextDelete
} from './styles'

export default function ModalDelete({
	onClose,
	type,
	handleDeleteUserEnd,
	target
}: IModalDeleteProps) {
	const [isLoading, setLoading] = useState(false)
	// const updateContractsFilter = useUpdateContractsFilter()

	const isActive = !!target
	const title = TITLE_ENUM[type]
	const message = MESSAGE_ENUM[type]

	return (
		<Modal
			className='modal-primary'
			size={376}
			title={title}
			isActive={isActive}
			onClose={onClose}
			scrollRef='scroll-ref'
		>
			<DeleteContainer>
				<TextDelete>{message}</TextDelete>

				{target?.name && <TargetName>{target.name} ?</TargetName>}

				{isLoading ? (
					<LoadingWrapper>
						<Loading type='Sentry' primaryColor={colors.secondary} />
					</LoadingWrapper>
				) : (
					<Button onClick={handleDeleteUserEnd} {...primaryButtonStyle}>
						{type === 'KIT_ASSOCIATE'
							? 'CONFIRMAR'
							: type !== 'KIT_DISASSOCIATE'
							? 'EXCLUIR'
							: 'CONFIRMAR'}
					</Button>
				)}
			</DeleteContainer>
		</Modal>
	)
}
