import { subHours } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'

import { IDashboardItem } from 'shared/interfaces/dasboard'
import {
	IFilter,
	PaginatedRequest,
	PaginatedResult
} from 'shared/interfaces/pagination'

import {
	IUnit,
	IDeleteUnit,
	IUnitsPage,
	IUnitPaginated
} from 'shared/interfaces/unit'

import { QUERY_KEYS } from 'shared/util/reactQuery'
import axiosInstance from './axiosInstance'

export async function createUnit(
	unit: Omit<IUnit, '_id' | 'location'>
): Promise<string> {
	const response = await axiosInstance.post(`/units`, unit)

	return response.data
}

export async function updateUnit(
	id: string,
	unit: Omit<IUnit, 'entityType' | 'contract' | 'companyFiscalID' | 'location'>,
	email: string,
	password: string
): Promise<string> {
	const response = await axiosInstance.patch(`/units/${id}`, unit, {
		headers: {
			EAuth: email,
			PAuth: password
		}
	})

	return response.data
}

export async function deleteUnit(
	id: string,
	email: string,
	password: string
): Promise<IDeleteUnit> {
	const response = await axiosInstance.delete(`/units/${id}`, {
		headers: {
			EAuth: email,
			PAuth: password
		}
	})

	return response.data
}

export async function findUnit(id: string): Promise<IUnit> {
	const response = await axiosInstance.get(`/units/${id}`)

	return response.data
}

export interface IGetDashboardRequest {
	args: {
		filters: IFilter[]
		sort: string
		order: number
		startDate?: Date
		endDate?: Date
	}
}
export interface IGetLiveDashboardRequest {
	args: {
		filters: IFilter[]
		sort: string
		order: number
	}
}

export type IGetDashboardResponse = IDashboardItem[]

async function getDashboard({
	args
}: IGetDashboardRequest): Promise<IGetDashboardResponse> {
	const response = await axiosInstance.post('/units/get-dashboard', args)

	return response.data
}

export function useDashboard({
	args
}: IGetDashboardRequest): UseQueryResult<IGetDashboardResponse> {
	const { filters, order, sort, startDate, endDate } = args

	const enabled = !!(filters && order && sort && startDate && endDate)

	return useQuery(
		[QUERY_KEYS.DASHBOARD, { args }],
		async ({ queryKey }) => {
			const [_key, params] = queryKey
			const { args } = params as IGetDashboardRequest

			return getDashboard({ args })
		},
		{
			enabled,
			refetchInterval: 60000,

			/**
			 * This will make the refetch occurr only when triggers
			 * the interval, focus or online and the data is not stale anymore.
			 * obs: When the interval is triggered the stale is ignored.
			 */
			staleTime: 60000
		}
	)
}

export function useLiveDashboard({
	args
}: IGetLiveDashboardRequest): UseQueryResult<IGetDashboardResponse> {
	const { filters, order, sort } = args

	const enabled = !!(filters && order && sort)

	const now = new Date()
	const startDateLive = subHours(now, 4)

	return useQuery(
		[QUERY_KEYS.LIVE_DASHBOARD, { args }],
		async ({ queryKey }) => {
			const [_key, params] = queryKey
			const { args } = params as IGetDashboardRequest

			return getDashboard({
				args: { ...args, startDate: now, endDate: startDateLive }
			})
		},
		{
			enabled,
			refetchInterval: 60000,

			/**
			 * This will make the refetch occurr only when triggers
			 * the interval, focus or online and the data is not stale anymore.
			 * obs: When the interval is triggered the stale is ignored.
			 */
			staleTime: 60000
		}
	)
}

export type IFetchUnitsRequest = PaginatedRequest<{}>
export type IFetchUnitsResponse = PaginatedResult<IUnitPaginated>

export async function fetchUnitsPage({
	page,
	limit,
	search,
	sort,
	order,
	filters
}: IFetchUnitsRequest): Promise<IFetchUnitsResponse> {
	// The server call this pageIndex but actually it's the page number
	const response = await axiosInstance.post('/units/get-page', {
		pageIndex: page,
		maxItemsInPage: limit,
		searchString: search,
		sort,
		order,
		filters
	})

	return response.data
}

export function useUnitsPage({
	page,
	limit,
	search,
	sort,
	order,
	filters
}: IFetchUnitsRequest): UseQueryResult<IFetchUnitsResponse> {
	const enabled = !!(page && limit && filters)

	return useQuery(
		[QUERY_KEYS.UNITS_PAGE, { page, limit, search, sort, order, filters }],
		async ({ queryKey }) => {
			const [_key, params] = queryKey
			const { page, limit, search, sort, order, filters } =
				params as IFetchUnitsRequest

			return fetchUnitsPage({
				page,
				limit,
				search,
				sort,
				order,
				filters
			})
		},
		{
			enabled
		}
	)
}
