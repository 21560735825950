import { TableNavProps } from '@buildbox/components'
import { QueryStatus } from 'react-query'
import { IAcessorAndOrder } from 'shared/components/Table/types'
import { CustomerType, CustomerTypeEnum } from 'shared/interfaces/contract'
import { IColumns, IKitLog, IKitLogHistory } from 'shared/interfaces/financial'

export interface IViewProps {
	tableColumns: any[]
	tableRows: Object[]
	navProps: TableNavProps
	range: IRangeCalendar
	isVisible: boolean
	monthYear: ISelectCalendar
	showMonthPicker: () => void
	handleOnDismiss: () => void
	handleOnChange: (year: number, month: number) => void
	getMonthValue: () => string
	sortBy: IAcessorAndOrder
	handlerSortColumn(acessor: string, order: number): IAcessorAndOrder
	periodSelected: string
	handleSearch(value: string): void
	search: string
	tableTotal: any
	handleModal: (id: string) => void
	modalIsOpen: boolean
	onClose: () => void
	kitLog: IModalKitLogs
	handleDowloadExamData(contractID: string): void
	isCommercialPartnerProfile: () => boolean
	financialStatus: QueryStatus
	handleSelectChange(SelectOption: any): void
	customerType: CustomerTypeEnum | CustomerType.ALL
}

export interface ISelectCalendar {
	month: number
	year: number
}

export interface IRangeCalendar {
	min: ISelectCalendar
	max: ISelectCalendar
}

export const INITIAL_SORT: IAcessorAndOrder = {
	acessor: 'createdAt',
	order: -1
}

export interface ISelectCheckbox {
	value: string
	checked: boolean
}

export interface ITableRow {
	name: string
	kitsActive: number
	totalSubscriptions: string
	examsMade: number
	totalExams: string
	total: string
	idContract: string
	subRows?: ITableRow[]
	kits?: IKitLogHistory[]
	currentExamFee?: string
	currentSubscriptionFee?: string
}

export interface ITableColumn {}

export interface IModalKitLogs extends IColumns {
	unit?: IKitLog
}
