import React from 'react'

import InputSearch from '../InputSearch'
import MenuPrimary from '../MenuPrimary'
import { IMenu } from '../MenuPrimary/types'
import MenuOption from '../MenuPrimary/MenuOption'
import { IProps } from './types'
import { CompositeMenuWrapper, InputWrapper } from './styles'
import { colors } from 'shared/styles/theme'
import { deepCopy } from 'shared/util/object'
import Switch from '../Switch'

export default function CompositeMenu({
	searchLabel,
	searchValue,
	getSearch,
	allSelectedValue,
	getAllSelected,
	menuContent,
	getContentUpdate,
	showChevron = false,
	styles,
	renderSwitch,
	renderSwitchHeader
}: IProps): JSX.Element {
	// const [search, setSearch] = useState(searchValue)
	// const [searchDebounce] = useDebounce(search, 500)
	// const [allSelected, setAllSelected] = useState(allSelectedValue)
	// const [menus, setMenus] = useState<IMenu[]>(menuContent)

	function handleSearch(value: string): void {
		getSearch(value)
	}

	function handleMenuUpdate(menuValues: IMenu) {
		const menuIndex = menuContent.findIndex(
			(menu) => menu.value === menuValues.value
		)
		if (menuIndex !== -1) {
			const updatedMenus = deepCopy(menuContent)
			updatedMenus[menuIndex] = menuValues

			if (updatedMenus.every((menu) => menu.selected)) getAllSelected(true)

			if (updatedMenus.some((menu) => !menu.selected)) getAllSelected(false)

			// setMenus(updatedMenus)
			getContentUpdate(updatedMenus)
		}
	}

	function toggleAllSelected() {
		const selectedUpdated = !allSelectedValue
		// setAllSelected(selectedUpdated)
		getAllSelected(selectedUpdated)
		setAllMenusSelections(selectedUpdated)
	}

	function setAllMenusSelections(value: boolean) {
		const updatedMenus: IMenu[] = deepCopy(menuContent)
		updatedMenus.forEach((menu) => {
			menu.selected = value
			menu.options.forEach((option) => (option.selected = value))
		})
		// setMenus(updatedMenus)
		getContentUpdate(updatedMenus)
	}

	// useEffect(() => getSearch(searchDebounce), [searchDebounce])
	// useEffect(() => setMenus(menuContent), [menuContent])

	const menuStyles = {
		...styles,
		width: '100%',
		backgroundColor: styles.menuBackgroundColor
	}

	const styleSwitch = renderSwitch
		? {
				display: 'flex',
				justifyContent: 'space-between'
		  }
		: {}
	const menuOptionStyles = {
		...menuStyles,
		width: '100%',
		height: styles.optionsHeight,
		paddingTop: 20,
		paddingBottom: 20,
		marginBottom: 4,
		...styleSwitch
	}
	return (
		<CompositeMenuWrapper
			style={{
				backgroundColor: styles.backgroundColor,
				width: styles.width,
				maxHeight: styles.maxHeight,
				top: styles.top,
				left: styles.left
			}}
		>
			<InputWrapper style={{ width: '100%' }}>
				<InputSearch
					id='search'
					label={searchLabel}
					onChange={(e: any) => handleSearch(e.target.value)}
					value={searchValue}
					style={{
						// width: '100%',
						borderColor: colors.backgroundMenuActive
					}}
				/>
			</InputWrapper>

			<MenuOption
				id={'all-selected'}
				label={'Todos'}
				selected={allSelectedValue}
				onSelect={toggleAllSelected}
				styles={menuOptionStyles}
				renderSwitch={renderSwitch}
			/>

			{menuContent.map((menu) => {
				return menu.visible ? (
					<MenuPrimary
						key={menu.value}
						menuValues={menu}
						getMenuUpdate={handleMenuUpdate}
						styles={menuStyles}
						showChevron={showChevron}
						renderSwitch={renderSwitchHeader ? menu.selected : false}
					/>
				) : null
			})}
		</CompositeMenuWrapper>
	)
}
