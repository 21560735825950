import { Action, combineReducers, Reducer, ReducersMapObject } from 'redux'
import { IActionsCreators } from '../../interfaces/action'
import { IStore } from '../../interfaces/store'
import token from './token'
import user from './user'
import filter from './filter'
import profile from './profile'

const reducers: ReducersMapObject<IStore, Action<IActionsCreators>> = {
	user,
	token,
	filter,
	profile
}

export const Reducers: Reducer<IStore, Action<IActionsCreators>> =
	combineReducers(reducers)
