import { fontType } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'
import styled from 'styled-components'

export const MonthYearPicker = styled.div`
	.hidden {
		opacity: 0;
		pointer-events: none;
	}
	> div {
		background: #f0edef;
		margin-top: 3px;
		padding: 12px 12px 30px;

		header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 14px;

			h1 {
				${fontType(0)};
				font-size: 14px;
				color: ${colors.darkGrey};
			}
			button {
				border: none;
				width: 20px;
				height: 20px;
			}
		}

		ul {
			width: 100%;
			display: grid;
			grid-template-columns: repeat(4, 1fr);
		}
		li {
			${fontType(2)};
			font-size: 12px;
			list-style: none;
			margin: 1px;
			background: #dddbdf;
			text-transform: uppercase;
			text-align: center;
			color: #5d615d;
			padding: 20px;
			cursor: pointer;

			&:hover {
				background-color: ${colors.backgroundCheckBox};
			}
		}
		.month-disabled {
			color: ${colors.grey};
			cursor: not-allowed;
			&:hover {
				background: #dddbdf;
			}
		}
		.month-selected {
			background-color: ${colors.checkboxSecondary};
		}
	}
`
