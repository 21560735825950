import React from 'react'
import { LoadingContent, UserWrapper } from './styles'

import routesEnum from 'modules/Routes/enum'
import GoBack from 'shared/components/GoBack'
import { colors, primaryButtonStyle } from 'shared/styles/theme'
import { Button, Loading } from '@buildbox/components'
import PageTitle from 'shared/components/PageTitle'
import { IViewProps, textButton, titleScreen } from './types'
import DataUser from './DataUser'
import AccessProfile from './AccessProfile'
import ModalDelete from 'shared/components/ModalDelete'
import AuthModal from 'shared/components/AuthModal'

function CreateUser(props: IViewProps): JSX.Element {
	const {
		profileOptions,
		dataUser,
		handleChange,
		addProfileAndAccess,
		onChangeProfileAndAccess,
		removeProfile,
		profileAndAccess,
		showProfile,
		handleBtnNext,
		enableBtnNext,
		handleSubmit,
		action,
		renderButtonDelete,
		targetUser,
		handleDeleteOnClose,
		enableBtnSubmit,
		loading,
		loadingSubmit,
		handleStateChange,
		CRMError,
		stateError,
		hasPhysician,
		authModalConfig,
		handleSubmitFunction,
		handleSubmitDelete,
		isDelete,
		handleAuthSubmitDelete
	} = props

	if (loading) {
		return (
			<LoadingContent>
				<Loading type='Sentry' primaryColor={colors.secondary} />
			</LoadingContent>
		)
	}

	return (
		<>
			<UserWrapper className='animated-right'>
				<GoBack routeName={routesEnum.USERS} text='Todos os Usuários' />

				<PageTitle>{titleScreen[action]}</PageTitle>

				<DataUser
					dataUser={dataUser}
					handleChange={handleChange}
					disableInput={showProfile}
					CRMError={CRMError}
					action={action}
					handleState={handleStateChange}
					stateError={stateError}
					hasPhysician={hasPhysician}
				/>

				{!showProfile ? (
					<Button
						className='btn-next btn'
						{...primaryButtonStyle}
						onClick={handleBtnNext}
						disabled={!enableBtnNext}
					>
						Próximo
					</Button>
				) : null}

				{showProfile && (
					<section className='access-profile'>
						<div className='access-profile-div'>
							<h2>Tipos de Perfil e Acessos</h2>
						</div>

						{profileAndAccess.map((profiles, index) => (
							<AccessProfile
								key={index}
								onUpdate={onChangeProfileAndAccess}
								index={index}
								dataSize={profileAndAccess.length}
								profileAndAccess={profiles}
								profileOptions={profileOptions}
								addAccessProfile={addProfileAndAccess}
								removeAccessProfile={removeProfile}
								action={action}
								dataUser={dataUser}
							/>
						))}

						{loadingSubmit ? (
							<LoadingContent>
								<Loading type='Sentry' primaryColor={colors.secondary} />
							</LoadingContent>
						) : (
							<div className='btn-save'>
								{renderButtonDelete()}

								<Button
									className='btn'
									onClick={handleSubmitFunction}
									disabled={!enableBtnSubmit}
									{...primaryButtonStyle}
								>
									{textButton[action]}
								</Button>
							</div>
						)}
					</section>
				)}
			</UserWrapper>
			<AuthModal {...authModalConfig} />
			<ModalDelete
				onClose={handleDeleteOnClose}
				handleDeleteUserEnd={handleAuthSubmitDelete}
				target={targetUser}
				type='USER'
			/>
		</>
	)
}

export default CreateUser
