import { DefaultStyles } from '@buildbox/components'
import { DefaultTheme } from 'styled-components'

export const colors = {
	primary: '#292928',
	secondary: '#E61C95',
	checkboxSecondary: '#FFDBFE',
	kitBoxBackground: '#d28ab5',
	grey: '#979E97',
	darkGrey: '#4F4F4D',
	modalBackground: '#0000008F',
	colorTextPlaceholder: '#8F8F8B',
	lightGrey: '#EEEEEE',
	white: '#FFFFFF',
	pageBackground: '#F9F9F9',
	placeholder: '#B5B0B8',
	backgroundMenu: '#DEDDDF',
	backgroundMenuActive: '#D4D2D6',
	backgroundCheckBox: '#ffdaf0',
	backgroundSelect: '#F0edef',
	backgroundBtnSecondary: '#4E4D4F00',
	backgroundBoxFinance: '#dddbdf',
	backgroundDiagnosis: '#fff0f9',
	borderDiagnosis: '#ffc2e6',
	backgroundResultDiagnosis: '#efedf0'

}

export const tableStyle: DefaultStyles = {
	primaryColor: colors.darkGrey,
	secondaryColor: colors.darkGrey,
	backgroundColor: colors.pageBackground,
	fontSize: 12,
	fontFamily: 'Regular',
	padding: [8, 8, 8, 8]
}

export const primaryInputStyle: DefaultStyles = {
	primaryColor: colors.placeholder,
	secondaryColor: colors.placeholder,
	backgroundColor: colors.white,
	fontSize: 14,
	fontFamily: 'Regular'
}

export const primaryButtonStyle: DefaultStyles = {
	primaryColor: colors.white,
	secondaryColor: colors.placeholder,
	backgroundColor: colors.secondary,
	round: true,
	padding: [14, 32, 14, 32],
	fontSize: 10,
	fontFamily: 'Regular'
}

export const secondaryButtonStyle: DefaultStyles = {
	primaryColor: colors.darkGrey,
	secondaryColor: colors.darkGrey,
	backgroundColor: colors.backgroundBtnSecondary,
	round: true,
	padding: [14, 32, 14, 32],
	fontSize: 10,
	fontFamily: 'Bold'
}

export const primaryCheckBoxStyle: DefaultStyles = {
	primaryColor: colors.secondary,
	secondaryColor: colors.backgroundCheckBox,
	backgroundColor: colors.white,
	fontSize: 14,
	fontFamily: 'Regular'
}

export const selectStyle: DefaultStyles = {
	primaryColor: colors.darkGrey,
	secondaryColor: colors.backgroundSelect,
	backgroundColor: colors.white,
	fontSize: 12,
	fontFamily: 'Regular'
}

export const datePickerPrimaryStyles: DefaultStyles = {
	// primaryColor: colors.darkGrey,
	// secondaryColor: colors.white,
	backgroundColor: colors.white,
	fontSize: 16,
	fontFamily: 'Regular'
}
// Please, don't use this anymore.
// It will be deprecated
export { theme }
const theme: DefaultTheme = {
	colors: {
		primary: colors.primary,
		secondary: colors.secondary,
		titleColor: colors.grey,
		modalBackground: colors.modalBackground,
		pageBackground: colors.pageBackground,
		white: colors.white
	}
}
