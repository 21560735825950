import styled from 'styled-components'

export const ModalContainer = styled.form`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.button {
		margin-top: 20px;
	}
`
