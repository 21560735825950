import React from 'react'
import { ScreenActionsEnum } from './types'

export function renderEditIcon(
	id: string,
	onClick: (action: ScreenActionsEnum, contractId: string) => void
): JSX.Element {
	return (
		<button className='icon' onClick={() => onClick('EDIT', id)}>
			<img
				className='img'
				src={require('../../assets/images/dots.svg')}
				alt='Editar'
			/>
		</button>
	)
}
