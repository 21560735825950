import axiosInstance from './axiosInstance'
import { useQuery, UseQueryResult } from 'react-query'
import { QUERY_KEYS } from '../util/reactQuery'
import { IContractsOptions } from 'shared/interfaces/contract'

export interface getFilterOptionsRequest {
	userId: string
}

export interface getFilterOptionsResponse {
	contractsOptions: IContractsOptions[]
	accessToken: string
}

async function getFilterOptions({
	userId
}: getFilterOptionsRequest): Promise<getFilterOptionsResponse> {
	const response = await axiosInstance.get(`/users/filter-options/${userId}`)

	return response.data
}

export function useFilterOptions({
	userId
}: getFilterOptionsRequest): UseQueryResult<getFilterOptionsResponse> {
	const enabled = !!userId

	return useQuery(
		[QUERY_KEYS.FILTER_OPTIONS, { userId }],
		async ({ queryKey }) => {
			const [_key, params] = queryKey
			const { userId } = params as getFilterOptionsRequest

			return getFilterOptions({ userId })
		},
		{
			enabled,
			refetchInterval: 60000,

			/**
			 * This will make the refetch occurr only when triggers
			 * the interval, focus or online and the data is not stale anymore.
			 * obs: When the interval is triggered the stale is ignored.
			 */
			staleTime: 60000
		}
	)
}
