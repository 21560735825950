import styled from 'styled-components'
import { toRem } from '../../shared/styles/functions'

export const ContractsListWrapper = styled.div`
	button {
		cursor: pointer;
	}
	.btn button {
		text-transform: uppercase;
	}
`
export const TableContainer = styled.div`
	margin-top: ${toRem(50)};
	.icon {
		border: none;
		display: flex;
		margin: auto;
		background: transparent;
		.img {
		}
	}
`

export const LoadingContent = styled.section`
	display: flex;
	margin-top: ${toRem(50)};
	justify-content: center;
`
