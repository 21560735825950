import { SelectOption } from '@buildbox/components'
import { UtilMask, UtilValidators } from '@buildbox/utils'
import cogoToast from 'cogo-toast'
import React from 'react'
import { ChangeEvent, createElement, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import routesEnum from 'modules/Routes/enum'
import { ScreenActionsEnum } from 'modules/UnitsPage/types'
import getAddressFromPostalCode from 'shared/util/getAddressFromPostalCode'
import { IModalDeleteTarget } from 'shared/components/ModalDelete/types'
import { IUnit, IUnitPaginated, StateFromBrazil } from 'shared/interfaces/unit'
import { createUnit, updateUnit, findUnit } from 'shared/services/unit.service'
import { findContract } from 'shared/services/contract.service'
import { EntityTypeEnum } from 'shared/interfaces/contract'
import { errorMessages, successMessages } from 'shared/util/Messages'
import cogoDefaultOptions from 'shared/util/toaster'
import { INITIAL_DATA_UNIT, IViewProps } from './types'
import UnitCreateAndEditView from './view'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'

import { useSelectedProfileIsOneOf } from 'shared/util/profilePermission'
import {
	IAuthenticationModalProps,
	INITIAL_AUTHENTICATION_MODAL_PROPS
} from 'shared/components/AuthModal/types'
import { FUNC_ENUM, SUCCESS_MESSAGE } from 'shared/components/ModalDelete/data'

function UnitCreateAndEdit(): JSX.Element {
	const { checkCNPJ, checkPostalCode, checkTel } = UtilValidators
	const history = useHistory()
	const { profile, filter, user } = useTypedSelector([
		'profile',
		'user',
		'filter'
	])

	const { pathname, state } =
		useLocation<{ action: ScreenActionsEnum; unit: IUnitPaginated }>()

	const { action, unit } = state || {
		action: 'CREATE',
		unit: INITIAL_DATA_UNIT
	}

	const [originalDataUnit, setOriginalDataUnit] =
		useState<IUnit>(INITIAL_DATA_UNIT)
	const [dataUnit, setDataUnit] = useState<IUnit>(INITIAL_DATA_UNIT)
	const [contractsOptions, setContractsOptions] = useState<SelectOption[]>([])
	const [contractSelected, setContractSelected] =
		useState<SelectOption | null>(null)

	const [enableBtnSubmit, setEnableBtnSubmit] = useState(false)
	const [targetUnit, setTargetUnit] = useState<IModalDeleteTarget | null>(null)
	const [viewErrors, setViewErrors] = useState<string[]>([])
	const [isLoading, setIsLoading] = useState(true)
	// const updateContractsFilter = useUpdateContractsFilter()
	const canDeleteUnit = useSelectedProfileIsOneOf(['ADMIN', 'CONTRACT_ADMIN'])

	const [authModal, setAuthModal] = useState(INITIAL_AUTHENTICATION_MODAL_PROPS)
	const [currentTargetUnit, setCurrentTargetUnit] =
		useState<IModalDeleteTarget | null>(null)
	const [isDelete, setIsDelete] = useState(false)

	function initializeFields() {
		if (pathname === routesEnum.UNITS_PAGE_EDIT && action === 'CREATE') {
			history.replace({
				pathname: routesEnum.UNITS_PAGE_CREATE,
				state: {
					action,
					unit
				}
			})
		}

		if (action === 'EDIT') {
			getUnit()
		}

		if (action === 'CREATE') {
			getContractsFromProfile()
		}
	}

	function getUnit() {
		;(async () => {
			try {
				const unitFetched = await findUnit(unit._id)
				setDataUnit(unitFetched)
				setOriginalDataUnit(unitFetched)
				setContractSelected({
					value: unit.contract._id,
					label: unit.contract.name
				})
			} catch (error) {
				console.log(error)
			} finally {
				setIsLoading(false)
			}
		})()
	}

	function getContractsFromProfile() {
		const options = filter.map((contract) => ({
			value: contract.value,
			label: contract.label
		}))

		setContractsOptions(options)
		setIsLoading(false)
	}

	function handleCompanyType(type: EntityTypeEnum) {
		setDataUnit({
			...dataUnit,
			entityType: type
		})
	}

	function handleCopyFromContract(copyFromContract: boolean) {
		;(async () => {
			try {
				if (copyFromContract) {
					setIsLoading(true)

					if (!contractSelected?.value) return
					const selectedContract = await findContract(contractSelected.value)

					setDataUnit({
						...dataUnit,
						name: selectedContract.name,
						entityType: selectedContract.entityType,
						companyFiscalID: selectedContract.companyFiscalID
					})
					return
				}

				setDataUnit({
					...dataUnit,
					name: '',
					entityType: 'PUBLIC',
					companyFiscalID: ''
				})
			} catch (error) {
				console.log(error)
			} finally {
				if (contractSelected) {
					setIsLoading(false)
				}
			}
		})()
	}

	function handleFetchAddressByPostalCode() {
		;(async () => {
			try {
				if (
					checkPostalCode(dataUnit.postalCode) &&
					dataUnit.postalCode !== originalDataUnit.postalCode &&
					(!!originalDataUnit.postalCode || action === 'CREATE')
				) {
					setIsLoading(true)
					const address = await getAddressFromPostalCode(dataUnit.postalCode)

					if (address) {
						setDataUnit({
							...dataUnit,
							...address
						})
					}
				}
			} catch (error) {
				console.log(error)
			} finally {
				setIsLoading(false)
			}
		})()
	}

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		const { id, value } = event.target

		let formatValue: any = value

		if (id === 'companyFiscalID') {
			formatValue = UtilMask.formatCNPJ(value)
		}

		if (id === 'postalCode') {
			formatValue = UtilMask.formatPostalCode(value)
		}

		setDataUnit({
			...dataUnit,
			[id]: formatValue
		})
	}

	function handleStateChange(state: SelectOption | null) {
		if (state)
			setDataUnit({
				...dataUnit,
				state: state.value as StateFromBrazil
			})
	}

	function isObjectEqual(oldObject: any, newObject: any) {
		const oldKeys = Object.getOwnPropertyNames(oldObject)
		const newKeys = Object.getOwnPropertyNames(newObject)

		if (oldKeys.length !== newKeys.length) return false

		for (let i = 0; i < oldKeys.length; i++) {
			const key = oldKeys[i]

			if (oldObject[key] !== newObject[key]) return false
		}

		return true
	}

	function handleValidateForm() {
		const errors: string[] = []

		const isCnpjValid = checkCNPJ(dataUnit.companyFiscalID)
		const isPostalCodeValid = checkPostalCode(dataUnit.postalCode)
		const isPhoneValid = checkTel(dataUnit.telephone)

		const isValidated = isPostalCodeValid && isPhoneValid
		const isFilled =
			// !!dataUnit.CNES &&
			!!dataUnit.name &&
			!!dataUnit.companyName &&
			!!dataUnit.streetName &&
			!!dataUnit.city &&
			!!dataUnit.state &&
			!!dataUnit.addressNumber &&
			!!dataUnit.contract

		if (dataUnit.postalCode && !isPostalCodeValid) {
			errors.push(errorMessages.PostalCodeFilledCorrectly)
		}

		if (dataUnit.telephone && !isPhoneValid) {
			errors.push(errorMessages.PhoneFilledCorrectly)
		}

		setViewErrors(errors)

		if (action === 'EDIT') {
			const isUpdated = !isObjectEqual(originalDataUnit, dataUnit)

			setEnableBtnSubmit(isValidated && isFilled && isUpdated)
			return
		}

		if (dataUnit.companyFiscalID && !isCnpjValid) {
			errors.push(errorMessages.CNPJFilledCorrectly)
		}

		setEnableBtnSubmit(isCnpjValid && isValidated && isFilled)
	}

	function renderButtonDelete(): JSX.Element {
		if ('CREATE' === action) return <></>

		if (!canDeleteUnit) return <></>

		return (
			<button
				className='btn-clean btn-remove'
				onClick={() => setTargetUnit({ id: unit._id || '', name: unit.name })}
			>
				Excluir Unidade
			</button>
		)
	}

	function handleDeleteUnitEnd() {
		setTargetUnit(null)
		setCurrentTargetUnit({ id: unit._id || '', name: unit.name })
		setIsDelete(true)
		handleOpenAuthModal()
	}

	function handleDeleteOnClose() {
		setTargetUnit(null)
	}

	async function handleSubmit() {
		const {
			companyName,
			entityType,
			name,
			CNES,
			companyFiscalID,
			contract,
			telephone,
			observation,
			addressNumber,
			postalCode,
			streetName,
			addressComplement,
			neighborhood,
			city,
			state
		} = dataUnit

		try {
			setIsLoading(true)

			if (action === 'CREATE') {
				await createUnit({
					name,
					contract,
					companyName,
					entityType,
					companyFiscalID,
					CNES,
					telephone,
					observation,
					addressNumber,
					postalCode,
					streetName,
					addressComplement,
					neighborhood,
					city,
					state
				})

				cogoToast.success(successMessages.unitCreated, cogoDefaultOptions)
			} else {
				if (!unit._id) return

				await updateUnit(
					unit._id,
					{
						companyName,
						name,
						CNES,
						telephone,
						observation,
						addressNumber,
						postalCode,
						streetName,
						addressComplement,
						neighborhood,
						city,
						state
					},
					user.email,
					authModal.password
				)

				handleCloseAuthModal()

				cogoToast.success(successMessages.unitUpdated, cogoDefaultOptions)
			}
			// await updateContractsFilter()
			history.push(routesEnum.UNITS)
		} catch (err) {
		} finally {
			setIsLoading(false)
		}
	}

	function handleSelectedContract() {
		if (contractSelected) {
			setDataUnit({
				...dataUnit,
				contract: contractSelected.value
			})
		}
	}

	function handleSelectContract(value: any) {
		setContractSelected(value)

		if (value !== contractSelected?.value) {
			setDataUnit({
				...dataUnit,
				name: '',
				entityType: 'PUBLIC',
				companyFiscalID: ''
			})
		}
	}

	async function handleSubmitDelete() {
		try {
			const successMessage = SUCCESS_MESSAGE['UNIT']
			const deleteTarget = FUNC_ENUM['UNIT']

			if (!currentTargetUnit) return null
			await deleteTarget(currentTargetUnit.id, user.email, authModal.password)

			setCurrentTargetUnit(null)
			setIsDelete(false)
			history.replace(routesEnum.UNITS)
			cogoToast.success(successMessage, cogoDefaultOptions)
		} catch (error) {}
	}

	const handleSubmitFunction =
		action === 'CREATE' ? handleSubmit : handleOpenAuthModal

	function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
		const password = event.target.value || ''

		setAuthModal((state) => ({ ...state, password }))
	}

	function handleClose() {
		setIsDelete(false)
		setAuthModal(INITIAL_AUTHENTICATION_MODAL_PROPS)
	}

	function handleOpenAuthModal() {
		setAuthModal((state) => ({ ...state, value: true }))
	}

	function handleCloseAuthModal() {
		setAuthModal((state) => ({ ...state, value: false }))
	}

	const isEnableDelete = isDelete && !!currentTargetUnit

	const authModalConfig: IAuthenticationModalProps = {
		...authModal,
		handleSubmit: isEnableDelete ? handleSubmitDelete : handleSubmit,
		handleClose,
		handlePasswordChange,
		loadingSubmit: isLoading
	}

	useEffect(initializeFields, [])
	useEffect(handleValidateForm, [dataUnit])
	useEffect(handleFetchAddressByPostalCode, [dataUnit.postalCode])
	useEffect(handleSelectedContract, [contractSelected])
	useEffect(getContractsFromProfile, [filter])

	const viewProps: IViewProps = {
		contractSelected,
		handleSelectContract,
		dataUnit,
		contractsOptions,
		action,
		renderButtonDelete,
		targetUnit,
		enableBtnSubmit,
		viewErrors,
		isLoading,
		handleCompanyType,
		handleCopyFromContract,
		handleChange,
		handleStateChange,
		handleSubmit,
		handleDeleteOnClose,
		handleDeleteUnitEnd,
		authModalConfig,
		handleSubmitFunction
	}

	return createElement(UnitCreateAndEditView, viewProps)
}

export default UnitCreateAndEdit
