import { IMenu } from 'shared/components/MenuPrimary/types'
import { ProfileNameEnum, UserProfileContract } from 'shared/interfaces/user'
import { store } from 'shared/store'
import { friendlyRole } from './friendlyRole'

export interface IOptionProfile {
	value: ProfileNameEnum
	label: string
}

export const PROFILE_OPTIONS: IOptionProfile[] = [
	{
		label: friendlyRole['CONTRACT_ADMIN'],
		value: 'CONTRACT_ADMIN'
	},

	{
		label: friendlyRole['ADMIN'],
		value: 'ADMIN'
	},

	{
		label: friendlyRole['FINANCE'],
		value: 'FINANCE'
	},

	{
		label: friendlyRole['UNIT_MANAGER'],
		value: 'UNIT_MANAGER'
	},

	{
		label: friendlyRole['OPERATOR'],
		value: 'OPERATOR'
	},
	{
		label: friendlyRole['PHYSICIAN'],
		value: 'PHYSICIAN'
	},
	{
		label: friendlyRole['COMMERCIAL_PARTNER'],
		value: 'COMMERCIAL_PARTNER'
	}
]

export function buildingOptionsAccordingToProfile(
	profileName: string,
	copied: IMenu[],
	selectType: 'HEADER' | 'USER'
) {
	if (!profileName) {
		return [] as IMenu[]
	}

	if (
		(selectType === 'USER' && profileName === friendlyRole['CONTRACT_ADMIN']) ||
		(selectType === 'USER' &&
			profileName === friendlyRole['COMMERCIAL_PARTNER'])
	) {
		const contracts = copied.map((contracts) => {
			return {
				expanded: contracts.expanded,
				label: contracts.label,
				options: [],
				selected: contracts.selected,
				value: contracts.value,
				visible: contracts.visible
			} as IMenu
		})

		return contracts
	}

	if (
		profileName === friendlyRole['UNIT_MANAGER'] ||
		profileName === friendlyRole['OPERATOR'] ||
		profileName === friendlyRole['PHYSICIAN']
	) {
		const units = copied.filter((unit) => unit.options.length > 0)

		return units
	}

	return copied
}
