import { QueryStatus } from 'react-query'
import { ILogsSummary } from 'shared/components/BarGraph/types'
import { IFromTo } from 'shared/components/DatePicker/types'
import { ILatLng, IMarkers } from 'shared/components/GoogleMapsApi/types'

export default interface IProps {}
export interface IViewProps {
	handleSetDates(value: IFromTo): void
	selectedDates: IFromTo
	tableColumns: any[]
	tableRows: Object[]
	centerMap: ILatLng
	markersMap: IMarkers[]
	graphicBarData: ILogsSummary[]
	graphicPieData: ILogsSummary[]
	totalExams: number
	neverHadMammogram: string
	handleShowDates: (show: boolean) => void
	zoomMap: number
	dashboardStatus: QueryStatus
	liveStatus: QueryStatus
}

export const INITIAL_LAT_LNG: ILatLng = {
	lat: -23.5864794,
	lng: -46.6826261
}

export interface ITableColumn {}

export interface ITableRow {
	name: string
	kits: number
	kitsUnused: number
	numberOfExams: number
	neverHadAMammogram: string
	suspicious: string
}
