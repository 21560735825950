import { IDashboardTable } from 'shared/interfaces/dasboard'
import { IDatasheet } from 'shared/interfaces/datasheet'
import { IExam, ExamDiagnosis, ExamDiagnosisEnum } from 'shared/interfaces/exam'
import { IKit } from 'shared/interfaces/kit'
import { IGetDashboardResponse } from 'shared/services/unit.service'
import { fixedPercentage } from 'shared/util/format'

export function calculateTableData(
	dashboardData: IGetDashboardResponse
): IDashboardTable[] {
	const rows: IDashboardTable[] = dashboardData.map((response) => {
		const examCount = response.exams.length
		return {
			name: response.unit.name,
			kits: response.kits.length,
			kitsUnused: calculateUnusedKits(response.kits, response.exams),
			numberOfExams: examCount,
			neverHadAMammogram: calculateNeverHadMammogram(
				response.exams.map((exam) => exam?.datasheet as IDatasheet),
				response.exams.length
			),
			suspicious:
				examCount > 0
					? `${(
							(countSuspiciousExams(response.exams) / examCount) *
							100.0
					  ).toFixed(1)}%`
					: ' - '
		}
	})

	return rows
}

export function calculateUnusedKits(kits: IKit[], exams: IExam[]): number {
	// const kitSet = new Set(kits.map((kit) => kit._id))
	// const examsKitSet = new Set(exams.map((exam) => exam.kit as string))
	// const differenceSet = stringSetDifference(kitSet, examsKitSet)

	// return Array.from(differenceSet).length

	let numberOfUnusedKits = 0

	kits.forEach((kit) => {
		const findExams = exams.find((exam) => {
			return exam.kit === kit._id
		})

		if (!findExams) numberOfUnusedKits++
	})

	return numberOfUnusedKits
}

export function calculateNeverHadMammogram(
	datasheet: IDatasheet[],
	totalExams: number
): string {
	const hadMammogram = datasheet.filter(
		(datasheet) => datasheet?.mammography?.value === false
	)

	return `${(totalExams > 0
		? (hadMammogram.length / totalExams) * 100.0
		: 0
	).toFixed(1)}%`
}

export function countSuspiciousExams(exams: IExam[]): number {
	return exams.reduce((examCount, exam) => {
		return exam.diagnosis === ExamDiagnosis.SUSPICIOUS ? ++examCount : examCount
	}, 0)
}

export function returnGraphicData(
	id: string,
	label: string,
	value: number,
	color: string
) {
	if (value <= 0) return
	return {
		id,
		label,
		value,
		color
	}
}

export function buildingGraphPie(
	exams: IExam[],
	diagnosis: ExamDiagnosisEnum,
	color: string,
	description: string
) {
	const totalExams = exams.length

	const totalOfExamsDescription = exams.filter(
		(exam) => exam.diagnosis === diagnosis
	).length

	const percentOfExamsDescription = (totalOfExamsDescription / totalExams) * 100

	const textGraphic =
		percentOfExamsDescription < 0.1
			? `0.1% - ${description}`
			: `${fixedPercentage(percentOfExamsDescription, 1)}% - ${description}`

	const dataGraphic = returnGraphicData(
		textGraphic,
		textGraphic,
		totalOfExamsDescription,
		color
	)

	return dataGraphic
}

// function calculateAssociatedKits(
// 	kits: IKit[],
// 	startDate: Date,
// 	endDate: Date
// ): number {
// 	return kits.reduce((kitCount, kit) => {
// 		return wasKitAssociated(kit.kitLog ?? [], startDate, endDate)
// 			? ++kitCount
// 			: kitCount
// 	}, 0)
// }

// function wasKitAssociated(
// 	kitLog: IKitEvent[],
// 	startDate: Date,
// 	endDate: Date
// ): boolean {
// 	return !!kitLog.find((event) => {
// 		const changeDate = new Date(event.changeDate)
// 		return (
// 			event.status === KitStatus.ACTIVE &&
// 			changeDate > startDate &&
// 			changeDate < endDate
// 		)
// 	})
// }

// function stringSetDifference(setA: Set<string>, setB: Set<string>) {
// 	let _difference = new Set(setA)
// 	for (let elem of setB) {
// 		_difference.delete(elem)
// 	}
// 	return _difference
// }

// function countExams(exams: IExam[], startDate: Date, endDate: Date): number {
// 	return exams.reduce((examCount, exam) => {
// 		const examDate = new Date(exam.accomplishedAt)
// 		//			console.log('examDate => ', examDate)
// 		return examDate > startDate && examDate < endDate
// 			? ++examCount
// 			: examCount
// 	}, 0)
// }

// function handleProfile() {
// 	if (!profile.profileId || showDate) return
// 	getDashboardData()
// 	//		getDashboardPage()
// }
