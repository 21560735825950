import {
	createElement,
	useState,
	useEffect,
	useCallback,
	ChangeEvent
} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import ExamEdit from './view'
import cogoToast from 'cogo-toast'
import { ActionElement, IQuestionsDatasheet, IViewProps } from './types'
import { IExam, IPhysicianDiagnoses } from 'shared/interfaces/exam'
import { IDatasheet } from 'shared/interfaces/datasheet'
import {
	friendlyBiopsy,
	friendlyMammography,
	friendlySurgery
} from 'shared/util/friendlyRole'
import { formatDate, formatMonthAndYear } from 'shared/util/format'
import iconInfoCircle from '../../assets/images/info-circle@2x.png'
import { getExamById, updateExam } from 'shared/services/exam.service'
import jsPDF from 'jspdf'
import { IPatient } from 'shared/interfaces/patient'
import { grid } from '../../shared/styles/variables'
import { errorMessages, successMessages } from 'shared/util/Messages'
import cogoDefaultOptions from 'shared/util/toaster'
import {
	IAuthenticationModalProps,
	INITIAL_AUTHENTICATION_MODAL_PROPS
} from 'shared/components/AuthModal/types'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'

function ExamEditContainer(): JSX.Element {
	const { user } = useTypedSelector(['user'])

	const { examId } = useParams<{ examId: string }>()

	const [isLoading, setLoading] = useState(false)
	const [examDetail, setExamDetail] = useState<IExam>({} as IExam)
	const [imageInfoCircle, setImageInfoCircle] = useState<any>('')
	const [image, setImage] = useState<string>('')
	const [btnScrollToTop, setBtnScrollToTop] = useState(false)
	const [hiddenBtnExport, setHiddenBtnExport] = useState(false)
	const [btnExportClicked, setBtnExportClicked] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [authModal, setAuthModal] = useState(INITIAL_AUTHENTICATION_MODAL_PROPS)
	const [questionsDatasheet, setQuestionsDatasheet] = useState<
		IQuestionsDatasheet[]
	>([] as IQuestionsDatasheet[])
	const [report, setReport] = useState<IPhysicianDiagnoses>()

	function initializeData() {
		getExamDetail()
	}

	const buildQuestions = useCallback((datasheet: IDatasheet) => {
		const arrayQuestions: IQuestionsDatasheet[] = []

		if (
			datasheet.menopause &&
			verifyPropertyValueExist(datasheet, 'menopause')
		) {
			arrayQuestions.push({
				key: 'menopause',
				value: translate(datasheet.menopause.value)
			})
		}
		if (datasheet?.menopause?.dateLastMenstruation) {
			arrayQuestions.push({
				key: 'dateLastMenstruation',
				value: datasheet.menopause.dateLastMenstruation.toISOString()
			})
		}

		if (datasheet?.eitherMotherSisterOrDaugtherHadCancer) {
			arrayQuestions.push({
				key: 'eitherMotherSisterOrDaugtherHadCancer',
				value: translate(datasheet.eitherMotherSisterOrDaugtherHadCancer)
			})
		}

		if (datasheet.surgery && verifyPropertyValueExist(datasheet, 'surgery')) {
			arrayQuestions.push({
				key: 'surgery',
				value: translate(datasheet.surgery.value)
			})
		}

		if (datasheet?.surgery?.surgeryResult) {
			arrayQuestions.push({
				key: 'surgeryResult',
				value: friendlySurgery[datasheet.surgery.surgeryResult]
			})
		}

		if (datasheet?.menopause?.dateLastMenstruation) {
			arrayQuestions.push({
				key: 'dateLastMenstruation',
				value: formatDate(datasheet.menopause.dateLastMenstruation)
			})
		}
		if (
			datasheet.mammography &&
			verifyPropertyValueExist(datasheet, 'mammography')
		) {
			arrayQuestions.push({
				key: 'mammography',
				value: translate(datasheet.mammography.value)
			})
		}

		if (datasheet?.mammography?.mammographyResult) {
			arrayQuestions.push({
				key: 'mammographyResult',
				value: friendlyMammography[datasheet.mammography.mammographyResult]
			})
		}
		if (datasheet?.mammography?.dateLastMammography) {
			arrayQuestions.push({
				key: 'dateLastMammography',
				value: formatDate(new Date(datasheet.mammography.dateLastMammography))
			})
		} else if (datasheet?.mammography?.date) {
			arrayQuestions.push({
				key: 'dateLastMammography',
				value: formatMonthAndYear(
					new Date(
						datasheet?.mammography?.date?.year,
						datasheet?.mammography?.date?.month
					)
				)
			})
		}

		if (datasheet?.biopsy && verifyPropertyValueExist(datasheet, 'biopsy')) {
			arrayQuestions.push({
				key: 'biopsy',
				value: translate(datasheet.biopsy.value)
			})
		}

		if (datasheet?.biopsy?.biopsyResult) {
			arrayQuestions.push({
				key: 'biopsyResult',
				value: friendlyBiopsy[datasheet.biopsy.biopsyResult]
			})
		}

		if (datasheet?.biopsy?.size) {
			arrayQuestions.push({
				key: 'size',
				value: `${datasheet.biopsy.size} cm`
			})
		}

		setQuestionsDatasheet(arrayQuestions)
	}, [])

	const getExamDetail = useCallback(() => {
		;(async () => {
			try {
				setLoading(true)

				const examFetched = await getExamById(examId)

				if (!examFetched) return

				setExamDetail(examFetched)
				await getBase64FromImage(examFetched.image)

				const icon = await getBase64ImageFile(iconInfoCircle)
				setImageInfoCircle(icon)

				buildQuestions(examFetched.datasheet as IDatasheet)
			} catch (error) {
				console.log(error)
				setExamDetail(null as any)
			} finally {
				setLoading(false)
			}
		})()
	}, [examId, buildQuestions])

	async function getBase64FromImage(url: string) {
		try {
			const response = await axios.get(url, {
				responseType: 'arraybuffer'
			})

			const mediaType = response.headers['content-type']

			const bytes = new Uint8Array(response.data)
			const binary = [].map
				.call(bytes, function (byte) {
					return String.fromCharCode(byte)
				})
				.join('')

			const base64 = [
				'data:',
				mediaType ? mediaType + ';' : '',
				'base64,',
				btoa(binary)
			].join('')

			setImage(base64)
		} catch (error) {
			console.log({ error })
		}
	}

	async function getBase64ImageFile(
		imageUrl: string
	): Promise<FileReader | string | ArrayBuffer | null> {
		const response = await fetch(imageUrl, {
			mode: 'no-cors'
		})

		const blob = await response.blob()

		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(blob)
			reader.onload = () => resolve(reader.result)
			reader.onerror = (error) => reject(error)

			return reader
		})
	}

	function translate(value: boolean) {
		switch (value) {
			case true:
				return 'Sim'
			case false:
				return 'Não'
			default:
				return value
		}
	}

	function reportDiagnosis(report: IPhysicianDiagnoses) {
		setReport(report)
	}

	function verifyPropertyValueExist(datasheet: IDatasheet, acessor: string) {
		const data = (datasheet as any)[acessor] || null

		if (data === null) return false

		return Object.keys(data).includes('value')
	}

	function toggleVisibility() {
		if (window.pageYOffset > 300) {
			setBtnScrollToTop(true)
		} else {
			setBtnScrollToTop(false)
		}
	}

	function scrollToTop() {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}

	function handleElement(action: ActionElement) {
		const hiddenCheckbox = Array.from(
			document.querySelectorAll('body #content #report-content .hidden')
		) as HTMLInputElement[]

		const actionStyle = action === ActionElement.DISABLE ? 'none' : ''

		hiddenCheckbox.forEach((element) => {
			if (!element.checked) element.style.display = actionStyle
		})
	}

	async function generatePdf() {
		if (!examDetail) return
		setHiddenBtnExport(true)
		setBtnExportClicked(true)

		const queryDoc = document.querySelector(
			'body #content #report-content  '
		) as HTMLElement

		const firstPage = document.querySelector(
			'body #content #report-content #page-1 '
		) as HTMLElement

		try {
			handleElement(ActionElement.DISABLE)

			if (!queryDoc) return

			const pdf = new jsPDF('p', 'px', 'a2')
			firstPage.style.height = `${pdf.internal.pageSize.getHeight()}px`

			if (document.body.offsetWidth <= grid.md) {
				queryDoc.style.width = `${pdf.internal.pageSize.getWidth()}px`
			}

			pdf.html(queryDoc, {
				callback: function (doc) {
					const patient = examDetail.patient as IPatient

					const fileName = `${patient.name}`

					doc.save(`${fileName}.pdf`)
				}
			})
		} catch (error) {
			console.log({ error })
		} finally {
			setTimeout(() => {
				setHiddenBtnExport(false)
				firstPage.style.height = 'auto'
				handleElement(ActionElement.ENABLE)
				setBtnExportClicked(false)
				if (document.body.offsetWidth <= grid.md) {
					queryDoc.style.width = 'auto'
				}
			}, 1000)
		}
	}

	function openModal() {
		setShowModal(true)
	}

	function onClose() {
		setShowModal(false)
	}

	const handleSubmit = useCallback(() => {
		;(async () => {
			try {
				setLoading(true)
				if (!report) return
				onClose()
				await updateExam(examDetail._id, report, {
					email: user.email,
					password: authModal.password
				})
				getExamDetail()
				cogoToast.success(successMessages.examUpdated, cogoDefaultOptions)
			} catch (error) {
				cogoToast.error(errorMessages.examNotUpdated, cogoDefaultOptions)
			} finally {
				setAuthModal(INITIAL_AUTHENTICATION_MODAL_PROPS)
				setLoading(false)
				onClose()
			}
		})()
	}, [report, user.email, authModal.password, examDetail._id, getExamDetail])

	const handleToggleAuthModal = useCallback(() => {
		setShowModal(false)
		setAuthModal(state => ({
			...state,
			value: !state.value
		}))
	}, [])

	const handlePasswordChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		const password = event.target.value || ''
		setAuthModal((state) => ({ ...state, password }))
	}, [])

	useEffect(initializeData, [])
	useEffect(() => {
		document.addEventListener('scroll', function (e) {
			toggleVisibility()
		})
	}, [])

	const authModalConfig: IAuthenticationModalProps = {
		...authModal,
		handleSubmit,
		handleClose: handleToggleAuthModal,
		handlePasswordChange,
		loadingSubmit: isLoading
	}

	const viewProps: IViewProps = {
		examDetail,
		questionsDatasheet,
		imageInfoCircle,
		isLoading,
		image,
		hiddenBtnExport,
		generatePdf,
		btnScrollToTop,
		scrollToTop,
		btnExportClicked,
		openModal,
		showModal,
		onClose,
		reportDiagnosis,
		report,
		openAuthModal: handleToggleAuthModal,
		authModalConfig
	}

	return createElement(ExamEdit, viewProps)
}

export default ExamEditContainer
