import React from 'react'
import { Column, Row } from 'shared/styles'
import { FloatLabelInput } from '@buildbox/components'
import { primaryInputStyle } from 'shared/styles/theme'
import { IProps } from './types'
import { UtilMask, UtilValidators } from '@buildbox/utils'
import SelectState from 'modules/UnitCreateAndEdit/FormUnit/SelectState'

function DataUser(props: IProps): JSX.Element {
	const {
		dataUser,
		handleChange,
		disableInput,
		CRMError,
		handleState,
		stateError,
		hasPhysician
	} = props
	return (
		<Row>
			<Column sm='12' lg='5'>
				<FloatLabelInput
					id='name'
					label='Nome Completo'
					onChange={handleChange}
					value={dataUser.name}
					{...primaryInputStyle}
				/>
			</Column>

			<Column sm='12' lg='3'>
				<FloatLabelInput
					id='personalFiscalID'
					label='CPF'
					onChange={handleChange}
					maxLength={14}
					value={UtilMask.formatCnpjCpf(dataUser.personalFiscalID).trim()}
					disabled={disableInput}
					hasError={!UtilValidators.checkCPF(dataUser.personalFiscalID)}
					errorMessage={'CPF inválido'}
					{...primaryInputStyle}
				/>
			</Column>

			<Column sm='12' lg='4'>
				<FloatLabelInput
					id='email'
					label='E-mail'
					onChange={handleChange}
					value={dataUser.email.trim()}
					disabled={disableInput}
					{...primaryInputStyle}
				/>
			</Column>

			{hasPhysician ? (
				<>
					<Column sm='12' lg='2'>
						<FloatLabelInput
							id='crm'
							label='CRM'
							onChange={handleChange}
							disabled={!hasPhysician}
							value={dataUser.crm}
							// disabled={action === 'EDIT' && !!dataUser?.crm}
							maxLength={10}
							{...primaryInputStyle}
						/>

						{CRMError && <p className='error-text'>{CRMError}</p>}
					</Column>

					<Column sm='12' lg='2'>
						<SelectState
							handleStateChange={handleState}
							stateSelected={dataUser.state ? dataUser.state : ''}
							isDisabled={!hasPhysician}
						/>

						{stateError && <p className='error-text'>{stateError}</p>}
					</Column>
				</>
			) : null}
		</Row>
	)
}

export default DataUser
