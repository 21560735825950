import React from 'react'
import { Link } from 'react-router-dom'
import { GoBackWrapper } from './styles'
import { IProps } from './types'

export default function GoBack({ routeName, text }: IProps): JSX.Element {
	return (
		<GoBackWrapper>
			<Link to={routeName} className='header'>
				<img src={require('assets/images/arrow-left.svg')} alt='Arrow Left' />
				<p className='title'>{text}</p>
			</Link>
		</GoBackWrapper>
	)
}
