import React, { ChangeEvent, createElement, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { SelectOption } from '@buildbox/components'
import cogoToast from 'cogo-toast'

import routesEnum from 'modules/Routes/enum'
import { ScreenActionsEnum } from 'modules/UnitsPage/types'
import { IModalDeleteTarget } from 'shared/components/ModalDelete/types'

import { successMessages } from 'shared/util/Messages'
import cogoDefaultOptions from 'shared/util/toaster'
import { INITIAL_DATA_KIT, IViewProps } from './types'
import UnitCreateAndEditView from './view'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { IKit } from 'shared/interfaces/kit'
import { associateKit, findKit } from 'shared/services/kit.service'
import { IMenu } from 'shared/components/MenuPrimary/types'
import { useSelectedProfileIsOneOf } from 'shared/util/profilePermission'
import {
	IAuthenticationModalProps,
	INITIAL_AUTHENTICATION_MODAL_PROPS
} from 'shared/components/AuthModal/types'
import { FUNC_ENUM, SUCCESS_MESSAGE } from 'shared/components/ModalDelete/data'

function UnitCreateAndEdit(): JSX.Element {
	const history = useHistory()
	const { filter, profile, user } = useTypedSelector([
		'filter',
		'profile',
		'user'
	])

	const { state } = useLocation<{ action: ScreenActionsEnum; kitId: string }>()

	const { action, kitId } = state || {
		action: 'CREATE',
		unit: ''
	}

	const [currentKit, setCurrentKit] = useState<IKit>(INITIAL_DATA_KIT)

	const [contractsOptions, setContractsOptions] = useState<SelectOption[]>([])
	const [contractSelected, setContractSelected] =
		useState<SelectOption | null>(null)

	const [unitsOptions, setUnitsOptions] = useState<SelectOption[]>([])
	const [unitSelected, setUnitSelected] = useState<SelectOption | null>(null)

	const [enableBtnSubmit, setEnableBtnSubmit] = useState(false)
	const [targetKit, setTargetKit] = useState<IModalDeleteTarget | null>(null)
	const [viewErrors, setViewErrors] = useState<string[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const isAdmin = useSelectedProfileIsOneOf(['ADMIN'])

	const [authModal, setAuthModal] = useState(INITIAL_AUTHENTICATION_MODAL_PROPS)

	const [currentTargetKit, setCurrentTargetKit] =
		useState<IModalDeleteTarget | null>(null)

	const kitIsActive =
		!!currentKit?.activatedAt && currentKit.status === 'ACTIVE'

	function initializeFields() {
		handleSelectContractAndUnits()
		if (action === 'EDIT') {
			getKit()
		}

		if (action === 'CREATE') {
			history.replace(routesEnum.KITS)
		}
	}

	function getKit() {
		;(async () => {
			setIsLoading(true)

			try {
				const responseKit = await findKit(kitId)
				setCurrentKit(responseKit)
			} catch (error) {
				console.log(error)
			} finally {
				setIsLoading(false)
			}
		})()
	}

	function handleOptionsSelected() {
		if (action === 'CREATE') return

		if (
			!!!currentKit.contract ||
			!!!currentKit.unit ||
			!contractsOptions.length
		)
			return

		const contractSelected = contractsOptions.find(
			(e) => e.value === currentKit?.contract
		)

		if (!contractSelected) return
		setContractSelected(contractSelected)

		const optionsUnits = handleSelectedUnits(contractSelected.value)

		const unitSelected = optionsUnits.find((e) => e.value === currentKit?.unit)

		if (!unitSelected) return
		setUnitSelected(unitSelected)
	}

	function handleEnableBtn() {
		if (contractSelected?.value && unitSelected?.value) {
			setEnableBtnSubmit(isAdmin)
			return
		}

		setEnableBtnSubmit(false)
	}

	function handleSelectChange(selectedOption: any, id: string) {
		if (!selectedOption) return

		if (id === 'contract') {
			setContractSelected(selectedOption)

			handleSelectedUnits(selectedOption.value)
			setUnitSelected(null)
			setCurrentKit({
				...currentKit,
				contract: selectedOption.value,
				unit: ''
			})
		} else {
			setUnitSelected(selectedOption)
			setCurrentKit({ ...currentKit, unit: selectedOption.value })
		}
	}

	function handleSelectContractAndUnits() {
		const buildContractsOptions = filter
			.filter((option) => option.options.length > 0)
			.map((item) => {
				return { label: item.label, value: item.value }
			})

		setContractsOptions(buildContractsOptions)
	}

	function handleSelectedUnits(contractSelected: string) {
		const filterOptions = filter.find(
			(e) => e.value === contractSelected
		) as IMenu

		if (!filterOptions) return []

		const buildUnitsOptions: SelectOption[] = filterOptions?.options
			.map((item) => {
				return {
					value: item.value,
					label: item.label
				}
			})
			.flat()

		setUnitsOptions(buildUnitsOptions)

		return buildUnitsOptions
	}

	function handleDeleteKitEnd() {
		setCurrentTargetKit({
			id: kitId || '',
			name: currentKit.deviceID,
			type: targetKit?.type
		})

		setTargetKit(null)
		handleOpenAuthModal()
	}

	function handleDeleteOnClose() {
		setTargetKit(null)
		setAuthModal((state) => ({ ...state, value: false }))
	}

	function renderModel(title: string, value: string | number) {
		return (
			<span>
				<strong>{title}</strong>
				<p>{value}</p>
			</span>
		)
	}

	function renderButtonDelete(): JSX.Element | null {
		if (action === 'CREATE' || currentKit.status !== 'DISSOCIATED') return null
		return (
			<button
				className='btn-clean btn-remove'
				onClick={() => {
					setTargetKit({
						id: kitId || '',
						name: currentKit.deviceID,
						type: 'DELETE'
					})

					handleOpenAuthModal()
				}}
			>
				Excluir Kit
			</button>
		)
	}

	function handleErrorView() {
		if (!currentKit.unit && !currentKit.contract) {
			setViewErrors(['Contrato e Unidade são obrigatórios'])
			return true
		}

		if (!currentKit.contract) {
			setViewErrors(['Contrato é obrigatório'])
			return true
		}

		if (!currentKit.unit) {
			setViewErrors(['Unidade é obrigatória'])
			return true
		}

		setViewErrors([])
		return false
	}

	async function handleSubmit() {
		const hasError = handleErrorView()

		if (hasError) return

		try {
			setIsLoading(true)

			const { contract, unit, _id } = currentKit

			if (!contract || !unit) return

			await associateKit(_id, contract, unit, user.email, authModal.password)

			cogoToast.success(successMessages.kitAssociated, cogoDefaultOptions)
			history.replace(routesEnum.KITS)
		} catch (error) {
		} finally {
			setIsLoading(false)
		}
	}

	function onDisassociateKit() {
		setTargetKit({
			id: currentKit._id || '',
			name: currentKit.deviceID,
			type: 'DISASSOCIATE'
		})
	}

	function handleSendSubmit() {
		setTargetKit({
			id: currentKit._id || '',
			name: currentKit.deviceID,
			type: 'ASSOCIATE'
		})
	}

	async function handleDessasociate() {
		try {
			const kitType =
				currentTargetKit?.type === 'DISASSOCIATE'
					? 'KIT_DISASSOCIATE'
					: 'KIT_DELETE'

			const successMessage = SUCCESS_MESSAGE[kitType]
			const deleteTarget = FUNC_ENUM[kitType]

			if (!currentTargetKit) return null
			await deleteTarget(currentTargetKit.id, user.email, authModal.password)

			setTargetKit(null)

			history.replace(routesEnum.KITS)
			cogoToast.success(successMessage, cogoDefaultOptions)
		} catch (error) {}
	}

	function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
		const password = event.target.value || ''

		setAuthModal((state) => ({ ...state, password }))
	}

	function handleClose() {
		setTargetKit(null)
		setAuthModal(INITIAL_AUTHENTICATION_MODAL_PROPS)
		setCurrentTargetKit(null)
	}

	function handleOpenAuthModal() {
		setAuthModal((state) => ({ ...state, value: true }))
	}

	const authModalConfig: IAuthenticationModalProps = {
		...authModal,
		handleSubmit:
			currentTargetKit?.type === 'ASSOCIATE'
				? handleSubmit
				: handleDessasociate,
		handleClose,
		handlePasswordChange,
		loadingSubmit: isLoading
	}

	useEffect(initializeFields, [])
	useEffect(handleOptionsSelected, [currentKit])
	useEffect(handleEnableBtn, [unitSelected, contractSelected])

	const viewProps: IViewProps = {
		action,
		handleDeleteKitEnd,
		enableBtnSubmit,
		handleSubmit,
		isLoading,
		targetKit,
		viewErrors,
		currentKit,
		contractsOptions,
		unitsOptions,
		renderModel,
		onDisassociateKit,
		handleDeleteOnClose,
		contractSelected,
		unitSelected,
		handleSelectChange,
		kitIsActive,
		renderButtonDelete,
		authModalConfig,
		handleSendSubmit
	}

	return createElement(UnitCreateAndEditView, viewProps)
}

export default UnitCreateAndEdit
