import { TableNavProps } from '@buildbox/components'
import { QueryStatus } from 'react-query'
import { IAcessorAndOrder } from 'shared/components/Table/types'

export default interface IProps {}
export interface IViewProps {
	tableColumns: any[]
	tableRows: Object[]
	navProps: TableNavProps
	usersPerPage: number
	handleSearch(value: string): void
	search: string
	handlerSortColumn(acessor: string, order: number): IAcessorAndOrder
	sortBy: IAcessorAndOrder
	handleCreateUser: () => void
	usersStatus: QueryStatus
}

export const INITIAL_SORT: IAcessorAndOrder = {
	acessor: 'createdAt',
	order: -1
}

export interface ITableColumn {}

export interface ITableRow {
	name: string
	role: JSX.Element
	contracts: JSX.Element
	units: JSX.Element
	editIcon: JSX.Element
}

export enum ScreenActions {
	CREATE,
	EDIT
}

export type ScreenActionsEnum = keyof typeof ScreenActions
export const ScreenActionsValues: ScreenActionsEnum[] = ['CREATE', 'EDIT']
