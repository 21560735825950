import React from 'react'
import { IViewProps } from './types'
import { TableContainer, ContractsListWrapper } from './styles'
import { tableStyle } from 'shared/styles/theme'

import Table from 'shared/components/Table'
import { HeaderPage } from 'shared/components/HeaderPage'

function ContractsPage(props: IViewProps): JSX.Element {
	const {
		showNewButton,
		tableRows,
		tableColumns,
		navProps,
		usersPerPage,
		search,
		sortBy,
		handleSearch,
		handleSortColumn,
		handleCreateContract,
		contractsStatus
	} = props

	return (
		<ContractsListWrapper className='animated-left'>
			<HeaderPage
				labelInput={'Buscar contrato'}
				titleButton={'Novo Contrato'}
				title={'Contratos'}
				showButton={showNewButton}
				handleSearch={handleSearch}
				handleSubmit={handleCreateContract}
				search={search}
				// isSearch={false}
			/>

			<TableContainer>
				<Table
					// Workaround while we need to fix columns type on lib
					columns={tableColumns as any}
					data={tableRows}
					navProps={navProps}
					pageSize={usersPerPage}
					{...tableStyle}
					padding={[26, 16, 26, 16]}
					getSelectedColumnAcessorAndOrder={handleSortColumn}
					sortBy={sortBy}
					noItensText='No momento, não existem contratos a serem exibidos.'
					isLoading={contractsStatus === 'loading'}
				/>
			</TableContainer>
		</ContractsListWrapper>
	)
}

export default ContractsPage
