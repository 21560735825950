// TODO: Type later
const routesEnum: any = {
	LOGIN: '/login',
	USERS: '/page/users',
	UNITS: '/page/units',
	CONTRACTS: '/page/contracts',
	KITS: '/page/kits',
	FINANCES: '/page/finance',
	FORGOT_PASSWORD: '/forgot-pw',
	SET_PASSWORD: '/change-password/:resetToken',
	USERS_CREATE_AND_UPDATE: '/page/users/new',
	UNITS_PAGE_CREATE: '/page/units/new',
	UNITS_PAGE_EDIT: '/page/units/edit',
	CONTRACTS_CREATE_AND_UPDATE: '/page/contracts/new',
	KITS_CREATE_AND_UPDATE: '/page/kits/edit',
	DASHBOARD: '/page/dashboard',
	EXAMS: '/page/exams',
	EXAMS_PAGE_EDIT: '/page/exams/edit/:examId',
}

export default routesEnum
