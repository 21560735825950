import { fontType, toRem } from '../../styles/functions'
import styled from 'styled-components'
import { colors } from 'shared/styles/theme'

export const DefaultPagePlaceholderWrapper = styled.div`
	/* margin-top: 128px; */
	padding: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.placeholder-image {
		margin-bottom: 16px;
	}

	.placeholder-text {
		color: ${colors.colorTextPlaceholder};
		font-size: ${toRem(14)};
		${fontType(0)};
		max-width: 218px;
		text-align: center;
	}
`
