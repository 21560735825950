import { DefaultPagePlaceholderWrapper } from './styles'
import { IDefaultPlaceholderProps } from './types'
import React from 'react'

function DefaultPagePlaceholder({
	className,
	text = 'Nada para mostrar aqui...',
	marginTop = 128
}: IDefaultPlaceholderProps) {
	return (
		<DefaultPagePlaceholderWrapper className={className} style={{ marginTop }}>
			<img
				className='placeholder-image'
				src={require('../../../assets/images/placeholder-icon.svg')}
				alt='Placeholder Cargas'
			/>
			<p className='placeholder-text'>{text}</p>
		</DefaultPagePlaceholderWrapper>
	)
}

export default DefaultPagePlaceholder
