import React, { useEffect, useState } from 'react'
import { ChevronRight } from 'react-feather'
import { useDispatch } from 'react-redux'

import { colors } from 'shared/styles/theme'
import CompositeMenu from 'shared/components/CompositeMenu'
import { IMenu } from 'shared/components/MenuPrimary/types'
import { BackgroundLayer, ContractFilterWrapper } from './styles'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { Creators as FilterActions } from 'shared/store/ducks/filter'

import { diff } from 'deep-object-diff'
import { isEmpty } from 'ramda'
import { deepCopy } from 'shared/util/object'

const contractFilterStyles = {
	width: '40%',
	maxHeight: 500,
	top: 90,
	left: 90,
	optionsHeight: 48,
	checkboxSize: 16,
	checkboxLevel: 1,
	checkboxPrimaryColor: colors.secondary,
	checkboxSecondaryColor: colors.checkboxSecondary,
	checkboxBackgroundColor: colors.pageBackground,
	backgroundColor: colors.lightGrey,
	menuBackgroundColor: colors.backgroundMenu,
	color: colors.darkGrey,
	fontSize: 12,
	fontFamily: 'Regular'
}

export default function ContractFilter(): JSX.Element {
	const dispatch = useDispatch()
	const [filterOpened, setFilterOpened] = useState(false)
	const [filterSearch, setFilterSearch] = useState('')
	const [allSelected, setAllSelected] = useState(true)
	const { filter } = useTypedSelector(['filter'])
	const [filterMenu, setFilterMenu] = useState<IMenu[]>(filter)
	const [unitsCount, setUnitsCount] = useState(0)

	function toggleFilterOpened() {
		setFilterOpened(!filterOpened)
	}

	function getFilterUpdates(menus: IMenu[]) {
		setFilterMenu(menus)
	}

	function getSearchUpdates(searchText: string) {
		setFilterSearch(searchText)
	}

	function getAllSelectedUpdates(value: boolean) {
		setAllSelected(value)
	}

	function setFilterMenuAccordingToSearch() {
		const filterCopy = deepCopy(filter)
		const lowerCasefilterSearch = filterSearch.toLowerCase()

		filterCopy.forEach((menu) => {
			if (menu?.label?.toLowerCase().includes(lowerCasefilterSearch)) {
				menu.visible = true
				menu.options.forEach((option) => (option.visible = true))
			} else {
				menu?.options.forEach((option) => {
					if (option.label.toLowerCase().includes(lowerCasefilterSearch))
						option.visible = true
					else option.visible = false
				})
				menu.visible = menu?.options.some((option) => option.visible)
			}
		})

		setFilterMenu(filterCopy)
	}

	function calculateUnitsCount() {
		const newUnitsCount = filterMenu.reduce((count, menu) => {
			return (
				count +
				menu.options.reduce((menuCount, option) => {
					return menuCount + (option.selected ? 1 : 0)
				}, 0)
			)
		}, 0)

		setUnitsCount(newUnitsCount)
	}

	function isAllSelected() {
		const allSelected = filter.every(
			(contract) =>
				contract.value &&
				contract.selected &&
				contract.options.every((units) => units.selected)
		)

		setAllSelected(allSelected)
	}

	function dispatchStateWhenClosed() {
		if (filterOpened) return

		const diffA = removeUnusedProps(filter)
		const diffB = removeUnusedProps(filterMenu)

		const filtersDiff = diff(diffA, diffB)
		const hasChanged = !isEmpty(filtersDiff)

		if (!hasChanged) return

		dispatch(FilterActions.setFilter(filterMenu))
	}

	// : Omit<T, 'expanded'>[]
	function removeUnusedProps<T extends IMenu>(menu: T[]) {
		const withoutExpanded = menu.map((item) => ({
			label: item.label,
			value: item.value,
			selected: item.selected,
			options: item.options.map((option) => ({
				label: option.label,
				value: option.value,
				selected: option.selected
			}))
		}))

		return withoutExpanded
	}

	function updateFilter() {
		setFilterMenuAccordingToSearch()
		isAllSelected()
	}

	function recalculate() {
		calculateUnitsCount()
	}

	useEffect(recalculate, [filterMenu])
	useEffect(updateFilter, [filterSearch, filter])
	useEffect(dispatchStateWhenClosed, [filterOpened, filterMenu])

	return (
		<>
			<BackgroundLayer
				style={{ position: filterOpened ? 'absolute' : 'unset' }}
				onClick={toggleFilterOpened}
			/>
			<ContractFilterWrapper>
				<div
					className='filter-box'
					onClick={toggleFilterOpened}
					style={
						filterOpened
							? { backgroundColor: contractFilterStyles.backgroundColor }
							: { backgroundColor: colors.white }
					}
				>
					<div className='filter-box-content'>
						<span className='filter-title'>
							<p>Filtro</p>
							<ChevronRight size={14} color={colors.darkGrey} />
							{unitsCount > 0 ? (
								<div className='badge'>{unitsCount}</div>
							) : null}
						</span>
						{allSelected ? <h1>Todos</h1> : <h1>Unidades</h1>}
					</div>
				</div>
			</ContractFilterWrapper>
			{filterOpened ? (
				<CompositeMenu
					searchLabel={'Buscar contrato ou unidade'}
					searchValue={filterSearch}
					getSearch={getSearchUpdates}
					allSelectedValue={allSelected}
					getAllSelected={getAllSelectedUpdates}
					menuContent={filterMenu}
					getContentUpdate={getFilterUpdates}
					styles={contractFilterStyles}
				/>
			) : null}
		</>
	)
}
