import React, { createElement, useEffect, useState } from 'react'
import { TableNavProps } from '@buildbox/components'
import { useDebounce } from 'use-debounce/lib'
import { IAcessorAndOrder } from 'shared/components/Table/types'
import { IUserResponsePaginated, IUsersPage } from 'shared/interfaces/user'
import { useUsersPage } from 'shared/services/user.service'
import {
	IViewProps,
	ITableColumn,
	ITableRow,
	INITIAL_SORT,
	ScreenActionsEnum
} from './types'
import UsersPage from './view'

import routesEnum from 'modules/Routes/enum'
import { useHistory } from 'react-router-dom'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { transformMenu } from 'shared/util/format'

import {
	renderContracts,
	renderEditIcon,
	renderRole,
	renderUnits
} from './util'

const USERS_PER_PAGE = 10

function UsersPageContainer(): JSX.Element {
	const history = useHistory()

	const [usersPage, setUsersPage] = useState<IUsersPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0
	})
	const [search, setSearch] = useState('')
	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [searchDebounce] = useDebounce(search, 1000)
	const [sortBy, setSortBy] = useState<IAcessorAndOrder>(INITIAL_SORT)
	const { profile, filter } = useTypedSelector(['profile', 'filter'])

	const onlySelected = transformMenu(filter)
	const { status: usersStatus, data: usersData } = useUsersPage({
		page: usersPage.page,
		search: searchDebounce,
		limit: USERS_PER_PAGE,
		filters: onlySelected,
		order: sortBy.order,
		sort: sortBy.acessor,
		profileID: profile.profileId
	})

	function handleSearch(value: string): void {
		setSearch(value)
	}

	function activateScreenUser(
		action: ScreenActionsEnum,
		name: string,
		id: string
	) {
		history.push({
			pathname: routesEnum.USERS_CREATE_AND_UPDATE,
			state: {
				action,
				userId: id
			}
		})
	}

	function handleCreateUser() {
		activateScreenUser('CREATE', '', '')
	}

	function handlerSortColumn(acessor: string, order: number): any {
		if (order === 0) {
			setSortBy(INITIAL_SORT)
			return
		}

		setSortBy({
			acessor,
			order
		})
	}

	const tableColumns: ITableColumn[] = [
		{
			Header: 'Nome',
			accessor: 'name',
			sortType: 'basic'
		},
		{
			Header: 'Perfil',
			accessor: 'role',
			disableSortBy: true
		},
		{
			Header: 'Contratos',
			accessor: 'contracts',
			disableSortBy: true
		},
		{
			Header: 'Unidades',
			accessor: 'units',
			disableSortBy: true
		},
		{
			Header: '',
			accessor: 'editIcon',
			disableSortBy: true
		}
	]

	function goToPage(pageIndex: number) {
		setUsersPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	function handleParseData() {
		if (!usersData || usersStatus === 'loading') return

		const rows =
			usersData.docs.map((user: IUserResponsePaginated) => {
				return {
					name: user.name,
					role: renderRole(user.profiles || []),
					contracts: renderContracts(user.profiles || []),
					units: renderUnits(user.profiles || []),
					editIcon: renderEditIcon(user.name, user._id, activateScreenUser)
				}
			}) || []

		setTableRows(rows)
		setUsersPage({
			numberOfPages: usersData.totalPages,
			page: usersData.page || 1,
			totalDocs: usersData.totalDocs
		})
	}

	const navProps: TableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: usersPage.numberOfPages,
		pageIndex: usersPage.page - 1,
		totalDocs: usersPage.totalDocs
	}

	useEffect(handleParseData, [usersData])

	const viewProps: IViewProps = {
		tableColumns,
		usersStatus,
		navProps,
		tableRows,
		usersPerPage: USERS_PER_PAGE,
		handleSearch,
		search,
		handlerSortColumn,
		sortBy,
		handleCreateUser
	}

	return createElement(UsersPage, viewProps)
}
export default UsersPageContainer
