import React, { createElement, useEffect, useState } from 'react'
import { TableNavProps } from '@buildbox/components'
import { format, getMonth, getYear } from 'date-fns'
import pt from 'date-fns/locale/pt-BR'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { capitalize, transformMenu } from 'shared/util/format'
import {
	IModalKitLogs,
	INITIAL_SORT,
	IRangeCalendar,
	ISelectCalendar,
	ITableColumn,
	ITableRow,
	IViewProps
} from './types'

import FinancePage from './view'
import chevronDown from '../../assets/images/expand-button.svg'

import { toMoney } from 'shared/util/currency'
import {
	getExamDataExcel,
	useFinancialPage
} from 'shared/services/financial.service'
import { IKitLog, IPageFinance, PageContent } from 'shared/interfaces/financial'
import { IAcessorAndOrder } from 'shared/components/Table/types'
import { useDebounce } from 'use-debounce/lib'
import { IProfile } from 'shared/interfaces/profile'
import { ProfileNameEnum } from 'shared/interfaces/user'
import { CustomerType, CustomerTypeEnum } from 'shared/interfaces/contract'

function FinancePageContainer(): JSX.Element {
	const [financialPage, setFinancialPage] = useState<IPageFinance>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0
	})

	const [isVisible, setVisibility] = useState(false)
	const [monthYear, setMonthYear] = useState<ISelectCalendar>({
		month: getMonth(new Date()),
		year: getYear(new Date())
	})

	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [tableTotal, setTableTotal] = useState<any[]>()

	const { filter, profile, user } = useTypedSelector([
		'filter',
		'profile',
		'user'
	])
	const [sortBy, setSortBy] = useState<IAcessorAndOrder>(INITIAL_SORT)
	const [search, setSearch] = useState('')
	const [searchDebounce] = useDebounce(search, 1000)

	const [periodSelected, setPeriodSelected] = useState('')
	const [modalIsOpen, setModalOpen] = useState(false)
	const [kitLog, setKitLog] = useState<IModalKitLogs>({} as IModalKitLogs)

	const range: IRangeCalendar = {
		min: { year: 2021, month: 0 },
		max: { year: getYear(new Date()), month: getMonth(new Date()) + 1 }
	}

	const [customerType, setCustomerType] = useState<
		CustomerTypeEnum | CustomerType.ALL
	>(CustomerType.ALL)

	const [customerTypeDebounce] = useDebounce(customerType, 1000)

	const onlySelected = transformMenu(filter)
	const { status: financialStatus, data: financialData } = useFinancialPage({
		page: financialPage.page,
		search: searchDebounce,
		limit: onlySelected.length,
		filters: onlySelected,
		order: sortBy.order,
		sort: sortBy.acessor,
		month: monthYear.month + 1, // WS is expecting the month from 1 to 12
		year: monthYear.year,
		customerType: customerTypeDebounce
	})

	function showMonthPicker() {
		setVisibility(!isVisible)
	}

	function handleOnDismiss() {
		setVisibility(false)
	}

	function handleSelectChange(SelectOption: any) {
		setCustomerType(SelectOption.value)
	}

	function handleOnChange(year: number, month: number) {
		setMonthYear({ year, month })
		setVisibility(false)
	}

	function handleSearch(value: string): void {
		setSearch(value)
	}

	function getMonthValue(): string {
		const month = monthYear && monthYear.month ? monthYear.month : 0
		const year = monthYear && monthYear.year ? monthYear.year : 0

		const monthString = format(new Date(year, month + 1, 0), 'MMMM', {
			locale: pt
		})

		const period = capitalize(`${monthString} - ${year}`)

		setPeriodSelected(period)

		return period
	}

	function handlerSortColumn(acessor: string, order: number): any {
		if (order === 0) {
			setSortBy(INITIAL_SORT)
			return
		}
		setSortBy({
			acessor,
			order
		})
	}

	function valueFormatted(data: PageContent[]) {
		const values: ITableRow[] = data.map((item) => {
			const {
				examsMade,
				kitsActive,
				total,
				totalExams,
				totalSubscriptions,
				name,
				units,
				_id,
				currentExamFee,
				currentSubscriptionFee
			} = item

			return {
				_id,
				name: name || '',
				kitsActive,
				totalSubscriptions: toMoney(totalSubscriptions),
				examsMade,
				totalExams: toMoney(totalExams),
				total: toMoney(total),
				idContract: item._id,
				subRows: units?.length ? renderSubrows(units) : [],
				currentExamFee: toMoney(currentExamFee),
				currentSubscriptionFee: toMoney(currentSubscriptionFee)
			}
		})

		return values
	}

	function valueTotal(data: PageContent[]) {
		return [
			'Total',
			data.reduce((kits, acc) => kits + acc.kitsActive, 0),
			toMoney(data.reduce((kits, acc) => kits + acc.currentSubscriptionFee, 0)),
			toMoney(data.reduce((total, acc) => total + acc.totalSubscriptions, 0)),
			data.reduce((examsMade, acc) => examsMade + acc.examsMade, 0),
			toMoney(data.reduce((kits, acc) => kits + acc.currentExamFee, 0)),
			toMoney(data.reduce((totalExams, acc) => totalExams + acc.totalExams, 0)),
			toMoney(data.reduce((total, acc) => total + acc.total, 0)),
			'',
			''
		]
	}

	function renderSubrows(units: IKitLog[]): ITableRow[] {
		const buildingSubrows: ITableRow[] = units.map((item) => {
			const {
				examsMade,
				kitsActive,
				total,
				totalExams,
				totalSubscriptions,
				name,
				_id,
				kits
			} = item

			return {
				name: name || '',
				_id: _id,
				kitsActive,
				totalSubscriptions: toMoney(totalSubscriptions),
				examsMade,
				totalExams: toMoney(totalExams),
				total: toMoney(total),
				idContract: item._id,
				kits
			}
		})

		return buildingSubrows
	}

	function handleModal(id: string) {
		setModalOpen(true)

		const findUnit = financialData?.docs.find(
			(contract) =>
				contract.units && contract.units.find((unit) => unit._id === id)
		)

		if (!findUnit) {
			setModalOpen(false)
			return
		}

		const unit = findUnit?.units?.find((unit) => unit._id === id)

		if (!unit) return

		setKitLog({ ...findUnit, unit })
	}

	function onClose() {
		setModalOpen(false)
	}

	function handleDowloadExamData(contractID: string) {
		const month = monthYear && monthYear.month ? monthYear.month : 0
		const year = monthYear && monthYear.year ? monthYear.year : 0

		const monthToSearch = new Date(year, month + 1, 0)

		getExamDataExcel(contractID, monthToSearch.toISOString())
	}

	function findProfileByName(name: ProfileNameEnum) {
		return user.profiles.find(
			(access) =>
				(access.profile as IProfile).name === name &&
				(access.profile as IProfile)._id === profile.profileId
		)
	}

	function isCommercialPartnerProfile(): boolean {
		return !!findProfileByName('COMMERCIAL_PARTNER')
	}

	function isAdminOrFinance(): boolean {
		return !!findProfileByName('ADMIN') || !!findProfileByName('FINANCE')
	}

	function handleParseData() {
		if (!financialData || financialStatus === 'loading') return

		const rows = valueFormatted(financialData.docs)
		const total = valueTotal(financialData.docs)

		setTableTotal(total)
		setTableRows(rows)

		setTableRows(rows)
		setFinancialPage({
			numberOfPages: financialData.totalPages,
			page: financialData.page || 1,
			totalDocs: financialData.totalDocs
		})
	}

	function goToPage(pageIndex: number) {
		setFinancialPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const navProps: TableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: financialPage.numberOfPages,
		pageIndex: financialPage.page - 1,
		totalDocs: financialPage.totalDocs
	}

	const tableColumns: ITableColumn[] = [
		{
			Header: 'Responsável',
			accessor: 'name',
			sortType: 'basic'
		},
		{
			Header: 'Ativações no Período',
			accessor: 'kitsActive',
			disableSortBy: true
		},
		{
			Header: 'Assinatura Por Kit Atual',
			accessor: 'currentSubscriptionFee',
			disableSortBy: true
		},
		{
			Header: `${
				isAdminOrFinance() ? 'Receita' : 'Custo'
			} Total de Assinaturas`,
			accessor: 'totalSubscriptions',
			disableSortBy: true
		},
		{
			Header: 'Exames Realizados',
			accessor: 'examsMade',
			disableSortBy: true
		},
		{
			Header: 'Valor Por Exame Atual',
			accessor: 'currentExamFee',
			disableSortBy: true
		},
		{
			Header: `${isAdminOrFinance() ? 'Receita' : 'Custo'} de Exames`,
			accessor: 'totalExams',
			disableSortBy: true
		},
		{
			Header: `${isAdminOrFinance() ? 'Receita' : 'Custo'} Total Mensal`,
			accessor: 'total',
			disableSortBy: true
		},

		{
			id: 'expander',
			Header: '',
			Cell: ({ row }: any) =>
				row.canExpand && row.subRows.length > 0 ? (
					<button
						style={{}}
						className={`btn-clean btn-expanded ${
							row.isExpanded ? 'expanded' : ''
						}`}
						{...row.getToggleRowExpandedProps()}
					>
						<img src={chevronDown} alt='Chevron Down' />
					</button>
				) : null
			// <button
			// 	onClick={() => openModal(row.original._id)}
			// 	style={{ height: 20, width: 20 }}
			// 	className='btn-clean'
			// >
			// 	<img src={fileText} alt='Ver detalhes' />
			// </button>
		},
		{
			Header: '',
			accessor: 'idContract',
			disableSortBy: true
		}
	]

	useEffect(handleParseData, [financialData])

	const viewProps: IViewProps = {
		range,
		isVisible,
		monthYear,
		showMonthPicker,
		handleOnDismiss,
		handleOnChange,
		getMonthValue,
		tableColumns,
		navProps,
		tableRows,
		sortBy,
		handlerSortColumn,
		periodSelected,
		handleSearch,
		search,
		tableTotal,
		handleModal,
		modalIsOpen,
		onClose,
		kitLog,
		handleDowloadExamData,
		isCommercialPartnerProfile,
		financialStatus,
		handleSelectChange,
		customerType
	}

	return createElement(FinancePage, viewProps)
}

export default FinancePageContainer
