import styled from 'styled-components'
import { fontType, mediaQuery, toRem } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'

export const InfoExamWrapper = styled.article`
	button {
		${fontType(0)};
	}
	h1,
	h2 {
		${fontType(1)};
		color: ${colors.darkGrey};
	}

	h1 {
		margin-bottom: ${toRem(20)};
		font-size: ${toRem(24)};
	}

	h2 {
		margin-bottom: ${toRem(18)};
		font-size: ${toRem(16)};
	}

	.font-medium {
		${fontType(1)}
		color: ${colors.darkGrey};
	}

	.font-regular {
		${fontType(0)};
		color: ${colors.darkGrey};
	}

	.background-box {
		background: ${colors.white};
	}

	.exported {
		padding: ${toRem(16)} ${toRem(48)};
	}

	.not-exported {
		padding: ${toRem(58)} ${toRem(48)};
	}

	.flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.border {
		padding-bottom: ${toRem(12)};
		border-bottom: 1px solid ${colors.backgroundSelect};
		width: 100%;
	}

	.info-exam {
		margin-top: ${toRem(8)};
		h1 {
			margin-bottom: ${toRem(32)};
		}
		figure {
			margin: auto;
			height: ${toRem(440)};
			width: ${toRem(620)};

			img {
				width: 100%;
				height: 100%;
			}
		}

		.report {
			display: flex;
			background: ${colors.backgroundResultDiagnosis};
			padding: ${toRem(12)};
			align-items: center;
			border-radius: 2px;
			margin-bottom: ${toRem(28)};

			p {
				${fontType(0)};
				color: ${colors.darkGrey};
				margin-left: ${toRem(12)};
				font-size: ${toRem(12)};
			}
			img {
				width: 20px;
				height: 20px;
			}
		}
	}

	.box-medical-report {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: ${toRem(16)};

		.checkbox {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.display-none {
				display: none;
			}
		}

		p {
			font-size: 12px;
		}
	}

	.textarea {
		position: relative;
		margin-bottom: ${toRem(16)};

		.placeholder {
			${fontType(2)};
			font-size: 10px;
			color: ${colors.placeholder};
			margin-bottom: 10px;
			width: 96%;
			padding: 5px;
		}

		.allowed {
			background: ${colors.white};
		}

		.not-allowed {
			background: #fafafa;
		}
	}

	.placeholder {
		position: absolute;
		left: 8px;
		top: 1px;
		pointer-events: none;
		text-align: left;
		z-index: 1;
	}

	.box-textarea {
		${fontType(0)};
		padding: ${toRem(24)} ${toRem(12)};
		/* height: ${toRem(77)}; */
		width: 100%;
		border: 1px solid #dbd8df;
		border-radius: 2px;
		resize: none;
		color: ${colors.placeholder};
		${fontType(0)};
		font-size: 14px;
		position: relative;
		:disabled {
			background: #fafafa;
		}
	}

	.limit-characters {
		${fontType(2)};
		font-size: 10px;
		color: ${colors.placeholder};
		text-align: end;
	}

	.center {
		text-align: center;
	}
	.btn-edit-report {
		button {
			border: 2px solid ${colors.darkGrey};
		}

		button[disabled] {
			background: #b5b0b8;
			cursor: not-allowed;
			opacity: 1;
			border: none;
			color: ${colors.white};
		}
	}
	.result-exam {
		font-size: 12px;
		margin-top: ${toRem(8)};
		padding: 16px;
		background: ${colors.backgroundSelect};

		${fontType(0)};

		.result-text {
			color: ${colors.darkGrey};
			margin-bottom: ${toRem(16)};
			font-family: sans-serif;
		}

		.result-text-warning {
			color: #df2726;
		}

		div {
			h2 {
				margin: 0;
			}
			margin-bottom: ${toRem(16)};

			span {
				padding: 5px;
				border-radius: 12px;
				font-size: 8px;
				${fontType(2)};
			}

			.suspicious {
				color: #d63f1a;
				background: #ffd5c3;
			}

			.not-suspicious {
				color: #93c61c;
				background: #e3ffc3;
			}

			.pending {
				background: #fff2c3;
				color: #e3b91b;
			}
		}
	}

	${mediaQuery('md')(`
		.box-medical-report {
			display: block;
	
			.checkbox {
				margin-top: 10px;
				justify-content: start;
			}
		}
    `)}
`
