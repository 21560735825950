export interface IKit {
	_id: string
	deviceID: string
	deviceModel: string
	simCardID: number
	activatedAt?: Date
	contract?: string
	unit?: string
	status: KitStatusEnum
	kitLog?: IKitEvent[]
	cameraType?: string
}

export interface IKitEvent {
	contract: string
	unit: string
	status: KitStatusEnum
	changeDate: Date
}

export enum KitStatus {
	PENDING = 'PENDING',
	ACTIVE = 'ACTIVE',
	DISSOCIATED = 'DISSOCIATED'
}

export interface IKitPaginated {
	_id: string
	deviceID: string
	deviceModel: string
	simCardID: string
	cameraType?: string
	contract?: IName
	unit?: IName
	activatedAt?: Date
	status: KitStatus
}

export interface IName {
	_id: string
	name: string
}

export type KitStatusEnum = keyof typeof KitStatus
