import React from 'react'
import { Button, Loading } from '@buildbox/components'

import routesEnum from 'modules/Routes/enum'
import GoBack from 'shared/components/GoBack'
import PageTitle from 'shared/components/PageTitle'
import ModalDelete from 'shared/components/ModalDelete'
import ViewErrors from 'shared/components/ViewErrors'
import { colors, primaryButtonStyle } from 'shared/styles/theme'

import { IViewProps, textButton, titleScreen } from './types'
import FormUnit from './FormUnit'
import { LoadingContainer, UnitWrapper } from './styles'
import AuthModal from 'shared/components/AuthModal'

function UnitCreateAndEditView(props: IViewProps): JSX.Element {
	const {
		action,
		dataUnit,
		targetUnit,
		viewErrors,
		enableBtnSubmit,
		contractSelected,
		contractsOptions,
		isLoading,
		authModalConfig,
		renderButtonDelete,
		handleSelectContract,
		handleSubmit,
		handleChange,
		handleStateChange,
		handleDeleteOnClose,
		handleCompanyType,
		handleCopyFromContract,
		handleDeleteUnitEnd,
		handleSubmitFunction
	} = props

	return (
		<>
			<UnitWrapper className='animated-right'>
				<GoBack routeName={routesEnum.UNITS} text='Todas as Unidades' />

				<PageTitle>{titleScreen[action]}</PageTitle>

				<FormUnit
					action={action}
					dataUnit={dataUnit}
					handleChange={handleChange}
					handleStateChange={handleStateChange}
					contractSelected={contractSelected}
					contractsOptions={contractsOptions}
					setContractSelected={handleSelectContract}
					handleCompanyType={handleCompanyType}
					handleCopyFromContract={handleCopyFromContract}
				/>

				<ViewErrors errors={viewErrors} color='red' />

				<div className='btn-save'>
					{renderButtonDelete()}

					<Button
						className='btn'
						onClick={handleSubmitFunction}
						disabled={!enableBtnSubmit || isLoading}
						{...primaryButtonStyle}
					>
						{textButton[action]}
					</Button>
				</div>

				{isLoading && (
					<LoadingContainer>
						<Loading type='Sentry' primaryColor={colors.secondary} />
					</LoadingContainer>
				)}
			</UnitWrapper>
			<AuthModal {...authModalConfig} />
			<ModalDelete
				onClose={handleDeleteOnClose}
				target={targetUnit}
				type='UNIT'
				handleDeleteUserEnd={handleDeleteUnitEnd}
			/>
		</>
	)
}

export default UnitCreateAndEditView
