import React from 'react'
import { HeaderPage } from 'shared/components/HeaderPage'
import Table from 'shared/components/Table'
import { tableStyle } from 'shared/styles/theme'
import { KitsListWrapper } from './styles'

import { IViewProps } from './types'
import { renderBtnExportExcel } from './util'

function KitsPage(props: IViewProps): JSX.Element {
	const {
		tableColumns,
		tableRows,
		navProps,
		itemsPerPage,
		handleSearch,
		search,
		handlerSortColumn,
		sortBy,
		handleCreateKit,
		kitsStatus,
		handleExportExcel
	} = props

	return (
		<KitsListWrapper className='animated-left'>
			<HeaderPage
				title={'Kits'}
				labelInput={'Buscar Kit'}
				titleButton={'Novo Kit'}
				handleSearch={handleSearch}
				showButton={false}
				search={search}
				// isSearch={false}
				handleSubmit={handleCreateKit}
			/>

			{renderBtnExportExcel(handleExportExcel)}

			<div className='tableContainer'>
				<Table
					// Workaround while we need to fix columns type on lib
					columns={tableColumns as any}
					data={tableRows}
					navProps={navProps}
					pageSize={itemsPerPage}
					getSelectedColumnAcessorAndOrder={handlerSortColumn}
					noItensText='No momento, não há Kits para serem exibidas.'
					sortBy={sortBy}
					isLoading={kitsStatus === 'loading'}
					{...tableStyle}
					padding={[26, 16, 26, 16]}
				/>
			</div>
		</KitsListWrapper>
	)
}
export default KitsPage
