import { TableNavProps } from '@buildbox/components'

import { QueryStatus } from 'react-query'
import { IAcessorAndOrder } from 'shared/components/Table/types'

export interface IViewProps {
	tableColumns: ITableColumn[]
	tableRows: ITableRow[]
	navProps: TableNavProps
	itemsPerPage: number
	handleSearch(value: string): void
	search: string
	handlerSortColumn(acessor: string, order: number): IAcessorAndOrder
	sortBy: IAcessorAndOrder
	onClickRow: (id: string) => void
	examStatus: QueryStatus
}

export interface ITableColumn {}

export const INITIAL_SORT: IAcessorAndOrder = {
	acessor: 'accomplishedAt',
	order: -1
}

export interface ITableRow {
	examId: string
	accomplishedAt: string
	patientName: string
	personalFiscalID: string
	diagnosis: JSX.Element
	editIcon: JSX.Element
	operatorName: string
	physicianName: string
}
