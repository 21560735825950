import React, { useEffect, useState } from 'react'
import { Select, SelectOption } from '@buildbox/components'
import { selectStyle } from 'shared/styles/theme'
import { useDispatch } from 'react-redux'
import { Creators as ProfileActions } from 'shared/store/ducks/profile'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { friendlyRole } from 'shared/util/friendlyRole'
import routesEnum from 'modules/Routes/enum'
import { useLocation } from 'react-router-dom'
import { getRouteAccessTypes } from 'modules/Routes/data'
import { IProfile } from 'shared/interfaces/profile'

export default function SelectProfile(): JSX.Element {
	const [options, setOptions] = useState<SelectOption[]>([])
	const [valueOption, setValueOption] = useState<SelectOption | null>(null)
	const { user, profile } = useTypedSelector(['user', 'profile'])
	const location = useLocation()

	const dispatch = useDispatch()

	function handleOptions() {
		const accessType = getRouteAccessTypes(location.pathname)
		if (!accessType) return

		const buildOptions: SelectOption[] = user.profiles
			.filter((profile) => {
				return (
					(profile.profile as IProfile).name !== 'OPERATOR' &&
					(profile.profile as IProfile).permissions.includes(accessType)
				)
			})
			.map((profile) => {
				return {
					value: (profile.profile as IProfile)._id,
					label: friendlyRole[(profile.profile as IProfile).name]
				}
			})

		if (!buildOptions.length) return

		const filterOption = buildOptions.find(
			(option) => option.value === profile.profileId
		)

		const haveOption = filterOption ? filterOption : buildOptions[0]

		setValueOption(haveOption)
		setOptions(buildOptions || [])
		dispatch(ProfileActions.setProfile({ profileId: haveOption.value }))
	}

	function handleSelectChange(selectedOption: any) {
		if (!selectedOption) return
		setValueOption(selectedOption)

		dispatch(ProfileActions.setProfile({ profileId: selectedOption.value }))
	}

	useEffect(handleOptions, [user])

	return (
		<div className='box-select'>
			<Select
				id='access'
				label='Acesso'
				className='select'
				options={options}
				placeholder={'Acesso'}
				value={valueOption}
				onChange={(value: any) => handleSelectChange(value)}
				isDisabled={
					location.pathname === routesEnum.USERS_CREATE_AND_UPDATE ||
					location.pathname === routesEnum.KITS_CREATE_AND_UPDATE
				}
				isSearchable={false}
				{...selectStyle}
			/>
		</div>
	)
}

/*
	Previous code, just in case any bug arises
		const buildOptions: SelectOption[] = contracts
			.filter((profile) => profile.profileName !== 'OPERATOR')
			.map((profile) => {
				return {
					value: profile.profileID,
					label: friendlyRole[profile.profileName]
				}
			})
*/
