import { CheckBoxPrimaryWrapper } from './styles'
import React from 'react'
import { ICheckBoxProps } from './types'

function CheckBox({ ...rest }: ICheckBoxProps) {
	return (
		<CheckBoxPrimaryWrapper>
			<input
				className='checkbox-input'
				type='checkbox'
				name='checkbox'
				{...rest}
			/>

			<div className='checkmark'>{rest.checked && <span />}</div>
		</CheckBoxPrimaryWrapper>
	)
}

export default CheckBox
