import { format } from 'date-fns'
import fileDownload from 'js-file-download'
import { useQuery, UseQueryResult } from 'react-query'
import { IKit, IKitPaginated } from 'shared/interfaces/kit'
import { PaginatedRequest, PaginatedResult } from 'shared/interfaces/pagination'
import { QUERY_KEYS } from 'shared/util/reactQuery'

import axiosInstance from './axiosInstance'

export async function findKit(id: string): Promise<IKit> {
	const response = await axiosInstance.get(`/kits/${id}`)

	return response.data
}

export async function disassociateKit(
	_id: string,
	email: string,
	password: string
): Promise<string> {
	const response = await axiosInstance.patch(
		`/kits/dissociate/${_id}`,
		{},
		{
			headers: {
				EAuth: email,
				PAuth: password
			}
		}
	)

	return response.data
}

export async function associateKit(
	_id: string,
	contract: string,
	unit: string,
	email: string,
	password: string
): Promise<string> {
	const response = await axiosInstance.patch(
		`/kits/associate/${_id}`,
		{
			contract,
			unit
		},
		{
			headers: {
				EAuth: email,
				PAuth: password
			}
		}
	)

	return response.data
}

export async function deleteKit(
	_id: string,
	email: string,
	password: string
): Promise<string> {
	const response = await axiosInstance.delete(`/kits/delete/${_id}`, {
		headers: {
			EAuth: email,
			PAuth: password
		}
	})

	return response.data
}

export async function getExcel() {
	const response = await axiosInstance.post(
		`/kits/generate-excel`,
		{},
		{
			responseType: 'blob'
		}
	)

	const fileName = `${format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")}.xlsx`

	fileDownload(response.data, fileName)
}

export type IFetchKitsRequest = PaginatedRequest<{ profileID?: string }>
export type IFetchKitsResponse = PaginatedResult<IKitPaginated>

export async function fetchKitsPage({
	page,
	limit,
	search,
	sort,
	order,
	filters,
	profileID
}: IFetchKitsRequest): Promise<IFetchKitsResponse> {
	// The server call this pageIndex but actually it's the page number
	const response = await axiosInstance.post('/kits/get-page', {
		pageIndex: page,
		maxItemsInPage: limit,
		searchString: search,
		sort,
		order,
		filters,
		profileID
	})

	return response.data
}

export function useKitsPage({
	page,
	limit,
	search,
	sort,
	order,
	filters,
	profileID
}: IFetchKitsRequest): UseQueryResult<IFetchKitsResponse> {
	const enabled = !!(page && limit && filters && profileID)

	return useQuery(
		[
			QUERY_KEYS.KITS_PAGE,
			{ page, limit, search, sort, order, filters, profileID }
		],
		async ({ queryKey }) => {
			const [_key, params] = queryKey
			const { page, limit, search, sort, order, filters, profileID } =
				params as IFetchKitsRequest

			return fetchKitsPage({
				page,
				limit,
				search,
				sort,
				order,
				filters,
				profileID
			})
		},
		{
			enabled
		}
	)
}
