import { fontType, mediaQuery, toRem } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'
import styled from 'styled-components'

export const ChangePasswordWrapper = styled.div`
	${fontType(0)}
	display: flex;
	justify-content: space-between;
	padding: 0 111px;
	width: 100%;
	min-height: 100vh;
	align-items: center;
	background-image: url(${require('assets/images/password-background.jpg')});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	.container-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: start;
	}

	.logo-wrapper {
		max-width: ${toRem(283)};
	}

	.change-password-title h1 {
		font-size: 24px;
		color: ${colors.white};
		width: 330px;
		${fontType(-1)}
		margin-top: 32px;
	}

	${mediaQuery('md')(`
        flex-direction: column;
	    justify-content: center;
        padding: 0;

		.container-wrapper{
			margin-bottom: 24px;
			align-items: center;
		}

		.change-password-title{
			font-size: 16px;
			text-align: center;
		}
	`)};

	.content-wrapper {
		background-color: ${colors.white};
		justify-content: space-between;
		display: flex;
		width: ${toRem(360)};
		box-shadow: 0px 2px 4px #00000029;
		border-radius: 4px;
		margin-bottom: 10px;

		.formContainer {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 100%;
			padding: 0px 24px;
			text-align: center;

			.input {
				margin-bottom: 12px;
			}

			.button {
				padding: 8px;

				button {
					text-transform: uppercase;
				}
			}
		}
	}
`
