import { colors } from 'shared/styles/theme'
import { fontType, toRem } from 'shared/styles/functions'
import styled from 'styled-components'

export const ContractFilterWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 180px;
	height: 79px;
	/* box-shadow: 0px 2px 3px #00000014; */
	/* border-bottom: 1px solid ${colors.lightGrey}; */
	border-right: 2px solid #eee;
	/* border-left: 2px solid #eee; */
	background: ${colors.white};
	cursor: pointer;
	color: ${colors.darkGrey};
	font-family: 'Regular';
	align-items: center;

	.filter-box {
		display: flex;
		justify-content: center; // adjusts y positiion
		align-items: center; // adjusts x positiion
		width: 90%;
		height: 80%;

		.filter-box-content {
			display: flex;
			flex-direction: column;
			justify-content: center; // adjusts y positiion
			align-items: start; // adjusts x positiion
			width: 80%;
			height: 80%;

			.filter-title {
				width: 100%;
				height: 18px;
				display: flex;
				flex-direction: row;

				p {
					margin-right: 6px;
					${fontType(0)};
					font-size: ${toRem(12)};
				}
				.badge {
					margin-left: auto;
					width: 19px;
					height: 19px;
					color: ${colors.white};
					background-color: ${colors.secondary};
					border-radius: 50%;
					padding: 2px;
					font-size: ${toRem(10)};
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
			h1 {
				${fontType(-1)};
				font-size: ${toRem(24)};
			}
		}
	}
`
export const BackgroundLayer = styled.div`
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
`
