import React from 'react'
import { LoadingContainer, UserWrapper } from './styles'

import routesEnum from 'modules/Routes/enum'
import GoBack from 'shared/components/GoBack'
import { colors, primaryButtonStyle } from 'shared/styles/theme'
import { Button, Loading } from '@buildbox/components'
import PageTitle from 'shared/components/PageTitle'
import { IViewProps, textButton, titleScreen } from './types'
import ModalDelete from 'shared/components/ModalDelete'
import FormData from './FormData'
import ViewErrors from 'shared/components/ViewErrors'
import AuthModal from 'shared/components/AuthModal'

function CreateEditContract(props: IViewProps): JSX.Element {
	const {
		action,
		renderButtonDelete,
		newFormContract,
		handleChange,
		handleCompanyType,
		renderUpload,
		removeUpload,
		enableBtnSubmit,
		handleSetDate,
		viewErrors,
		loading,
		checkboxTablet,
		handleCheckboxTablet,
		onSubmit,
		authModalConfig,
		modalDeleteProps,
		handleSelectChange
	} = props

	return (
		<>
			<UserWrapper className='animated-right'>
				<GoBack routeName={routesEnum.CONTRACTS} text='Todos os Contratos' />

				<PageTitle>{titleScreen[action]}</PageTitle>

				<h2 className='subtitle'>Natureza Jurídica</h2>

				<FormData
					action={action}
					contract={newFormContract}
					handleChange={handleChange}
					handleCompanyType={handleCompanyType}
					renderUpload={renderUpload}
					removeUpload={removeUpload}
					handleSetDate={handleSetDate}
					checkboxTablet={checkboxTablet}
					handleSelectChange={handleSelectChange}
					handleCheckboxTablet={handleCheckboxTablet}
				/>

				<ViewErrors
					// className='view-error'
					errors={viewErrors}
					color='red'
				/>

				{loading ? (
					<LoadingContainer>
						<Loading type='Sentry' primaryColor={colors.secondary} />{' '}
					</LoadingContainer>
				) : (
					<div className='btn-save'>
						{renderButtonDelete()}
						<Button
							className='btn'
							disabled={!enableBtnSubmit || loading}
							onClick={onSubmit}
							{...primaryButtonStyle}
						>
							{textButton[action]}
						</Button>
					</div>
				)}
			</UserWrapper>

			<ModalDelete type='CONTRACT' {...modalDeleteProps} />

			<AuthModal {...authModalConfig} />
		</>
	)
}

export default CreateEditContract
