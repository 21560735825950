import routesEnum from '../../modules/Routes/enum'
import { PermissionEnum } from 'shared/interfaces/user'

export function redirectRoute(
	userPermissions: PermissionEnum[],
	accessToken: string
) {
	if (userPermissions.length && accessToken) {
		const route = routesEnum[userPermissions[0]]

		return route ? route : routesEnum.LOGIN
	}

	return routesEnum.LOGIN
}
