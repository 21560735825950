import styled from 'styled-components'

export const MenuOptionWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 16px;
	
	.checkbox-level1 {
		/* margin-left: 16px; */
	}
	.checkbox-level2 {
		margin-left: 16px;
	}
	.checkbox-level3 {
		margin-left: 32px;
	}
	.checkbox-level4 {
		margin-left: 48px;
	}

	p {
		margin-left: 12px;
	}
`
