export interface IViewProps {
	type: TypeWrapper
	onSubmit: () => void
	isFormValid: boolean
	isLoading: boolean
	title?: string
	subtitle?: string
	titleButton?: string
	children: JSX.Element
	height?: number
}

export enum TypeWrapper {
	ForgotPassword,
	ChangePassword
}
