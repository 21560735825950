import React from 'react'
import { Column, Row } from 'shared/styles'
import { FloatLabelInput, Select } from '@buildbox/components'

import CheckBoxLegalNature from 'shared/components/CheckBoxLegalNature'
import { COMPANY_TYPES } from 'shared/interfaces/contract'
import { primaryInputStyle, selectStyle } from 'shared/styles/theme'
import { translate } from 'shared/util/translate'

import CheckboxCopyFromContract from './CheckboxCopyFromContract'

import { IProps } from './types'

function EntityTypeAndContract(props: IProps): JSX.Element {
	const {
		action,
		entityType,
		checkboxState,
		handleCheckbox,
		contractsOptions,
		contractSelected,
		setContractSelected,
		changeCopyFromContract,
		copyFromContract
	} = props

	if (action === 'EDIT') {
		return (
			<Row>
				<Column md='5' lg='2'>
					<FloatLabelInput
						id='entityType'
						className='input-disabled'
						label='Natureza Jurídica'
						disabled={true}
						value={translate(entityType)}
						{...primaryInputStyle}
					/>
				</Column>
				<Column md='7' lg='4'>
					<FloatLabelInput
						id='contract'
						className='input-disabled'
						label='Contrato'
						disabled={true}
						value={contractSelected ? contractSelected.label : ''}
						{...primaryInputStyle}
					/>
				</Column>
			</Row>
		)
	}

	return (
		<>
			<Row>
				<Column md='12'>
					<div className='checkboxes-label'>Natureza Jurídica</div>
					<div className='checkboxes'>
						{COMPANY_TYPES.map((type) => (
							<CheckBoxLegalNature
								key={type}
								checkboxState={checkboxState}
								handleCheckbox={handleCheckbox}
								type={type}
							/>
						))}
					</div>
				</Column>
			</Row>

			<Row>
				<Column xs='12' md='7' lg='4'>
					<Select
						id='contract'
						label='Selecione o Contrato'
						options={contractsOptions}
						value={contractSelected}
						placeholder={'Selecione o Contrato'}
						onChange={(value: any) => setContractSelected(value)}
						{...selectStyle}
					/>
				</Column>
				<Column xs='12' md='5' lg='8'>
					<CheckboxCopyFromContract
						changeCopyFromContract={changeCopyFromContract}
						checked={copyFromContract}
						disabled={!!!contractSelected}
					/>
				</Column>
			</Row>
		</>
	)
}

export default EntityTypeAndContract
