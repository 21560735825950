import React from 'react'

import { ExamDiagnosisEnum, IExam } from 'shared/interfaces/exam'
import { friendlyDiagnosisForward } from 'shared/util/friendlyRole'

export function renderDiagnosis(exam: IExam) {
	const diagnosis = lastReportUpdated(exam)
	return (
		<div style={{ display: 'flex' }}>
			<p className={diagnosis === 'NONE' ? 'diagnosis-pending' : ''}>
				{friendlyDiagnosisForward[diagnosis]}
			</p>
		</div>
	)
}

function lastReportUpdated(exam: IExam): ExamDiagnosisEnum {
	if (!exam.physicianDiagnoses?.length) {
		return 'NONE'
	}

	return exam?.physicianDiagnoses?.reduce((a, b) => {
		return new Date(a.date) > new Date(b.date) ? a : b
	}).diagnosis
}

export function renderEditIcon(): JSX.Element {
	return (
		// <Link
		// 	to={(routesEnum.EXAMS_PAGE_EDIT, { state: { examId } })}
		// 	target='_blank'
		// 	// onClick={(event) => {
		// 	// 	event.preventDefault()
		// 	// 	window.open(this.makeHref('route'))
		// 	// }}
		// >
		// 	<img
		// 		className='img'
		// 		src={require('../../assets/images/dots.svg')}
		// 		alt='Editar'
		// 	/>
		// </Link>

		// <a target='_blank' href={routesEnum.EXAMS_PAGE_EDIT}>
		// 	<img
		// 		className='img'
		// 		src={require('../../assets/images/dots.svg')}
		// 		alt='Editar'
		// 	/>
		// </a>
		<button className='icon'>
			<img
				className='img'
				src={require('../../assets/images/dots.svg')}
				alt='Editar'
			/>
		</button>
	)
}
