import React, { createElement, useEffect, useState } from 'react'
import { TableNavProps } from '@buildbox/components'
import { useDebounce } from 'use-debounce/lib'
import { IAcessorAndOrder } from 'shared/components/Table/types'

import { useUnitsPage } from 'shared/services/unit.service'
import { IUnitPaginated, IUnitsPage } from 'shared/interfaces/unit'
import { IViewProps, ITableRow, ITableColumn, INITIAL_SORT } from './types'
import UnitsPage from './view'
import routesEnum from 'modules/Routes/enum'
import { useHistory } from 'react-router-dom'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { transformMenu } from 'shared/util/format'
import { useSelectedProfileIsOneOf } from 'shared/util/profilePermission'
import { renderEditIcon } from './util'

const ITEMS_PER_PAGE = 10

function UnitsPageContainer(): JSX.Element {
	const history = useHistory()

	const [unitsPage, setUnitsPage] = useState<IUnitsPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0
	})
	const [search, setSearch] = useState('')
	const [searchDebounce] = useDebounce(search, 1000)
	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [sortBy, setSortBy] = useState<IAcessorAndOrder>(INITIAL_SORT)
	const { filter } = useTypedSelector(['filter', 'profile'])

	const showNewButton = useSelectedProfileIsOneOf(['ADMIN', 'CONTRACT_ADMIN'])

	const onlySelected = transformMenu(filter)
	const { status: unitsStatus, data: unitsData } = useUnitsPage({
		page: unitsPage.page,
		search: searchDebounce,
		limit: ITEMS_PER_PAGE,
		filters: onlySelected,
		order: sortBy.order,
		sort: sortBy.acessor
	})

	function handleSearch(value: string): void {
		setSearch(value)
	}

	function handleEditUnit(unit: IUnitPaginated) {
		history.push({
			pathname: routesEnum.UNITS_PAGE_EDIT,
			state: {
				action: 'EDIT',
				unit
			}
		})
	}

	function handleCreateUnit() {
		history.push({
			pathname: routesEnum.UNITS_PAGE_CREATE,
			state: {
				action: 'CREATE'
			}
		})
	}

	function handlerSortColumn(acessor: string, order: number): any {
		if (order === 0) {
			setSortBy(INITIAL_SORT)
			return
		}

		setSortBy({
			acessor,
			order
		})
	}

	function handleParseData() {
		if (!unitsData || unitsStatus === 'loading') return

		const rows =
			unitsData.docs.map((unit: IUnitPaginated) => {
				return {
					name: unit.name,
					contract: unit.contract ? unit.contract.name : '-sem contrato-',
					operators: unit.operators.length,
					kits: unit.kits.length,
					editIcon: renderEditIcon(unit, handleEditUnit)
				}
			}) || []

		setTableRows(rows)
		setUnitsPage({
			numberOfPages: unitsData.totalPages,
			page: unitsData.page || 1,
			totalDocs: unitsData.totalDocs
		})
	}

	function goToPage(pageIndex: number) {
		setUnitsPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const navProps: TableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: unitsPage.numberOfPages,
		pageIndex: unitsPage.page - 1,
		totalDocs: unitsPage.totalDocs
	}

	const tableColumns: ITableColumn[] = [
		{
			Header: 'Nome da Unidade',
			accessor: 'name',
			sortType: 'basic'
		},
		{
			Header: 'Contratante',
			accessor: 'contract',
			sortType: 'basic'
		},
		{
			Header: 'Operadores',
			accessor: 'operators',
			sortType: 'basic'
		},
		{
			Header: 'Kits Ativos',
			accessor: 'kits',
			sortType: 'basic'
		},
		{
			Header: '',
			accessor: 'editIcon',
			disableSortBy: true
		}
	]

	useEffect(handleParseData, [unitsData])

	const viewProps: IViewProps = {
		showNewButton,
		tableColumns,
		tableRows,
		unitsStatus,
		navProps,
		itemsPerPage: ITEMS_PER_PAGE,
		handleSearch,
		search,
		handlerSortColumn,
		sortBy,
		handleCreateUnit
	}

	return createElement(UnitsPage, viewProps)
}
export default UnitsPageContainer
