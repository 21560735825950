import { SelectOption } from '@buildbox/components'
import { ISelectOption } from '@buildbox/components/lib/components/Select/types'
import { UtilMask, UtilValidators } from '@buildbox/utils/lib'
import cogoToast from 'cogo-toast'
import routesEnum from 'modules/Routes/enum'
import { ScreenActionsEnum } from 'modules/UsersPage/types'
import React, { Fragment } from 'react'
import {
	ChangeEvent,
	createElement,
	FormEvent,
	useEffect,
	useState
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
	IModalDeleteTarget,
	TargetTypesEnum
} from 'shared/components/ModalDelete/types'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import {
	IUser,
	ProfileNameEnum,
	UserProfile,
	UserProfileContract
} from 'shared/interfaces/user'
import {
	createUser,
	findUser,
	IUserRequest,
	updateUser,
	updateUserProfile,
	userAlreadyExists
} from 'shared/services/user.service'
import { INITIAL_STATE } from 'shared/store/ducks/user'
import { buildingOptionsProfile } from 'shared/util/formatSelect'
import { friendlyRole } from 'shared/util/friendlyRole'
import { errorMessages, successMessages } from 'shared/util/Messages'
import { profilePermissions } from 'shared/util/profilePermission'
import cogoDefaultOptions from 'shared/util/toaster'
import {
	IDataUser,
	INITIAL_DATA_ACCESS,
	INITIAL_DATA_USER,
	IProfilesSelected,
	IViewProps,
	ProfileAndAccess
} from './types'
import CreateUser from './view'
import { IProfile } from 'shared/interfaces/profile'
import {
	IAuthenticationModalProps,
	INITIAL_AUTHENTICATION_MODAL_PROPS
} from 'shared/components/AuthModal/types'
import { FUNC_ENUM, SUCCESS_MESSAGE } from 'shared/components/ModalDelete/data'
import handleError from 'shared/util/handleError'
import { IError } from 'shared/interfaces/error'

function CreateUserContainer(): JSX.Element {
	const { state } = useLocation<{ action: ScreenActionsEnum; userId: string }>()
	const { action, userId } = state || {
		action: 'CREATE',
		userId: ''
	}

	const [currentUser, setCurrentUser] = useState<IUser>(INITIAL_STATE)
	const { profile, user, filter } = useTypedSelector([
		'user',
		'profile',
		'filter'
	])
	const [dataUser, setDataUser] = useState<IDataUser>(INITIAL_DATA_USER)
	const [profileOptions, setProfileOptions] = useState<SelectOption[]>([])
	// const [userProfileIsAdmin, setUserProfileIsAdmin] = useState<boolean>(false)
	const [profileOptionsSelected, setProfileOptionsSelected] = useState<
		IProfilesSelected[]
	>([])
	const { checkEmail, checkCPFCNPJ } = UtilValidators

	const [profileAndAccess, setProfileAndAccess] = useState<ProfileAndAccess[]>([
		INITIAL_DATA_ACCESS
	])
	const [showProfile, setShowProfile] = useState(false)
	const [enableBtnNext, setEnableBtnNext] = useState(false)
	const [enableBtnSubmit, setEnableBtnSubmit] = useState(false)
	const [targetUser, setTargetUser] = useState<IModalDeleteTarget | null>(null)
	const [oldValues, setOldValues] = useState<any[]>()
	const [loading, setLoading] = useState(false)
	const [loadingSubmit, setLoadingSubmit] = useState(false)
	const [CRMError, setCRMError] = useState<string>('')
	const [stateError, setStateError] = useState<string>('')
	const [hasPhysician, setHasPhysician] = useState(false)

	const [isDelete, setIsDelete] = useState(false)
	const [authModal, setAuthModal] = useState(INITIAL_AUTHENTICATION_MODAL_PROPS)
	const [userToDelete, setUserToDelete] =
		useState<IModalDeleteTarget | null>(null)

	const history = useHistory()

	function initializeFields() {
		if (action === 'EDIT') {
			getUserDetails()
			setShowProfile(true)
		} else {
			const accessAndProfile = [createNewListProfileAndAccess()]
			setProfileAndAccess(accessAndProfile)
		}
	}

	async function getUserDetails() {
		try {
			if (!!!profileOptions || profileOptions.length === 0) return []
			setLoading(true)
			const userResponse = await findUser(userId)

			const accessAndProfile = populateListProfileAndAccess(userResponse)

			setCurrentUser(userResponse)
			setProfileAndAccess(accessAndProfile)
			defineProfilesSelectedOnLoad(accessAndProfile)

			const { personalFiscalID, email, name, crm, state } = userResponse

			setDataUser({
				personalFiscalID,
				name,
				email,
				crm,
				state
			})

			if (!userResponse.profiles?.length) {
				const accessAndProfiles = [createNewListProfileAndAccess()]
				setProfileAndAccess(accessAndProfiles)
				setOldValues(accessAndProfiles)

				return
			}

			const formatProfileAndAccess = formatRole(accessAndProfile)

			setOldValues(formatProfileAndAccess)
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	function defineProfilesSelectedOnLoad(accessAndProfile: ProfileAndAccess[]) {
		const profilesSelected = accessAndProfile.map(
			(item, index) =>
				({
					index,
					profileId: item.profile.value
				} as IProfilesSelected)
		)
		setProfileOptionsSelected(profilesSelected)
	}

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		const { id, value } = event.target

		let formatValue: string | number = value

		if (id === 'personalFiscalID') {
			formatValue = UtilMask.formatCPF(value)
		}

		setDataUser({
			...dataUser,
			[id]: formatValue
		})
	}

	function isOperadorOrUnitManagerOrPhysician(index: number) {
		const label = profileAndAccess[index].profile.label

		return [
			friendlyRole['OPERATOR'],
			friendlyRole['UNIT_MANAGER'],
			friendlyRole['PHYSICIAN']
		].includes(label)
	}

	function isContractAdmin(index: number) {
		const label = profileAndAccess[index].profile.label

		return label === friendlyRole['CONTRACT_ADMIN']
	}

	function handleStateChange(state: SelectOption | null) {
		if (!state) return

		setDataUser({
			...dataUser,
			state: state.value
		})
	}

	const onChangeProfileAndAccess = (
		index: number,
		updatedProfile: ProfileAndAccess
	) => {
		const isAdminOrFinance =
			updatedProfile.profile.label === friendlyRole['ADMIN'] ||
			updatedProfile.profile.label === friendlyRole['FINANCE']

		const isContractAdminOrCommercialPartner =
			updatedProfile.profile.label === friendlyRole['CONTRACT_ADMIN'] ||
			updatedProfile.profile.label === friendlyRole['COMMERCIAL_PARTNER']

		profileAndAccess[index] = {
			profile: updatedProfile.profile,
			contracts: isAdminOrFinance
				? []
				: isContractAdminOrCommercialPartner
				? updatedProfile.contracts === null
					? null
					: updatedProfile.contracts
				: mappedAccessProfile(updatedProfile.contracts || [])
		}

		setProfileAndAccess([...profileAndAccess])

		handlerListOfProfiles(index, profileAndAccess[index].profile)
	}

	function handlerListOfProfiles(
		index: number,
		profileSelected: ISelectOption
	): void {
		// if has added item, remove it
		const indexSelectedProfile = profileOptionsSelected.findIndex(
			(x) => x.index === index
		)

		const hasAdded = indexSelectedProfile !== -1
		if (hasAdded) profileOptionsSelected.splice(indexSelectedProfile, 1)

		// add new item
		profileOptionsSelected.push({ index, profileId: profileSelected.value })
		setProfileOptionsSelected(profileOptionsSelected)
	}

	function listAvaliableProfiles(): ISelectOption[] {
		const profilesIdsSelected = profileOptionsSelected.map(
			({ profileId }) => profileId
		)
		const profilesIds = profileAndAccess.map(
			(profileIds) => profileIds.profile.value
		)

		const temp = profileOptions.filter(
			(profile: any) =>
				!profilesIdsSelected.includes(profile.value) &&
				!profile.isDisabled &&
				!profilesIds.includes(profile.value)
		)

		return temp
	}

	function populateListProfileAndAccess(user: IUser): ProfileAndAccess[] {
		// if (!!!profileOptions || profileOptions.length === 0) return []

		const dataInitial: ProfileAndAccess[] = (user.profiles || []).map(
			(profile) => {
				const realProfile = profileOptions.find(
					(x) => x.value === profile.profile
				) as SelectOption

				return {
					profile: realProfile,
					contracts: mappedAccessProfile(profile.contracts || [])
				}
			}
		)
		return dataInitial
	}

	function createNewListProfileAndAccess(): ProfileAndAccess {
		return INITIAL_DATA_ACCESS
	}

	function isValidateProfileAndAccess() {
		const data = formatRole(profileAndAccess)
		let isValid = data.every((item) => !!item.profile)

		data.forEach((item) => {
			item.profile = profileAndAccess.find(
				(x) => x.profile.value === item.profile
			)?.profile.label as string
		})

		let isValidContracts = data.every((item) => {
			if (selectedProfileRequiresContracts(item.profile)) {
				return item?.contracts && item?.contracts?.length > 0
			}

			if (
				item.profile.includes(friendlyRole['CONTRACT_ADMIN']) ||
				item.profile.includes(friendlyRole['COMMERCIAL_PARTNER'])
			) {
				return item.contracts !== null
			}

			return true
		})
		return isValid && isValidContracts
	}

	function selectedProfileRequiresContracts(profileSelected: string): boolean {
		const profilesRequired = [
			friendlyRole['ADMIN'],
			friendlyRole['FINANCE'],
			friendlyRole['CONTRACT_ADMIN'],
			friendlyRole['COMMERCIAL_PARTNER']
		]
		return !profilesRequired.includes(profileSelected)
	}

	function isArrayEqual(array: any[], compareArray: any[]) {
		if (!array) return false
		if (array.length !== compareArray.length) return false
		for (var index = 0; index < array.length; ++index) {
			if (
				JSON.stringify(array[index]) !== JSON.stringify(compareArray[index])
			) {
				return false
			}
		}
		return true
	}

	function thereUpdateContractsAndUnits() {
		const newInputValues = formatRole(profileAndAccess)
		if (!oldValues?.length || !newInputValues.length) return false
		const hasNewData = !isArrayEqual(oldValues, newInputValues)

		return hasNewData
	}

	function wasThereAnUpdateInInputs(): boolean {
		if (!currentUser.email) return false

		const wasUpdateName = dataUser.name !== currentUser.name
		const wasUpdateCrm = dataUser.crm !== currentUser.crm
		const wasUpdateState = dataUser.state !== currentUser.state
		const hasNewData = thereUpdateContractsAndUnits()
		const isValidate = isValidateProfileAndAccess()

		const udpated =
			hasNewData || wasUpdateName || wasUpdateCrm || wasUpdateState
		return udpated && isValidate
	}

	function addProfileAndAccess() {
		if (isValidateProfileAndAccess()) {
			const newList = createNewListProfileAndAccess()
			setProfileAndAccess([...profileAndAccess, newList])
		}
	}

	function removeProfile(index: number, e?: FormEvent<HTMLButtonElement>) {
		if (e) e.preventDefault()

		const listUpdated = [...profileAndAccess]
		const removed = listUpdated.splice(index, 1)[0]
		setProfileAndAccess(listUpdated)
		setProfileOptionsSelected((item) =>
			item.filter((x) => x.profileId !== removed.profile.value)
		)
	}

	function permissionToCreateUser(
		handleProfile: ISelectOption[]
	): ProfileNameEnum[] {
		// const profile = user.profiles?.map((profile) => profile.profile) || []

		if (!profile.profileId) return []
		const profileSelected = handleProfile.find(
			(e) => e.value === profile.profileId
		) || { label: '', value: '' }

		return profilePermissions(profileSelected?.label)
	}

	async function handleEffectProfile() {
		try {
			const handleProfile = await buildingOptionsProfile()
			if (!handleProfile) return

			const allowedOptions = permissionToCreateUser(handleProfile)
			const labelFriendy = allowedOptions.map((item) => friendlyRole[item])

			if (action === 'CREATE') {
				const filterOptionsProfileAllowed = handleProfile.filter((allowed) =>
					labelFriendy.includes(allowed.label)
				)

				setProfileOptions(filterOptionsProfileAllowed)

				return
			}

			const userLogged = user.profiles.find(
				(profileAccess) =>
					(profileAccess.profile as IProfile)._id === profile.profileId &&
					(profileAccess.profile as IProfile).name !== 'ADMIN'
			)

			const filterOptionsProfileAllowed = handleProfile.map(
				({ label, value }) => {
					return {
						label,
						value,
						isDisabled:
							!labelFriendy.includes(label) ||
							(user._id === userId && userLogged)
					}
				}
			)

			setProfileOptions(filterOptionsProfileAllowed)
		} catch (error) {}
	}

	function validateFields() {
		const { email, personalFiscalID, name } = dataUser

		if (!name || !email || !personalFiscalID) {
			setEnableBtnNext(false)
			return
		}

		const isEmailValid = checkEmail(email)
		const isCpfValid = checkCPFCNPJ(personalFiscalID)

		const result = isEmailValid && isCpfValid

		wasThereAnUpdateInInputs()

		setEnableBtnNext(result)
	}

	async function handleBtnNext() {
		try {
			const { email, personalFiscalID } = dataUser

			await userAlreadyExists({ email, personalFiscalID })
			setShowProfile((show) => !show)
		} catch (error) {
			setShowProfile(false)
		}
	}

	function renderButtonDelete(): JSX.Element | null {
		if (
			!profileOptions.length ||
			!profileAndAccess.length ||
			action === 'CREATE'
		)
			return null

		const isUserAllowed = permissionToCreateUser(profileOptions)
		const labelValues = isUserAllowed.map(
			(profileName) => friendlyRole[profileName]
		)

		const hasProfilesNotAllowed = profileAndAccess
			.map((option) => !labelValues.includes(option.profile.label))
			.some((e) => e)

		if (hasProfilesNotAllowed) return null

		const isUserNotAllowedToDelete = user._id === userId

		return (
			<Fragment>
				{!isUserNotAllowedToDelete && (
					<button
						className='btn-clean btn-remove'
						onClick={() => {
							setTargetUser({ id: userId || '', name: dataUser.name })
						}}
					>
						Excluir Usuário
					</button>
				)}
			</Fragment>
		)
	}

	function handleValidateForm() {
		let isValidAccessAndProfile: boolean
		if (action === 'CREATE') {
			isValidAccessAndProfile = isValidateProfileAndAccess()
		} else {
			isValidAccessAndProfile = wasThereAnUpdateInInputs()
		}

		const userLogged = user.profiles.find(
			(profileAccess) =>
				(profileAccess.profile as IProfile).name === 'ADMIN' &&
				(profileAccess.profile as IProfile)._id === profile.profileId
		)
		if (!userLogged && user._id === userId) {
			setEnableBtnSubmit(false)
			return
		}

		const hasPhysician = profileAndAccess.find(
			(x) => x.profile.label === friendlyRole.PHYSICIAN
		)

		setHasPhysician(!!hasPhysician)

		if (hasPhysician) {
			const enableButton =
				isValidAccessAndProfile && !!dataUser.crm && !!dataUser.state

			setEnableBtnSubmit(enableButton)

			if (!dataUser.crm) setCRMError(errorMessages.crmIsRequired)
			else setCRMError('')

			if (!dataUser.state) setStateError(errorMessages.crmIsRequired)
			else setStateError('')
			return
		} else {
			setCRMError('')
			setStateError('')
			setDataUser((state) => ({ ...state, crm: '', state: '' }))
		}
		setEnableBtnSubmit(isValidAccessAndProfile)
	}

	function formatRole(profiles: ProfileAndAccess[]) {
		return profiles.map((item) => {
			// if (!item.access && !item.profile.value) return []
			return {
				profile: item.profile.value,
				contracts:
					item.contracts === null ? null : mappedAccessProfile(item.contracts)
			}
		})
	}

	function mappedAccessProfile(contracts: UserProfileContract[]) {
		const compareContractToFilter: UserProfileContract[] = contracts?.filter(
			(contract) => {
				const findContract = filter.find((menu) => menu.value === contract.id)

				const unitsId = findContract?.options.map(({ value }) => value)
				const updatedUnits = contract?.units?.filter(({ id }) =>
					unitsId?.includes(String(id))
				)

				const isDifference = contract.units.length !== updatedUnits.length

				if (isDifference && updatedUnits.length === 0) return

				contract.units = updatedUnits
				return { id: findContract?.value === contract.id, units: updatedUnits }
			}
		)
		return compareContractToFilter
	}

	async function handleSubmit() {
		const profiles: UserProfile[] = formatRole(
			profileAndAccess
		) as UserProfile[]

		if (profiles.some((x) => x.contracts === null)) return

		try {
			setLoadingSubmit(true)

			if (action === 'CREATE') {
				const userCreate: IUserRequest = {
					...dataUser,
					profiles: profiles
				}

				await createUser(userCreate)

				cogoToast.success(successMessages.userCreated, cogoDefaultOptions)
				history.push(routesEnum.USERS)
			} else {
				const nameChange = dataUser.name !== currentUser.name
				const nameCrm = dataUser.crm !== currentUser.crm
				const stateCrm = dataUser.state !== currentUser.state

				if (nameChange || nameCrm || stateCrm) {
					await updateUser(
						currentUser._id,
						dataUser.name,
						user.email,
						authModal.password,
						dataUser.crm,
						dataUser.state
					)
				}

				if (thereUpdateContractsAndUnits()) {
					await updateUserProfile(
						currentUser._id,
						{ profiles },
						user.email,
						authModal.password
					)
				}

				handleClose()
				cogoToast.success(successMessages.userUpdated, cogoDefaultOptions)
				history.push(routesEnum.USERS)
			}

			setIsDelete(false)
		} catch (err) {
		} finally {
			setLoadingSubmit(false)
		}
	}

	function handleDeleteOnClose() {
		setIsDelete(false)
		setTargetUser(null)
	}

	async function handleSubmitDelete() {
		try {
			const successMessage = SUCCESS_MESSAGE['USER']
			const deleteTarget = FUNC_ENUM['USER']

			if (!userToDelete) return null
			await deleteTarget(userToDelete.id, user.email, authModal.password)

			setUserToDelete(null)
			setIsDelete(false)
			handleClose()
			history.replace(routesEnum.USERS)
			cogoToast.success(successMessage, cogoDefaultOptions)
		} catch (error) {}
	}

	function handleAuthSubmitDelete() {
		setTargetUser(null)
		setUserToDelete({ id: userId || '', name: dataUser.name })
		setIsDelete(true)
		handleOpenAuthModal()
	}

	const handleSubmitFunction =
		action === 'CREATE' ? handleSubmit : handleOpenAuthModal

	function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
		const password = event.target.value || ''

		setAuthModal((state) => ({ ...state, password }))
	}

	function handleClose() {
		setIsDelete(false)
		setAuthModal(INITIAL_AUTHENTICATION_MODAL_PROPS)
	}

	function handleOpenAuthModal() {
		setAuthModal((state) => ({ ...state, value: true }))
	}

	const isEnableDelete = isDelete && !!userToDelete

	const authModalConfig: IAuthenticationModalProps = {
		...authModal,
		handleSubmit: isEnableDelete ? handleSubmitDelete : handleSubmit,
		handleClose,
		handlePasswordChange,
		loadingSubmit
	}

	useEffect(() => {
		async function initializeOptions() {
			await handleEffectProfile()
		}
		initializeOptions()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(initializeFields, [profileOptions])
	useEffect(validateFields, [dataUser])
	useEffect(handleValidateForm, [
		profileAndAccess,
		dataUser.name,
		dataUser.crm,
		dataUser.state
	])

	const viewProps: IViewProps = {
		profileOptions: listAvaliableProfiles(),
		handleChange,
		dataUser,
		onChangeProfileAndAccess,
		addProfileAndAccess,
		removeProfile,
		profileAndAccess,
		showProfile,
		handleBtnNext,
		enableBtnNext,
		handleSubmit,
		action,
		renderButtonDelete,
		targetUser,
		handleDeleteOnClose,
		enableBtnSubmit,
		loading,
		loadingSubmit,
		CRMError,
		stateError,
		handleStateChange,
		hasPhysician,
		authModalConfig,
		handleOpenAuthModal,
		handleSubmitFunction,
		handleSubmitDelete,
		isDelete,
		handleAuthSubmitDelete
	}

	return createElement(CreateUser, viewProps)
}

export default CreateUserContainer
