import React, { useEffect, useState } from 'react'
import { GoogleMap } from '@react-google-maps/api'
import orangePin from '../../../assets/images/orange-pin.svg'
import grayPin from '../../../assets/images/gray-pin.svg'
import greenPin from '../../../assets/images/green-pin.svg'
import { IProps } from './types'
import { sortByNumber } from 'shared/util/onlyUnique'

const containerStyle = {
	width: '100%',
	height: '100%'
}

function renderMarker(value: string) {
	if (value === 'sem kits') return grayPin
	if (value === 'com kits, sem exames') return orangePin
	if (value === 'com kits, com exames') return greenPin
	else return ''
}

export function GoogleMaps({ markers, valueCenter, zoom }: IProps) {
	const [renderMap, setRenderMap] = useState(false)

	function onLoad(map: google.maps.Map) {
		if (!map) return

		let lat_min = valueCenter.lat
		let lat_max = valueCenter.lat
		let lng_min = valueCenter.lng
		let lng_max = valueCenter.lng

		const latMin = markers
			.map(({ position }) => position.lat)
			.sort((a, b) => sortByNumber(a, b))

		const lngMin = markers
			.map(({ position }) => position.lng)
			.sort((a, b) => sortByNumber(a, b))

		lat_min = latMin[0] || valueCenter.lat
		lat_max = latMin[markers.length - 1] || valueCenter.lat

		lng_min = lngMin[0] || valueCenter.lng
		lng_max = lngMin[markers.length - 1] || valueCenter.lng

		map.setCenter(
			new google.maps.LatLng(
				(lat_max + lat_min) / 2.0,
				(lng_max + lng_min) / 2.0
			)
		)

		if (!markers.length) {
			return
		}
		map.fitBounds(
			new google.maps.LatLngBounds(
				//bottom left
				new google.maps.LatLng(lat_min, lng_min),
				//top right
				new google.maps.LatLng(lat_max, lng_max)
			)
		)

		for (let i = 0; i < markers.length; i++) {
			const marker = new google.maps.Marker({
				position: new google.maps.LatLng(
					markers[i].position.lat,
					markers[i].position.lng
				),
				icon: renderMarker(markers[i].value),
				map: map
			})

			const infowindow = new google.maps.InfoWindow()
			google.maps.event.addListener(
				marker,
				'mouseover',
				(function (marker, i) {
					return function () {
						const popupString = `
							<p> ${markers[i].title}</p>
						`
						infowindow.setContent(popupString)
						infowindow.open(map, marker)
					}
				})(marker, i)
			)

			google.maps.event.addListener(marker, 'mouseout', function () {
				infowindow.close()
			})
		}
	}

	function refreshMap() {
		setRenderMap(false)
		setTimeout(() => {
			setRenderMap(true)
		}, 0)
	}

	useEffect(refreshMap, [markers])

	if (!renderMap) return null
	return (
		<GoogleMap
			mapContainerStyle={containerStyle}
			zoom={zoom}
			onLoad={onLoad}
		></GoogleMap>
	)
}
