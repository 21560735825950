import { UtilGenerators } from '@buildbox/utils'
import React from 'react'
import { IContract } from 'shared/interfaces/contract'
import { IUnit } from 'shared/interfaces/unit'

import { IUserProfilePaginate } from 'shared/interfaces/user'
import { friendlyRole } from 'shared/util/friendlyRole'
import { ScreenActionsEnum } from './types'

const { generateRandomSerial } = UtilGenerators

export function renderRole(profile: IUserProfilePaginate[]): JSX.Element {
	return (
		<div className='role'>
			{profile.map(({ profile }, index) => (
				<p
					key={`${generateRandomSerial(5)} - ${index}`}
					className='role-container'
				>
					<span>{friendlyRole[profile.name]}</span>
				</p>
			))}
		</div>
	)
}

export function renderContracts(profile: IUserProfilePaginate[]): JSX.Element {
	const onlyNames = profile
		.map(({ contracts }) =>
			contracts.map((contract) => (contract?.id as IContract).name)
		)
		.flat()

	return renderUnitsAndContracts(onlyNames.flat(), true)
}

export function renderUnitsAndContracts(array: string[], isContract = false) {
	const nameOfContractsOrUnits = [...new Set(array)]
		.flat()
		.filter((arrayNames) => arrayNames !== undefined)
	if (!nameOfContractsOrUnits.length) return <></>

	const buildArray = [nameOfContractsOrUnits[0], nameOfContractsOrUnits[1]]
	return (
		<div className='unit'>
			{buildArray.map((unitsArray, index) => (
				<p key={`${generateRandomSerial(5)} - ${index}`}>
					{unitsArray}
					{nameOfContractsOrUnits.length > 1 &&
						nameOfContractsOrUnits.length >= index &&
						','}
				</p>
			))}
			{array.length > 2 && (
				<p>
					{isContract ? 'Outros' : 'Outras'} ({array.length - buildArray.length}
					)
				</p>
			)}
		</div>
	)
}

export function renderUnits(profile: IUserProfilePaginate[]): JSX.Element {
	const onlyUnitsNames = profile
		.map(({ contracts }) =>
			contracts
				.map(({ units }) => units.map((unit) => (unit?.id as IUnit).name))
				.flat()
		)
		.flat()

	if (!onlyUnitsNames.length) return <></>

	return renderUnitsAndContracts(onlyUnitsNames.flat())
}

export function renderEditIcon(
	name: string,
	id: string,
	onClick: (action: ScreenActionsEnum, name: string, id: string) => void
): JSX.Element {
	return (
		<button className='icon' onClick={() => onClick('EDIT', name, id)}>
			<img
				className='img'
				src={require('../../assets/images/dots.svg')}
				alt='Editar'
			/>
		</button>
	)
}
