import { createElement } from 'react'
import { deepCopy } from 'shared/util/object'

import { IMenu, IProps, IViewProps } from './types'
import MenuPrimaryView from './view'

export default function MenuPrimary({
	menuValues,
	getMenuUpdate,
	showChevron,
	styles,
	renderSwitch
}: IProps): JSX.Element {
	// const [menuData, setMenuData] = useState<IMenu>(menuValues)

	function handleOptionUpdate(
		optionValue: string | number,
		selectedUpdated: boolean
	) {
		const optionIndex = menuValues.options.findIndex(
			(option) => option.value === optionValue
		)
		if (optionIndex !== -1) {
			const updatedMenuData = deepCopy(menuValues)
			updatedMenuData.options[optionIndex].selected = selectedUpdated

			if (updatedMenuData.options.every((option) => option.selected))
				updatedMenuData.selected = true

			if (updatedMenuData.options.some((option) => !option.selected))
				updatedMenuData.selected = false

			// setMenuData(updatedMenuData)
			getMenuUpdate(updatedMenuData)
		}
	}

	function toggleSelected() {
		setAll(!menuValues.selected)
	}

	function setAll(selected: boolean) {
		const updatedMenuData = deepCopy(menuValues)
		updatedMenuData.selected = selected
		updatedMenuData.options.forEach((option) => {
			option.selected = selected
		})
		// setMenuData(updatedMenuData)
		getMenuUpdate(updatedMenuData)
	}

	function toggleExpanded() {
		const updatedMenuData: IMenu = deepCopy(menuValues)
		updatedMenuData.expanded = !menuValues.expanded
		// setMenuData(updatedMenuData)
		getMenuUpdate(updatedMenuData)
	}

	function toggleGeneralPermission() {
		const updatedMenuData: IMenu = deepCopy(menuValues)
		updatedMenuData.generalPermission = !menuValues.generalPermission

		if (updatedMenuData.generalPermission) {
			updatedMenuData.options.forEach((option) => (option.selected = true))
			getMenuUpdate(updatedMenuData)

			return
		}

		getMenuUpdate(updatedMenuData)
	}

	// useEffect(() => setMenuData(menuValues), [menuValues])
	//	useEffect(() => setAll(menuValues.selected), [menuValues.selected])

	const viewProps: IViewProps = {
		menuData: menuValues,
		toggleSelected,
		toggleExpanded,
		toggleGeneralPermission,
		handleOptionUpdate,
		styles,
		showChevron,
		renderSwitch
	}

	return createElement(MenuPrimaryView, viewProps)
}
