import { fontType, toRem } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'
import styled from 'styled-components'

export const ModalContainer = styled.main`
	border-top: 2px solid ${colors.backgroundResultDiagnosis};
`
export const HeaderContainer = styled.header`
	display: flex;
	align-items: center;
	background: ${colors.backgroundCheckBox};
	border-left: 2px solid ${colors.secondary};
	padding: ${toRem(20)} ${toRem(14)};
	margin-top: ${toRem(10)};

	strong {
		${fontType(2)}
		color: ${colors.kitBoxBackground};
		font-size: ${toRem(10)};
		text-transform: uppercase;
	}

	p {
		${fontType(0)};
		font-size: ${toRem(14)};
		color: ${colors.darkGrey};
	}

	div:nth-child(1) {
		width: 70%;
	}

	div:nth-child(2) {
		width: 15%;
	}
	div:nth-child(3) {
		width: 15%;
		text-align: end;
	}
`

export const KitInfoContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: ${toRem(2)};
	margin-top: ${toRem(20)};

	background: ${colors.backgroundResultDiagnosis};
	padding: ${toRem(20)} ${toRem(14)};

	strong {
		${fontType(2)};
		color: #b5b3b8;
		font-size: ${toRem(10)};
		text-transform: uppercase;
	}

	p {
		font-size: ${toRem(14)};
		${fontType(0)};
		color: ${colors.darkGrey};
	}

	div:nth-child(1) {
		width: 70%;
	}

	div:nth-child(2) {
		width: 15%;
	}
	div:nth-child(3) {
		width: 15%;
		text-align: end;
	}
`

export const KitLogContainer = styled.section`
	display: flex;
	align-items: center;
	justify-content: start;
	padding: ${toRem(8)} ${toRem(14)};

	p {
		font-size: ${toRem(12)};
		${fontType(0)};
		color: ${colors.darkGrey};
	}

	.period {
		width: 50%;
	}

	div {
		width: 20%;
	}

	strong {
		${fontType(2)};
		color: #b5b3b8;
		font-size: ${toRem(10)};
		text-transform: uppercase;
	}
`
export const IntervalsContainer = styled.div`
	padding: ${toRem(8)} 0;
	background: ${colors.backgroundResultDiagnosis};
`
