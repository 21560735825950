import { IProfile } from 'shared/interfaces/profile'

import axiosInstance from './axiosInstance'

export async function getPermissions(ids: string[]): Promise<IProfile[]> {
	const response = await axiosInstance.get(`/profiles/id`, {
		params: {
			ids: ids
		}
	})

	return response.data
}

export async function getProfiles(): Promise<IProfile[]> {
	const response = await axiosInstance.get(`/profiles/`)

	return response.data
}
