import { IAuthenticationModalProps } from 'shared/components/AuthModal/types'
import { IExam, IPhysicianDiagnoses } from 'shared/interfaces/exam'

export interface IViewProps {
	examDetail: IExam
	questionsDatasheet: IQuestionsDatasheet[]
	imageInfoCircle: string
	isLoading: boolean
	image: string
	hiddenBtnExport: boolean
	generatePdf: () => void
	btnScrollToTop: boolean
	scrollToTop: () => void
	btnExportClicked: boolean
	openModal: () => void
	showModal: boolean
	onClose: () => void
	reportDiagnosis: (report: IPhysicianDiagnoses) => void
	report: IPhysicianDiagnoses | undefined
	openAuthModal: () => void
	authModalConfig: IAuthenticationModalProps
}
export interface IQuestionsDatasheet {
	value: string
	key: string
}

export const screenMessageExam = {
	examDetail: 'Detalhes do Exame',
	reportTitle: 'Resumo da Captura de Imagem Térmica',
	infoPatient: 'Dados do paciente',
	titleImageExam: 'Exame capturado',
	infoMedicalReport: 'Conduta médica',
	questionMedicalReport: 'Qual a conduta médica dessa consulta?',
	questionResultDiagnosis:
		'De acordo com a sua avaliação da paciente, qual é a conduta adotada?',
	datasheetTitle: 'Ficha Clínica',
	titleResultExam: 'Resultado do Comparador'
}

export enum ActionElement {
	ENABLE,
	DISABLE
}
