import React from 'react'
import { Button, FloatLabelInput, Loading } from '@buildbox/components'

import {
	primaryInputStyle,
	primaryButtonStyle,
	colors
} from 'shared/styles/theme'

import { IViewProps } from './types'
import { LoginWrapper } from './styles'
import InputPassword from 'shared/components/InputPassword'

function Login(props: IViewProps): JSX.Element {
	const {
		handleLogin,
		handleChange,
		handleRequestChangePassword,
		userLogin,
		isFormValid,
		isLoading
	} = props

	return (
		<LoginWrapper>
			<img
				className='logo-wrapper'
				src={require('assets/images/logo-linda-light.svg')}
				alt='Logo Linda'
			/>

			<section>
				<div className='content-wrapper'>
					<form
						className='formContainer'
						onSubmit={(e: React.SyntheticEvent) => {
							e.preventDefault()
							handleLogin()
						}}
					>
						<FloatLabelInput
							className='input'
							label='E-mail'
							id='email'
							value={userLogin.email}
							onChange={handleChange}
							{...primaryInputStyle}
						/>
						<InputPassword
							className='input'
							label='Senha'
							id='password'
							value={userLogin.password}
							onChange={handleChange}
							{...primaryInputStyle}
							style={{ marginBottom: 32 }}
						/>

						{isLoading ? (
							<Loading type='Sentry' primaryColor={colors.secondary} />
						) : (
							<Button
								className='button'
								disabled={!isFormValid}
								{...primaryButtonStyle}
							>
								{'Entrar'}
							</Button>
						)}
					</form>
				</div>
				<p className='forgot-password' onClick={handleRequestChangePassword}>
					Esqueci minha senha
				</p>
			</section>
		</LoginWrapper>
	)
}

export default Login
