import React from 'react'
import { IViewProps } from './types'
import { ForgotPasswordWrapper } from './styles'

import { FloatLabelInput } from '@buildbox/components'
import { primaryInputStyle } from 'shared/styles/theme'

import { Link } from 'react-router-dom'

import routesEnum from 'modules/Routes/enum'
import WrapperChangePassword from 'shared/components/WrapperChangePassword'
import { TypeWrapper } from 'shared/components/WrapperChangePassword/types'

function ForgotPassword(props: IViewProps): JSX.Element {
	const { handleRecoverPassword, handleEmail, email, isFormValid, isLoading } =
		props

	return (
		<ForgotPasswordWrapper>
			<WrapperChangePassword
				type={TypeWrapper.ForgotPassword}
				onSubmit={handleRecoverPassword}
				isFormValid={isFormValid}
				isLoading={isLoading}
				titleButton='Enviar'
				height={333}
			>
				<>
					<Link to={routesEnum.LOGIN} className='forgot-password-header'>
						<img
							src={require('assets/images/arrow-left.svg')}
							alt='Arrow Left'
						/>
						<p className='forgot-password-title'>Voltar</p>
					</Link>

					<p className='forgot-password-subtitle'>
						Digite seu e-mail abaixo e enviaremos um link para redefinir sua
						senha:
					</p>

					<FloatLabelInput
						className='input'
						label='E-mail'
						id='email'
						value={email}
						onChange={handleEmail}
						{...primaryInputStyle}
					/>
				</>
			</WrapperChangePassword>
		</ForgotPasswordWrapper>
	)
}

export default ForgotPassword
