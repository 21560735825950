import {
	ITableProps,
	ITableColumn,
	ITableInstance,
	ITableOptions,
	ITableState
} from './types'
import { TableWrapper, LoadingContent } from './styles'
import { usePagination, useSortBy, useTable } from 'react-table'
import React, { useEffect } from 'react'
import { colors } from '../../styles/theme'

import { paddingToString } from 'shared/util/Lib.inside.util'
import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import { Loading } from '@buildbox/components'

function Table<T extends object>(props: ITableProps<T>) {
	const {
		data = [],
		columns,
		navProps = undefined,
		primaryColor = colors.darkGrey,
		backgroundColor = colors.lightGrey,
		round = false,
		secondaryColor = colors.darkGrey,
		fontSize = 16,
		fontFamily = 'sans-serif',
		padding = [20, 16, 20, 16],
		pageSize = 10,
		separate = false,
		className,
		getSelectedColumnAcessorAndOrder,
		sortBy,
		noItensText,
		isLoading,
		total = false,
		showPaginate = true,
		totalDocsNumber,
		dataSize,
		onClickRow
	} = props

	const paddingString = paddingToString(padding)

	const mergedStyles = {
		primaryColor,
		backgroundColor,
		round,
		secondaryColor,
		fontSize,
		padding: paddingString,
		fontFamily
	} as any

	const tableOptions: ITableOptions<T> = {
		columns,
		data,
		manualSortBy: !!getSelectedColumnAcessorAndOrder
	}

	const tableInstance: ITableInstance<T> = useTable<T>(
		tableOptions,
		useSortBy,
		usePagination
	) as ITableInstance<T>

	let {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		page,
		state,
		gotoPage,
		previousPage,
		nextPage,
		canPreviousPage,
		canNextPage,
		pageCount,
		setPageSize
	} = tableInstance

	let { pageIndex } = state as ITableState<T>
	let totalDocs = rows.length

	if (navProps) {
		pageCount = navProps.pageCount
		pageIndex = navProps.pageIndex
		totalDocs = navProps.totalDocs
		canNextPage = !!navProps ? pageIndex + 1 < pageCount : canNextPage
		canPreviousPage = !!navProps ? pageIndex > 0 : canPreviousPage
	}

	const callPreviousPage = !!navProps ? navProps.previousPage : previousPage
	const callNextPage = !!navProps ? navProps.nextPage : nextPage
	const callGoToPage = !!navProps ? navProps.gotoPage : gotoPage

	const filterPages = (visiblePages: any[], totalPages: number) => {
		return visiblePages.filter((page: number) => page <= totalPages)
	}

	const getVisiblePages = (page: number, total: number) => {
		if (total < 7) {
			return filterPages([1, 2, 3, 4, 5, 6], total)
		} else {
			if (page % 5 >= 0 && page > 4 && page + 2 < total) {
				return [1, page - 1, page, page + 1, total]
			} else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
				return [1, total - 3, total - 2, total - 1, total]
			} else {
				return [1, 2, 3, 4, 5, total]
			}
		}
	}

	const formatPagination = (x: number, idx: number, arr: number[]) => {
		const { length } = arr

		const total = arr[length - 1]

		if (total <= 6) {
			return x
		} else if (length > 5) {
			return idx === 5 ? '...' + x : x
		} else {
			if (idx === 0) return x + '...'
			if (idx === 4 && arr[2] < total - 2) return '...' + x
			return x
		}
	}

	function getOrder(isSortedDesc: boolean | undefined) {
		return isSortedDesc === undefined ? -1 : isSortedDesc ? 1 : 0
	}

	function getNextOrder(isSortedDesc: boolean | undefined) {
		return isSortedDesc === undefined ? true : isSortedDesc ? false : undefined
	}

	function getOrderAndAcessor(id: string, isSortedDesc: any) {
		const value =
			getSelectedColumnAcessorAndOrder &&
			getSelectedColumnAcessorAndOrder(id, isSortedDesc)

		return value
	}

	function handleOnClickRow(id: string) {
		onClickRow && onClickRow(id)
	}

	useEffect(() => {
		if (!pageSize) return

		setPageSize(pageSize)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageSize])

	return (
		<>
			{isLoading && (
				<LoadingContent>
					<Loading type='Sentry' primaryColor={colors.secondary} />
				</LoadingContent>
			)}
			{!data.length && !isLoading && (
				<DefaultPagePlaceholder text={noItensText} />
			)}
			{data.length ? (
				<TableWrapper className={className} styles={mergedStyles}>
					<table
						{...getTableProps()}
						className={`table ${separate ? 'separate' : ''} ${
							isLoading ? 'loading' : ''
						}`}
					>
						<thead>
							{/* TODO: FINISH TYPES */}
							{headerGroups.map((headerGroup: any, i) => (
								<tr key={i} {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column: ITableColumn<T>) => {
										const getColumnHeaderProps = {
											onClick: () => {
												if (isLoading || !column.canSort) return
												getSelectedColumnAcessorAndOrder &&
													getOrderAndAcessor(
														column.id,
														getOrder(column.isSortedDesc)
													)

												const isDecending = getNextOrder(column.isSortedDesc)

												isDecending === undefined && column.clearSortBy()

												column.toggleSortBy &&
													column.toggleSortBy(isDecending, false)
												isDecending === undefined && column.clearSortBy()
											}
										}

										return (
											<th
												{...column.getHeaderProps(
													column.getSortByToggleProps()
												)}
												{...(getSelectedColumnAcessorAndOrder
													? getColumnHeaderProps
													: undefined)}
											>
												{column.render('Header')}
												<span>
													{!getSelectedColumnAcessorAndOrder && column.isSorted
														? column.isSortedDesc
															? '  ⇓'
															: '  ⇑'
														: ''}
													{sortBy &&
													column.id === sortBy?.acessor &&
													sortBy?.order !== 0
														? sortBy?.order === -1
															? '  ⇓'
															: '  ⇑'
														: ''}
												</span>
											</th>
										)
									})}
								</tr>
							))}
						</thead>

						<tbody {...getTableBodyProps()}>
							{page.map((row, i) => {
								prepareRow(row)
								return (
									<tr
										{...row.getRowProps()}
										style={{
											backgroundColor:
												total && pageIndex === 0 && i === 0
													? colors.backgroundSelect
													: ''
										}}
									>
										{row.cells.map((cell) => {
											return (
												<td
													{...cell.getCellProps()}
													onClick={() => {
														if (isLoading) return
														handleOnClickRow((cell.row.original as any).examId)
													}}
													style={
														!!onClickRow
															? { cursor: 'pointer' }
															: { cursor: 'initial' }
													}
												>
													{cell.render('Cell')}
												</td>
											)
										})}
									</tr>
								)
							})}
						</tbody>
					</table>
					<div className='table-footer'>
						<span className='result-span'>
							Resultados, {dataSize ? dataSize : page.length} de{' '}
							{totalDocsNumber ? totalDocsNumber : totalDocs}
						</span>

						{showPaginate ? (
							<div className='pagination'>
								<button
									className='button'
									onClick={() => callPreviousPage(pageIndex - 1)}
									disabled={!canPreviousPage}
								>
									{'Anterior'}
								</button>

								<div className='page-numbers'>
									{getVisiblePages(pageIndex + 1, pageCount).map(
										(x, index, arr) => {
											return (
												<span
													key={index}
													className={`page-number ${
														x - 1 === pageIndex ? 'active' : ''
													}`}
													onClick={() => {
														callGoToPage(x - 1)
													}}
												>
													{formatPagination(x, index, arr)}
												</span>
											)
										}
									)}
								</div>
								<button
									className='button'
									onClick={() => callNextPage(pageIndex + 1)}
									disabled={!canNextPage}
								>
									{'Próxima'}
								</button>
							</div>
						) : null}
					</div>
				</TableWrapper>
			) : null}
		</>
	)
}

export default Table
