import { useQuery, UseQueryResult } from 'react-query'
import {
	CustomerType,
	CustomerTypeEnum,
	EntityTypeEnum,
	IContract,
	IContractResponsePaginate
} from 'shared/interfaces/contract'
import { PaginatedRequest, PaginatedResult } from 'shared/interfaces/pagination'
import { QUERY_KEYS } from 'shared/util/reactQuery'

import axiosInstance from './axiosInstance'

export async function findContract(_id: string): Promise<IContract> {
	const response = await axiosInstance.get(`/contracts/${_id}`)

	return response.data
}

interface ICreateContract {
	entityType: EntityTypeEnum
	name: string
	companyFiscalID: string
	contractedKits: number
	subscriptionFee: number
	examFee: number
	activatedAt: Date
	contractMonthLength: number
	files?: string[]
	units?: string[]
	observation?: string
	customerType: CustomerTypeEnum
}

export async function createContract(
	contract: ICreateContract
): Promise<string> {
	const response = await axiosInstance.post(`/contracts/`, contract)

	return response.data
}

export async function updateContract(
	id: string,
	contract: Omit<ICreateContract, 'entityType'>,
	auth: {
		email: string
		password: string
	}
): Promise<string> {
	const response = await axiosInstance.patch(`/contracts/${id}`, contract, {
		headers: {
			EAuth: auth.email,
			PAuth: auth.password
		}
	})

	return response.data
}

export async function deleteContract(
	_id: string,
	email: string,
	password: string
): Promise<string> {
	const response = await axiosInstance.delete(`/contracts/${_id}`, {
		headers: {
			EAuth: email,
			PAuth: password
		}
	})

	return response.data
}

export type IFetchContractsRequest = PaginatedRequest<{}>
export type IFetchContractsResponse = PaginatedResult<IContractResponsePaginate>

export async function fetchContractsPage({
	page,
	limit,
	search,
	sort,
	order,
	filters
}: IFetchContractsRequest): Promise<IFetchContractsResponse> {
	// The server call this pageIndex but actually it's the page number
	const response = await axiosInstance.post('/contracts/get-page', {
		pageIndex: page,
		maxItemsInPage: limit,
		searchString: search,
		sort,
		order,
		filters,
		customerType: CustomerType.ALL
	})

	return response.data
}

export function useContractsPage({
	page,
	limit,
	search,
	sort,
	order,
	filters
}: IFetchContractsRequest): UseQueryResult<IFetchContractsResponse> {
	const enabled = !!(page && limit && filters)

	return useQuery(
		[QUERY_KEYS.CONTRACTS_PAGE, { page, limit, search, sort, order, filters }],
		async ({ queryKey }) => {
			const [_key, params] = queryKey
			const { page, limit, search, sort, order, filters } =
				params as IFetchContractsRequest

			return fetchContractsPage({
				page,
				limit,
				search,
				sort,
				order,
				filters
			})
		},
		{
			enabled
		}
	)
}
