import React, { useState, useEffect } from 'react'
import { FloatLabelInput } from '@buildbox/components'
import { UtilMask, UtilValidators } from '@buildbox/utils'

import { Column, Offset, Row } from 'shared/styles'
import { EntityTypeEnum } from 'shared/interfaces/contract'
import { primaryInputStyle } from 'shared/styles/theme'
import { IProps } from './types'

import SelectState from './SelectState'
import EntityTypeAndContract from './EntityTypeAndContract'

function FormUnit(props: IProps): JSX.Element {
	const {
		action,
		dataUnit,
		contractsOptions,
		contractSelected,
		setContractSelected,
		handleChange,
		handleCompanyType,
		handleStateChange,
		handleCopyFromContract
	} = props

	const [checkboxState, setCheckBoxState] = useState({
		name: dataUnit.entityType,
		checked: dataUnit.entityType ? true : false
	})

	function handleCheckbox(id: EntityTypeEnum) {
		if (action === 'EDIT') return
		setCheckBoxState({
			name: id,
			checked: true
		})

		handleCompanyType(id)
		return
	}

	const [copyFromContract, setCopyFromContract] = useState(false)

	function changeCopyFromContract() {
		setCopyFromContract(!copyFromContract)
		handleCopyFromContract(!copyFromContract)
	}

	useEffect(() => {
		setCheckBoxState({
			name: dataUnit.entityType,
			checked: true
		})
	}, [dataUnit.entityType])

	useEffect(() => {
		setCopyFromContract(false)
	}, [contractSelected])

	return (
		<>
			<h2 className='subtitle'>Dados da Unidade</h2>
			<EntityTypeAndContract
				action={action}
				entityType={dataUnit.entityType}
				checkboxState={checkboxState}
				handleCheckbox={handleCheckbox}
				contractsOptions={contractsOptions}
				contractSelected={contractSelected}
				setContractSelected={setContractSelected}
				changeCopyFromContract={changeCopyFromContract}
				copyFromContract={copyFromContract}
			/>

			<Row>
				<Column md='12' lg='4'>
					<FloatLabelInput
						id='name'
						label='Nome'
						onChange={handleChange}
						value={dataUnit.name}
						{...primaryInputStyle}
					/>
				</Column>
				<Column md='12' lg='2'>
					<FloatLabelInput
						id='CNES'
						label='CNES'
						onChange={handleChange}
						maxLength={8}
						value={dataUnit.CNES}
						{...primaryInputStyle}
					/>
				</Column>
				<Column md='12' lg='3'>
					<FloatLabelInput
						id='companyFiscalID'
						label='CNPJ'
						className={action === 'EDIT' ? 'input-disabled' : ''}
						onChange={handleChange}
						disabled={action === 'EDIT' ? true : false}
						maxLength={18}
						value={UtilMask.formatCNPJ(dataUnit.companyFiscalID)}
						hasError={!UtilValidators.checkCNPJ(dataUnit.companyFiscalID)}
						errorMessage={'CNPJ inválido'}
						{...primaryInputStyle}
					/>
				</Column>
				<Column md='12' lg='3'>
					<FloatLabelInput
						id='telephone'
						label='Telefone'
						onChange={handleChange}
						maxLength={15}
						value={UtilMask.formatPhone(dataUnit.telephone)}
						{...primaryInputStyle}
					/>
				</Column>

				<Column md='12' lg='4'>
					<FloatLabelInput
						id='companyName'
						label='Nome Empresarial'
						onChange={handleChange}
						value={dataUnit.companyName}
						{...primaryInputStyle}
					/>
				</Column>

				<Offset lg='8' />
			</Row>
			<Row>
				<Column md='12' lg='2'>
					<FloatLabelInput
						id='postalCode'
						label='CEP'
						onChange={handleChange}
						maxLength={9}
						value={UtilMask.formatPostalCode(dataUnit.postalCode)}
						{...primaryInputStyle}
					/>
				</Column>
				<Column md='12' lg='5'>
					<FloatLabelInput
						id='streetName'
						label='Rua'
						onChange={handleChange}
						value={dataUnit.streetName}
						{...primaryInputStyle}
					/>
				</Column>
				<Column md='12' lg='2'>
					<FloatLabelInput
						id='addressNumber'
						label='Número'
						onChange={handleChange}
						value={dataUnit.addressNumber}
						{...primaryInputStyle}
					/>
				</Column>

				<Offset lg='3' />

				<Column md='12' lg='3'>
					<FloatLabelInput
						id='addressComplement'
						label='Complemento'
						onChange={handleChange}
						value={dataUnit.addressComplement}
						{...primaryInputStyle}
					/>
				</Column>
				<Column md='12' lg='3'>
					<FloatLabelInput
						id='neighborhood'
						label='Bairro'
						onChange={handleChange}
						value={dataUnit.neighborhood}
						{...primaryInputStyle}
					/>
				</Column>
				<Column md='12' lg='4'>
					<FloatLabelInput
						id='city'
						label='Cidade'
						onChange={handleChange}
						value={dataUnit.city}
						{...primaryInputStyle}
					/>
				</Column>
				<Column md='12' lg='2'>
					<SelectState
						stateSelected={dataUnit.state}
						handleStateChange={handleStateChange}
					/>
				</Column>
			</Row>
			<div className='comments-div'>
				<h2 className='subtitle'>Observações</h2>

				<textarea
					name='observation'
					id='observation'
					placeholder='Campo aberto para escritas sobre contato, informações e outras observações'
					onChange={handleChange}
					value={dataUnit.observation}
				/>
			</div>
		</>
	)
}

export default FormUnit
