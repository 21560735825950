import React from 'react'

import { IInputSearchProps } from './types'
import { InputSearchWrapper } from './styles'
import { FloatLabelInput } from '@buildbox/components'
import { primaryInputStyle } from 'shared/styles/theme'

import searchIcon from '../../../assets/images/search.svg'

function InputSearchPage({
	id,
	label,
	value,
	onChange,
	...props
}: IInputSearchProps) {
	return (
		<InputSearchWrapper>
			<FloatLabelInput
				id={id}
				label={label}
				value={value}
				onChange={onChange}
				type='text'
				padding={[26, 40, 10, 16]}
				{...props}
				{...primaryInputStyle}
			/>

			<div className='search-icon'>
				<img src={searchIcon} alt='Search exams' />
			</div>
		</InputSearchWrapper>
	)
}

export default InputSearchPage
