import { mediaQuery, toRem, transition } from 'shared/styles/functions'
import styled from 'styled-components'

export const FinanceWrapper = styled.div`
	position: relative;

	.background {
		background: transparent;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		position: fixed;
		z-index: 1;
	}

	.input-wrapper {
		width: 100%;
		max-width: 450px;
		display: flex;
	}

	.inputs {
		display: flex;

		.select-client-type {
			margin-right: 20px;
			height: 54px;
			display: flex;
			width: 150px;

			> div {
				width: 100%;
			}
		}
	}

	.select-calendar {
		z-index: 2;
		height: ${toRem(80)};
		margin-left: 20px;
		input {
			cursor: pointer;
		}
	}

	.btn-clean {
		cursor: pointer;
		background: transparent;
		border: none;
	}

	.expanded {
		transform: rotate(181deg);
		${transition()};
	}

	.btn-expanded {
		position: absolute;
		right: -10px;
		bottom: 20px;
	}

	.btn-upload {
		position: absolute;
		right: ${toRem(-40)};
	}
`

export const FinanceHeader = styled.header`
	display: flex;
	justify-content: space-between;
	margin-bottom: ${toRem(40)};

	${mediaQuery('xs')(`
		flex-direction: column;
		.inputs { margin-top: 20px; } 
	`)}
`

export const LoadingContainer = styled.div`
	display: flex;
	justify-content: center;
`
