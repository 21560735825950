import { fontType, toRem } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'
import styled from 'styled-components'

export const ChangePasswordWrapper = styled.div`
	.message-container {
		display: flex;
		margin-bottom: ${toRem(32)};
	}
	.message-info {
		display: flex;
		align-items: center;

		.circle {
			border-radius: 5px;
			width: 8px;
			height: 8px;
			margin-right: 3px;
		}

		p {
			text-transform: uppercase;
			font-size: ${toRem(10)};
			${fontType(0)}
		}

		+ .message-info {
			margin-left: ${toRem(8)};
		}
	}

	.invalid {
		background-color: #EFEDF0;
		padding: 8px;
		border-radius: 15px;
		.circle {
			background-color: ${colors.backgroundBoxFinance};
		}
		color: ${colors.placeholder};
	}

	.valid {
		background-color: #E3F7D1;
		padding: 8px;
		border-radius: 15px;
		.circle {
			background-color: #7be61c;
		}
		color: ${colors.darkGrey};
	}
`

export const CheckboxForm = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 23px;

	p {
		margin-left: 7px;
		text-align: justify;
		${fontType(0)}
		font-size: ${toRem(10)};
		color: ${colors.placeholder};
	}
`
