import { colors } from 'shared/styles/theme'
import { mediaQuery } from 'shared/styles/functions'
import styled from 'styled-components'

export const HeaderWrapper = styled.div`
	position: fixed;
	top: 0;
	z-index: 20;
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 80px;
	box-shadow: 0px 2px 3px #00000014;
	border-bottom: 1px solid ${colors.lightGrey};
	background: ${colors.white};
	font-family: 'Regular';
	padding: 10px;

	.menu-container {
		display: flex;
		justify-content: center;
		text-align: center;
		border-right: 2px solid #eee;
	}

	.bar-container {
		flex: 1;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.filter-container {
		height: 80px;
		display: flex;
		align-items: center;

		.box-select {
			height: 100%;
			border-right: 2px solid #eee;
			display: flex;
			align-items: center;
		}

		.select {
			margin: 0 9px;
			width: 150px;

			> div {
				border: none;
			}
		}
	}

	${mediaQuery('xs')(`
		.logo{
			display: none;
		}
	`)}
`
