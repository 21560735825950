import {
	IChangePasswordResponse,
	IValidateResetTokenResponse
} from 'shared/interfaces/auth'
import { ILoginResponse, RequestChangePayload } from 'shared/interfaces/auth'
import { UserStatusEnum } from 'shared/interfaces/user'
import axiosInstance from './axiosInstance'

export async function login(
	email: string,
	password: string
): Promise<ILoginResponse> {
	const response = await axiosInstance.post('/auth/panel/login', {
		email,
		password
	})

	return response.data
}

export async function requestChangePassword(
	payload: RequestChangePayload
): Promise<ILoginResponse> {
	const response = await axiosInstance.post(
		'/users/request-change-password',
		payload
	)

	return response.data
}

export async function validateResetToken(
	resetToken: string
): Promise<IValidateResetTokenResponse> {
	const response = await axiosInstance.get(
		`auth/validate/reset-token/${resetToken}`
	)

	return response.data
}

export async function changePassword(
	id: string,
	status: UserStatusEnum,
	resetToken: string,
	password: string
): Promise<IChangePasswordResponse> {
	const userStatus = status === 'PENDING' ? 'activate' : 'password'

	const response = await axiosInstance.patch(`/users/${userStatus}/${id}`, {
		resetToken,
		password,
		confirmPassword: password
	})

	return response.data
}
