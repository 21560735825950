import { createGlobalStyle } from 'styled-components'

import RobotoBold from '../../assets/fonts/Roboto-Bold.woff'
import RobotoMedium from '../../assets/fonts/Roboto-Medium.woff'
import RobotoRegular from '../../assets/fonts/Roboto-Regular.woff'
import RobotoLight from '../../assets/fonts/Roboto-Light.woff'

import { fontType, mediaQuery, toRem } from './functions'
import { animate } from './animated'


export const GlobalStyle = createGlobalStyle`

	@font-face {
		font-family: 'Light';
		src: url(${RobotoLight}) format('woff');
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'Regular';
		src: url(${RobotoRegular}) format('woff');
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'Medium';
		src: url(${RobotoMedium}) format('woff');
		font-weight: 500;
		font-style: normal;
	}

	@font-face {
		font-family: 'Bold';
		src: url(${RobotoBold}) format('woff');
		font-weight: 700;
		font-style: normal;
	}

	* {
		margin: 0;
		padding: 0;
		outline: 0;
		box-sizing: border-box;
	}

	html {
		font-size: 16px;

		${mediaQuery('xs')(`
			font-size: 14px;
		`)}
	}

	img {
		vertical-align: middle;
		border-style: none;
		max-width: 100%;
		object-fit: cover;
		object-position: center;
	}

	#ct-container {
		${fontType(1)};
	}

	.modal-primary {
		${fontType(0)};
		margin-top: ${toRem(80)};
		.modal-wrapper{
			background: #F9F9F9 !important;
		}
	}

	/* width */
	::-webkit-scrollbar {
		width: 8px;
		height: 6px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: rgb(238, 238, 238);
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #c1c1c1;
		border-radius: 5px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: lighten(#c1c1c1, 8);
	}
	.animated-left{
		opacity: 0;
		transform: translateX(-30%);
		animation: ${animate} 0.4s forwards;
	}

	.animated-right {
		opacity: 0;
		transform: translateX(20%);
		animation: ${animate} 0.4s forwards;
	}

	.animated-top {
		opacity: 0;
		transform: translateY(-10px);
		animation: ${animate} 0.4s forwards;
	}
`
