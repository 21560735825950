import { format } from 'date-fns'
import fileDownload from 'js-file-download'
import { useQuery, UseQueryResult } from 'react-query'
import { CustomerType, CustomerTypeEnum } from 'shared/interfaces/contract'
import { PageContent } from 'shared/interfaces/financial'
import { PaginatedRequest, PaginatedResult } from 'shared/interfaces/pagination'
import { QUERY_KEYS } from 'shared/util/reactQuery'

import axiosInstance from './axiosInstance'

export async function getExcel(
	title: string,
	header: string[],
	content: any[]
) {
	const response = await axiosInstance.post(
		'/financial/generate-excel',
		{
			title,
			header,
			content
		},
		{
			responseType: 'blob'
		}
	)
	const fileName = `${format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")}.xlsx`

	fileDownload(response.data, fileName)
}

export async function getExamDataExcel(contractID: string, mounth: string) {
	const response = await axiosInstance.post(
		`/exams/findExamsByContractIdAndExport/${contractID}`,
		{
			mounthDate: mounth
		},
		{
			responseType: 'blob'
		}
	)
	const fileName = `${format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")}.xlsx`

	fileDownload(response.data, fileName)
}

export type IFetchFinancialRequest = PaginatedRequest<{
	month: number
	year: number
	customerType: CustomerTypeEnum | CustomerType.ALL
}>
export type IFetchFinancialResponse = PaginatedResult<PageContent>

export async function fetchFinancialPage({
	page,
	limit,
	search,
	sort,
	order,
	filters,
	month,
	year,
	customerType
}: IFetchFinancialRequest): Promise<IFetchFinancialResponse> {
	// The server call this pageIndex but actually it's the page number
	const response = await axiosInstance.post('/financial/get-page', {
		pageIndex: page,
		maxItemsInPage: limit,
		searchString: search,
		sort,
		order,
		filters,
		month,
		year,
		customerType
	})

	return response.data
}

export function useFinancialPage({
	page,
	limit,
	search,
	sort,
	order,
	filters,
	month,
	year,
	customerType
}: IFetchFinancialRequest): UseQueryResult<IFetchFinancialResponse> {
	const enabled = !!(page && limit && filters && month && year)

	return useQuery(
		[
			QUERY_KEYS.FINANCIAL_PAGE,
			{ page, limit, search, sort, order, filters, month, year, customerType }
		],
		async ({ queryKey }) => {
			const [_key, params] = queryKey
			const { page, limit, search, sort, order, filters, month, year } =
				params as IFetchFinancialRequest

			return fetchFinancialPage({
				page,
				limit,
				search,
				sort,
				order,
				filters,
				month,
				year,
				customerType
			})
		},
		{
			enabled
		}
	)
}
