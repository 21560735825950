import { CustomerTypeEnum, EntityTypeEnum } from 'shared/interfaces/contract'
import { KitStatus } from 'shared/interfaces/kit'

export function translate(type: EntityTypeEnum) {
	switch (type) {
		case 'PUBLIC':
			return 'Pública'
		case 'PRIVATE':
			return 'Privada'
		case 'ONG':
			return 'ONG'
		default:
			return type
	}
}

export function friendlyCustomerType(type: string) {
	switch (type) {
		case 'PARTNERSHIP_CUSTOMER':
			return 'Cliente Parceiro'
		case 'COMMERCIAL_CUSTOMER':
			return 'Cliente Comercial'

		default:
			return type
	}
}

export const translateStatus: { [key in KitStatus]: string } = {
	ACTIVE: 'Ativo',
	DISSOCIATED: 'Desassociado',
	PENDING: 'Pendente'
}

export const translateHeader = [
	'Tipo',
	'Responsável',
	'Ativações no Período',
	'Custo Total de Assinaturas',
	'Exames Realizados',
	'Custo Total de Exames',
	'Custo Total Mensal'
]

export const translateQuestionsDatasheet: { [key in string]: string } = {
	menopause: 'Menopausa?',
	dateLastMenstruation: 'Quando foi sua última menstruação?',
	eitherMotherSisterOrDaugtherHadCancer:
		'Possui mãe, irmã ou filha que tem/teve câncer de mama?',
	surgery: 'Fez cirurgia na mama?',
	surgeryResult: 'Qual tipo da cirurgia?',
	mammography: 'Já realizou mamografia?',
	mammographyResult: 'Qual foi o resultado da mamografia?',
	dateLastMammography: 'Data da última mamografia',
	biopsy: 'Já realizou biópsia?',
	biopsyResult: 'Resultado da biópsia',
	size: 'Tamanho / Extensão (em centímetros)'
}
