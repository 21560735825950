import { EntityTypeEnum } from './contract'
import { IKit } from './kit'

export interface IUnit {
	_id?: string
	name: string
	contract: string
	companyName: string
	entityType: EntityTypeEnum
	companyFiscalID: string
	CNES: string
	telephone: string
	observation: string
	operators?: string[]
	kits?: string[] | IKit[]
	createdAt?: Date
	updatedAt?: Date

	postalCode: string
	streetName: string
	addressNumber: string
	addressComplement: string
	neighborhood: string
	city: string
	state: StateFromBrazil
	location: ILocation
}

export interface ILocation {
	type: LocationTypesEnum
	coordinates: number[]
}

export enum LocationTypes {
	Point = 'Point'
}

export interface IUnitPaginated {
	_id: string
	name: string
	contract: IUnitContract
	operators: string[]
	kits: string[]
}

export interface IUnitContract {
	_id: string
	name: string
}

export interface IUnitsPage {
	page: number
	numberOfPages: number
	totalDocs: number
}

export interface IDeleteUnit {
	_id: string
}

export enum StateFromBrazil {
	AC = 'AC',
	AL = 'AL',
	AP = 'AP',
	AM = 'AM',
	BA = 'BA',
	CE = 'CE',
	DF = 'DF',
	ES = 'ES',
	GO = 'GO',
	MA = 'MA',
	MT = 'MT',
	MS = 'MS',
	MG = 'MG',
	PA = 'PA',
	PB = 'PB',
	PR = 'PR',
	PE = 'PE',
	PI = 'PI',
	RJ = 'RJ',
	RN = 'RN',
	RS = 'RS',
	RO = 'RO',
	RR = 'RR',
	SC = 'SC',
	SP = 'SP',
	SE = 'SE',
	TO = 'TO'
}

export type LocationTypesEnum = keyof typeof LocationTypes
