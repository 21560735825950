import styled from 'styled-components'
import { fontType, toRem } from '../../shared/styles/functions'

export const UnitsListWrapper = styled.div`
	button {
		cursor: pointer;
	}

	.tableContainer {
		margin-top: ${toRem(50)};
		${fontType(0)}

		.icon {
			border: none;
			display: flex;
			margin: auto;
			background: transparent;
			min-width: 16px;
		}
	}
`
