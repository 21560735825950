import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'
import { ISelectedProfile } from 'shared/interfaces/profile'

import {
	IActionsFromCreators,
	IProfileActionCreators
} from '../../interfaces/action'

/**
 * Action types & creators
 */
export const { Types, Creators }: IActionsFromCreators<IProfileActionCreators> =
	createActions({
		setProfile: ['profile'],
		resetProfile: []
	})

/**
 * Handlers
 */

export const INITIAL_STATE: ISelectedProfile = {
	profileId: ''
}

const set = (state = INITIAL_STATE, action: any) => action.profile
const reset = (state = INITIAL_STATE, action: any) => INITIAL_STATE

/**
 * Reducer
 */
export default createReducer<ISelectedProfile, Action<IProfileActionCreators>>(
	INITIAL_STATE,
	{
		[Types.SET_PROFILE]: set,
		[Types.RESET_PROFILE]: reset
	}
)
