import React, { createElement, useEffect, useState } from 'react'
import { TableNavProps } from '@buildbox/components'
import { useHistory } from 'react-router-dom'
import { useDebounce } from 'use-debounce/lib'

import { IContractsPage } from 'shared/interfaces/contract'
import {
	ITableColumn,
	ITableRow,
	IViewProps,
	IAcessorAndOrder,
	ScreenActionsEnum
} from './types'
import ContractsPage from './view'
import routesEnum from 'modules/Routes/enum'
import { useContractsPage } from 'shared/services/contract.service'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { transformMenu } from 'shared/util/format'
import { useSelectedProfileIsOneOf } from 'shared/util/profilePermission'
import { renderEditIcon } from './util'
import { friendlyCustomerType } from 'shared/util/translate'

const CONTRACTS_PER_PAGE = 10

function ContractsPageContainer(): JSX.Element {
	const history = useHistory()
	const [contractsPage, setContractsPage] = useState<IContractsPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0
	})

	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [search, setSearch] = useState('')
	const [searchDebounce] = useDebounce(search, 1000)
	const [sortBy, setSortBy] = useState<IAcessorAndOrder>({
		acessor: 'createdAt',
		order: -1
	})

	const { filter } = useTypedSelector(['filter'])
	const showNewButton = useSelectedProfileIsOneOf(['ADMIN'])

	const onlySelected = transformMenu(filter)
	const { status: contractsStatus, data: contractsData } = useContractsPage({
		page: contractsPage.page,
		search: searchDebounce,
		limit: CONTRACTS_PER_PAGE,
		filters: onlySelected,
		order: sortBy.order,
		sort: sortBy.acessor
	})

	function handleSearch(value: string): void {
		setSearch(value.trim())
	}

	function handleSortColumn(acessor: string, order: number) {
		if (order === 0) {
			setSortBy({
				acessor: 'createdAt',
				order: -1
			})

			return
		}
		setSortBy({
			acessor,
			order
		})
	}

	function activateScreenContract(
		action: ScreenActionsEnum,
		contractId: string
	) {
		history.push({
			pathname: routesEnum.CONTRACTS_CREATE_AND_UPDATE,
			state: {
				action,
				contractId: contractId
			}
		})
	}

	function handleCreateContract() {
		activateScreenContract('CREATE', '')
	}
	function handleUpdateContract() {}

	function goToPage(pageIndex: number) {
		setContractsPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const navProps: TableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: contractsPage.numberOfPages,
		pageIndex: contractsPage.page - 1,
		totalDocs: contractsPage.totalDocs
	}

	const tableColumns: ITableColumn[] = [
		{
			Header: 'Nome',
			accessor: 'name',
			sortType: 'basic'
		},
		{
			Header: 'Kits Contratados',
			accessor: 'contractedKits',
			sortType: 'basic'
		},
		{
			Header: 'Tipo de Cliente',
			accessor: 'customerType',
			disableSortBy: true
		},
		{
			Header: 'Kits Ativos',
			accessor: 'kits',
			disableSortBy: true
		},
		{
			Header: 'Unidades',
			accessor: 'units',
			disableSortBy: true
		},
		{
			Header: 'Operadores',
			accessor: 'operators',
			disableSortBy: true
		},
		{
			Header: '',
			accessor: 'editIcon',
			disableSortBy: true
		}
	]

	function handleParseData() {
		if (!contractsData || contractsStatus === 'loading') return

		const rows =
			contractsData.docs.map((contract) => {
				const operators = contract.units.reduce(
					(prev, unit) => [
						...prev,
						...unit.operators.filter((operator) => !prev.includes(operator))
					],
					[] as string[]
				)

				return {
					name: contract.name,
					contractedKits: contract.contractedKits,
					kits: contract.kits?.length || 0,
					units: contract.units.length || 0,
					operators: operators.length,
					customerType: friendlyCustomerType(contract.customerType),
					editIcon: renderEditIcon(contract._id, activateScreenContract)
				}
			}) || []

		setTableRows(rows)
		setContractsPage({
			numberOfPages: contractsData.totalPages,
			page: contractsData.page || 1,
			totalDocs: contractsData.totalDocs
		})
	}
	useEffect(handleParseData, [contractsData])

	function handleResetPage() {
		setContractsPage((state) => ({ ...state, page: 1 }))
	}
	useEffect(handleResetPage, [searchDebounce])

	const viewProps: IViewProps = {
		showNewButton,
		tableColumns,
		tableRows,
		contractsStatus,
		navProps,
		usersPerPage: CONTRACTS_PER_PAGE,
		search,
		sortBy,
		handleSearch,
		handleSortColumn,
		handleCreateContract,
		handleUpdateContract
	}
	return createElement(ContractsPage, viewProps)
}

export default ContractsPageContainer
