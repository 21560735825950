import styled from 'styled-components'
import { toRem } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'
import { fadeIn } from 'shared/styles/animated'

export const ForgotPasswordWrapper = styled.div`
	animation: ${fadeIn} 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;

	a {
		text-decoration: none;
	}

	.forgot-password-header {
		display: flex;
		align-items: center;
		margin-bottom: 32px;
		cursor: pointer;

		.forgot-password-title {
			margin-left: 6px;
			text-transform: capitalize;
			color: ${colors.secondary};
			font-size: ${toRem(12)};
		}
	}

	.forgot-password-subtitle {
		text-align: start;
		color: ${colors.darkGrey};
		font-size: ${toRem(14)};
		margin-bottom: 24px;
	}
`
