import { SelectOption } from '@buildbox/components'
import { getProfiles } from 'shared/services/profiles.service'
import { friendlyRole } from './friendlyRole'

export async function buildingOptionsProfile() {
	const response = await getProfiles()

	if (!response) return

	const buildOptions: SelectOption[] = response.map((profile) => {
		return {
			value: profile._id,
			label: friendlyRole[profile.name]
		}
	})

	return buildOptions
}
