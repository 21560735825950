import React, { createElement, useState, useEffect } from 'react'

import { TableNavProps } from '@buildbox/components'
import { IAcessorAndOrder } from 'shared/components/Table/types'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { IExam, IExamsPage } from 'shared/interfaces/exam'
import { formatDateWithHour, transformMenu } from 'shared/util/format'
import { useDebounce } from 'use-debounce/lib'
import { IViewProps, ITableColumn, INITIAL_SORT, ITableRow } from './types'
import ExamsPage from './view'
import routesEnum from 'modules/Routes/enum'
import { IPatient } from 'shared/interfaces/patient'
import { useExamsPage } from 'shared/services/exam.service'
import { renderDiagnosis, renderEditIcon } from './util'
import { IUser } from 'shared/interfaces/user'

const ITEMS_PER_PAGE = 10
function ExamsPageContainer(): JSX.Element {
	const [examsPage, setExamsPage] = useState<IExamsPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0
	})

	const [search, setSearch] = useState('')
	const { filter } = useTypedSelector(['filter'])
	const [searchDebounce] = useDebounce(search, 1000)

	const [sortBy, setSortBy] = useState<IAcessorAndOrder>(INITIAL_SORT)
	const [tableRows, setTableRows] = useState<ITableRow[]>([])

	const onlySelected = transformMenu(filter)
	const { status: examStatus, data: examData } = useExamsPage({
		page: examsPage.page,
		search: searchDebounce.trim(),
		limit: ITEMS_PER_PAGE,
		filters: onlySelected,
		order: sortBy.order,
		sort: sortBy.acessor
	})

	function handleSearch(value: string): void {
		setSearch(value)
	}

	function handlerSortColumn(acessor: string, order: number): any {
		// if (order === 0) {
		// 	setSortBy(INITIAL_SORT)
		// 	return
		// }

		setSortBy({
			acessor,
			order
		})
	}

	function onClickRow(id: string) {
		handleEditExam(id)
	}

	function handleEditExam(examId: string) {
		const path = routesEnum.EXAMS_PAGE_EDIT.replace(':examId', examId)
		window.open(path, '_blank')
	}

	function handleParseData() {
		if (!examData || examStatus === 'loading') return

		const rows =
			examData.docs.map((exam: IExam) => {
				const lastReport = exam?.physicianDiagnoses?.length
					? exam.physicianDiagnoses.reduce((a, b) => {
							return new Date(a.date) > new Date(b.date) ? a : b
					  })?.physician
					: ''

				return {
					examId: exam._id,
					accomplishedAt: formatDateWithHour(
						new Date(exam.accomplishedAt || exam.createdAt)
					),
					patientName: (exam.patient as IPatient)?.name,
					personalFiscalID: (exam.patient as IPatient)?.personalFiscalID,
					operatorName: (exam.operator as IUser)?.name,
					physicianName: (lastReport as IUser)?.name || '',
					diagnosis: renderDiagnosis(exam),
					editIcon: renderEditIcon()
				}
			}) || []

		setTableRows(rows)
		setExamsPage({
			numberOfPages: examData.totalPages,
			page: examData.page || 1,
			totalDocs: examData.totalDocs
		})
	}

	function goToPage(pageIndex: number) {
		setExamsPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const navProps: TableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: examsPage.numberOfPages,
		pageIndex: examsPage.page - 1,
		totalDocs: examsPage.totalDocs
	}

	useEffect(handleParseData, [examData])

	const tableColumns: ITableColumn[] = [
		{
			Header: 'Data',
			accessor: 'accomplishedAt',
			sortType: 'basic'
		},
		{
			Header: 'Nome do Paciente',
			accessor: 'patientName',
			disableSortBy: true
		},
		{
			Header: 'CPF do Paciente',
			accessor: 'personalFiscalID',
			disableSortBy: true
		},
		{
			Header: 'Nome do Operador',
			accessor: 'operatorName',
			disableSortBy: true
		},
		{
			Header: 'Nome do Médico',
			accessor: 'physicianName',
			disableSortBy: true
		},
		{
			Header: 'Parecer Médico',
			accessor: 'diagnosis',
			disableSortBy: true
		},
		{
			Header: '',
			accessor: 'editIcon',
			disableSortBy: true
		}
	]

	const viewProps: IViewProps = {
		tableColumns,
		tableRows,
		examStatus,
		navProps,
		itemsPerPage: ITEMS_PER_PAGE,
		handleSearch,
		search,
		handlerSortColumn,
		sortBy,
		onClickRow
	}

	return createElement(ExamsPage, viewProps)
}

export default ExamsPageContainer
