// import { IAttachment } from './attachment'

import { PermissionEnum, ProfileNameEnum } from './user'

/**
 * Contract interface
 */

export interface IContract {
	_id: string
	entityType: EntityTypeEnum
	customerType: CustomerTypeEnum | null
	name: string
	companyFiscalID: string
	contractedKits: number
	subscriptionFee: number
	examFee: number
	activatedAt: Date
	contractMonthLength: number
	files?: string[]
	kits?: string[]
	units?: string[]
	observation?: string
	status: ContractStatusEnum
	tablet: boolean
}

export enum EntityType {
	PUBLIC = 'PUBLIC',
	PRIVATE = 'PRIVATE',
	ONG = 'ONG'
}

export type INewContrat = Omit<IContract, '_id'>

export const COMPANY_TYPES: EntityType[] = [
	EntityType.PUBLIC,
	EntityType.PRIVATE,
	EntityType.ONG
]

export enum ContractStatus {
	PENDING = 'PENDING',
	ACTIVE = 'ACTIVE',
	DELETED = 'DELETED'
}

export const INITIAL_STATE: INewContrat = {
	entityType: EntityType.PUBLIC,
	name: '',
	companyFiscalID: '',
	contractedKits: 0,
	subscriptionFee: 0,
	examFee: 0,
	activatedAt: new Date(),
	contractMonthLength: 0,
	status: ContractStatus.PENDING,
	files: [],
	kits: [],
	observation: '',
	units: [],
	tablet: false,
	customerType: null
}

export interface IContractsPage {
	page: number
	numberOfPages: number
	totalDocs: number
}

export interface IContractsOptions {
	profilePermissions: PermissionEnum[]
	profileID: string
	profileName: ProfileNameEnum
	contracts: IFilterOption[]
}

export interface IFilterOption {
	id: string
	name: string
	units: [
		{
			id: string
			name: string
		}
	]
}

export interface IContractResponsePaginate {
	_id: string
	name: string
	units: IUnit[]
	kits: string[]
	contractedKits: number
	customerType: string
}

interface IUnit {
	operators: string[]
	name: string
}

export type EntityTypeEnum = keyof typeof EntityType

export type ContractStatusEnum = keyof typeof ContractStatus

export enum CustomerType {
	PARTNERSHIP_CUSTOMER = 'PARTNERSHIP_CUSTOMER',
	COMMERCIAL_CUSTOMER = 'COMMERCIAL_CUSTOMER',
	ALL = 'ALL'
}

export type CustomerTypeEnum = keyof typeof CustomerType
