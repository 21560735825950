import React, { useEffect, useState } from 'react'
import { ChevronUp } from 'react-feather'
import { CheckBox } from '@buildbox/components'

import { IProps } from './types'
import { MenuHeaderWrapper } from './styles'
import { SwitchMenu } from 'shared/components/SwitchMenu'

export default function MenuHeader({
	label,
	selected,
	expanded,
	onSelect,
	onExpand,
	onGeneralPermission,
	styles,
	showChevron = false,
	renderSwitch = false,
	isGeneralPermission: onPermission = false
}: IProps): JSX.Element {
	const [checked, setChecked] = useState(selected)
	const [isExpanded, setIsExpanded] = useState(expanded)
	const [isGeneralPermission, setIsGeneralPermission] = useState(onPermission)

	function toggleChecked(event: any) {
		const checkedUpdated = !checked
		setChecked(checkedUpdated)
		onSelect(checkedUpdated)
	}

	function toggleExpanded(event: any) {
		const expandedIsUpdated = !isExpanded
		onExpand(expandedIsUpdated)
	}

	function toggleGeneralPermission() {
		const generalPermissionUpdated = !isGeneralPermission
		onGeneralPermission && onGeneralPermission(generalPermissionUpdated)
	}

	function getCssClassName(checkboxLevel: number): string {
		return `checkbox-level${checkboxLevel}`
	}

	useEffect(() => setChecked(selected), [selected])

	return (
		<MenuHeaderWrapper
			style={styles}
			className={renderSwitch ? 'menu-header' : ''}
		>
			<div className='menu-content'>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<CheckBox
						fontSize={styles.checkboxSize}
						primaryColor={styles.checkboxPrimaryColor}
						secondaryColor={styles.checkboxSecondaryColor}
						backgroundColor={styles.checkboxBackgroundColor}
						className={getCssClassName(styles.checkboxLevel)}
						checked={checked}
						onChange={toggleChecked}
					/>
					<p>{label}</p>
				</div>
				{renderSwitch ? (
					<SwitchMenu
						checked={isGeneralPermission}
						label='Incluir unidades novas do contrato.'
						setCheched={toggleGeneralPermission}
					/>
				) : null}
			</div>

			{!!showChevron && (
				<div className='expand-chevron'>
					<ChevronUp
						className={expanded ? 'active' : ''}
						size={14}
						color={styles.color}
						onClick={toggleExpanded}
					/>
				</div>
			)}
		</MenuHeaderWrapper>
	)
}
