import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'
import { IMenu } from 'shared/components/MenuPrimary/types'

import {
	IActionsFromCreators,
	IFilterActionCreators
} from '../../interfaces/action'

/**
 * Action types & creators
 */
export const { Types, Creators }: IActionsFromCreators<IFilterActionCreators> =
	createActions({
		setFilter: ['filter'],
		resetFilter: []
	})

/**
 * Handlers
 */

export const INITIAL_STATE: IMenu[] = [
	{
		value: '',
		selected: false,
		options: [],
		expanded: false,
		visible: true,
		label: ''
	}
]

const set = (state = INITIAL_STATE, action: any) => action.filter
const reset = (state = INITIAL_STATE, action: any) => INITIAL_STATE

/**
 * Reducer
 */
export default createReducer<IMenu[], Action<IFilterActionCreators>>(
	INITIAL_STATE,
	{
		[Types.SET_FILTER]: set,
		[Types.RESET_FILTER]: reset
	}
)
