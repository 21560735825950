import { ISelectOption } from '@buildbox/components/lib/components/Select/types'
import { ScreenActionsEnum } from 'modules/ContractsPage/types'
import { ChangeEvent, FocusEvent } from 'react'
import { IAuthenticationModalProps } from 'shared/components/AuthModal/types'
import { IModalDeleteTarget } from 'shared/components/ModalDelete/types'
import { EntityTypeEnum, INewContrat } from 'shared/interfaces/contract'

export interface IViewProps {
	action: ScreenActionsEnum
	handleChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
	renderButtonDelete: () => JSX.Element | null
	newFormContract: INewContrat
	handleCompanyType: (type: EntityTypeEnum) => void
	renderUpload(): JSX.Element
	onSubmit: () => void
	removeUpload: (index: number) => void
	enableBtnSubmit: boolean
	handleSetDate: (event: FocusEvent<HTMLInputElement>) => void
	viewErrors: string[]
	loading: boolean
	handleCheckboxTablet: () => void
	checkboxTablet: boolean
	authModalConfig: IAuthenticationModalProps
	modalDeleteProps: IModalDeleteProps
	handleSelectChange(selectOption: any): void
}

export const titleScreen = {
	CREATE: 'Novo Contrato',
	EDIT: 'Dados do Contrato '
}

export const textButton = {
	CREATE: 'Cadastrar',
	EDIT: 'Salvar alterações'
}

export interface IModalDeleteProps {
	onClose: () => void
	target: IModalDeleteTarget | null
	handleDeleteUserEnd: () => void
	state: boolean
}
