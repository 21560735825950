import React, { useState, useEffect } from 'react'
import { Select, SelectOption } from '@buildbox/components'

import { StateFromBrazil } from 'shared/interfaces/unit'
import { selectStyle } from 'shared/styles/theme'
import { IViewProps } from './types'

function SelectState(props: IViewProps): JSX.Element {
	const { stateSelected, handleStateChange, isDisabled = false } = props

	const statesAbbrs = Object.keys(StateFromBrazil)

	const [stateOptions, setStateOptions] = useState<SelectOption[]>([])

	useEffect(() => {
		const options = statesAbbrs.map((state) => ({
			value: state,
			label: state
		}))

		setStateOptions(options)
	}, [])

	return (
		<Select
			id='state'
			label='Estado'
			options={stateOptions}
			value={stateSelected && { label: stateSelected, value: stateSelected }}
			placeholder={'Estado'}
			onChange={(state: any) => handleStateChange(state)}
			isDisabled={isDisabled}
			{...selectStyle}
		/>
	)
}

export default SelectState
