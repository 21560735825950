import React from 'react'
import { primaryButtonStyle, primaryInputStyle } from 'shared/styles/theme'
import { Button } from '@buildbox/components'
// import InputSearch from '../InputSearch'
import PageTitle from '../PageTitle'
import { Header, HeaderContainer } from './styles'
import { IViewProps } from './types'
import InputSearchPage from '../InputSearchPage'

export function HeaderPage({
	title,
	handleSearch,
	search,
	titleButton,
	handleSubmit,
	showButton = true,
	labelInput,
	isSearch = true
}: IViewProps) {
	return (
		<Header>
			<PageTitle>{title}</PageTitle>

			<HeaderContainer>
				{isSearch ? (
					<InputSearchPage
						id='search'
						label={labelInput}
						onChange={(e: any) => handleSearch(e.target.value)}
						value={search && search}
						{...primaryInputStyle}
					/>
				) : null}
				{showButton && (
					<Button onClick={handleSubmit} {...primaryButtonStyle}>
						{titleButton}
					</Button>
				)}
			</HeaderContainer>
		</Header>
	)
}
