import { ChangeEvent } from 'react'

export interface IAuthenticationModalProps {
	value: boolean
	handleClose(): void
	handlePasswordChange(event: ChangeEvent<HTMLInputElement>): void
	handleSubmit(): void
	loadingSubmit: boolean
	password: string
}

export interface IAuthenticationModalState {
	value: boolean
	password: string
}

export const INITIAL_AUTHENTICATION_MODAL_PROPS: IAuthenticationModalState = {
	value: false,
	password: ''
}
