import { fontType, mediaQuery, toRem, transition } from '../../styles/functions'
import styled from 'styled-components'
import { colors } from 'shared/styles/theme'

export const LoadingContent = styled.section`
	display: flex;
	margin-top: ${toRem(50)};
	justify-content: center;
`

export const TableWrapper = styled.div`
	/* overflow: auto;
	position: relative; */

	${fontType(0)}
	.checkbox-files {
		position: absolute;
		left: 12px;
	}

	.select-dowloads {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: ${toRem(30)};

		div {
			display: flex;
			align-items: center;

			label {
				margin-left: 8px;
				${fontType(0)};
				color: ${colors.darkGrey};
				font-size: 12px;
			}

			button {
				text-transform: uppercase;
			}
		}
	}

	section {
		min-width: ${toRem(840)};
	}

	.header-height {
		height: ${toRem(83)};
		margin-bottom: ${toRem(20)};
	}

	.box-company {
		height: ${toRem(64)};

		.data-report:nth-child(1) {
			padding-left: ${toRem(40)};
		}
	}

	.total {
		.data-report p {
			padding-right: 5px;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			word-break: break-word;
			overflow: hidden;
			line-height: 1rem;
		}
	}

	main {
		.box-company {
			margin-top: ${toRem(20)};
			position: relative;

			.contract-name-wrapper {
				display: flex;
				flex-direction: column;

				height: 100%;
				border-left: 4px solid white;
				padding: 25px;
				width: 100%;

				.contract-name {
					height: 100%;
					display: flex;
					align-items: center;
					display: flex;
					justify-content: space-between;
					width: 100%;
	
					button {
						background: transparent;
						border: none;
	
						cursor: pointer;
					}
				}
	
				.badge-total-active-kits {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 95%;
	
					p {
						background: ${colors.borderDiagnosis};
						border-radius: 10px;
						border: none;
						color: ${colors.secondary};
						padding: 0 7px;
						font-size: 8px;
						margin-top: 5px;
					}
				}
			}
		}

		.btn-open-modal {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 95%;

			button {
				background: ${colors.borderDiagnosis};
				border-radius: 10px;
				cursor: pointer;
				border: none;
				color: ${colors.secondary};
				padding: 5px 7px;
				font-size: 8px;
				margin-left: 5px;
			}
		}
	}

	.box-units {
		height: ${toRem(64)};
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.data-report {
			background-color: ${colors.backgroundBoxFinance};
			width: 100%;
			font-size: ${toRem(12)};
			color: ${colors.darkGrey};
			height: 100%;
			padding-left: 16px;
			display: flex;
			align-items: center;
		}

		.data-report p {
			display: inline-block;
			text-overflow: ellipsis;
			overflow: hidden;
			line-height: 1rem;
			/* padding-top: 24px; */
		}

		.data-report:nth-child(1) {
			margin-right: 4px;
			min-width: 25%;

			p {
				padding-right: 5px;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				word-break: break-word;
				overflow: hidden;
				line-height: 1rem;
			}
		}

		.data-report:nth-child(n + 2):nth-child(-n + 10) {
			min-width: 7%;
		}

		.data-report:nth-child(n + 11):nth-child(-n + 12) {
			width: 0;
		}

		.data-report:nth-child(4) {
			margin-right: 4px;
			padding-left: ${toRem(16)};
		}

		.data-report:nth-child(7) {
			margin-right: 4px;
			padding-left: ${toRem(16)};
			/* min-width: ${toRem(230)}; */
		}
	}

	.box-units .data-report {
		background: ${colors.backgroundSelect};
	}

	.table-footer {
		margin-top: 24px;
		border-top: 1px solid ${colors.backgroundMenu};

		.result-span {
			display: inline-block;
			margin-top: ${toRem(12)};
			margin-bottom: ${toRem(18)};
			font-size: ${toRem(12)};
		}

		.pagination {
			display: flex;
			justify-content: center;
			align-items: center;

			.button {
				border: none;
				background: none;
				cursor: pointer;
				padding: ${toRem(16)};

				color: ${colors.darkGrey};

				${transition()};

				&[disabled] {
					color: ${colors.lightGrey};
				}
			}

			.page-numbers {
				display: flex;
				align-items: center;
				font-size: ${toRem(14)};
				.page-number {
					padding: ${toRem(8)} ${toRem(10)};
					border-radius: 4px;
					color: ${colors.darkGrey};

					${transition()};

					cursor: pointer;
					&.active {
						background: ${colors.darkGrey};
						color: white;
					}
				}
			}
		}
	}

	${mediaQuery('md')(`
		overflow: auto;
	`)}
`
