import React from 'react'

import { IViewProps, screenMessageExam } from './types'
import {
	ExamContent,
	ExamModal,
	ExamWrapper,
	LoadingContainer,
	ExamWrapperContainer
} from './styles'
import PageTitle from 'shared/components/PageTitle'
import InfoPatient from './InfoPatient'
import iconPdfExport from '../../assets/images/export-pdf-exam.svg'
import iconBackTop from '../../assets/images/back-top.svg'
import { IPatient } from 'shared/interfaces/patient'
import InfoExam from './InfoExam'
import { Loading } from '@buildbox/components'
import { colors } from 'shared/styles/theme'
import ModalConfirm from './ModalConfirm'
import { IContract } from 'shared/interfaces/contract'
import { IUser } from 'shared/interfaces/user'
import { friendlyDiagnosisForward } from 'shared/util/friendlyRole'
import AuthModal from 'shared/components/AuthModal'
import { IUnit } from 'shared/interfaces/unit'

function ExamEdit(props: IViewProps): JSX.Element {
	const {
		examDetail,
		questionsDatasheet,
		imageInfoCircle,
		isLoading,
		image,
		hiddenBtnExport,
		generatePdf,
		btnScrollToTop,
		scrollToTop,
		btnExportClicked,
		openModal,
		showModal,
		onClose,
		reportDiagnosis,
		report,
		openAuthModal,
		authModalConfig
	} = props

	if (!examDetail) return <PageTitle>Exame não encontrado</PageTitle>

	if (isLoading)
		return (
			<LoadingContainer>
				<Loading primaryColor={colors.secondary} type='Sentry' />
			</LoadingContainer>
		)
	return (
		<ExamWrapperContainer>
			<ExamWrapper className='animated-right' id='content'>
				<PageTitle>{screenMessageExam.examDetail}</PageTitle>

				<ExamContent id='report-content'>
					<InfoPatient
						patient={examDetail.patient as IPatient}
						questionsDatasheet={questionsDatasheet}
						unit={(examDetail.unit as IUnit)?.name}
						operator={(examDetail.operator as IUser)?.name}
						contractName={(examDetail.contract as IContract)?.name}
						accomplishedAt={examDetail.accomplishedAt}
					/>

					<InfoExam
						exam={examDetail}
						imageInfoCircle={imageInfoCircle}
						image={image}
						btnExportClicked={btnExportClicked}
						handleSubmit={openModal}
						returnReport={reportDiagnosis}
					/>

					{!hiddenBtnExport && (
						<div className='btn-export'>
							<button className='icon' onClick={generatePdf}>
								<img src={iconPdfExport} alt='Exportar Pdf' />
							</button>
						</div>
					)}
				</ExamContent>
			</ExamWrapper>

			<ExamModal>
				{btnScrollToTop && (
					<div className='btn-scroll'>
						<button className='icon' onClick={scrollToTop}>
							<img src={iconBackTop} alt='Voltar para o topo' />
						</button>
					</div>
				)}
				{report && (
					<ModalConfirm
						handleSubmit={openAuthModal}
						isLoading={isLoading}
						isActive={showModal}
						onClose={onClose}
						diagnosis={friendlyDiagnosisForward[report?.diagnosis]}
					/>
				)}
			</ExamModal>

			<AuthModal {...authModalConfig} />
		</ExamWrapperContainer>
	)
}

export default ExamEdit
