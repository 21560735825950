import { createElement, useEffect, useState } from 'react'
import { startOfMonth } from 'date-fns/esm'
import { INITIAL_LAT_LNG, ITableRow, IViewProps } from './types'
import DashboardPage from './view'
import { IFromTo } from 'shared/components/DatePicker/types'
import { endOfToday, formatISO, set, subHours } from 'date-fns'
import { ITableColumn } from './types'

import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { ILatLng, IMarkers } from 'shared/components/GoogleMapsApi/types'
import { useDashboard, useLiveDashboard } from 'shared/services/unit.service'
import { ILogsSummary } from 'shared/components/BarGraph/types'
import { IUnit } from 'shared/interfaces/unit'
import { IExam } from 'shared/interfaces/exam'
import { IDatasheet } from 'shared/interfaces/datasheet'
import {
	calculateNeverHadMammogram,
	calculateTableData,
	returnGraphicData,
	buildingGraphPie
} from './util'
import { transformMenu } from 'shared/util/format'

function DashboardPageContainer(): JSX.Element {
	const { filter } = useTypedSelector(['filter'])

	const [selectedDates, setSelectedDates] = useState<IFromTo>({
		from: startOfMonth(new Date()),
		to: endOfToday()
	})

	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [centerMap, setCenterMap] = useState<ILatLng>({
		lat: 0,
		lng: 0
	})
	const [zoomMap, setZoomMap] = useState(0)

	const [markersMap, setMarkersMap] = useState<IMarkers[]>([])
	const [graphicBarData, setGraphicBarData] = useState<ILogsSummary[]>([])
	const [graphicPieData, setGraphicPieData] = useState<ILogsSummary[]>([])
	const [totalExams, setTotalExams] = useState(0)
	const [neverHadMammogram, setNeverHadMammogram] = useState('')
	const [showDate, setShowDates] = useState(false)

	// filter,
	// 	'accomplishedAt',
	// 	-1,
	// 	selectedDates.from,
	// 	selectedDates.to

	// filter,
	// 	'accomplishedAt',
	// 	-1
	// const now = set(new Date(), {
	// 	milliseconds: 0,
	// 	seconds: 0,
	// 	minutes: 0
	// })
	// const startDateLive = formatISO(subHours(now, 4))
	// const endDateLive = formatISO(now)

	const filtersMenu = transformMenu(filter)
	const { status: dashboardStatus, data: dashboardData = [] } = useDashboard({
		args: {
			filters: filtersMenu,
			order: -1,
			sort: 'accomplishedAt',
			startDate: selectedDates.from,
			endDate: selectedDates.to
		}
	})

	const { status: liveStatus, data: liveData = [] } = useLiveDashboard({
		args: {
			filters: filtersMenu,
			order: -1,
			sort: 'accomplishedAt'
		}
	})

	function handleSetDates(value: IFromTo) {
		setSelectedDates(value)
	}

	function handleShowDates(show: boolean) {
		setShowDates(show)
	}

	function processDashboard() {
		if (dashboardStatus === 'loading') return

		const onlyExams = dashboardData.map((data: any) => data.exams).flat()
		const onlyDatasheet = onlyExams
			.map((data: any) => data?.datasheet as IDatasheet)
			.flat()
		initializeGraphPie(onlyExams)
		setTotalExams(onlyExams.length)

		const percertNeverHadMammogram = calculateNeverHadMammogram(
			onlyDatasheet,
			onlyExams.length
		)
		setNeverHadMammogram(percertNeverHadMammogram)

		const rows = calculateTableData(dashboardData)

		rows.unshift({
			name: 'Total',
			kits: rows.length
				? rows.map((kit) => kit.kits).reduce((kit, acc) => kit + acc)
				: 0,
			kitsUnused: rows.length
				? rows
						.map(({ kitsUnused }) => kitsUnused)
						?.reduce((kit, acc) => kit + acc)
				: 0,
			numberOfExams: onlyExams.length,
			neverHadAMammogram: percertNeverHadMammogram,
			suspicious:
				onlyExams.length > 0
					? `${(
							(onlyExams.filter((exam: any) => exam.diagnosis === 'SUSPICIOUS')
								.length /
								onlyExams.length) *
							100.0
					  ).toFixed(1)}%`
					: ' - '
		})

		setTableRows(rows)
	}

	function processLiveDashboard() {
		if (liveStatus === 'loading') return

		const onlyUnitsLive = liveData.map((data) => data.unit)
		const onlyExamsLive = liveData.map((data) => data.exams).flat()

		initializeMarkersMaps(onlyUnitsLive, onlyExamsLive)
		initializeGraphBar(onlyUnitsLive, onlyExamsLive)

		// if (dashboardLive.length === 0 || isErrorLive) {
		// 	setCenterMap(INITIAL_LAT_LNG)
		// 	setZoomMap(17)
		// }
	}

	function initializeMarkersMaps(units: IUnit[], exams: IExam[]) {
		// if (!units.length) return

		const mappedMarkers: IMarkers[] = units
			.filter(
				(unit) =>
					unit?.location &&
					unit.location.coordinates[0] !== 0 &&
					unit.location.coordinates[1] !== 0
			)
			.map((item) => {
				const findExam =
					exams.length > 0 && exams.find((exam) => exam.unit === item._id)

				const value =
					item.kits?.length && findExam
						? 'com kits, com exames'
						: item.kits?.length && !findExam
						? 'com kits, sem exames'
						: 'sem kits'

				return {
					value: value,
					title: item.name,
					position: {
						lat: item.location.coordinates[1],
						lng: item.location.coordinates[0]
					}
				}
			})

		if (!mappedMarkers.length) {
			setCenterMap(INITIAL_LAT_LNG)
			setZoomMap(17)
			setMarkersMap([])

			return
		}

		setMarkersMap(mappedMarkers)
		setCenterMap(mappedMarkers[0].position)
	}

	function initializeGraphBar(units: IUnit[], exams: IExam[]) {
		const hasNoKits = units.filter((unit) => !unit.kits?.length).length
		const withKitsWithoutExams = units.filter((unit) => {
			const findExams = exams.find((exam) => exam.unit === unit._id)

			return unit.kits?.length && !findExams
		}).length
		const withKitsAndExams = units.filter((unit) => {
			const findExams = exams.find((exam) => exam.unit === unit._id)

			return unit.kits?.length && findExams
		}).length

		const dataHasNoKits = returnGraphicData(
			'Sem Kits',
			'Sem Kits',
			hasNoKits,
			'#B5B3B8'
		)

		const dataWithKitsWithoutExams = returnGraphicData(
			'Com Kits, Sem Exames',
			'Com Kits, Sem Exames',
			withKitsWithoutExams,
			'#F8B0B0'
		)

		const datawithKitsAndExams = returnGraphicData(
			'Com Kits, Com Exames',
			'Com Kits, Com Exames',
			withKitsAndExams,
			'#ADB4EE'
		)

		let data: ILogsSummary[] = []

		if (dataHasNoKits) data.push(dataHasNoKits)
		if (dataWithKitsWithoutExams) data.push(dataWithKitsWithoutExams)
		if (datawithKitsAndExams) data.push(datawithKitsAndExams)

		setGraphicBarData(data)
	}

	function initializeGraphPie(exams: IExam[]) {
		if (!exams.length) {
			setGraphicPieData([])
			return
		}

		const suspicious = buildingGraphPie(
			exams,
			'SUSPICIOUS',
			'#F7B1DB',
			'Suspeito'
		)
		const notSuspicious = buildingGraphPie(
			exams,
			'NOT_SUSPICIOUS',
			'#d9dcf9',
			'Não Suspeito'
		)
		const none = buildingGraphPie(exams, 'NONE', '#EFEDF0', 'Não processado')

		let data: ILogsSummary[] = []

		if (notSuspicious) data.push(notSuspicious)
		if (suspicious) data.push(suspicious)
		if (none) data.push(none)

		setGraphicPieData(data)
	}

	const tableColumns: ITableColumn[] = [
		{ Header: 'Unidade', accessor: 'name', sortType: 'basic' },
		{ Header: 'Kits Associados', accessor: 'kits', sortType: 'basic' },
		{
			Header: 'Kits sem uso',
			accessor: 'kitsUnused',
			sortType: 'basic'
		},
		{
			Header: 'Quantidade de Exames',
			accessor: 'numberOfExams',
			sortType: 'basic'
		},
		{
			Header: '% Nunca fizeram Mamografia',
			accessor: 'neverHadAMammogram',
			sortType: 'basic'
		},
		{
			Header: '% Suspeitos',
			accessor: 'suspicious',
			sortType: 'basic'
		}
	]

	useEffect(processDashboard, [dashboardData])
	useEffect(processLiveDashboard, [liveData])

	const viewProps: IViewProps = {
		selectedDates,
		handleSetDates,
		tableColumns,
		tableRows,
		dashboardStatus,
		liveStatus,
		centerMap,
		markersMap,
		graphicBarData,
		graphicPieData,
		totalExams,
		neverHadMammogram,
		handleShowDates,
		zoomMap
	}

	return createElement(DashboardPage, viewProps)
}
export default DashboardPageContainer
