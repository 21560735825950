import React, { FormEvent } from 'react'
import { Button, Loading, Modal } from '@buildbox/components'
import {
	colors,
	primaryButtonStyle,
	primaryInputStyle
} from 'shared/styles/theme'
import InputPassword from '../InputPassword'
import { ModalContainer } from './styles'
import { IAuthenticationModalProps } from './types'

function AuthModal(props: IAuthenticationModalProps) {
	const {
		value,
		password,
		handleSubmit,
		handleClose,
		handlePasswordChange,
		loadingSubmit
	} = props

	function handleAuthentication(event: FormEvent) {
		event.preventDefault()

		handleSubmit()
	}

	return (
		<Modal
			className='modal-primary'
			size={376}
			title='Assinatura Eletrônica'
			isActive={value}
			onClose={handleClose}
			scrollRef='scroll-ref'
		>
			<ModalContainer onSubmit={handleAuthentication}>
				<InputPassword
					id='password'
					label='Senha'
					onChange={handlePasswordChange}
					value={password.trim()}
					{...primaryInputStyle}
				/>

				{!loadingSubmit ? (
					<Button
						className='button'
						type='submit'
						disabled={!password}
						{...primaryButtonStyle}
					>
						Confirmar
					</Button>
				) : (
					<Loading type='Sentry' primaryColor={colors.secondary} />
				)}
			</ModalContainer>
		</Modal>
	)
}

export default AuthModal
