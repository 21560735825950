import { GlobalStyle } from './shared/styles/globalStyle'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './shared/store'
import { Provider, useDispatch } from 'react-redux'
import { theme } from './shared/styles/theme'
import { ThemeProvider } from 'styled-components'
import React, { StrictMode, useEffect } from 'react'
import Routes from './modules/Routes'

import '@buildbox/components/lib/index.css'
import { LoadScript } from '@react-google-maps/api'

import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 2,
			refetchOnWindowFocus: true,
			refetchOnMount: true,
			refetchOnReconnect: true,
			refetchIntervalInBackground: false
		}
	}
})

function App() {
	return (
		<StrictMode>
			<GlobalStyle />
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<ThemeProvider theme={theme}>
						<LoadScript
							googleMapsApiKey='AIzaSyDKFna64XbvPLXruuv0nJxe0l-iC85BdSE'
							loadingElement={() => null}
						>
							<QueryClientProvider client={queryClient}>
								<Routes />
							</QueryClientProvider>
						</LoadScript>
					</ThemeProvider>
				</PersistGate>
			</Provider>
		</StrictMode>
	)
}

export default App
