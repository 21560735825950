import { ChangeEvent, createElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { IViewProps } from './types'
import ForgotPassword from './view'
import routesEnum from '../Routes/enum'
import { logout } from 'shared/util/session'

import cogoToast from 'cogo-toast'
import cogoDefaultOptions from 'shared/util/toaster'
import { successMessages } from 'shared/util/Messages'
import { UtilValidators } from '@buildbox/utils'
import { requestChangePassword } from 'shared/services/auth.service'

function ForgotPasswordContainer(): JSX.Element {
	const history = useHistory()
	const { checkEmail } = UtilValidators
	const [email, setEmail] = useState<string>('')
	const [isFormValid, setIsFormValid] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	async function handleRecoverPassword() {
		try {
			setIsLoading(true)

			await requestChangePassword({ email })

			cogoToast.success(
				`${successMessages.passwordResetEmailSent} ${email}`,
				cogoDefaultOptions
			)
			history.push(routesEnum.LOGIN)
		} catch (error) {
		} finally {
			setIsLoading(false)
		}
	}

	function handleEmail(event: ChangeEvent<HTMLInputElement>) {
		const { value } = event.target

		setEmail(value.trim())
	}

	useEffect(() => {
		const isEmailValid = checkEmail(email)

		setIsFormValid(isEmailValid)
	}, [checkEmail, email])

	useEffect(() => {
		logout()
	}, [])

	const viewProps: IViewProps = {
		handleRecoverPassword,
		handleEmail,
		email,
		isFormValid,
		isLoading
	}

	return createElement(ForgotPassword, viewProps)
}

export default ForgotPasswordContainer
