import { transition } from 'shared/styles/functions'
import styled from 'styled-components'

export const MenuHeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	.menu-header {
		display: flex;
		justify-content: 'space-between';
	}

	.menu-content {
		display: flex;
		justify-content: space-between;
		width: 94%;
	}

	.checkbox-level1 {
		margin-left: 16px;
	}
	.checkbox-level2 {
		margin-left: 32px;
	}
	.checkbox-level3 {
		margin-left: 48px;
	}
	.checkbox-level4 {
		margin-left: 64px;
	}

	.active {
		transform: rotate(181deg);
		${transition()};
	}

	p {
		margin-left: 12px;
	}

	.expand-chevron {
		cursor: pointer;
		position: absolute;
		right: 20px;
	}
`
