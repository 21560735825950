import React, { createElement, useEffect, useState } from 'react'
import { TableNavProps } from '@buildbox/components'
import { useDebounce } from 'use-debounce/lib'
import { IAcessorAndOrder } from 'shared/components/Table/types'

import { getExcel, useKitsPage } from 'shared/services/kit.service'
import KitsPage from './view'
import routesEnum from 'modules/Routes/enum'
import { useHistory } from 'react-router-dom'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { transformMenu } from 'shared/util/format'
import {
	ITableRow,
	INITIAL_SORT,
	ITableColumn,
	IViewProps,
	IKitsPage,
	ScreenActionsEnum
} from './types'
import { IKitPaginated } from 'shared/interfaces/kit'
import { renderAssociated, renderEditIcon } from './util'

const ITEMS_PER_PAGE = 10

function KitsPageContainer(): JSX.Element {
	const history = useHistory()

	const [kitsPage, setKitsPage] = useState<IKitsPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0
	})

	const [search, setSearch] = useState('')
	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [sortBy, setSortBy] = useState<IAcessorAndOrder>(INITIAL_SORT)
	const [searchDebounce] = useDebounce(search, 1000)
	const { filter, profile } = useTypedSelector(['filter', 'profile'])

	const onlySelected = transformMenu(filter)
	const { status: kitsStatus, data: kitsData } = useKitsPage({
		page: kitsPage.page,
		search: searchDebounce,
		limit: ITEMS_PER_PAGE,
		filters: onlySelected,
		order: sortBy.order,
		sort: sortBy.acessor,
		profileID: profile.profileId
	})

	function handleSearch(value: string): void {
		setSearch(value)
	}

	function activateScreenUser(action: ScreenActionsEnum, id: string) {
		history.push({
			pathname: routesEnum.KITS_CREATE_AND_UPDATE,
			state: {
				action,
				kitId: id
			}
		})
	}

	function handleCreateKit() {
		activateScreenUser('CREATE', '')
	}

	function handlerSortColumn(acessor: string, order: number): any {
		if (order === 0) {
			setSortBy(INITIAL_SORT)
			return
		}
		setSortBy({
			acessor,
			order
		})
	}
	function handleExportExcel() {
		;(async () => {
			try {
				await getExcel()
			} catch (error) {}
		})()
	}

	function handleParseData() {
		if (!kitsData || kitsStatus === 'loading') return

		const rows =
			kitsData.docs.map((kit: IKitPaginated) => {
				return {
					deviceID: kit.deviceID,
					deviceModel: kit.deviceModel,
					simCardID: kit.simCardID,
					cameraType: kit?.cameraType || '',
					contract: kit?.contract?.name ? kit.contract.name : '',
					unit: kit?.unit?.name ? kit.unit.name : '',
					activatedAt: renderAssociated(kit),
					editIcon: renderEditIcon(kit._id, activateScreenUser)
				}
			}) || []

		setTableRows(rows)
		setKitsPage({
			numberOfPages: kitsData.totalPages,
			page: kitsData.page || 1,
			totalDocs: kitsData.totalDocs
		})
	}
	useEffect(handleParseData, [kitsData])

	function goToPage(pageIndex: number) {
		setKitsPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const navProps: TableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: kitsPage.numberOfPages,
		pageIndex: kitsPage.page - 1,
		totalDocs: kitsPage.totalDocs
	}

	const tableColumns: ITableColumn[] = [
		{
			Header: 'UID',
			accessor: 'deviceID',
			sortType: 'basic'
		},
		{
			Header: 'Modelo',
			accessor: 'deviceModel',
			sortType: 'basic'
		},
		{
			Header: 'SIM CARD',
			accessor: 'simCardID',
			sortType: 'basic'
		},
		{
			Header: 'Tipo de Câmera',
			accessor: 'cameraType',
			sortType: 'basic'
		},
		{
			Header: 'Contrato',
			accessor: 'contract',
			disableSortBy: true
		},

		{
			Header: 'Unidade',
			accessor: 'unit',
			disableSortBy: true
		},

		{
			Header: 'Associação',
			accessor: 'activatedAt',
			sortType: 'basic'
		},
		{
			Header: '',
			accessor: 'editIcon',
			disableSortBy: true
		}
	]

	const viewProps: IViewProps = {
		tableColumns,
		tableRows,
		navProps,
		itemsPerPage: ITEMS_PER_PAGE,
		handleSearch,
		search,
		handlerSortColumn,
		sortBy,
		handleCreateKit,
		kitsStatus,
		handleExportExcel
	}

	return createElement(KitsPage, viewProps)
}
export default KitsPageContainer
