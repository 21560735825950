import { TableNavProps } from '@buildbox/components'
import { QueryStatus } from 'react-query'
import { IAcessorAndOrder } from 'shared/components/Table/types'

export interface IViewProps {
	showNewButton: boolean
	tableColumns: ITableColumn[]
	tableRows: ITableRow[]
	navProps: TableNavProps
	itemsPerPage: number
	handleSearch(value: string): void
	search: string
	handlerSortColumn(acessor: string, order: number): IAcessorAndOrder
	sortBy: IAcessorAndOrder
	handleCreateUnit: () => void
	unitsStatus: QueryStatus
}

export const INITIAL_SORT: IAcessorAndOrder = {
	acessor: 'createdAt',
	order: -1
}

export interface ITableColumn {}

export interface ITableRow {
	name: string
	contract: string
	operators: number
	kits: number
	editIcon: JSX.Element
}

export enum ScreenActions {
	CREATE,
	EDIT
}

export type ScreenActionsEnum = keyof typeof ScreenActions
export const ScreenActionsValues: ScreenActionsEnum[] = ['CREATE', 'EDIT']
