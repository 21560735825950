import styled from 'styled-components'

export const InputCalendarWrapper = styled.div`
	position: relative;

	input {
		text-transform: capitalize;
	}

	.icon-calendar {
		position: absolute;
		right: 20px;
		top: 16px;
	}
`
