import styled from 'styled-components'
import { fontType, mediaQuery, toRem } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'

export const KitWrapper = styled.div`
	.btn button {
		text-transform: uppercase;
	}

	.btn-clean {
		cursor: pointer;
		background: transparent;
		border: none;
	}

	.btn-remove {
		${fontType(2)};
		color: ${colors.darkGrey};
		text-decoration: underline;
		text-transform: uppercase;
		font-size: ${toRem(10)};
	}

	.btn-save {
		margin-top: ${toRem(60)};
		display: flex;
		justify-content: flex-end;
		align-items: center;
		text-transform: uppercase;

		.btn-remove {
			margin-right: ${toRem(16)};
		}

		.btn-disassociate {
			button {
				border: 2px solid ${colors.darkGrey};
			}
		}
	}

	.box-modelKit {
		display: flex;
		max-width: ${toRem(600)};
		background: ${colors.backgroundCheckBox};
		padding: ${toRem(20)};
		border-left: 2px solid ${colors.secondary};
		margin: ${toRem(40)} 0;
		justify-content: space-between;
		border-radius: 2px;

		strong {
			${fontType(2)};
			color: ${colors.kitBoxBackground};
			font-size: ${toRem(10)};
			text-transform: uppercase;
		}

		p {
			${fontType(0)};
			font-size: 14px;
			color: ${colors.darkGrey};
		}

		${mediaQuery('xs')(`
       		display: flex;
			flex-direction: column;
			gap: 1rem;
		`)};
	}

	.box-contracts-units {
		.title {
			border-bottom: 1px solid ${colors.backgroundMenu};
			margin-bottom: ${toRem(30)};

			h2 {
				${fontType(0)};
				font-size: 16px;
				margin-bottom: ${toRem(5)};
				color: ${colors.darkGrey};
			}
		}

		.box-associated {
			${fontType(0)};
			font-size: ${toRem(12)};
			display: flex;
			align-items: center;
			border-radius: 2px;
			background-color: ${colors.backgroundResultDiagnosis};
			padding: 13px 16px;
			margin-bottom: ${toRem(16)};

			img {
				margin-right: ${toRem(10)};
			}

			figure {
				color: ${colors.darkGrey};
			}
		}
	}
`

export const LoadingContainer = styled.div`
	display: flex;
	justify-content: center;
`
