import { fontType, toRem } from '../../styles/functions'
import styled from 'styled-components'

export const ViewErrosWrapper = styled.div`
	.view-errors {
		.error-text {
			font-size: ${toRem(14)};
			color: ${({ theme }) => theme.colors.primary};
			${fontType(0)};
			margin-bottom: 8px;
		}
	}
`
