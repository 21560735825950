import { useQuery, UseQueryResult } from 'react-query'
import {
	IFilter,
	PaginatedRequest,
	PaginatedResult
} from 'shared/interfaces/pagination'
import {
	IDeleteUser,
	IUser,
	IUserResponsePaginated,
	UserProfile
} from 'shared/interfaces/user'
import { QUERY_KEYS } from 'shared/util/reactQuery'
import axiosInstance from './axiosInstance'

export interface IUserRequest {
	name: string
	personalFiscalID: string
	email: string
	crm?: string
	state?: string
	profiles: UserProfile[]
}

export async function createUser(user: IUserRequest): Promise<IUser> {
	const response = await axiosInstance.post(`/users/`, user)

	return response.data
}

export interface IUpdateUser {
	profiles: UserProfile[]
}
// export interface IUpdateUserRequest {
// 	profile: string
// 	contracts: string[]
// 	units: string[]
// }

export async function updateUserProfile(
	id: string,
	profiles: IUpdateUser,
	email: string,
	password: string
): Promise<IUserRequest> {
	const response = await axiosInstance.patch(
		`/users/profiles/${id}/`,
		profiles,
		{
			headers: {
				EAuth: email,
				PAuth: password
			}
		}
	)

	return response.data
}

interface AuthSettingsProps {}

export async function updateUser(
	id: string,
	name: string,
	email: string,
	password: string,
	crm?: string,
	state?: string
): Promise<IUserRequest> {
	const response = await axiosInstance.patch(
		`/users/${id}/`,
		{
			name: name,
			crm: crm,
			state: state
		},
		{
			headers: {
				EAuth: email,
				PAuth: password
			}
		}
	)

	return response.data
}

export async function deleteUser(
	_id: string,
	email: string,
	password: string
): Promise<IDeleteUser> {
	const response = await axiosInstance.delete(`/users/${_id}`, {
		headers: {
			EAuth: email,
			PAuth: password
		}
	})

	return response.data
}

export async function findUser(id: string): Promise<IUser> {
	const response = await axiosInstance.get(`/users/${id}`)

	return response.data
}

interface IUserAlreadyExistsRequest {
	personalFiscalID?: string
	email?: string
}

export async function userAlreadyExists(
	payload: IUserAlreadyExistsRequest
): Promise<IUser> {
	const response = await axiosInstance.post(`/users/user-exists`, payload)

	return response.data
}

export interface getUserProfilesRequest {
	userId: string
}

export type getUserProfilesResponse = UserProfile[]

async function getUserProfiles({
	userId
}: getUserProfilesRequest): Promise<getUserProfilesResponse> {
	const response = await axiosInstance.get(`/users/profiles/${userId}`)

	return response.data
}

export function useUserProfiles({
	userId
}: getUserProfilesRequest): UseQueryResult<getUserProfilesResponse> {
	const enabled = !!userId

	return useQuery(
		[QUERY_KEYS.USER_PROFILES, { userId }],
		async ({ queryKey }) => {
			const [_key, params] = queryKey
			const { userId } = params as getUserProfilesRequest

			return getUserProfiles({ userId })
		},
		{
			enabled,
			refetchInterval: 60000,

			/**
			 * This will make the refetch occurr only when triggers
			 * the interval, focus or online and the data is not stale anymore.
			 * obs: When the interval is triggered the stale is ignored.
			 */
			staleTime: 60000
		}
	)
}

export type IFetchUsersRequest = PaginatedRequest<{
	profileID: string
}>

export type IFetchUsersResponse = PaginatedResult<IUserResponsePaginated>

export async function fetchUsersPage({
	page,
	limit,
	search,
	sort,
	order,
	filters,
	profileID
}: IFetchUsersRequest): Promise<IFetchUsersResponse> {
	// The server call this pageIndex but actually it's the page number
	const response = await axiosInstance.post('/users/get-page', {
		pageIndex: page,
		maxItemsInPage: limit,
		searchString: search,
		sort,
		order,
		filters,
		profileID
	})

	return response.data
}

export function useUsersPage({
	page,
	limit,
	search,
	sort,
	order,
	filters,
	profileID
}: IFetchUsersRequest): UseQueryResult<IFetchUsersResponse> {
	const enabled = !!(page && limit && filters && profileID)

	return useQuery(
		[
			QUERY_KEYS.USERS_PAGE,
			{ page, limit, search, sort, order, filters, profileID }
		],
		async ({ queryKey }) => {
			const [_key, params] = queryKey
			const { page, limit, search, sort, order, filters, profileID } =
				params as IFetchUsersRequest

			return fetchUsersPage({
				page,
				limit,
				search,
				sort,
				order,
				filters,
				profileID
			})
		},
		{
			enabled
		}
	)
}
