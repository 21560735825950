import { colors } from 'shared/styles/theme'
import styled from 'styled-components'
import { fontType, transition, toRem } from '../../styles/functions'

export const DayPickerWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 200px;

	${fontType(0)};

	.date-picker-primary {
		position: relative;
		background-color: ${colors.white};
		width: 100%;

		.label {
			position: absolute;
			left: 12px;
			top: 12px;
			color: ${colors.placeholder};
			font-size: 10px;
		}

		.icon-calendar {
			position: absolute;
			right: 20px;
			top: 16px;
		}

		.DayPickerInput {
			width: 100%;
			input {
				width: 100%;
				border-radius: 2px;
				background: none;
				border: 1px solid #d1d1d1;
				padding: 27px 32px 12px 12px;
				color: ${({ theme }) => theme.colors.primary};
				font-size: ${toRem(12)};

				${transition(0)}
				${fontType(0)}

				letter-spacing: -0.3px;

				&::placeholder {
					color: ${({ theme }) => theme.colors.secondary};
				}
			}
		}
	}

	.DayPicker-Day--today {
		color: red !important;
	}

	.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
		background-color: ${colors.lightGrey} !important;
		color: #e61c95 !important;
	}

	.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
		background-color: lighten($green, 30%) !important;
		color: #f7b1db !important;
	}
	.DayPicker-Day {
		border-radius: 0 !important;
	}
	.DayPicker-Day--start {
		border-top-left-radius: 48% !important;
		border-bottom-left-radius: 48% !important;
	}
	.DayPicker-Day--end {
		border-top-right-radius: 48% !important;
		border-bottom-right-radius: 48% !important;
	}
`
