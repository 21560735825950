import { toRem } from '../../styles/functions'
import { colors } from '../../styles/theme'
import styled from 'styled-components'

const lockDefaultStyle = `
	position: absolute;
	right: 16px;
	top: 16px;
	width: ${toRem(16)};
	height: ${toRem(16)};
	cursor: pointer;
	color: ${colors.grey};
	transition: 
`

export const InputPasswordWrapper = styled.div`
	position: relative;
	width: 100%;

	input {
		padding: 26px 50px 10px 16px;
	}

	.at-icon {
		${lockDefaultStyle};
	}

	.lock-icon {
		${lockDefaultStyle};
	}
`
