import React from 'react'
import { HeaderPage } from 'shared/components/HeaderPage'
import Table from 'shared/components/Table'
import { tableStyle } from 'shared/styles/theme'
import { UsersListWrapper } from './styles'
import { IViewProps } from './types'

function UsersPage(props: IViewProps): JSX.Element {
	const {
		navProps,
		tableColumns,
		tableRows,
		usersPerPage,
		handleSearch,
		search,
		handlerSortColumn,
		sortBy,
		handleCreateUser,
		usersStatus
	} = props
	return (
		<UsersListWrapper className='animated-left'>
			<HeaderPage
				labelInput={'Buscar usuário'}
				titleButton={'Novo usuário'}
				title={'Usuários'}
				handleSearch={handleSearch}
				handleSubmit={handleCreateUser}
				search={search}
				// isSearch={false}
			/>

			<div className='tableContainer'>
				<Table
					// Workaround while we need to fix columns type on lib
					columns={tableColumns as any}
					data={tableRows}
					navProps={navProps}
					pageSize={usersPerPage}
					{...tableStyle}
					padding={[26, 16, 26, 16]}
					getSelectedColumnAcessorAndOrder={handlerSortColumn}
					noItensText='No momento, não há Usuários para serem exibidos.'
					sortBy={sortBy}
					isLoading={usersStatus === 'loading'}
				/>
			</div>
		</UsersListWrapper>
	)
}
export default UsersPage
