export const QUERY_KEYS = {
	FILTER_OPTIONS: 'FILTER_OPTIONS',
	USER_PROFILES: 'USER_PROFILES',
	DASHBOARD: 'DASHBOARD',
	LIVE_DASHBOARD: 'LIVE_DASHBOARD',
	EXAMS_PAGE: 'EXAMS_PAGE',
	USERS_PAGE: 'USERS_PAGE',
	UNITS_PAGE: 'UNITS_PAGE',
	CONTRACTS_PAGE: 'CONTRACTS_PAGE',
	KITS_PAGE: 'KITS_PAGE',
	FINANCIAL_PAGE: 'FINANCIAL_PAGE'
}
