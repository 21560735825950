export function validateDuration(duration: number) {
	if (duration < 1) return false

	return true
}

const IS_VALID_PASSWORD = /^(?=.*\d)(?=.*[a-zA-Z]).{6,20}$/

export function checkPassword(password: string) {
	password = password.trim()
	const isValidPassword = IS_VALID_PASSWORD.test(password)
	return password.length !== 0 && isValidPassword
}

export function hasNumber(text: string) {
	return /\d/.test(text)
}

export function hasLetter(text: string) {
	return /[a-zA-Z]/g.test(text)
}
