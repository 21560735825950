import React from 'react'
import { HeaderPage } from 'shared/components/HeaderPage'
import Table from 'shared/components/Table'
import { tableStyle } from 'shared/styles/theme'

import { UnitsListWrapper } from './styles'
import { IViewProps } from './types'

function UnitsPage(props: IViewProps): JSX.Element {
	const {
		showNewButton,
		tableColumns,
		tableRows,
		navProps,
		itemsPerPage,
		handleSearch,
		search,
		handlerSortColumn,
		sortBy,
		handleCreateUnit,
		unitsStatus
	} = props

	return (
		<UnitsListWrapper className='animated-left'>
			<HeaderPage
				labelInput={'Buscar unidade'}
				titleButton={'Nova unidade'}
				title={'Unidades de Atendimento'}
				handleSearch={handleSearch}
				handleSubmit={handleCreateUnit}
				showButton={showNewButton}
				search={search}
				// isSearch={false}
			/>

			<div className='tableContainer'>
				<Table
					// Workaround while we need to fix columns type on lib
					columns={tableColumns as any}
					data={tableRows}
					navProps={navProps}
					pageSize={itemsPerPage}
					{...tableStyle}
					padding={[26, 16, 26, 16]}
					getSelectedColumnAcessorAndOrder={handlerSortColumn}
					noItensText='No momento, não há Unidades de Atendimento para serem exibidas.'
					sortBy={sortBy}
					isLoading={unitsStatus === 'loading'}
				/>
			</div>
		</UnitsListWrapper>
	)
}
export default UnitsPage
