import styled from 'styled-components'

export const CompositeMenuWrapper = styled.div`
	position: absolute;
	z-index: 20;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 2px 3px #00000014;
	align-items: center;
	overflow-y: auto;
	padding: 16px;
`

export const InputWrapper = styled.div`
	margin-top: 8px;
	margin-bottom: 8px;
`
