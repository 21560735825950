import React from 'react'
import { CheckBox } from '@buildbox/components'

import { IProps } from './types'
import { MenuOptionWrapper } from './styles'

export default function MenuOption({
	id,
	label,
	selected,
	onSelect,
	styles,
	renderSwitch
}: IProps): JSX.Element {
	// const [checked, setChecked] = useState(selected)

	function toggleChecked(event: any) {
		const checkedUpdated = !selected
		// setChecked(checkedUpdated)
		onSelect(id, checkedUpdated)
	}

	function getCssClassName(checkboxLevel: number): string {
		return `checkbox-level${checkboxLevel}`
	}

	// useEffect(() => setChecked(selected), [selected])

	const stylesCheckBox = {
		display: 'flex',
		alignItems: 'center'
	}
	return (
		<MenuOptionWrapper style={styles}>
			<div style={stylesCheckBox}>
				<CheckBox
					fontSize={styles.checkboxSize}
					primaryColor={styles.checkboxPrimaryColor}
					secondaryColor={styles.checkboxSecondaryColor}
					backgroundColor={styles.checkboxBackgroundColor}
					className={getCssClassName(styles.checkboxLevel)}
					checked={selected}
					onChange={toggleChecked}
				/>
				<p>{label}</p>
			</div>
			{renderSwitch ? renderSwitch : null}
		</MenuOptionWrapper>
	)
}
