import styled from 'styled-components'
import {
	fontType,
	mediaQuery,
	toRem,
	transition
} from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'

export const UserWrapper = styled.div`
	margin-bottom: ${toRem(200)};

	.row {
		margin-top: ${toRem(40)};
		margin-bottom: ${toRem(34)};
	}
	.btn-next {
		text-align: end;
	}

	.btn button {
		text-transform: uppercase;
	}

	.error-text {
		margin-top: 4px;
		font-size: 0.625rem;
		color: rgb(255, 51, 51);
		${fontType(0)}
	}

	.access-profile {
		.box-access {
			position: relative;
			.ewNqUx {
				/* position: absolute; */
				/* width: 98% !important; */

				> div {
				}
			}
		}
		.select-contracts {
			svg:first-child {
				display: none;
			}
		}
		.selected-container {
			position: relative;

			.chevron-icon {
				position: absolute;
				right: 20px;
				top: 16px;
			}
			.active {
				transform: rotate(181deg);
				${transition()};
			}
		}
		.row {
			background: ${colors.white};
			border-radius: 2px;
			padding: 32px 24px;
		}

		.access-profile-div {
			border-bottom: 1px solid ${colors.backgroundMenu};
			margin-bottom: ${toRem(30)};
		}

		h2 {
			${fontType(0)};
			font-size: ${toRem(16)};
			color: ${colors.darkGrey};
			margin-bottom: ${toRem(10)};
		}

		h3 {
			${fontType(0)};
			font-size: ${toRem(20)};
			color: ${colors.darkGrey};
			margin-bottom: ${toRem(10)};
		}

		.btn-save {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			text-transform: uppercase;
		}

		.btn-clean {
			cursor: pointer;
			background: transparent;
			border: none;
		}
		.btn-list {
			position: absolute;
			right: ${toRem(-80)};
			top: 42%;
		}

		.btn-plus {
			display: flex;
			justify-content: center;
		}

		.btn-remove {
			${fontType(2)};
			color: ${colors.darkGrey};
			margin-right: ${toRem(20)};
			text-decoration: underline;
			text-transform: uppercase;
			font-size: ${toRem(10)};
		}

		${mediaQuery('md')(`
            .btn-list {
                right: ${toRem(-27)};
            }
        `)}
	}
`

export const LoadingContent = styled.section`
	display: flex;
	justify-content: center;
`
