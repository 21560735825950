import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { SwitchWrapper } from './styles'
import { IPropsView } from './types'

export function SwitchMenu(props: IPropsView) {
	const { label, checked: isChecked, setCheched } = props

	const [checkboxState, setCheckBoxState] = useState(isChecked)

	function handleCheckBoxState() {
		setCheckBoxState(!checkboxState)
		setCheched(!checkboxState)
	}

	useEffect(() => {
		setCheckBoxState(isChecked)
	}, [isChecked])
	return (
		<SwitchWrapper data-tip={label} data-for={label}>
			<label className='switch'>
				<input
					type='checkbox'
					checked={checkboxState}
					onChange={handleCheckBoxState}
					// {...rest}
				/>
				<span className='slider round' />
			</label>

			<ReactTooltip id={label} type='dark' isCapture={true} />
		</SwitchWrapper>
	)
}
