import React from 'react'
import { isValid, parseISO } from 'date-fns'
import {
	KitInfoContainer,
	KitLogContainer,
	IntervalsContainer
} from '../styles'
import { IViewProps } from './types'
import { translateStatus } from 'shared/util/translate'
import { toMoney } from 'shared/util/currency'
import { formatDate } from 'shared/util/format'
import { IKitIntervals } from 'shared/interfaces/financial'
import { UtilGenerators } from '@buildbox/utils'

export function KitInfo(props: IViewProps): JSX.Element {
	const { logs } = props
	function returnInfoText(title: string, subtitle: string | number) {
		return (
			<div>
				<strong>{title}</strong>
				<p>{subtitle}</p>
			</div>
		)
	}

	function returnLogText(subtitle: string | number) {
		return <p>{subtitle}</p>
	}

	function textPeriod(start: Date, end?: Date) {
		const isValidDate = isValid(end)
		return (
			<div className='period'>
				<strong>Intervalo de Ativação</strong>
				<p>
					{formatDate(start)}
					{isValidDate && end ? ` até ${formatDate(end)}` : ' até Agora'}
				</p>
			</div>
		)
	}

	function renderIntervals(kitIntervals: IKitIntervals[]) {
		return (
			<IntervalsContainer>
				{kitIntervals.map((intervals) => (
					<KitLogContainer key={UtilGenerators.generateRandomSerial(5)}>
						{textPeriod(
							parseISO(String(intervals.startDate)),
							parseISO(String(intervals?.endDate))
						)}

						{returnInfoText('Dias no Período', intervals.daysToCharge)}
						{returnInfoText('Valor Por Dia', toMoney(intervals.valuePerDay))}
						{returnLogText(toMoney(intervals.proportionalValueToCharge))}
					</KitLogContainer>
				))}
			</IntervalsContainer>
		)
	}

	return (
		<section>
			{logs.map((log) => (
				<React.Fragment
					key={UtilGenerators.generateRandomSerial(5) + log.deviceID}
				>
					<KitInfoContainer>
						{returnInfoText('UID KIT', log.deviceID)}
						{returnInfoText('STATUS ATUAL', translateStatus[log.status])}
						{returnInfoText('VALOR TOTAL', toMoney(log.totalSubscriptions))}
					</KitInfoContainer>

					{log.kitIntervals.length ? renderIntervals(log.kitIntervals) : null}
				</React.Fragment>
			))}
		</section>
	)
}
