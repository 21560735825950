import styled from 'styled-components'
import { fontType, toRem } from '../../shared/styles/functions'

export const UsersListWrapper = styled.div`
	button {
		cursor: pointer;
	}
	.tableContainer {
		margin-top: ${toRem(50)};
		${fontType(0)}

		.icon {
			border: none;
			display: flex;
			margin: auto;
			background: transparent;
			min-width: 16px;
		}

		.unit {
			${fontType(0)};
		}
		.role {
			display: flex;
			max-width: ${toRem(200)};
			flex-wrap: wrap;
			.role-container {
				background: #dddbdf;
				border-radius: ${toRem(10)};
				padding: ${toRem(3)} ${toRem(8)};
				margin-right: ${toRem(10)};
				margin-bottom: 2px;

				span {
					font-size: ${toRem(8)};
					text-transform: uppercase;
					${fontType(2)}
				}
			}
		}
	}
`
