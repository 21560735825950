import { IError } from '../interfaces/error'
import cogoDefaultOptions from './toaster'
import cogoToast from 'cogo-toast'
import { logout } from './session'
import { errorMessages } from './Messages'

function handleError(error: IError): void {
	console.log({ error })

	if (!error?.message) {
		cogoToast.error(errorMessages.serverUnavailable, cogoDefaultOptions)
	} else if (error.statusCode === 401) {
		cogoToast.error(error.message, cogoDefaultOptions)
		logout()
	} else if (!/^5\d{2}/.test(error.statusCode.toString())) {
		cogoToast.error(error.message, cogoDefaultOptions)
	}
}

export default handleError
