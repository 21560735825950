import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Creators as ProfileActions } from 'shared/store/ducks/profile'

import { logout } from 'shared/util/session'
import routesEnum from 'modules/Routes/enum'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { IRouteData } from 'modules/Routes/types'
import { getRouteAccessTypes } from 'modules/Routes/data'
import { IProfile } from 'shared/interfaces/profile'
import { UserProfile } from 'shared/interfaces/user'
import { IProps } from './types'
import { SideNavWrapper } from './styles'

export default function SideNav({
	isOpen,
	handleSetIsOpen,
	routes,
	name = ''
}: IProps): JSX.Element {
	const history = useHistory()
	const { user, profile } = useTypedSelector(['user', 'profile'])
	const dispatch = useDispatch()

	function Redirect() {
		const { LOGIN } = routesEnum

		logout()
		history.replace(LOGIN)
	}

	function listAllowedMenus(): IRouteData[] {
		const menus = routes.filter(({ accessTypes, isHidden, noWrapper }) => {
			if (!!!accessTypes || isHidden || noWrapper) return false

			const roles = user.permissions as any

			return roles.includes(accessTypes)
		})

		return menus
	}

	/*
	function checkAndSetProfile(routePath: string): boolean {
		if (hasProfileAccessToRoute(profile.profileId, routePath)) return true
		const profileWithAccess = findFirstProfileWithAccess(routePath)
		if (!profileWithAccess) return false
		dispatch(
			ProfileActions.setProfile({
				profileId: (profileWithAccess.profile as IProfile)._id
			})
		)

		return true
	}

	function findFirstProfileWithAccess(
		routePath: string
	): UserProfile | undefined {
		const routeAccessType = getRouteAccessTypes(routePath)
		if (!routeAccessType) return undefined
		return user.profiles.find((userProfile) =>
			(userProfile.profile as IProfile).permissions.includes(routeAccessType)
		)
	}

	function hasProfileAccessToRoute(
		profileId: string,
		routePath: string
	): boolean {
		const pageAccessType = getRouteAccessTypes(routePath)
		if (!pageAccessType) return false

		const profile = user.profiles.find(
			(userProfile) => (userProfile.profile as IProfile)._id === profileId
		)
		if (!profile) return false

		return (profile.profile as IProfile).permissions.includes(pageAccessType)
	}
*/

	return (
		<SideNavWrapper className={isOpen ? 'active' : ''}>
			<div className='background' onClick={handleSetIsOpen} />
			<div className='menu-wrapper'>
				<div className='spacer' />
				<div className='nav-wrapper'>
					<nav>
						<div className='upper-part'>
							<ul className='nav-top'>
								<div className='profile'>
									{/* <div className='photo'>
										<img
											src={require('../../assets/images/logo-gpvc.svg')}
											alt='profile'
										/>
									</div> */}

									<div className='info'>
										<p className='name'>Olá, {user.name}</p>
										{/* <p className='profile-link'>
											Meu Perfil
										</p> */}
									</div>
								</div>
							</ul>
							<ul className='nav-mid'>
								{listAllowedMenus().map((route) => (
									<li key={route.name}>
										<NavLink
											className='nav-link'
											activeClassName='active'
											to={route.path}
										>
											<p
												className='nav-text'
												// This onClick treatment has to be nested inside
												// NavLink. Otherwise, it is executed after
												// navigation to selected page
												//onClick={() => checkAndSetProfile(route.path)}
											>
												{route.name}
											</p>
										</NavLink>
									</li>
								))}
							</ul>
						</div>

						<div className='bottom-part'>
							<ul className='nav-bottom'>
								<li onClick={Redirect}>Sair</li>
							</ul>
						</div>
					</nav>
				</div>
			</div>
		</SideNavWrapper>
	)
}
