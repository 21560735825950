import { CustomerType, CustomerTypeEnum } from 'shared/interfaces/contract'
import {
	BiopsyResultEnum,
	MammographyResult,
	SurgeryResultEnum
} from 'shared/interfaces/datasheet'
import { ExamDiagnosisEnum } from 'shared/interfaces/exam'
import { ProfileNameEnum } from 'shared/interfaces/user'

export const friendlyRole: { [key in ProfileNameEnum]: string } = {
	ADMIN: 'ADM Linda',
	FINANCE: 'FIN Linda',
	CONTRACT_ADMIN: 'ADM Contrato',
	UNIT_MANAGER: 'Gerente Un.',
	OPERATOR: 'Operador',
	PHYSICIAN: 'Médico',
	COMMERCIAL_PARTNER: 'Parceiro Comercial'
}

export const friendlyDiagnosis: { [key in ExamDiagnosisEnum]: string } = {
	SUSPICIOUS: 'Suspeito',
	NOT_SUSPICIOUS: 'Não Suspeito',
	NONE: 'Pendente'
}

export const friendlyDiagnosisForward: { [key in ExamDiagnosisEnum]: string } =
	{
		SUSPICIOUS: 'Encaminhada',
		NOT_SUSPICIOUS: 'Não Encaminhada',
		NONE: 'Pendente'
	}

export const friendlySurgery: { [key in SurgeryResultEnum]: string } = {
	DO_NOT_KNOW: 'Não sabe',
	AESTHETICS: 'Estética',
	PROSTHESIS: 'Protese',
	MALIGNANT_DISEASE: 'Doença Maligna',
	BENIGN_DISEASE: 'Doença Benigna'
}

export const friendlyCustomerType: { [key in CustomerTypeEnum]: string } = {
	PARTNERSHIP_CUSTOMER: 'Cliente Parceiro',
	COMMERCIAL_CUSTOMER: 'Cliente Comercial',
	ALL: 'Todos'
}

export const friendlyBiopsy: { [key in BiopsyResultEnum]: string } = {
	DO_NOT_KNOW: 'Não sabe',
	BENIGN: 'Benigno',
	MALIGNANT: 'Maligno',
	DO_NOT_KNOW_RESULT: 'Não lembra o resultado',
	NO: 'Não'
}

export const friendlyMammography: { [key in MammographyResult]: string } = {
	NORMAL: 'Normal',
	ABNORMAL: 'Anormal',
	DO_NOT_KNOW: 'Não sabe',
	DO_NOT_KNOW_RESULT: 'Não lembra o resultado',
	DO_NOT_REMEMBER: 'Não lembra'
}
