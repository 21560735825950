import { useQuery, UseQueryResult } from 'react-query'
import { IExam, IPhysicianDiagnoses } from 'shared/interfaces/exam'
import { PaginatedRequest, PaginatedResult } from 'shared/interfaces/pagination'

import { QUERY_KEYS } from 'shared/util/reactQuery'
import axiosInstance from './axiosInstance'

export async function getImageUrl(imageUrl: string): Promise<string> {
	const response = await axiosInstance.post(`exams/get-image`, {
		imageUrl
	})

	return response.data
}

export async function getExamById(examId: string): Promise<IExam> {
	const response = await axiosInstance.get(`exams/${examId}`)

	return response.data
}

export async function updateExam(
	examId: string,
	physicianDiagnoses: IPhysicianDiagnoses,
	auth: {
		email: string
		password: string
	}
): Promise<IExam> {
	const response = await axiosInstance.patch(
		`exams/${examId}/update-physician-diagnoses`,
		{
			observation: physicianDiagnoses.observation,
			diagnosis: physicianDiagnoses.diagnosis,
			physician: physicianDiagnoses.physician
		},
		{
			headers: {
				EAuth: auth.email,
				PAuth: auth.password
			}
		}
	)

	return response.data
}

export type IFetchExamsRequest = PaginatedRequest<{}>
export type IFetchExamsResponse = PaginatedResult<IExam>

export async function fetchExamsPage({
	page,
	limit,
	search,
	sort,
	order,
	filters
}: IFetchExamsRequest): Promise<IFetchExamsResponse> {
	// The server call this pageIndex but actually it's the page number
	const response = await axiosInstance.post('/exams/get-page', {
		pageIndex: page,
		maxItemsInPage: limit,
		searchString: search,
		sort,
		order,
		filters
	})

	return response.data
}

export function useExamsPage({
	page,
	limit,
	search,
	sort,
	order,
	filters
}: IFetchExamsRequest): UseQueryResult<IFetchExamsResponse> {
	const enabled = !!(page && limit && filters)

	return useQuery(
		[QUERY_KEYS.EXAMS_PAGE, { page, limit, search, sort, order, filters }],
		async ({ queryKey }) => {
			const [_key, params] = queryKey
			const { page, limit, search, sort, order, filters } =
				params as IFetchExamsRequest

			return fetchExamsPage({
				page,
				limit,
				search,
				sort,
				order,
				filters
			})
		},
		{
			enabled
		}
	)
}
