import { ISelectOption } from '@buildbox/components/lib/components/Select/types'
import { ScreenActionsEnum } from 'modules/UnitsPage/types'
import { IAuthenticationModalProps } from 'shared/components/AuthModal/types'
import { IModalDeleteTarget } from 'shared/components/ModalDelete/types'
import { IKit } from 'shared/interfaces/kit'

export interface IViewProps {
	action: ScreenActionsEnum
	targetKit: IModalDeleteTarget | null
	enableBtnSubmit: boolean
	viewErrors: string[]
	isLoading: boolean
	handleSubmit: () => void
	handleDeleteKitEnd: () => void
	currentKit: IKit
	contractsOptions: ISelectOption[]
	unitsOptions: ISelectOption[]
	renderModel: (title: string, value: string | number) => JSX.Element
	onDisassociateKit: () => void
	handleDeleteOnClose: () => void
	contractSelected: ISelectOption | null
	unitSelected: ISelectOption | null
	handleSelectChange: (selectedOption: any, id: string) => void
	kitIsActive: boolean
	renderButtonDelete(): JSX.Element | null
	authModalConfig: IAuthenticationModalProps
	handleSendSubmit(): void
}

export const INITIAL_DATA_KIT: IKit = {
	_id: '',
	contract: '',
	deviceID: '',
	deviceModel: '',
	simCardID: 0,
	status: 'PENDING',
	unit: ''
}

export const titleScreen = {
	CREATE: 'Cadastrar Kit',
	EDIT: 'Dados do Kit'
}

export const textButton = {
	CREATE: 'Cadastrar',
	EDIT: 'Associar Kit'
}
