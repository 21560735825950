import React, { useEffect, useState } from 'react'
import {
	Row,
	useExpanded,
	usePagination,
	useSortBy,
	useTable
} from 'react-table'
import { clone } from 'ramda'
import ReactTooltip from 'react-tooltip'
import { TableWrapper, LoadingContent } from './styles'
import {
	colors,
	primaryButtonStyle,
	primaryCheckBoxStyle
} from '../../styles/theme'
import { UtilGenerators } from '@buildbox/utils/lib/index'
import { Button, CheckBox, Loading } from '@buildbox/components'

import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import { IViewProps } from './types'
import {
	ITableColumn,
	ITableOptions
} from '@buildbox/components/lib/components/Table/types'
import { ITableInstance, ITableState } from '../Table/types'
import { ISelectCheckbox } from 'modules/FinancePage/types'
import CheckboxContract from './CheckboxContract'
import { toNumber } from 'shared/util/currency'
import { getExcel } from 'shared/services/financial.service'

import { FaDownload } from 'react-icons/fa'

function TableFinance<T extends object>(props: IViewProps<T>) {
	const {
		isLoading,
		className,
		navProps,
		noItensText,
		pageSize,
		data,
		columns,
		sortBy,
		getSelectedColumnAcessorAndOrder,
		periodSelected,
		total,
		handleModal,
		showPaginate = true,
		handleDowloadExamData,
		hiddenDowloadExam = false
	} = props

	const { generateRandomSerial } = UtilGenerators

	const tableOptions: ITableOptions<T> = {
		columns,
		data,
		paginateExpandedRows: false,
		manualSortBy: !!getSelectedColumnAcessorAndOrder
	}

	const tableInstance: ITableInstance<T> = useTable<T>(
		tableOptions,
		useSortBy,
		useExpanded,
		usePagination
	) as ITableInstance<T>

	let {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		page,
		state,
		gotoPage,
		previousPage,
		nextPage,
		canPreviousPage,
		canNextPage,
		pageCount,
		setPageSize,
	} = tableInstance

	tableInstance.autoResetExpanded = false

	const [allSelected, setAllSelected] = useState(true)
	const [partialSelected, setPartialSelected] = useState(false)
	const [fileSelected, setFileSelected] = useState<ISelectCheckbox[]>(() =>
		initialValue()
	)

	let { pageIndex } = state as ITableState<T>
	let totalDocs = rows.length

	if (navProps) {
		pageCount = navProps.pageCount
		pageIndex = navProps.pageIndex
		totalDocs = navProps.totalDocs
		canNextPage = !!navProps ? pageIndex + 1 < pageCount : canNextPage
		canPreviousPage = !!navProps ? pageIndex > 0 : canPreviousPage
	}

	const callPreviousPage = !!navProps ? navProps.previousPage : previousPage
	const callNextPage = !!navProps ? navProps.nextPage : nextPage
	const callGoToPage = !!navProps ? navProps.gotoPage : gotoPage

	const filterPages = (visiblePages: any[], totalPages: number) => {
		return visiblePages.filter((page: number) => page <= totalPages)
	}

	const getVisiblePages = (page: number, total: number) => {
		if (total < 7) {
			return filterPages([1, 2, 3, 4, 5, 6], total)
		} else {
			if (page % 5 >= 0 && page > 4 && page + 2 < total) {
				return [1, page - 1, page, page + 1, total]
			} else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
				return [1, total - 3, total - 2, total - 1, total]
			} else {
				return [1, 2, 3, 4, 5, total]
			}
		}
	}

	const formatPagination = (x: number, idx: number, arr: number[]) => {
		const { length } = arr

		const total = arr[length - 1]

		if (total <= 6) {
			return x
		} else if (length > 5) {
			return idx === 5 ? '...' + x : x
		} else {
			if (idx === 0) return x + '...'
			if (idx === 4 && arr[2] < total - 2) return '...' + x
			return x
		}
	}

	function getOrder(isSortedDesc: boolean | undefined) {
		return isSortedDesc === undefined ? -1 : isSortedDesc ? 1 : 0
	}

	function getNextOrder(isSortedDesc: boolean | undefined) {
		return isSortedDesc === undefined ? true : isSortedDesc ? false : undefined
	}

	function getOrderAndAcessor(id: string, isSortedDesc: any) {
		getSelectedColumnAcessorAndOrder &&
			getSelectedColumnAcessorAndOrder(id, isSortedDesc)
	}

	function openModal(id: string) {
		handleModal && handleModal(id)
	}

	function handleAllSelected() {
		setAllSelected(!allSelected)
		optionsAllSelected()
		setPartialSelected(false)
	}

	function optionsAllSelected() {
		fileSelected.forEach((option) => {
			option.checked = !allSelected
		})
	}

	function initialValue() {
		const filterColumns: ISelectCheckbox[] = rows.map((e) => {
			return { value: e.values.idContract, checked: true }
		})

		return filterColumns
	}

	function handleSelectValue(newValue: boolean, column: string) {
		const index = fileSelected.findIndex((e) => e.value === column)
		fileSelected[index].checked = newValue

		const isPartialSelected =
			[...new Set(fileSelected.map((x) => x.checked))].length === 2

		setFileSelected(fileSelected)
		setPartialSelected(isPartialSelected)
	}

	function renderCheckBox(value: string, index: number) {
		const hasSubrows = rows.find((e) => e.values?.idContract === value)?.subRows

		if (!hasSubrows) return null

		const isAllSelected = fileSelected.every((x) => x.checked)

		return (
			<CheckboxContract
				value={value}
				isSelected={allSelected || isAllSelected}
				onChange={handleSelectValue}
			/>
		)
	}

	function verifyCheckboxRules(): boolean {
		const isAllUnSelected = fileSelected.every((x) => !x.checked)

		if (isAllUnSelected) return false

		if (partialSelected) return false

		const isAllSelected = fileSelected.every((x) => x.checked)

		if (allSelected || isAllSelected) return true

		return false
	}

	useEffect(() => {
		if (!pageSize) return

		setPageSize(pageSize)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageSize])

	useEffect(() => {
		setFileSelected(initialValue)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rows])

	function formatToNumber(data: any[]) {
		return data.map((e) =>
			e.map((x: string | number, i: number) => {
				let formmated
				const values = i === 3 || i === 4 || i === 6 || i === 7 || i === 8

				if (values && typeof x === 'string') {
					formmated = toNumber(x)
				} else {
					formmated = x
				}

				return formmated
			})
		)
	}

	async function handleUploadFiles() {
		const getDataOnlySelected = fileSelected
			.filter((x) => x.checked)
			.map((e) => e.value)

		if (!getDataOnlySelected.length) {
			return
		}

		let columns: any[] = []

		const cloneRows = clone(rows)

		const header: string[] = headerGroups[0].headers
			.filter((x) => x.Header !== '')
			.map((title) => title.Header as string)

		const compareOnlySelected = cloneRows
			.filter((e) => getDataOnlySelected.includes(e.values.idContract))
			.map((items) => {
				delete items.values.idContract && delete items.values.expander
				return {
					contracts: items.values,
					units: items.subRows.map((y) => {
						delete y.values.idContract && delete y.values.expander
						return y.values
					})
				}
			})

		compareOnlySelected.map((x) => {
			return {
				Contrato: columns.push(['Contrato', ...Object.values(x.contracts)]),
				Unidade: x.units.map((unit) =>
					columns.push(['Unidade', ...Object.values(unit)])
				)
			}
		})

		const data = formatToNumber(columns)

		try {
			await getExcel(periodSelected, ['Tipo', ...header], data)
		} catch {}
	}

	function allIsUnSelected() {
		return fileSelected.every((e) => !e.checked)
	}

	function calcTotalActiveKits(row: Row<T>) {
		const activeKits = new Set<string>([])

		row.subRows.forEach((subRow: any) => {
			if (!!subRow.original.kitsActive) {
				subRow.original.kits.forEach((kit: any) => {
					if (kit.status === 'ACTIVE') {
						activeKits.add(kit.deviceID)
					}
				})
			}
		})

		return activeKits.size
	}

	function renderTableHeader(): JSX.Element {
		return (
			<div className='select-dowloads'>
				<div>
					<CheckBox
						type='checkbox'
						id='allSelected'
						name='select-files'
						onChange={handleAllSelected}
						checked={verifyCheckboxRules()}
						{...primaryCheckBoxStyle}
					/>

					<label htmlFor='allSelected'>Selecionar todos</label>
				</div>

				<Button
					className='btn'
					onClick={handleUploadFiles}
					disabled={allIsUnSelected()}
					{...primaryButtonStyle}
				>
					Exportar excel
				</Button>
			</div>
		)
	}

	function renderSectionHeader(): JSX.Element {
		return (
			<header className='header header-height'>
				{headerGroups.map((headerGroup: any, i) => (
					<React.Fragment key={i}>
						{headerGroup.headers.map((column: ITableColumn<T>) => {
							const getColumnHeaderProps = {
								onClick: () => {
									getSelectedColumnAcessorAndOrder &&
										getOrderAndAcessor(column.id, getOrder(column.isSortedDesc))

									const isDecending = getNextOrder(column.isSortedDesc)

									isDecending === undefined && column.clearSortBy()

									column.toggleSortBy && column.toggleSortBy(isDecending, false)
									isDecending === undefined && column.clearSortBy()
								}
							}

							return (
								<div
									{...column.getHeaderProps(column.getSortByToggleProps())}
									{...(getSelectedColumnAcessorAndOrder
										? getColumnHeaderProps
										: undefined)}
									key={i + `${generateRandomSerial(5)}`}
									className='data-report'
								>
									<p>{column.render('Header')}</p>

									<span>
										{sortBy &&
										column.id === sortBy?.acessor &&
										sortBy?.order !== 0
											? sortBy?.order === -1
												? '  ⇓'
												: '  ⇑'
											: ''}
									</span>
								</div>
							)
						})}
					</React.Fragment>
				))}
			</header>
		)
	}
	function renderSectionTotal(): JSX.Element {
		return (
			<div className='header total' style={{ height: 56 }}>
				{total.map((row, i) => {
					return (
						<div
							key={i}
							className='data-report box-company'
							style={{ background: '#f7eaf2' }}
						>
							<div data-tip={row.toString()} data-for={row.toString()}>
								<p>{row}</p>

								<ReactTooltip
									id={row.toString()}
									type='dark'
									isCapture={true}
								/>
							</div>
						</div>
					)
				})}
			</div>
		)
	}

	function renderSectionMain(): JSX.Element {
		return (
			<main {...getTableBodyProps()}>
				{page.map((row, i) => {
					prepareRow(row)

					const haveSubRows = row.subRows.length > 0
					const isSubRow = (row as any).depth > 0
					const totalActiveKits = !isSubRow ? calcTotalActiveKits(row) : 0

					return (
						<div
							{...row.getRowProps()}
							className={isSubRow ? 'header box-units' : 'header box-company'}
						>
							{row.cells.map((cell) => {
								return (
									<div
										data-tip={cell.value}
										data-for={cell.column.id}
										className='data-report'
										{...cell.getCellProps()}
									>
										{cell.column.id === 'idContract' ? (
											renderCheckBox(cell.value, i)
										) : cell.column.id === 'name' && !isSubRow ? (
											<div className='contract-name-wrapper'>
												<div className='contract-name'>
													<p>{cell.render('Cell')}</p>
													{!hiddenDowloadExam && (
														<button
															onClick={() =>
																handleDowloadExamData(
																	(cell.row.original as any)._id
																)
															}
														>
															<FaDownload color={colors.darkGrey} />
														</button>
													)}
												</div>
												<div className='badge-total-active-kits'>
													<p>KITS ATIVOS NO CONTRATO: {totalActiveKits}</p>
												</div>
											</div>
										) : cell.column.id === 'totalSubscriptions' && isSubRow ? (
											<div className='btn-open-modal'>
												<p>{cell.render('Cell')}</p>
												{(cell.row.original as any).kits &&
												(cell.row.original as any).kits.length ? (
													<button
														onClick={() =>
															openModal((cell.row.original as any)._id)
														}
													>
														VER
													</button>
												) : null}
											</div>
										) : (
											<p>
												{cell.render('Cell', {
													isExpanded: haveSubRows
												})}
											</p>
										)}
										<ReactTooltip
											id={cell.column.id !== 'idContract' ? cell.column.id : ''}
											type='dark'
											isCapture={true}
										/>
									</div>
								)
							})}
						</div>
					)
				})}
			</main>
		)
	}

	function renderTableFooter(): JSX.Element {
		return (
			<div className='table-footer'>
				<span className='result-span'>
					Resultados, {page.filter((e: any) => e?.depth === 0).length} de{' '}
					{totalDocs}
				</span>

				{showPaginate ? (
					<div className='pagination'>
						<button
							className='button'
							onClick={() => callPreviousPage(pageIndex - 1)}
							disabled={!canPreviousPage}
						>
							Anterior
						</button>

						<div className='page-numbers'>
							{getVisiblePages(pageIndex + 1, pageCount).map(
								(x, index, arr) => {
									return (
										<span
											key={index}
											className={`page-number ${
												x - 1 === pageIndex ? 'active' : ''
											}`}
											onClick={() => callGoToPage(x - 1)}
										>
											{formatPagination(x, index, arr)}
										</span>
									)
								}
							)}
						</div>
						<button
							className='button'
							onClick={() => callNextPage(pageIndex + 1)}
							disabled={!canNextPage}
						>
							Próxima
						</button>
					</div>
				) : null}
			</div>
		)
	}

	return (
		<>
			{isLoading && (
				<LoadingContent>
					<Loading type='Sentry' primaryColor={colors.secondary} />
				</LoadingContent>
			)}
			{!data.length && !isLoading && (
				<DefaultPagePlaceholder text={noItensText} />
			)}

			{data.length && !isLoading ? (
				<TableWrapper className={className}>
					{renderTableHeader()}
					<section {...getTableProps()}>
						{renderSectionHeader()}
						{renderSectionTotal()}
						{renderSectionMain()}
					</section>
					{renderTableFooter()}
				</TableWrapper>
			) : null}
		</>
	)
}

export default TableFinance
