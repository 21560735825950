import React from 'react'
import { Button, Loading, Select } from '@buildbox/components'

import routesEnum from 'modules/Routes/enum'
import GoBack from 'shared/components/GoBack'
import PageTitle from 'shared/components/PageTitle'
import ModalDelete from 'shared/components/ModalDelete'
import ViewErrors from 'shared/components/ViewErrors'
import {
	colors,
	primaryButtonStyle,
	secondaryButtonStyle,
	selectStyle
} from 'shared/styles/theme'

import { IViewProps, textButton, titleScreen } from './types'
import { KitWrapper, LoadingContainer } from './styles'
import { Column, Row } from 'shared/styles'
import infoCircle from '../../assets/images/info-circle.svg'
import { formatDate } from 'shared/util/format'
import AuthModal from 'shared/components/AuthModal'

function UnitCreateAndEditView(props: IViewProps): JSX.Element {
	const {
		action,
		targetKit,
		viewErrors,
		enableBtnSubmit,
		isLoading,
		handleSubmit,
		currentKit,
		contractsOptions,
		handleDeleteKitEnd,
		unitsOptions,
		renderModel,
		onDisassociateKit,
		handleDeleteOnClose,
		contractSelected,
		unitSelected,
		handleSelectChange,
		kitIsActive,
		renderButtonDelete,
		authModalConfig,
		handleSendSubmit
	} = props

	return (
		<>
			<KitWrapper className='animated-right'>
				<GoBack routeName={routesEnum.KITS} text='Todos os Kits' />

				<PageTitle>{titleScreen[action]}</PageTitle>

				{action === 'EDIT' && (
					<div className='box-modelKit'>
						{renderModel('Modelo do Kit', currentKit.deviceModel)}
						{renderModel('UID do Kit', currentKit.deviceID)}
						{renderModel('SIM Card', currentKit.simCardID)}
						{renderModel(
							'Tipo de Câmera',
							currentKit?.cameraType || 'Não informado'
						)}
					</div>
				)}

				<div className='box-contracts-units'>
					<div className='title'>
						<h2>
							{currentKit.status === 'ACTIVE' ? 'Associação' : 'Associar Kit'}
						</h2>
					</div>

					{currentKit?.activatedAt && currentKit.status === 'ACTIVE' ? (
						<div className='box-associated'>
							<img src={infoCircle} alt='Date associated' />
							<figure>
								Kit associado em {formatDate(new Date(currentKit?.activatedAt))}
							</figure>
						</div>
					) : null}

					<Row>
						<Column sm='12' lg='6'>
							<Select
								id='contracts'
								className='select-contract'
								placeholder='Contratos'
								options={contractsOptions}
								value={contractSelected}
								onChange={(e) => handleSelectChange(e, 'contract')}
								isDisabled={kitIsActive}
								{...selectStyle}
							/>
						</Column>

						<Column sm='12' lg='6'>
							<Select
								id='units'
								className='select-unit'
								placeholder='Unidade'
								options={unitsOptions}
								value={unitSelected}
								isDisabled={!contractSelected?.value || kitIsActive}
								onChange={(e) => handleSelectChange(e, 'unit')}
								{...selectStyle}
							/>
						</Column>
					</Row>
				</div>

				<ViewErrors errors={viewErrors} color='red' />

				{isLoading ? (
					<LoadingContainer>
						<Loading type='Sentry' primaryColor={colors.secondary} />
					</LoadingContainer>
				) : (
					<div className='btn-save'>
						{renderButtonDelete()}
						{currentKit.status === 'ACTIVE' ? (
							<Button
								className='btn btn-disassociate'
								onClick={onDisassociateKit}
								{...secondaryButtonStyle}
							>
								Desassociar Kit
							</Button>
						) : (
							<Button
								className='btn'
								onClick={handleSendSubmit}
								disabled={!enableBtnSubmit || isLoading}
								{...primaryButtonStyle}
							>
								{textButton[action]}
							</Button>
						)}
					</div>
				)}
			</KitWrapper>

			<AuthModal {...authModalConfig} />

			<ModalDelete
				onClose={handleDeleteOnClose}
				target={targetKit}
				type={
					targetKit?.type === 'DISASSOCIATE'
						? 'KIT_DISASSOCIATE'
						: targetKit?.type === 'DELETE'
						? 'KIT_DELETE'
						: 'KIT_ASSOCIATE'
				}
				handleDeleteUserEnd={handleDeleteKitEnd}
			/>
		</>
	)
}

export default UnitCreateAndEditView
