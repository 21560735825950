import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { IProfile } from 'shared/interfaces/profile'
import { ProfileNameEnum } from 'shared/interfaces/user'
import { friendlyRole } from './friendlyRole'

export function profilePermissions(profileSelected: string): ProfileNameEnum[] {
	if (profileSelected === friendlyRole['ADMIN']) {
		return [
			'ADMIN',
			'FINANCE',
			'CONTRACT_ADMIN',
			'UNIT_MANAGER',
			'OPERATOR',
			'PHYSICIAN',
			'COMMERCIAL_PARTNER'
		]
	} else if (profileSelected === friendlyRole['CONTRACT_ADMIN']) {
		return ['UNIT_MANAGER', 'OPERATOR']
	} else if (profileSelected === friendlyRole['UNIT_MANAGER']) {
		return ['UNIT_MANAGER', 'OPERATOR', 'PHYSICIAN']
	} else {
		return []
	}
}

export function useSelectedProfileIsOneOf(
	acceptedProfileNames: ProfileNameEnum[]
) {
	const { profile, user } = useTypedSelector(['profile', 'user'])

	const userProfiles = user.profiles.map(
		(userProfile) => userProfile.profile as IProfile
	)

	const selectedProfile = userProfiles.find(
		(userProfile) => userProfile._id === profile.profileId
	)

	const selectedProfileName = selectedProfile?.name || ''

	return acceptedProfileNames.some(
		(possibleProfileName) => possibleProfileName === selectedProfileName
	)
}
