import React from 'react'
import PageTitle from 'shared/components/PageTitle'
import { FinanceHeader, FinanceWrapper } from './styles'
import { IViewProps } from './types'
import { InputCalendar } from 'shared/components/InputCalendar'
import { SelectMonth } from 'shared/components/SelectMonth'
import TableFinance from 'shared/components/TableFinance'
import InputSearchPage from 'shared/components/InputSearchPage'
import { primaryInputStyle, selectStyle } from 'shared/styles/theme'
import ModalKitsLogs from './ModalKitsLogs'
import { Select } from '@buildbox/components'
import { CUSTOMER_SELECT_TYPE } from 'shared/util/Consts'
import { formatSelectOption } from 'shared/util/format'
import { Column, Row } from 'shared/styles'

function FinancePage(props: IViewProps): JSX.Element {
	const {
		getMonthValue,
		handleOnChange,
		handleOnDismiss,
		isVisible,
		monthYear,
		range,
		showMonthPicker,
		navProps,
		tableColumns,
		tableRows,
		sortBy,
		handlerSortColumn,
		periodSelected,
		handleSearch,
		search,
		tableTotal,
		handleModal,
		onClose,
		modalIsOpen,
		kitLog,
		handleDowloadExamData,
		isCommercialPartnerProfile,
		financialStatus,
		handleSelectChange,
		customerType
	} = props

	return (
		<>
			<FinanceWrapper className='animated-left'>
				<FinanceHeader>
					<PageTitle>Financeiro</PageTitle>

					<div className='inputs'>
						<Select
							id='client-type'
							className='select-client-type'
							placeholder='Tipo de Cliente'
							label='Tipo de Cliente'
							options={[
								{ label: 'Todos', value: 'ALL' },
								...CUSTOMER_SELECT_TYPE
							]}
							value={formatSelectOption(customerType)}
							onChange={handleSelectChange}
							{...selectStyle}
						/>
						<InputSearchPage
							id='search'
							label='Buscar contrato'
							onChange={(e: any) => handleSearch(e.target.value)}
							value={search}
							{...primaryInputStyle}
						/>
						<div className='select-calendar'>
							<InputCalendar
								id='select-period'
								label='Filtrar por Mês'
								value={getMonthValue()}
								onChange={() => showMonthPicker()}
								onFocus={() => showMonthPicker()}
							/>

							{isVisible && (
								<SelectMonth
									range={range}
									isVisible={isVisible}
									year={monthYear.year}
									month={monthYear.month}
									calendarSelected={monthYear}
									handleOnChange={handleOnChange}
								/>
							)}
						</div>
					</div>

					{isVisible && (
						<div className='background' onClick={handleOnDismiss} />
					)}
				</FinanceHeader>

				<TableFinance
					columns={tableColumns as any}
					data={tableRows}
					total={tableTotal}
					navProps={navProps}
					pageSize={tableRows.length}
					padding={[26, 16, 26, 16]}
					noItensText='No momento, não há Dados financeiros para serem exibidos.'
					isLoading={financialStatus === 'loading'}
					separate={true}
					getSelectedColumnAcessorAndOrder={handlerSortColumn}
					sortBy={sortBy}
					periodSelected={periodSelected}
					showPaginate={false}
					handleModal={handleModal}
					handleDowloadExamData={handleDowloadExamData}
					hiddenDowloadExam={isCommercialPartnerProfile()}
				/>
			</FinanceWrapper>
			<ModalKitsLogs
				onClose={onClose}
				isActive={modalIsOpen}
				kit={kitLog}
				period={monthYear}
			/>
		</>
	)
}

export default FinancePage
