import React from 'react'
import RDP from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import {
	addHours,
	endOfToday,
	getUnixTime,
	startOfToday,
	subHours,
	format,
	isPast,
	isValid,
	parse,
	parseISO,
	differenceInDays,
	addMonths
} from 'date-fns'
import cogoToast from 'cogo-toast'

import BR from 'date-fns/locale/pt-BR'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import iconCalendar from '../../../assets/images/calendar.svg'

import { IProps } from './types'
import { DayPickerWrapper } from './styles'
import {
	monthNamesArray,
	weekNamesLong,
	weekNamesShort
} from 'shared/util/date'
import cogoDefaultOptions from 'shared/util/toaster'

function DatePicker({
	dateSetter,
	value,
	datePickerTitle,
	setShowDates
}: IProps) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars

	let dayPicker: DayPickerInput

	const FORMAT = "dd 'de' LLLL yyyy"

	// const FORMAT = 'dd MM yyyy'

	const locale = { locale: BR }

	function parseToDisplay(date: Date) {
		if (!date) return 'DD/MM/YY'

		return format(date, 'dd/MM/yy', { locale: BR })
	}

	function handleDayChange(date: Date): void {
		const range = RDP.DateUtils.addDayToRange(date, value)

		if (!range.from || !range.to) return

		const addThreeMonths = addMonths(range.from, 3)

		const diffInDays = differenceInDays(addThreeMonths, range.to)

		if (diffInDays < 0) {
			cogoToast.error(
				'Data final alterada para intervalo máximo de 3 meses',
				cogoDefaultOptions
			)

			dateSetter({ to: addThreeMonths, from: range.from })
			return
		}

		dateSetter(range as any)

		// Hide Overlay When Selected Two
		// if (range.from && range.to) {
		// 	dayPicker.hideDayPicker()
		// }
	}

	function handleFormatDate(date: Date): string {
		if (!date || !isValid(date)) return ''

		return `${parseToDisplay(from)} até ${parseToDisplay(to)}`
	}

	function handleParseDate(date: string): Date | void {
		if (!date || !isValid(parseISO(date))) return

		if (isPast(parseISO(date))) return

		return parse(date, FORMAT, new Date(), locale)
	}

	function disabledFuture(from: any) {
		// if (!from) return new Date()

		// const differentInDays = differenceInDays(endOfDay(new Date()), from)

		// if (differentInDays < 60) return addDays(value.from, differentInDays)

		// return addDays(value.from, 60)

		return new Date()
	}
	const disabledDays = {
		// before: subDays(value.to, 60),
		after: disabledFuture(value.from)
	}

	const { from, to } = value
	const modifiers = { start: from, end: to }
	const dayPickerProps = {
		months: monthNamesArray,
		weekdaysLong: weekNamesLong,
		weekdaysShort: weekNamesShort,
		disabledDays,
		renderDay: (date: Date) => <p>{date.getDate()}</p>,
		renderWeek: (weekNumber: number) => <p>{weekNamesLong[weekNumber]}</p>,
		modifiers: modifiers,
		selectedDays: [from, { from, to }]
	}

	return (
		<DayPickerWrapper>
			<div className='date-picker-primary'>
				<p className='label'>PERÍODO</p>
				<DayPickerInput
					ref={(ref) => ref && (dayPicker = ref)}
					onDayChange={handleDayChange}
					value={`${parseToDisplay(from)} até ${parseToDisplay(to)}`}
					formatDate={handleFormatDate}
					parseDate={handleParseDate}
					placeholder={'Período'}
					onDayPickerHide={() => setShowDates(false)}
					onDayPickerShow={() => setShowDates(true)}
					inputProps={{ readOnly: true }}
					dayPickerProps={dayPickerProps}
					hideOnDayClick={false}
				/>
				<figure className='icon-calendar'>
					<img src={iconCalendar} alt='Icon Calendar' />
				</figure>
			</div>
		</DayPickerWrapper>
	)
}

export default DatePicker
