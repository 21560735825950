import React, { useState } from 'react'
import { IPropView } from './types'
import chevronLeft from '../../../assets/images/chevron-left.svg'
import chevronRight from '../../../assets/images/chevron-right.svg'

import { MonthYearPicker } from './styles'
import { ALL_MONTH } from 'shared/util/getLabelMonth'

export const SelectMonth = (props: IPropView) => {
	const { range, year, month, handleOnChange, isVisible, calendarSelected } =
		props

	const [yearText, setYearText] = useState(year)
	const monthSelected = calendarSelected.year === yearText

	function incYear() {
		if (range.max.year <= yearText) return
		setYearText(yearText + 1)
	}

	function decYear() {
		if (range.min.year >= yearText) return
		setYearText(yearText - 1)
	}

	function handleOnChangeMonth(yearSelected: number, index: number) {
		if (index >= range.max.month && yearSelected >= range.max.year) return
		if (index < range.min.month && yearText <= range.min.year) return

		handleOnChange(yearSelected, index)
	}

	function handleStyle(index: number) {
		if (monthSelected && month === index) {
			return 'month-selected'
		} else if (index >= range.max.month && yearText >= range.max.year) {
			return 'month-disabled'
		} else if (index < range.min.month && yearText <= range.min.year) {
			return 'month-disabled'
		}
		return ''
	}
	return (
		<MonthYearPicker>
			<div className={isVisible ? 'animated-top' : 'hidden'}>
				<header>
					<h1>{yearText}</h1>

					<div>
						<button onClick={decYear}>
							<img src={chevronLeft} alt='' />
						</button>

						<button onClick={incYear}>
							<img src={chevronRight} alt='' />
						</button>
					</div>
				</header>
				<ul>
					{ALL_MONTH.map((monthItem, index) => (
						<li
							key={`${index} - ${monthItem}`}
							className={handleStyle(index)}
							onClick={() => handleOnChangeMonth(yearText, index)}
						>
							{monthItem}
						</li>
					))}
				</ul>
			</div>
		</MonthYearPicker>
	)
}
