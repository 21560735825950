import React from 'react'
import { FloatLabelInputWrapper } from './styles'
import { IFloatLabelDateInputProps } from './types'

import NumberFormat from 'react-number-format'
import { FloatLabelInput } from '@buildbox/components'

function FloatLabelDateInput({
	label,
	id,
	className,
	setDate,
	date,
	...rest
}: IFloatLabelDateInputProps) {
	return (
		<FloatLabelInputWrapper className={className ? className : ''}>
			<NumberFormat
				className='fli-input'
				id={id}
				required
				format='##/##/####'
				mask='_'
				label={label}
				value={date}
				{...rest}
				customInput={FloatLabelInput}
			/>
		</FloatLabelInputWrapper>
	)
}

export default FloatLabelDateInput
