import { Creators as TokenActions } from '../store/ducks/token'
import { Creators as UserActions } from '../store/ducks/user'
import { Creators as FilterActions } from '../store/ducks/filter'
import { Creators as ProfileActions } from '../store/ducks/profile'

import { store } from '../store'

export function logout() {
	store.dispatch(TokenActions.resetToken())
	store.dispatch(UserActions.resetUser())
	store.dispatch(FilterActions.resetFilter())
	store.dispatch(ProfileActions.resetProfile())
}
