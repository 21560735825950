export function onlyUnique(value: any, index: number, self: any) {
	return self.indexOf(value) === index
}

export function sortBy(a: string, b: string) {
	if (!a || !b) return 0

	if (a.toLowerCase() > b.toLowerCase()) {
		return 1
	}
	if (a.toLowerCase() < b.toLowerCase()) {
		return -1
	}
	return 0
}

export function sortByNumber(a: number, b: number) {
	if (!a || !b) return 0

	if (a > b) return 1

	if (a < b) return -1

	return 0
}
