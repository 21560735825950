export const successMessages = {
	passwordUpdated: 'Senha alterada com sucesso!',
	passwordResetEmailSent: 'Enviamos um link de redefinição de senha para: ',
	unitCreated: 'Unidade cadastrada com sucesso!',
	unitUpdated: 'Unidade atualizada com sucesso!',
	unitDeleted: 'Unidade excluída com sucesso!',
	userCreated: 'Usuário cadastrado com sucesso!',
	userUpdated: 'Usuário atualizado com sucesso!',
	userDeleted: 'Usuário excluído com sucesso!',
	contractCreated: 'Contrato cadastrado com sucesso!',
	contractUpdated: 'Contrato atualizado com sucesso!',
	contractDeleted: 'Contrato excluído com sucesso!',
	kitDesassociated: 'Kit desassociado com sucesso!',
	kitAssociated: 'Kit associado com sucesso!',
	kitDeleted: 'Kit excluído com sucesso!',
	examUpdated: 'Exame atualizado com sucesso!'
}

export const errorMessages = {
	serverUnavailable: 'Servidor indisponível...',
	CNPJFilledCorrectly: 'CNPJ deve ser válido',
	PostalCodeFilledCorrectly: 'CEP deve ser válido',
	PhoneFilledCorrectly: 'Telefone deve ser válido',
	extensionFile: 'Extensão do arquivo é inválida',
	contractNotCreated: 'Não foi possivel cadastrar o contrato',
	contractNotUpdated: 'Não foi possivel atualizar o contrato',
	userHasNoAccess: 'Usuário não possui acesso ao portal.',
	examNotUpdated: 'Não foi possivel atualizar o exame',
	crmIsRequired:
		'Este campo é obrigatório caso o usuário possua um perfíl de médico'
}

export const viewErrorContract = {
	uploadSizeExceeded:
		'O arquivo que você está anexando é maior do que o permitido pelo servidor.',
	CNPJFilledCorrectly: 'CNPJ deve ser válido.',
	invalidDate: 'Insira uma data válida.',
	sizeMinDuration: 'Tempo de contrato no mínimo 1 mês.',
	customerType: 'Preencha o campo de "Tipo de Cliente".'
}
