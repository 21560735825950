import { fontType, toRem } from 'shared/styles/functions'
import styled from 'styled-components'

export const ExamsPageWrapper = styled.div`
	.tableContainer {
		margin-top: ${toRem(50)};
		${fontType(0)}

		.icon {
			cursor: pointer;
			border: none;
			display: flex;
			margin: auto;
			background: transparent;
			min-width: 16px;
		}

		tbody tr td .diagnosis-pending {
			background: #fff2c3;
			color: #e3b91b;
			padding: ${toRem(3)} ${toRem(8)};
			border-radius: ${toRem(10)};
		}
	}
`
export const HeaderContainer = styled.header`
	display: flex;
	align-items: center;
	justify-content: space-between;

`
export const InputSearchWrapper = styled.div`
	min-width: 273px;
	position: relative;
	.search-icon {
		position: absolute;
		right: 20px;
		top: 17px;
	}
`
