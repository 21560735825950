import { useDispatch } from 'react-redux'

import { formatPermissionsAndProfiles } from 'shared/util/format'
import { useTypedSelector } from './useTypedSelector'
import { Creators as UserActions } from 'shared/store/ducks/user'
import { useEffect } from 'react'
import { useUserProfiles } from 'shared/services/user.service'

export default function useUpdateUserProfiles() {
	const dispatch = useDispatch()
	const { user } = useTypedSelector(['user'])
	const { status, data } = useUserProfiles({ userId: user._id })

	function updateUserProfile() {
		if (!data?.length || status === 'error') return

		const { permissions, profiles } = formatPermissionsAndProfiles(data)

		if (!permissions.length || !profiles.length) return

		dispatch(
			UserActions.setUser({
				...user,
				permissions,
				profiles
			})
		)
	}

	useEffect(updateUserProfile, [data])
}
