import React, { useEffect, useRef, useState } from 'react'
import { Column, Row } from 'shared/styles'
import { Select } from '@buildbox/components'
import { colors, selectStyle } from 'shared/styles/theme'
import addLine from '../../../assets/images/add-line.svg'
import removeLine from '../../../assets/images/remove-line.svg'
import { IProps } from './types'
import { ProfileAndAccess } from '../types'
import CompositeMenu from 'shared/components/CompositeMenu'
import { IMenu, IMenuOption } from 'shared/components/MenuPrimary/types'
import chevronDown from '../../../assets/images/chevron-down.svg'
import { clone } from 'ramda'
import { friendlyRole } from 'shared/util/friendlyRole'
import { mappedFilterSelected, transformMenu } from 'shared/util/format'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { buildingOptionsAccordingToProfile } from 'shared/util/options'

import { SwitchMenu } from 'shared/components/SwitchMenu'

function AccessProfile(props: IProps): JSX.Element {
	const {
		profileOptions,
		index,
		addAccessProfile,
		profileAndAccess,
		removeAccessProfile,
		onUpdate,
		action,
		dataSize,
		dataUser
	} = props

	const [filterSearch, setFilterSearch] = useState('')
	const [allSelected, setAllSelected] = useState(false)
	const [isOpened, setOpened] = useState({ index: 0, isOpen: false })
	const [options, setOptions] = useState<IMenu[]>([])
	const [optionsSelected, setOptionsSelected] = useState<
		IMenu[] | IMenuOption[]
	>([])
	const selectRef = useRef<HTMLDivElement>(null)
	const { filter, user, profile } = useTypedSelector([
		'filter',
		'user',
		'profile'
	])

	const [checkedAllContracts, setCheckedAllContracts] = useState(false)

	function disabledSelectProfile() {
		const isDisabled: any = profileAndAccess.profile

		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		return isDisabled.isDisabled
	}

	function disabledSelectAccess() {
		if (isAdminOrFinance() || !profileAndAccess.profile.value) return true
		const isDisabled: any = profileAndAccess.profile

		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		return isDisabled.isDisabled
	}

	useOutsiteAlerter(selectRef)

	function useOutsiteAlerter(ref: React.RefObject<HTMLDivElement>) {
		useEffect(() => {
			function handleClickOutside(event: any) {
				if (
					ref.current &&
					!ref.current.contains(event.target) &&
					isOpened.isOpen
				) {
					setOpened({
						index,
						isOpen: false
					})
				}
			}

			// Bind the event listener
			document.addEventListener('mousedown', handleClickOutside)
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener('mousedown', handleClickOutside)
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [ref, isOpened.isOpen])
	}

	function cleanAllSelected() {
		// const copied = clone(filter)
		cleanItem(options)
		setOptions(options)
		setAllSelected(false)
	}

	const ALL_SELECTED = (label?: string) => [
		{
			expanded: false,
			label: label ? label : 'Todos',
			options: [],
			selected: true,
			visible: true,
			value: ''
		}
	]

	function isAllSelected() {
		setOptionsSelected(ALL_SELECTED())
	}

	function isAdminOrFinance() {
		const label = profileAndAccess.profile.label

		return [friendlyRole['FINANCE'], friendlyRole['ADMIN']].includes(label)
	}

	function isOperadorOrUnitManagerOrPhysician() {
		const label = profileAndAccess.profile.label

		return [
			friendlyRole['OPERATOR'],
			friendlyRole['UNIT_MANAGER'],
			friendlyRole['PHYSICIAN']
		].includes(label)
	}

	function handleUnitsSelected(onlySelected: IMenu[]): IMenu[] | IMenuOption[] {
		if (isOperadorOrUnitManagerOrPhysician()) {
			const mappedUnits: IMenuOption[] = onlySelected
				.map((contract) => {
					if (contract.generalPermission) {
						return {
							label: `${contract.label} + Novos`,
							value: contract.value
						} as IMenuOption
					}
					return contract.options.map((unit) => {
						return {
							value: unit.value,
							label: unit.label,
							selected: false
						} as IMenuOption
					})
				})
				.flat()

			return mappedUnits
		}

		return onlySelected
	}

	function getContracts(copied: IMenu[]) {
		if (!profileAndAccess.profile.value) {
			setOptions([])
			setOptionsSelected([])
			return
		}

		if (action === 'CREATE') {
			//clean all selected
			cleanAllSelected()
			return
		}

		const getUnits = profileAndAccess?.contracts
			?.map((contract) => contract.units.map((units) => units.id))
			.flat()

		const getContracts = profileAndAccess?.contracts?.map(
			(contract) => contract.id
		)

		if (isAdminOrFinance()) {
			setOptionsSelected([])
			setOptions([])

			return
		}

		//MONTANDO O MENU DE OPÇÕES DE ACESSO
		copied.forEach((contract) => {
			contract.selected = !!getContracts?.includes(contract.value)

			if (contract.options) {
				contract.options.forEach((unit) => {
					unit.selected = !!getUnits?.includes(unit.value)
				})

				//MENU OPTIONS - PERFIL ADMIN - OPERATOR - PHYSICIAN
				if (isOperadorOrUnitManagerOrPhysician()) {
					const isAllUnitsSelected = contract.options.every(
						(selected) => selected.selected
					)

					const allUnitsSelected =
						contract.options.length > 0 && isAllUnitsSelected

					if (allUnitsSelected) {
						contract.selected = true
					} else {
						contract.selected = false
					}

					//PERFIL COM PERMISSÃO TOTAL DO CONTRATO
					const getAllPermission =
						profileAndAccess?.contracts !== null &&
						profileAndAccess.contracts.filter(
							(contract) => contract.units.length === 0
						)

					if (
						getAllPermission &&
						getAllPermission.length > 0 &&
						!!getContracts?.includes(contract.value)
					) {
						contract.options.forEach((unit) => {
							if (
								getAllPermission.find(
									(findContract) => findContract.id === contract.value
								)
							) {
								contract.generalPermission = true
								unit.selected = true
								contract.selected = true
							}
						})
					}

					return
				}

				//MENU OPTIONS - PERFIL CONTRACT_ADMIN
				//PERFIL TOTAL DE TODOS OS CONTRATOS
				if (
					isContractAdminCommercialPartner() &&
					profileAndAccess.contracts?.length === 0
				) {
					contract.selected = true
					return
				}
			}
		})

		const options: IMenu[] = handleOptions(copied) || []

		//CONTRACT_ADMIN - SE O PERFIL FOR ACESSO A TODOS OS CONTRATOS
		//SETA O CHECKBOX 'TODOS' PARA TRUE
		if (
			isContractAdminCommercialPartner() &&
			profileAndAccess.contracts?.length === 0
		) {
			setAllSelected(true)
			setCheckedAllContracts(true)
			return
		}

		const onlySelected = mappedFilterSelected(options)

		const unitsAllSelected = options.every(
			(e) => e.selected && e.options.every((e) => e.selected)
		)

		if (unitsAllSelected) {
			setAllSelected(unitsAllSelected)
			isAllSelected()

			return
		}

		// if (!unitsAllSelected) {
		// 	setAllSelected(false)
		// }

		const getUnitsSelect = handleUnitsSelected(onlySelected)

		setOptionsSelected(getUnitsSelect)
	}

	function handleOptions(copied: IMenu[]) {
		if (!profileAndAccess.profile.value) {
			setOptions([])
			setOptionsSelected([])
			return [] as IMenu[]
		}

		const profileName = profileAndAccess.profile.label

		const getOptions = buildingOptionsAccordingToProfile(
			profileName,
			copied,
			'USER'
		)

		setOptions(getOptions)
		return getOptions
	}

	function cleanValues() {
		setOptionsSelected([])
		cleanAllSelected()
	}

	function isContractAdminCommercialPartner() {
		const label = profileAndAccess.profile.label
		return (
			label === friendlyRole['CONTRACT_ADMIN'] ||
			label === friendlyRole['COMMERCIAL_PARTNER']
		)
	}

	const updateHandler = (data: Partial<ProfileAndAccess>) => {
		if (data.profile?.value !== profileAndAccess.profile.value) {
			data.contracts = null

			onUpdate(index, {
				...profileAndAccess,
				...data
			})
			cleanValues()

			return
		}
		onUpdate(index, { ...profileAndAccess, ...data })
	}

	function getSearchUpdates(searchText: string) {
		setFilterSearch(searchText)
	}

	function getAllSelectedUpdates(value: boolean) {
		setAllSelected(value)
		value && isAllSelected()
	}

	function getFilterUpdates(menus: IMenu[]) {
		const copied = clone(menus)

		if (isOperadorOrUnitManagerOrPhysician()) {
			copied.forEach((menu) => {
				if (!menu.selected) menu.generalPermission = false
			})
			setOptions(copied)
		} else {
			setOptions(menus)
		}
		const onlySelected = mappedFilterSelected(copied)

		const isAllSelected = copied.every(
			(e) => e.selected && e.options.every((e) => e.selected)
		)

		if (isAllSelected) {
			// setAllSelected(isAllSelected)
		} else {
			// setAllSelected(false)
			setCheckedAllContracts(false)
			const getUnitsSelect = handleUnitsSelected(onlySelected)
			setOptionsSelected(getUnitsSelect)
		}

		const contractsAndUnitsSelected = transformMenu(copied)

		if (isOperadorOrUnitManagerOrPhysician()) {
			const getAllGeneralPermission = copied.filter(
				(contract) => contract.generalPermission && contract.selected
			)

			const onlyIds = getAllGeneralPermission.map((contract) => contract.value)

			const getAllUnitsSelectedExceptGeneralPermission =
				contractsAndUnitsSelected.filter(
					(contract) => !onlyIds.includes(contract.id)
				)

			const mappedArray = getAllGeneralPermission.map((menu) => {
				return {
					id: menu.value,
					units: []
				}
			})

			const concatContracts = [
				...mappedArray,
				...getAllUnitsSelectedExceptGeneralPermission
			]

			updateHandler({
				contracts: concatContracts,
				profile: profileAndAccess.profile
			})

			return
		}

		if (
			isContractAdminCommercialPartner() &&
			contractsAndUnitsSelected.length <= 0
		) {
			updateHandler({
				contracts: null,
				profile: profileAndAccess.profile
			})
			return
		}
		updateHandler({
			contracts: contractsAndUnitsSelected,
			profile: profileAndAccess.profile
		})
	}

	function openFilters() {
		setOpened({
			index,
			isOpen: true
		})
	}

	function labelSwitch() {
		return isOperadorOrUnitManagerOrPhysician()
			? 'Incluir unidades novas do contrato.'
			: 'Incluir contratos novos.'
	}

	const userFilterStyles = {
		width: '98%',
		maxHeight: 300,
		top: 98,
		left: 8,
		optionsHeight: 48,
		checkboxSize: 16,
		checkboxLevel: 1,
		checkboxPrimaryColor: colors.secondary,
		checkboxSecondaryColor: colors.checkboxSecondary,
		checkboxBackgroundColor: colors.pageBackground,
		backgroundColor: colors.lightGrey,
		menuBackgroundColor: colors.backgroundMenu,
		color: colors.darkGrey,
		fontSize: 12,
		fontFamily: 'Regular'
	}

	function renderButtonAddProfile() {
		if (index !== dataSize - 1) return null
		return (
			<>
				{profileOptions.length <= 0 ? null : (
					<div className='btn-plus'>
						<button className='btn-clean ' onClick={addAccessProfile}>
							<img src={addLine} alt='Add line' />
						</button>
					</div>
				)}
			</>
		)
	}

	function renderButtonRemoveProfile() {
		const isSameUser = dataUser.personalFiscalID === user.personalFiscalID
		const profileIsAdmin = profileAndAccess.profile.label === friendlyRole.ADMIN
		const userIsAdmin =
			profileIsAdmin && profile.profileId === profileAndAccess.profile.value
		if ((isSameUser && userIsAdmin && profileIsAdmin) || dataSize <= 1)
			return null
		return (
			<>
				{disabledSelectProfile() ? null : (
					<button
						className='btn-clean btn-list'
						onClick={(e) => removeAccessProfile(index, e)}
					>
						<img src={removeLine} alt='Remove line' />
					</button>
				)}
			</>
		)
	}

	function cleanItem(menus: IMenu[]) {
		menus.forEach((item, index) => {
			item.selected = false
			item.options.forEach((item) => (item.selected = false))
		})
	}

	function setOptionsAccordingToSearch() {
		if (options.length <= 0) return

		if (!filter) {
			setOptions(options)
			return
		}

		const resultMenu = clone(options)
		const lowerCasefilterSearch = filterSearch.toLowerCase()

		resultMenu.forEach((menu) => {
			if (menu.label.toLowerCase().includes(lowerCasefilterSearch)) {
				menu.visible = true
				menu.options.forEach((option) => (option.visible = true))
			} else {
				menu.options.forEach((option) => {
					if (option.label.toLowerCase().includes(lowerCasefilterSearch))
						option.visible = true
					else option.visible = false
				})
				menu.visible = menu.options.some((option) => option.visible)
			}
		})

		setOptions(resultMenu)
	}

	// useEffect(getContracts, [])
	useEffect(() => {
		const copied = clone(filter)
		cleanItem(copied)

		if (action === 'CREATE') {
			handleOptions(copied)
			return
		}

		if (profileAndAccess?.contracts) {
			getContracts(copied)
			return
		}

		handleOptions(copied)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profileAndAccess.profile, filter])

	function handleCheckAllContracts(value: boolean) {
		setCheckedAllContracts(value)

		if (value && allSelected) {
			updateHandler({
				contracts: [],
				profile: profileAndAccess.profile
			})
			return
		}

		if (!value && !allSelected) {
			updateHandler({
				contracts: null,
				profile: profileAndAccess.profile
			})
			return
		}

		if (allSelected && !value) {
			setOptionsSelected(ALL_SELECTED())
		}

		const contractsAndUnitsSelected = transformMenu(options)

		updateHandler({
			contracts: contractsAndUnitsSelected,
			profile: profileAndAccess.profile
		})
	}
	useEffect(setOptionsAccordingToSearch, [filterSearch])

	return (
		<div className='box-access'>
			<Row>
				<Column sm='12' lg='4'>
					<h3>Perfil</h3>
					<Select
						id={`profile-${index}`}
						label='Selecione o Perfil'
						options={profileOptions}
						value={profileAndAccess.profile}
						placeholder={'Selecione o Perfil'}
						onChange={(value: any) => updateHandler({ profile: value })}
						isDisabled={disabledSelectProfile()}
						{...selectStyle}
					/>
				</Column>

				<Column sm='12' lg='8'>
					<h3>Acessos</h3>

					<>
						<div ref={selectRef} className='filter-contracts'>
							<div className='selected-container'>
								<Select
									id={`access-${index}`}
									className='select-contracts'
									placeholder='Contratos'
									onFocus={openFilters}
									value={
										isAdminOrFinance() || checkedAllContracts
											? ALL_SELECTED('Todos + Novos')
											: optionsSelected
									}
									isMulti
									noOptionsMessage={() => null}
									isSearchable={false}
									isDisabled={disabledSelectAccess()}
									{...selectStyle}
								/>

								<figure
									className={
										isOpened.isOpen ? 'active chevron-icon' : 'chevron-icon'
									}
								>
									<img src={chevronDown} alt='Chevron Down' />
								</figure>
							</div>
							{isOpened.isOpen ? (
								<CompositeMenu
									searchLabel={'Contratos'}
									searchValue={filterSearch}
									getSearch={getSearchUpdates}
									allSelectedValue={allSelected}
									getAllSelected={getAllSelectedUpdates}
									menuContent={options}
									getContentUpdate={getFilterUpdates}
									styles={userFilterStyles}
									showChevron={!isContractAdminCommercialPartner()}
									renderSwitch={
										isContractAdminCommercialPartner() && allSelected ? (
											<SwitchMenu
												label={labelSwitch()}
												checked={checkedAllContracts}
												setCheched={(value) => handleCheckAllContracts(value)}
											/>
										) : (
											(undefined as any)
										)
									}
									renderSwitchHeader={isOperadorOrUnitManagerOrPhysician()}
								/>
							) : null}
						</div>
					</>
				</Column>
			</Row>
			{renderButtonAddProfile()}
			{renderButtonRemoveProfile()}
		</div>
	)
}

export default AccessProfile
