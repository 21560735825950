import React from 'react'
import { IViewProps } from './types'
import { ResponsiveBar } from '@nivo/bar'
import { fontType } from 'shared/styles/functions'

function BarGraph({ data }: IViewProps) {
	return (
		<ResponsiveBar
			data={data}
			indexBy='label'
			colors={data.map(({ color }) => color)}
			borderColor={{ from: 'color', modifiers: [['darker', 0.7]] }}
			colorBy={'indexValue'}
			margin={{ top: 20, right: 20, bottom: 30, left: 50 }}
			borderWidth={1}
			padding={0.7}
			borderRadius={2}
			enableLabel={false}
			axisBottom={null}
			tooltipLabel={'indexValue'}
			theme={{ fontFamily: fontType(0) }}
			legends={[
				{
					dataFrom: 'indexes',
					anchor: 'bottom',
					direction: 'row',
					justify: true,
					translateX: -20,
					translateY: 20,
					itemsSpacing: 60,
					itemWidth: 90,
					itemHeight: 10,
					itemDirection: 'left-to-right',
					itemOpacity: 0.85,
					symbolSize: 0
				}
			]}
		/>
	)
}

export default BarGraph
